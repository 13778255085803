import TextField from "./TextField";
import styled from "styled-components/macro";
import _ from "lodash";

import { InputAdornment } from "@material-ui/core";

const MUITextFieldWithIcon = styled(TextField)`
  .MuiOutlinedInput-adornedEnd {
    padding-right: 3px;
  }
`;

export default function TextFieldWithIcon(props) {
  if (
    props.icon &&
    (props.value || _.get(props.formikProps.values, props.name))
  ) {
    return (
      <MUITextFieldWithIcon
        InputProps={{
          endAdornment: (
            <InputAdornment position={props.iconPosition || "end"}>
              {props.icon}
            </InputAdornment>
          ),
        }}
        {...props}
      />
    );
  }

  return <MUITextFieldWithIcon {...props} />;
}
