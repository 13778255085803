import { useRef, useEffect, useCallback } from "react";
import axios from "axios";
import { BASE_URL } from "../config/config";
import { useAlert, useLoader } from ".";

export default function useAxiosV2({
  baseURL = "adminV2",
  disableAuthToken = false,
} = {}) {
  const loader = useLoader();
  const alert = useAlert();

  const axiosInstance = useRef(axios.create());

  useEffect(() => {
    axiosInstance.current = axios.create({
      baseURL: BASE_URL[baseURL].url + BASE_URL[baseURL].version,

      validateStatus: (status) => status < 500,
    });
  }, [disableAuthToken, baseURL]);

  return useCallback(
    ({
      url,
      method = "GET",
      data,
      params,
      authToken,
      disableRedirect = false,
    }) =>
      axiosInstance
        .current({
          url: url,
          method: method,
          data: data,
          params: params,
          headers: {
            ...(!disableAuthToken && {
              Authorization: authToken || "",
            }),
            "content-type": "application/json",
          },
        })
        .then((response) => {
          if (response.status > 201 || !response.data.status) {
            alert.error(response.data.message || "Error loading data!");

            if (!disableRedirect) {
              loader.error();
            }
          }

          return response.data;
        }),
    [alert, disableAuthToken, loader]
  );
}
