import React from "react";

import { Grid, TextField, Divider, Button, MenuItem } from "@material-ui/core";

export default function FormDisplay({
  errors,
  handleBlur,
  handleChange,
  isSubmitting,
  touched,
  values,
  isValid,
}) {
  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TextField
            name="firstName"
            value={values.firstName}
            label="First Name"
            variant="outlined"
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="lastName"
            value={values.lastName}
            label="Last Name"
            variant="outlined"
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={9}>
          <TextField
            name="email"
            value={values.email}
            label="Email"
            disabled
            variant="outlined"
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            select
            name="gender"
            value={values.gender}
            label="Gender"
            variant="outlined"
            error={Boolean(touched.gender && errors.gender)}
            helperText={touched.gender && errors.gender}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          >
            <MenuItem value={"Male"}>Male</MenuItem>
            <MenuItem value={"Female"}>Female</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="password"
            name="password"
            value={values.password || ""}
            label="Password"
            variant="outlined"
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="password"
            name="confirmPassword"
            value={values.confirmPassword || ""}
            label="Confirm Password"
            variant="outlined"
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs />
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting || !isValid}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
