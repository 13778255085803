import React from "react";

import Dialog from "./Dialog";

export default function DeleteDialog({
  dialogState,
  setDialogState,
  handleAgree,
}) {
  return (
    <Dialog
      dialogState={dialogState}
      setDialogState={setDialogState}
      handleAgree={handleAgree}
      dialogContent={{
        title: "Confirm Delete",
        body: "You have selected the option to delete! Please confirm before going further.",
        dangerButton: "Delete",
      }}
    />
  );
}
