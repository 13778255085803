import { useSelector } from "react-redux";

export default function usePermissions(pageID) {
  const permissions = useSelector((state) => state.authReducer.pagePermissions);

  var permissionFound = permissions.find(
    (pagePermission) => pagePermission.pageID === pageID
  );

  if (permissionFound !== undefined) {
    return permissionFound;
  }

  return {
    components: [],
    create: false,
    update: false,
    read: false,
    delete: false,
  };
}
