const pageID = {
  analyticsPage: {
    dev: "58ec8d27cd864fa4b73c38bf572fc826",
    uat: "2fb19164ba2944649802dfec57d39f70",
    prod: "adabe114af5741408821a48f0e9c0df8",
  },
  businessListPage: {
    dev: "14b5da0e0b684c449e9244f8eeb1ca49",
    uat: "e5aecdf12166459ea41fcd9ec4fb4914",
    prod: "199fe6abc46f4eeabcaf0a330394f310",
  },
  businessDetailsPage: {
    dev: "7e2d1a6f73ef4df4983a2304c8ea060a",
    uat: "fe6cbdbd611a41fc825d339d4111d43a",
    prod: "07324aac4e39483db5fd00682622f241",
  },
  botServiceListPage: {
    dev: "9282e2eab2d14ae5a9e4068c266b1aee",
    uat: "2f8246f4f6a242fcbd91d2faf92a3893",
    prod: "123920e774694bcf8908f3171d5c8317",
  },
  botServiceDetailsPage: {
    dev: "86e4e2e87b9e4363a1c3ec84b72cc0ff",
    uat: "4cc40248890b4555afd190c90d0a3e44",
    prod: "5c2db8633ed7438692c4192632e51797",
  },
  botListPage: {
    dev: "3e9ec2a4440f4af8bd81f82c7dd3f78b",
    uat: "32fd953c20e246caa180e7f79ca74829",
    prod: "e22141b27ed84f8daf7b88e22375758a",
  },
  botDetailsPage: {
    dev: "2735f7bea3d24fbe9d15b2b47ce78b84",
    uat: "89e24bacf88147b1a6e19a6f13ee325b",
    prod: "02cc2340703e4055acebe7b5c6748181",
  },
  userListPage: {
    dev: "73ad35f0084a4f479e59696a3dec384a",
    uat: "e8afb7d80340477ab956b09cfa88dcaf",
    prod: "094c684b148d4e029f02937db0f5c42c",
  },
  conversationsPage: {
    dev: "c960a8ef949a420883ffbe49e6aab037",
    uat: "12171cdfb2e34bdb88873c80a6ff5d0f",
    prod: "e45bd9e86d26491ea7d575a44fd865f0",
  },
  superadminPageListPage: {
    dev: "d1485b5d4bd24ca9b771f1e2e2992d14",
    uat: "06d2a23c8f0b4001ac58a84f226aeb2b",
    prod: "d30ecd13a0b64ff3b420e72839701921",
  },
  superadminPageDetailsPage: {
    dev: "70c81f50e54f4c9e8785c8843eee31d6",
    uat: "c5cba07a71e4446db08c7117838b7946",
    prod: "4703d9aa77774ebfbf4edb964dc77567",
  },
  adminPageListPage: {
    dev: "2e78255348fc4b429bec96bc96844f07",
    uat: "81cb3e87a714408e88e5120762704080",
    prod: "6fca946a134545eb8349cddb62f4c7f0",
  },
  adminPageDetailsPage: {
    dev: "209b2ada721e436e8bcd375b4df9b942",
    uat: "9e25357bed7a4f7b9938be40ddb3b2d2",
    prod: "da5073c3493c420c9fc88adf3b04afdb",
  },
  memberListPage: {
    dev: "0e74760e27de4275b1704f20202aa9a2",
    uat: "3c8746e0f7784792af4bd907061a91d5",
    prod: "019a0aab69ff41d5bc21ebf84ab2b595",
  },
  memberDetailsPage: {
    dev: "17feae0574ac4d43a447352c1f6d4898",
    uat: "4fe3299ce9a2406790fe4414f33cafad",
    prod: "1f33cd043f8e43d5b378784be72aab5b",
  },
  groupListPage: {
    dev: "bfd123484eb84d078965c9cbb39a2e1e",
    uat: "5969389a966c4b7582c4f79d5d512981",
    prod: "f7cd5a4ee67d482ebdac3106ef460115",
  },
  groupDetailsPage: {
    dev: "43b0933a596740ad95d6575fb7a9eb3f",
    uat: "6b4419c2c03b4ac29e50483e7c24e6b2",
    prod: "0cad708a5de443f899b6b732b750f8b6",
  },
  testPage: {
    dev: "512e594f533947a5ac356c86c5b4f141",
    uat: "0e20a62d84e546f98441a60f124052ff",
    prod: "e06e888c23a9476bac0fcd0de12cfabe",
  },
  integrationListPage: {
    dev: "901d6144d7d64ebe9b200673ce274bbc",
    uat: "e85341870c3e43a7aa094e4438473310",
    prod: "7d4452ccaa9346fdb9885057a911cc2d",
  },
  integrationDetailsPage: {
    dev: "31e98139b43c4863b59200951c3ce433",
    uat: "488c69fa252c463c93dc878281f84ae1",
    prod: "6434b578d07146589da2d1fadaa5ddad",
  },
  integrationGroupListPage: {
    dev: "06364df84ae9466b814ee5eda2793e87",
    uat: "51359727843b4a52a0a2abe5ba16095b",
    prod: "0817738dd6d3452a9f2f8953f349773e",
  },
  integrationGroupDetailsPage: {
    dev: "cc49175a830946b89160dc3c0360e856",
    uat: "6e34503dc4b64d5c9c3ee6e886f284d0",
    prod: "6681df5a485f41fcab137a20b10b7827",
  },
  botIntegrationListPage: {
    dev: "1ea6369d0d734717b0d4433206458432",
    uat: "fa5afba8a13547e2bdd80aa4abd4936e",
    prod: "2d71ca91ef054e4cb1b3211ebfc17e30",
  },
  botIntegrationDetailsPage: {
    dev: "e0e716d5042c480281a068e86e1a8a8f",
    uat: "e259e5dba83f472d9e26d675daed85ef",
    prod: "12afc9e43e3b41f682464b45288e6a0b",
  },
  utteranceListPage: {
    dev: "16ccd437d97a4cbd9f4269c9ddd0ccb9",
    uat: "562b65aebd13498194bf4f2fc3947cf6",
    prod: "3867d6197e5c4ddcb9f404407c155998",
  },
  utteranceDetailsPage: {
    dev: "665044ef2e7c4e78a86fd1e1ae124f6d",
    uat: "dd9ca469c1c14591b42e8bb50e49f3c8",
    prod: "1e334b6b85284efb9cdc20617611db3a",
  },
  whatsappNumberListPage: {
    dev: "8fa6689039e24900aaa864c687cf8900",
  },
  whatsappNumberDetailsPage: {
    dev: "beaf199f2cb84c0491a7550b693aaab2",
  },
};

export default pageID;
