import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";

import storageSession from "redux-persist/lib/storage/session";

import auth from "./authReducer";
import themeReducer from "./themeReducer";
import alertReducer from "./alertReducer";
import loaderReducer from "./loaderReducer";

const persistConfig = {
  key: "user",
  storage: storageSession,
};

const authReducer = persistReducer(persistConfig, auth);

export const rootReducer = combineReducers({
  authReducer,
  themeReducer,
  alertReducer,
  loaderReducer,
});
