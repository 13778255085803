import { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { Paper, DeleteDialog } from "../custom components";
import { useHistory } from "react-router-dom";

export default function ButtonFooter({
  id,
  editState,
  setEditState,
  deletePermission,
  handleDelete,
  formikProps,
}) {
  const [dialogState, setDialogState] = useState(false);
  const history = useHistory();

  if (!(deletePermission || editState)) {
    return "";
  }

  return (
    <Paper>
      {Boolean(handleDelete && deletePermission && id) && (
        <Grid item>
          <Button
            variant="contained"
            style={{ background: "#d32f2f", color: "#fff" }}
            onClick={() => setDialogState(true)}
          >
            Delete
          </Button>
        </Grid>
      )}

      <Grid item xs />

      {Boolean(editState) && (
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={
              formikProps.dirty
                ? formikProps.isSubmitting || !formikProps.isValid
                : !formikProps.dirty
            }
            onClick={formikProps.handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      )}

      {Boolean(editState) && (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (!!id) {
                setEditState(false);
                formikProps.handleReset();
              } else {
                history.goBack();
              }
            }}
          >
            {!!id ? "Cancel" : "Back"}
          </Button>
        </Grid>
      )}

      <DeleteDialog
        dialogState={dialogState}
        setDialogState={setDialogState}
        handleAgree={handleDelete}
      />
    </Paper>
  );
}
