import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string().max(255).required("Integration name is required."),
  groupID: Yup.string().required("Please select the integration group name."),
  integrationStatus: Yup.string().required(
    "Please select the integration status."
  ),
  description: Yup.string().max(525).required("Description is required."),
});

export default validationSchema;
