import React, { useState } from "react";
import { Alert } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/Add";
import BackspaceIcon from "@material-ui/icons/Backspace";
import {
  Paper,
  TableContainer,
  TableHead,
  TextField,
  Button,
  Typography,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";

import axios from "../../../util/axios";

export default function AdminForm(props) {
  const [errorOnSubmit, setErrorOnSubmit] = useState({
    message: "",
    status: false,
  });

  const [formattedDate, setFormattedDate] = useState("");

  const [projectObj, setProjectObj] = useState({
    requirement: "",
    desc: "",
  });

  const [oneTimePricingObj, setOneTimePricingObj] = useState({
    channel: "",
    one_time_setup: "",
    monthly_recurring: "",
    per_notification: "",
  });

  const [reccuringPricingObj, setReccuringPricingObj] = useState({
    feature_name: "",
    feature_value: "",
  });

  function handleDateChange(event) {
    var originialDate = event.target.value;
    setFormattedDate(originialDate);
    var newDate =
      originialDate.split("-")[2] +
      "-" +
      originialDate.split("-")[1] +
      "-" +
      originialDate.split("-")[0];
    props.setCustomerDetails({
      ...props.customerDetails,
      date: newDate,
    });
  }

  function handleProjectChange(event) {
    const { name, value } = event.target;
    setProjectObj({
      ...projectObj,
      [name]: value,
    });
  }

  function handleOneTimePricingChange(event) {
    const { name, value } = event.target;
    setOneTimePricingObj({
      ...oneTimePricingObj,
      [name]: value,
    });
  }

  function handleReccuringPricingChange(event) {
    const { name, value } = event.target;
    setReccuringPricingObj({
      ...reccuringPricingObj,
      [name]: value,
    });
  }

  function addProject() {
    if (projectObj.requirement === "" || projectObj.desc === "") {
      return;
    }

    props.setCustomerDetails({
      ...props.customerDetails,
      projects: [...props.customerDetails.projects, projectObj],
    });

    setProjectObj({
      requirement: "",
      desc: "",
    });
  }

  function addOneTimePricing() {
    if (
      oneTimePricingObj.channel === "" ||
      oneTimePricingObj.one_time_setup === "" ||
      oneTimePricingObj.monthly_recurring === "" ||
      oneTimePricingObj.per_notification === ""
    ) {
      return;
    }

    props.setCustomerDetails({
      ...props.customerDetails,
      oneTimePricing: [
        ...props.customerDetails.oneTimePricing,
        oneTimePricingObj,
      ],
    });

    setOneTimePricingObj({
      channel: "",
      one_time_setup: "",
      monthly_recurring: "",
      per_notification: "",
    });
  }

  function addReccuringPricing() {
    if (
      reccuringPricingObj.feature_name === "" ||
      reccuringPricingObj.feature_value === ""
    ) {
      return;
    }

    props.setCustomerDetails({
      ...props.customerDetails,
      reccuringPricing: [
        ...props.customerDetails.reccuringPricing,
        reccuringPricingObj,
      ],
    });

    setReccuringPricingObj({
      feature_name: "",
      feature_value: "",
    });
  }

  function deleteProjects(delIndex) {
    var newProjects = props.customerDetails.projects.filter(
      (element, index) => index !== delIndex
    );
    props.setCustomerDetails({
      ...props.customerDetails,
      projects: newProjects,
    });
  }

  function deleteOneTimePricing(delIndex) {
    var newOneTimePricing = props.customerDetails.oneTimePricing.filter(
      (element, index) => index !== delIndex
    );
    props.setCustomerDetails({
      ...props.customerDetails,
      oneTimePricing: newOneTimePricing,
    });
  }

  function deleteReccuringPricing(delIndex) {
    var newReccuringPricing = props.customerDetails.reccuringPricing.filter(
      (element, index) => index !== delIndex
    );
    props.setCustomerDetails({
      ...props.customerDetails,
      reccuringPricing: newReccuringPricing,
    });
  }
  function handleSubmit(event) {
    event.preventDefault();
    if (
      props.customerDetails.commercialPlan === "" ||
      props.customerDetails.date === "" ||
      props.customerDetails.oneTimePricing.length === 0 ||
      props.customerDetails.projects.length === 0 ||
      props.customerDetails.reccuringPricing.length === 0
    ) {
      alert(
        "Please fill in all the field required for agreement before submitting!"
      );
      return;
    }

    if (!window.confirm("This will submit the form! Are you sure?")) {
      return;
    }

    try {
      var data = {
        dated: props.customerDetails.date,
        project_requirement: props.customerDetails.projects,
        one_time_commercials: props.customerDetails.oneTimePricing,
        recuring_commercials_plan: props.customerDetails.commercialPlan,
        recurring_commercials_feature: props.customerDetails.reccuringPricing,
      };

      axios({
        url: "/agreement/" + props.bID,
        method: "PUT",
        data: data,
      }).then((response) => {
        if (response.data.code === 200) {
          alert("Submit successfull!");
        }
      });
    } catch (error) {
      setErrorOnSubmit({
        message: error.message || "Something went wrong!",
        status: true,
      });
    }
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} style={{ padding: 10 }}>
        <Grid container spacing={2} direction={"column"}>
          <Grid item>
            {errorOnSubmit.status && (
              <Alert severity="warning">
                {errorOnSubmit.message || "Something went wrong!"}
              </Alert>
            )}
          </Grid>
          <Grid item container alignItems={"center"} spacing={2}>
            <Grid item xs={6}>
              <TextField
                type="date"
                fullWidth
                name="formattedDate"
                value={formattedDate}
                onChange={handleDateChange}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant={"outlined"}>
                <InputLabel id="demo-simple-select-label">Plan</InputLabel>
                <Select
                  label="plan"
                  labelId="demo-simple-select-label"
                  name="commercialPlan"
                  value={props.customerDetails.commercialPlan}
                  onChange={props.handleChange}
                >
                  <MenuItem value={"dolphinchat_basic"}>Basic</MenuItem>
                  <MenuItem value={"dolphinchat_plus"}>Plus</MenuItem>
                  <MenuItem value={"dolphinchat_pro"}>Pro</MenuItem>
                  <MenuItem value={"dolphinchat_mega"}>Mega</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item container spacing={1} direction={"row"}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Project
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="requirement"
                label="Project Requirement"
                onChange={handleProjectChange}
                value={projectObj.requirement}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                multiline
                fullWidth
                name="desc"
                label="Project Description"
                onChange={handleProjectChange}
                value={projectObj.desc}
                variant="outlined"
              />
            </Grid>
            <Grid color="primary" item xs={12}>
              <Button variant="contained" onClick={addProject}>
                <AddIcon />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TableContainer
                component={Paper}
                style={{ background: "#f6f6f6" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Project Requirement</TableCell>
                      <TableCell>Project Description</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.customerDetails.projects &&
                      props.customerDetails.projects.map((project, index) => (
                        <TableRow key={index}>
                          <TableCell>{project.requirement}</TableCell>
                          <TableCell>{project.desc}</TableCell>
                          <TableCell>
                            <Button
                              onClick={() => deleteProjects(index)}
                              color="secondary"
                            >
                              <BackspaceIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Commercials - One Time Pricing
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="channel"
                label="Channel Name"
                onChange={handleOneTimePricingChange}
                value={oneTimePricingObj.channel}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="one_time_setup"
                label="One Time Charge"
                onChange={handleOneTimePricingChange}
                value={oneTimePricingObj.one_time_setup}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="monthly_recurring"
                label="Monthly Charge"
                onChange={handleOneTimePricingChange}
                value={oneTimePricingObj.monthly_recurring}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="per_notification"
                label="Per Notification"
                onChange={handleOneTimePricingChange}
                value={oneTimePricingObj.per_notification}
                variant="outlined"
              />
            </Grid>
            <Grid color="primary" item xs={12}>
              <Button variant="contained" onClick={addOneTimePricing}>
                <AddIcon />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TableContainer
                component={Paper}
                style={{ background: "#f6f6f6" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Channel Name</TableCell>
                      <TableCell>One Time Charges</TableCell>
                      <TableCell>Monthly Charges</TableCell>
                      <TableCell>Per Notification Charges</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.customerDetails.oneTimePricing &&
                      props.customerDetails.oneTimePricing.map(
                        (oneTimePricing, index) => (
                          <TableRow key={index}>
                            <TableCell>{oneTimePricing.channel}</TableCell>
                            <TableCell>
                              {oneTimePricing.one_time_setup}
                            </TableCell>
                            <TableCell>
                              {oneTimePricing.monthly_recurring}
                            </TableCell>
                            <TableCell>
                              {oneTimePricing.per_notification}
                            </TableCell>
                            <TableCell>
                              <Button
                                onClick={() => deleteOneTimePricing(index)}
                                color="secondary"
                              >
                                <BackspaceIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Commercials - Reccuring Pricing
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="feature_name"
                label="Feature"
                onChange={handleReccuringPricingChange}
                value={reccuringPricingObj.feature_name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                multiline
                fullWidth
                name="feature_value"
                label="Description"
                onChange={handleReccuringPricingChange}
                value={reccuringPricingObj.feature_value}
                variant="outlined"
              />
            </Grid>
            <Grid color="primary" item xs={12}>
              <Button variant="contained" onClick={addReccuringPricing}>
                <AddIcon />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TableContainer
                component={Paper}
                style={{ background: "#f6f6f6" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Reccuring Feature</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.customerDetails.reccuringPricing &&
                      props.customerDetails.reccuringPricing.map(
                        (reccuringPricing, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {reccuringPricing.feature_name}
                            </TableCell>
                            <TableCell>
                              {reccuringPricing.feature_value}
                            </TableCell>
                            <TableCell>
                              <Button
                                onClick={() => deleteReccuringPricing(index)}
                                color="secondary"
                              >
                                <BackspaceIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="outlined" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
