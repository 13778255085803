import React from "react";
import { Grid, Typography, Checkbox } from "@material-ui/core";

export default function ComponentPermissionRow({
  displayComponent,
  componentPermissions,
  pagePermissionValue,
  editState,
  setValues,
}) {
  var componentPermissionValue = componentPermissions.find(
    (componentPermission) =>
      componentPermission.component_id === displayComponent.component_id
  );

  var permissionTypes = ["read", "create", "update", "delete"];

  return (
    <React.Fragment>
      <Grid item xs={6}>
        <Typography
          variant="h6"
          style={{ textTransform: "capitalize", marginLeft: "2rem" }}
        >
          {displayComponent.name}
        </Typography>
      </Grid>

      <Grid item xs={6} container style={{ textAlign: "center" }}>
        {permissionTypes.map((permissionType, index) => (
          <Grid item xs={3} key={index}>
            <Checkbox
              name={permissionType}
              checked={
                componentPermissionValue?.permissions?.[permissionType] || false
              }
              onChange={handlePermissionChange}
              disabled={
                !editState ||
                (permissionType !== "read" &&
                  !componentPermissionValue?.permissions?.read)
              }
            />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );

  function handlePermissionChange(event) {
    const { name, checked } = event.target;

    setValues((prevValue) => {
      return {
        ...prevValue,
        pagePermissions: [
          ...prevValue.pagePermissions.map((pagePermission) => {
            if (pagePermission.page_id === pagePermissionValue.page_id) {
              return {
                ...pagePermission,
                components: filterComponentPermissions(
                  pagePermission.components
                ),
              };
            }

            return pagePermission;
          }),
        ],
      };
    });

    function filterComponentPermissions(componentPermissionsList) {
      var newComponentPermissions = [];

      if (componentPermissionValue === undefined) {
        newComponentPermissions.push(...componentPermissionsList, {
          component_name: displayComponent.name,
          component_id: displayComponent.component_id,
          permissions: {
            read: true,
            delete: false,
            create: false,
            update: false,
          },
        });
      } else {
        for (var componentPermission of componentPermissionsList) {
          if (
            componentPermission.component_id ===
            componentPermissionValue.component_id
          ) {
            newComponentPermissions.push({
              ...componentPermission,
              permissions: {
                ...(name === "read" && !checked
                  ? {
                      read: false,
                      delete: false,
                      create: false,
                      update: false,
                    }
                  : { ...componentPermission.permissions, [name]: checked }),
              },
            });
          } else newComponentPermissions.push(componentPermission);
        }
      }

      return newComponentPermissions;
    }
  }
}
