import React, { useState, useEffect } from "react";
import { useAxios, useLoader } from "../../custom hooks";

import ChatWindow from "./conversations components/ChatWindow";

import { Loader, PageHeader } from "../../custom components";

export default function Conversations(props) {
  const axios = useAxios();
  const loader = useLoader();
  const propDetails = props.location;

  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (propDetails.user !== undefined) {
      setUsers([propDetails.user]);
    } else {
      loader.start();
      axios({
        url: "/bot/users",
        params: {
          agent_id:
            propDetails.agentID !== undefined ? propDetails.agentID : null,
          business_id:
            propDetails.agentID !== undefined ? propDetails.businessID : null,
        },
      }).then((data) => {
        loader.stop();
        setUsers(data.data);
      });
    }
  }, [
    loader,
    axios,
    propDetails.user,
    propDetails.agentID,
    propDetails.businessID,
  ]);

  return (
    <Loader>
      <PageHeader pageName="Conversations" />

      <ChatWindow users={users} />
    </Loader>
  );
}
