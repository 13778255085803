import React from "react";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  ListItemIcon as MuiListItemIcon,
} from "@material-ui/core";

import { PowerSettingsNew, Face, ExpandMore } from "@material-ui/icons";

import { signOut } from "../../redux/actions/authActions";
import { alertInfo } from "../../redux/actions/alertActions";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const ListItemIcon = styled(MuiListItemIcon)`
  margin-right: 10px;
  min-width: 0px;
`;

function UserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleProfile = () => {
    history.push("/profile");
  };

  const handleSignOut = async () => {
    await dispatch(signOut());
    dispatch(alertInfo("You have been logged out!"));
    history.push("/sign-in");
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton onClick={toggleMenu} color="inherit">
          <ExpandMore />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={handleProfile}>
          <ListItemIcon>
            <Face />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default UserDropdown;
