import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { rootReducer } from "../reducers/index";
import { ENV } from "../../config/config";

const composeEnhancers = composeWithDevTools({});

const store =
  ENV === "prod"
    ? createStore(rootReducer, applyMiddleware(thunk))
    : createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
