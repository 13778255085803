import convertDate from "../../../util/convertDate";

export const pageDetailsObject = {
  pageID: "",
  pageName: "",
  pageStatus: "",
  pageDescription: "",
  createdBy: "" || "Not Available",
  createdAt: "" || "Not Available",
  updatedBy: "" || "Not Available",
  updatedAt: "" || "Not Available",
  components: [],
};

export function objectFromResponse(responseData) {
  return {
    pageID: responseData.page_id,
    pageName: responseData.page_name,
    pageStatus: responseData.is_active,
    createdBy: responseData.created_by_name,
    updatedBy: responseData.updated_by_name,
    createdAt: convertDate(responseData.created_at),
    updatedAt: convertDate(responseData.updated_at),
    pageDescription: responseData.description,
    components: responseData.component,
  };
}

export function objectFromFormData(formData) {
  return {
    page_name: formData.pageName,
    is_active: formData.pageStatus,
    description: formData.pageDescription,
  };
}
