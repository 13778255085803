import React from "react";

import { Helmet } from "react-helmet-async";
import { NavLink, useHistory } from "react-router-dom";

import { Add, Edit } from "@material-ui/icons";
import {
  Typography,
  Divider as MuiDivider,
  Breadcrumbs,
  Link,
  Grid,
  Button,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";
const Divider = styled(MuiDivider)(spacing);

export default function PageHeader({
  title = "",
  pageName,
  memberID,
  breadcrumbs = [],
  addIcon = [],
  editIcon = [],
  icons = [],
  buttons = [],
}) {
  const [editState, setEditState, updatePermission] = editIcon;
  const [buttonTitle, URL, createPermission] = addIcon;

  const history = useHistory();

  return (
    <React.Fragment>
      <Helmet title={title || pageName} />

      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Typography variant="h3">{pageName}</Typography>

          <Breadcrumbs>
            {!Boolean(memberID) && (
              <Link component={NavLink} exact to="/">
                Home
              </Link>
            )}

            {breadcrumbs.length > 0 &&
              breadcrumbs.map((breadcrumb, index) => (
                <Link
                  key={index}
                  component={NavLink}
                  exact
                  to={"/" + breadcrumb.location}
                >
                  {breadcrumb.name}
                </Link>
              ))}

            <Typography>{pageName}</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid
          item
          xs={6}
          container
          justifyContent="flex-end"
          style={{ gap: "5px" }}
        >
          {icons.length > 0 &&
            icons.map((icon, index) => (
              <Grid item key={index}>
                {icon}
              </Grid>
            ))}

          {createPermission && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => history.push(URL)}
              >
                {buttonTitle}
              </Button>
            </Grid>
          )}

          {buttons.length > 0 &&
            buttons.map((icon, index) => (
              <Grid alignSelf={"center"} item key={index}>
                {icon}
              </Grid>
            ))}

          {!editState && updatePermission && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Edit />}
                onClick={() => setEditState((prevValue) => !prevValue)}
              >
                Edit
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Divider my={4} />
    </React.Fragment>
  );
}
