import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useAxios from "../../../custom hooks/useAxios";

import {
  alertError,
  alertSuccess,
  alertWarning,
} from "../../../redux/actions/alertActions";

import validationSchema from "./validationSchema";
import { objectFromFormData } from "./groupDetailsObject";
import FormDisplay from "./FormDisplay";
import PagePermissionsDisplay from "./PagePermissionsDisplay";

export default function Form({
  formData,
  setFormData,
  pageList,
  componentPermissions,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const axios = useAxios();
  const [editState, setEditState] = useState(true);

  useEffect(() => {
    if (formData.groupID !== "") {
      setEditState(false);
    }
  }, [formData.groupID]);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        var method = "POST";
        var url = "/group";

        if (formData.groupID !== "") {
          method = "PATCH";
          url = url + "/" + formData.groupID;
        }
        axios({
          method: method,
          url: url,
          data: objectFromFormData(formData),
        })
          .then((response) => {
            if (response.status) {
              dispatch(alertSuccess(response.message));
              setEditState(false);
              if (method === "POST") {
                history.push("/group-details/" + response.data.group_id);
              } else {
                setFormData(formData);
              }
            } else {
              dispatch(alertError(response.message));
            }
            setSubmitting(false);
          })
          .catch((response) => dispatch(alertWarning(response.message)));
      }}
      validateOnMount
    >
      {(props) => (
        <form noValidate onSubmit={props.handleSubmit}>
          <FormDisplay
            editState={editState}
            setEditState={setEditState}
            formikProps={props}
          />

          <PagePermissionsDisplay
            editState={editState}
            setEditState={setEditState}
            pageList={pageList}
            formikProps={props}
          />
        </form>
      )}
    </Formik>
  );
}
