import React, { useState } from "react";
import { Formik } from "formik";
import { Grid } from "@material-ui/core";
import axios from "axios";
import WhatsappStyleBot from "./WhatsappStyleBot";
import FormDisplay from "./FormDisplay";
import validationSchema from "./validationSchema";
import { useDispatch } from "react-redux";
import { objectFromFormData } from "./whatsappDetailsObject";
import { alertError, alertSuccess } from "../../../redux/actions/alertActions";
import { ENV } from "../../../config/config";

export default function Form({
  configureDetails,
  setEditState,
  editState,
  setConfigureDetails,
  token,
  agentId,
  originalImage,
  setOriginalImage,
}) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState("panel1");
  const [styleModal, setStyleModal] = useState(false);
  return (
    <Formik
      enableReinitialize
      initialValues={configureDetails}
      validationSchema={validationSchema}
      onSubmit={(configureDetails, { setSubmitting }) => {
        var data = JSON.stringify(objectFromFormData(configureDetails));
        var config = {
          method: "patch",
          url:
            "https://" +
            (ENV === "prod" ? "" : ENV + ".") +
            "api.dolphinchat.ai/bot/v1/agent/widget",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            if (response.data.status === true) {
              dispatch(alertSuccess(response.data.message));
              setEditState(false);
              setSubmitting(false);
            } else {
              dispatch(alertError(response.data.message));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }}
    >
      {(props) => (
        <Grid alignItems="stretch" container spacing={2}>
          <Grid item xs={12} sm={7}>
            <form noValidate onSubmit={props.handleSubmit}>
              <FormDisplay
                formikProps={props}
                setConfigureDetails={setConfigureDetails}
                configureDetails={configureDetails}
                setEditState={setEditState}
                editState={editState}
                expanded={expanded}
                setExpanded={setExpanded}
                styleModal={styleModal}
                setStyleModal={setStyleModal}
                agentId={agentId}
                originalImage={originalImage}
                setOriginalImage={setOriginalImage}
              />
            </form>
          </Grid>
          <Grid item xs={12} sm={5}>
            <WhatsappStyleBot
              formikProps={props}
              expanded={expanded}
              setExpanded={setExpanded}
              agentId={agentId}
            />
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
