import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Divider, Grid, Button } from "@material-ui/core";
import { Delete, Edit, Save } from "@material-ui/icons";
import FormDisplay from "./FormDisplay";

import { objectFromFormData } from "./groupDetailsObject";
import { useAlert, useAxiosV2 } from "../../../../custom hooks";
import { DeleteDialog, Paper } from "../../../../custom components";
import {
  alertError,
  alertSuccess,
} from "../../../../redux/actions/alertActions";
import UserListComponent from "./UserListComponent";
import validationSchema from "./validationSchema";

export default function Form({
  formData,
  setFormData,
  userList,
  groupID,
  setListUsers,
  setUser,
  setUserList,
  authToken,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();

  const [dialogState, setDialogState] = useState(false);
  const [editState, setEditState] = useState(true);

  var memberID = localStorage.getItem("member_id")
    ? localStorage.getItem("member_id")
    : "";

  var agentID = localStorage.getItem("agent_id")
    ? localStorage.getItem("agent_id")
    : "";

  const axios = useAxiosV2({
    baseURL: "adminV2",
  });

  useEffect(() => {
    if (Boolean(formData.ID)) {
      setEditState(false);
    }
  }, [formData.ID]);

  const handleCancel = (formikProps) => {
    if (
      !window.confirm(
        "Selecting OK, will reset the form and redirect you to the broadcast list page. Are you sure want to do it ?"
      )
    ) {
      return;
    } else {
      formikProps.handleReset();
      history.push({
        pathname: "/group-list",
        search: "?agent_id=" + agentID + "&member_id=" + memberID,
      });
    }
  };

  const handleDeleteGroup = () => {
    axios({
      url: "/broadcast/delete/group/" + groupID,
      method: "DELETE",
      authToken: "Bearer " + authToken,
    })
      .then((res) => {
        if (res.status) {
          alert.success(res.message);
          history.push({
            pathname: "/group-list",
            search: "?agent_id=" + agentID + "&member_id=" + memberID,
          });
        } else {
          dispatch(alertError(res.message));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        var data = JSON.stringify(objectFromFormData(formData));
        axios({
          url: "/broadcast/update/group/" + groupID,
          method: "PUT",
          data: data,
          authToken: "Bearer " + authToken,
        }).then((res) => {
          if (res.status) {
            dispatch(alertSuccess(res.message));
            setFormData(formData);
          } else {
            dispatch(alertError(res.message));
          }
          setSubmitting(false);
          setEditState(false);
        });
      }}
    >
      {(formikProps) => (
        <form noValidate onSubmit={formikProps.handleSubmit}>
          <FormDisplay
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
            groupID={groupID}
            authToken={authToken}
          />

          <DeleteDialog
            dialogState={dialogState}
            setDialogState={setDialogState}
            handleAgree={handleDeleteGroup}
          />

          <Divider
            style={{
              margin: "16px 0 16px 0",
            }}
          />

          <UserListComponent
            userList={userList}
            setListUsers={setListUsers}
            groupID={groupID}
            setUser={setUser}
            formikProps={formikProps}
            setUserList={setUserList}
            authToken={authToken}
          />
          <Paper>
            {Boolean(formikProps.values.ID) && (
              <Grid item>
                <Button
                  variant="contained"
                  style={{ background: "#d32f2f", color: "#fff" }}
                  onClick={() => setDialogState(true)}
                >
                  <Delete size="small" style={{ fontSize: "18px" }} /> &nbsp;
                  Delete Group
                </Button>
              </Grid>
            )}
            <Grid item xs />

            {Boolean(editState) ? (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    formikProps.dirty
                      ? formikProps.isSubmitting || !formikProps.isValid
                      : !formikProps.dirty
                  }
                  onClick={formikProps.handleSubmit}
                >
                  <Save size="small" style={{ fontSize: "18px" }} />{" "}
                  &nbsp;Submit
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setEditState(true)}
                >
                  <Edit size="small" style={{ fontSize: "18px" }} /> &nbsp; Edit
                </Button>
              </Grid>
            )}

            {Boolean(editState) && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    if (Boolean(formikProps.values.ID)) {
                      setEditState(false);
                      handleCancel(formikProps);
                    } else {
                      history.goBack();
                    }
                  }}
                >
                  {Boolean(formikProps.values.ID) ? "Cancel" : "Back"}
                </Button>
              </Grid>
            )}
          </Paper>
        </form>
      )}
    </Formik>
  );
}
