export const adminPageDetailsObject = {
  pageID: "",
  pageName: "",
  pageDescription: "",
  pageStatus: "",
  defaultPermissions: {
    access: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  createdBy: "",
  createdAt: "",
  updatedBy: "",
  updatedAt: "",
};

export function objectFromResponse(responseData) {
  return {
    pageID: responseData._id,
    pageName: responseData.page_name || "",
    pageDescription: responseData.description || "",
    pageStatus: responseData.is_active || "",
    defaultPermissions: {
      access: responseData.default_permission.access || false,
      read: responseData.default_permission.read || false,
      create: responseData.default_permission.create || false,
      update: responseData.default_permission.update || false,
      delete: responseData.default_permission.delete || false,
    },
    createdAt: responseData.created_at,
    createdBy: responseData.created_by,
    updatedAt: responseData.updated_at,
    updatedBy: responseData.updated_by,
  };
}

export function objectFromFormData(formData) {
  return {
    page_name: formData.pageName,
    description: formData.pageDescription,
    is_active: formData.pageStatus,
    is_default: formData.defaultPermissions.access,
    default_permission: {
      access: formData.defaultPermissions.access,
      read: formData.defaultPermissions.read,
      create: formData.defaultPermissions.create,
      update: formData.defaultPermissions.update,
      delete: formData.defaultPermissions.delete,
    },
  };
}
