import { useState, useEffect } from "react";

import { Loader, PageHeader } from "../../custom components";
import { usePermissions, useAxios, useLoader } from "../../custom hooks";
import Table from "./whatsapp number components/Table";

export default function WhatsappNumberList(props) {
  const axios = useAxios();
  const loader = useLoader();
  const pagePermissions = usePermissions(props.pageID);

  const [whatsappNumber, setWhatsappNumber] = useState([]);

  useEffect(() => {
    loader.start();
    axios({
      url: "/get_list_of_whatsapp_no_details",
    })
      .then((response) => {
        if (response.status) {
          setWhatsappNumber(response.data);
          loader.stop();
        }
      })
      .catch((err) => console.log(err));
  }, [loader, axios]);

  return (
    <Loader>
      <PageHeader
        pageName="Whatsapp Numbers"
        addIcon={[
          "Register WhatsApp Number",
          "/whatsapp-number-detail",
          pagePermissions.create,
        ]}
      />
      <Table whatsappNumber={whatsappNumber} />
    </Loader>
  );
}
