import React from "react";
import styled, { withTheme } from "styled-components/macro";

import {
  Grid,
  AppBar as MuiAppBar,
  Toolbar,
  IconButton,
} from "@material-ui/core";

import { Menu } from "@material-ui/icons";

// import NotificationsDropdown from "./NotificationsDropdown";
// import LanguagesDropdown from "./LanguagesDropdown";
import UserDropdown from "./UserDropdown";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const AppBarComponent = ({ onDrawerToggle }) => (
  <React.Fragment>
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item>
            <IconButton color="inherit" onClick={onDrawerToggle}>
              <Menu />
            </IconButton>
          </Grid>
          <Grid item xs />
          <Grid item>
            {/* <NotificationsDropdown /> */}
            {/* <LanguagesDropdown /> */}
            <UserDropdown />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);

export default withTheme(AppBarComponent);
