import { useState, useEffect } from "react";
import { usePermissions, useAxios, useLoader } from "../../custom hooks";
import Table from "./utterances components/Table";

import { Loader, PageHeader } from "../../custom components";

export default function Utterances(props) {
  const axios = useAxios();
  const loader = useLoader();
  const pagePermissions = usePermissions(props.pageID);

  const [utterances, setUtterances] = useState([]);

  useEffect(() => {
    loader.start();

    axios({
      url: "/service/utterance",
    })
      .then((response) => {
        if (response.status) {
          loader.apiComplete();
          setUtterances(response.data);
        }
      })
      .catch((err) => console.log(err));
  }, [loader, axios]);

  return (
    <Loader>
      <PageHeader
        pageName="Utterances"
        addIcon={["Add New Utterance", "/utterances", pagePermissions.create]}
      />

      <Table utterances={utterances} />
    </Loader>
  );
}
