import { useHistory } from "react-router";
import convertDate from "../../../util/convertDate";
import DataTable from "../../../custom components/DataTable";

export default function Table({ botIntegrations }) {
  const history = useHistory();

  const options = {
    onRowClick: (_, cellMetaData) => {
      history.push(
        "/bot-integration-details/" +
          botIntegrations[cellMetaData.dataIndex]._id
      );
    },
    sortOrder: {
      name: "created_on",
      direction: "desc",
    },
  };

  return (
    <DataTable columns={columns} options={options} data={botIntegrations} />
  );
}

const columns = [
  {
    name: "_id",
    label: "Bot Integration ID",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "integration_name",
    label: "Integration Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "bot_name",
    label: "Bot Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "business_name",
    label: "Business Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "webhook_enabled",
    label: "Webhook Enabled",
    options: {
      customBodyRender: (value) => {
        return value ? "Enabled" : "Disabled";
      },
      display: false,
    },
  },
  {
    name: "is_active",
    label: "Integration Status",
    options: {
      customBodyRender: (value) => {
        return value ? "Active" : "Inactive";
      },
    },
  },
  {
    name: "created_by",
    label: "Created By",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "created_on",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
