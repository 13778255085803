import axios from "axios";
import { BASE_URL } from "../config/config";

export default async function axiosInstance({
  url,
  data,
  method = "GET",
  params,
  baseURL = "superadmin",
  customBaseURL = "",
  authToken = "",
}) {
  return axios({
    url: url,
    method: method,
    data: data,
    params: params,
    ...(Boolean(customBaseURL)
      ? { baseURL: customBaseURL }
      : { baseURL: BASE_URL[baseURL].url + BASE_URL[baseURL].version }),
    headers: {
      "content-type": "application/json",
      ...(Boolean(authToken) && { Authorization: authToken }),
    },
  });
}
