import { Formik } from "formik";
import { Grid, Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { useAxios, useAlert } from "../../../custom hooks";
import {
  passwordComponentObject,
  passwordComponentSchema,
} from "./resetPasswordObject";
import { Paper, TextField } from "../../../custom components";

export default function PasswordComponent({ forgotPasswordToken }) {
  const history = useHistory();
  const { info, error } = useAlert();
  const axios = useAxios({ disableAuthToken: true });

  return (
    <Formik
      validateOnMount
      initialValues={passwordComponentObject}
      validationSchema={passwordComponentSchema}
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/reset/forgot/password/" + forgotPasswordToken,
          data: {
            password1: formData.password,
            password2: formData.confirmPassword,
          },
          method: "POST",
        }).then((response) => {
          setSubmitting(false);

          if (response.status) {
            info("Your password had been reset! Please log in to continue");

            history.push("/sign-in");
          } else {
            error(response.error);
          }
        });
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <Paper>
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                Reset Password
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center">
                Please enter new password.
              </Typography>

              <Typography
                variant="subtitle2"
                align="center"
                style={{ color: "red" }}
              >
                It should have 8 characters, at least one small letter, one
                capital letter and a number.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                name="password"
                label="Password"
                formikProps={formikProps}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                name="confirmPassword"
                label="Confirm Password"
                formikProps={formikProps}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={!formikProps.isValid || formikProps.isSubmitting}
              >
                Submit
              </Button>
            </Grid>
          </Paper>
        </form>
      )}
    </Formik>
  );
}
