import { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Paper,
  TextField as MuiTextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Divider as MuiDivider,
} from "@material-ui/core";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";

import PerfectScrollbar from "react-perfect-scrollbar";
import "../../../vendor/perfect-scrollbar.css";

import ChatMessageComponent from "./ChatMessageComponent";

const TextField = styled(MuiTextField)(spacing);

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 85vh;
`;

const ChatSidebar = styled(Grid)`
  height: 100%;
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const ChatList = styled(Grid)`
  height: calc(85vh - 70px);
`;

const ChatMain = styled(Grid)``;

const ChatHead = styled(Grid)`
  min-height: 68px;
  padding: ${(props) => props.theme.spacing(2)}px;
`;

const ChatMessages = styled.div`
  height: calc(85vh - 70px);
`;

const Divider = styled(MuiDivider)(spacing);

export default function ChatWindow({ users }) {
  const [search, setSearch] = useState("");
  const [renderedUsers, setRenderedUsers] = useState([]);
  // const [renderedMessages, setRendenderedMessages] = useState([]);
  const [usersGroup, setUsersGroup] = useState(1);
  // const [messagesGroup, setMessagesGroup] = useState(1);

  useEffect(() => {
    setRenderedUsers(users.slice(0, 10));
    setUsersGroup(1);
  }, [users]);

  function handleUsersScroll() {
    if (renderedUsers.length > 9 && renderedUsers.length < users.length) {
      setRenderedUsers((prevValue) => [
        ...prevValue,
        ...users.slice(10 * usersGroup, 10 * usersGroup + 10),
      ]);
      setUsersGroup(usersGroup + 1);
    }
  }

  // function filter(users) {
  //  //Todo: Filter messages based on search
  //   return users.filter((user) =>
  //     user.name !== undefined ? user.name.indexOf(search) > -1 : false
  //   );
  // }

  // function handleMessageScroll() {
  //   //Todo: Preprend more messages
  // }

  function handleUserClick(index) {
    alert("clicked", index);
  }

  //Todo: On click for elements and when clciked set a clicked variable as the index of rendered users
  //Todo: and also inside the array object, set an variable.
  //Todo: when another element is clicked then get this  clicked variable and then change it

  return (
    <ChatContainer container component={Paper} elevation={4}>
      <ChatSidebar item xs={12} md={4} lg={3} container>
        <Grid item xs={12}>
          <Box p={2}>
            <TextField
              fullWidth
              label="Search contacts"
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              variant="outlined"
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <ChatList item xs={12}>
          <PerfectScrollbar onYReachEnd={handleUsersScroll}>
            <List>
              {renderedUsers.length > 0 &&
                renderedUsers.map((user, index) => (
                  <ListItem
                    button
                    key={user.sender_id}
                    onClick={() => handleUserClick(index)}
                  >
                    <ListItemIcon>
                      <Avatar
                        alt={user.name || "Not Available"}
                        //TODO: User pics
                        src="/static/img/avatars/avatar-3.jpg"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={user.name || "Unknown"}
                      //TODO: Secordary text
                      secondary="Hello! 👋"
                    />
                  </ListItem>
                ))}
            </List>
          </PerfectScrollbar>
        </ChatList>
      </ChatSidebar>
      <ChatMain item xs={12} md={8} lg={9}>
        <ChatHead>
          <TextField
            fullWidth
            variant="outlined"
            disabled
            value="Cassandra Mixon"
          />
        </ChatHead>
        <Divider />
        <ChatMessages>
          <PerfectScrollbar>
            <ChatMessageComponent
              // Todo:Implement chat messages
              name="Remy Sharp"
              message="Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo."
              time="11:20 am"
              position="left"
            />
            <ChatMessageComponent
              name="Remy Sharp"
              avatar="/static/img/avatars/avatar-2.jpg"
              message="Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat animal commodo."
              time="20 minutes ago"
              position="left"
            />
            <ChatMessageComponent
              name="You"
              avatar="/static/img/avatars/avatar-1.jpg"
              message="Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix."
              time="12 minutes ago"
              position="right"
            />
            <ChatMessageComponent
              name="Remy Sharp"
              avatar="/static/img/avatars/avatar-2.jpg"
              message="Cum ea graeci tractatos. 😄"
              time="8 minutes ago"
              position="left"
            />
            <ChatMessageComponent
              name="You"
              avatar="/static/img/avatars/avatar-1.jpg"
              message="Cras pulvinar, sapien id vehicula aliquet, diam velit elementum orci. 👍"
              time="5 minutes ago"
              position="right"
            />
            <ChatMessageComponent
              name="Remy Sharp"
              avatar="/static/img/avatars/avatar-2.jpg"
              message="Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix."
              time="3 minutes ago"
              position="left"
            />
          </PerfectScrollbar>
        </ChatMessages>
      </ChatMain>
    </ChatContainer>
  );
}
