export default function startDate(unixTimeStamp) {
  var timeStamp = new Date(unixTimeStamp * 1000);

  var year = timeStamp.getFullYear();
  var month = timeStamp.getMonth() + 1;
  var day = timeStamp.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  var time = year + "-" + month + "-" + day;

  return time;
}
