import React from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  objectFromFormData,
  signinDetailsObject,
  userDetailFromResponseData,
} from "./signinDetailsObject";

import {
  alertError,
  alertSuccess,
  alertWarning,
} from "../../../redux/actions/alertActions";
import { signInSchema } from "../Validation/validationSchema";
import { signIn } from "../../../redux/actions/authActions";

import axios from "../../../util/axios";
import FormDisplay from "./FormDisplay";

export default function Form() {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Formik
      enableReinitialize
      initialValues={signinDetailsObject}
      validationSchema={signInSchema}
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/login",
          method: "POST",
          data: objectFromFormData(formData),
        })
          .then((response) => {
            if (response.data.status) {
              const responseData = response.data.data;
              var authToken = "Bearer " + responseData.auth_token;

              axios({
                url: "/detail",
                method: "GET",
                authToken: authToken,
              }).then((response) => {
                if (response.data.status) {
                  const responseData = response.data.data;

                  dispatch(
                    signIn(
                      authToken,
                      userDetailFromResponseData(responseData),
                      responseData.group_detail.permission_page
                    )
                  );
                  dispatch(alertSuccess("Welcome!"));
                  history.push("/");
                } else {
                  dispatch(
                    alertWarning(
                      "There seems to be some error! Please try again later."
                    )
                  );
                }
              });
            } else {
              dispatch(alertError(response.data.error));
            }
            setSubmitting(false);
          })
          .catch((error) => {
            dispatch(alertError(error || "Something went wrong"));
            setSubmitting(false);
          });
      }}
      validateOnMount
    >
      {(props) => (
        <form noValidate onSubmit={props.handleSubmit}>
          <FormDisplay formikProps={props} />
        </form>
      )}
    </Formik>
  );
}
