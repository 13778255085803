import React, { useState } from "react";
import { Alert } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import axios from "../../../util/axios";

export default function CustomerForm(props) {
  const [errorOnSubmit, setErrorOnSubmit] = useState({
    message: "",
    status: false,
  });

  function handleSubmit(event) {
    event.preventDefault();

    if (
      props.customerDetails.companyName === "" ||
      props.customerDetails.companyDesc === "" ||
      props.customerDetails.companyNo === "" ||
      props.customerDetails.companyAddr === "" ||
      props.customerDetails.companyEmail === "" ||
      props.customerDetails.customerName === ""
    ) {
      alert(
        "Please fill in all the field required for agreement before submitting!"
      );
      return;
    }

    if (!window.confirm("This will submit the form! Are you sure?")) {
      return;
    }

    var data = {
      customer_company_legal_name: props.customerDetails.companyName,
      customer_company_desc: props.customerDetails.companyDesc,
      customer_contact_number: props.customerDetails.companyNo,
      customer_address: props.customerDetails.companyAddr,
      customer_email: props.customerDetails.companyEmail,
      customer_name: props.customerDetails.customerName,
    };

    try {
      axios({
        url: "agreement/customer/" + props.bID,
        method: "PUT",
        data: data,
      }).then((response) => {
        if (response.data.code === 200) {
          alert("Submission successful!");
        }
      });
    } catch (error) {
      setErrorOnSubmit({
        message: error.message || "Something went wrong!",
        status: true,
      });
    }
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit} style={{ padding: 10 }}>
        <Grid container spacing={1} direction="column">
          {errorOnSubmit.status && (
            <Grid item>
              <Alert mt={2} mb={1} severity="warning">
                {errorOnSubmit.message || "Something went wrong!"}
              </Alert>
            </Grid>
          )}
          <Grid item>
            <TextField
              required
              fullWidth
              name="companyName"
              label="Company Name"
              onChange={props.handleChange}
              value={props.customerDetails.companyName}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <TextField
              required
              fullWidth
              multiline
              name="companyDesc"
              label="Company Description"
              onChange={props.handleChange}
              value={props.customerDetails.companyDesc}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <TextField
              required
              fullWidth
              type="number"
              name="companyNo"
              label="Company Contact Number"
              onChange={props.handleChange}
              value={props.customerDetails.companyNo}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <TextField
              required
              fullWidth
              name="companyAddr"
              label="Company Address"
              onChange={props.handleChange}
              value={props.customerDetails.companyAddr}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <TextField
              required
              fullWidth
              type="email"
              name="companyEmail"
              label="Company Email"
              onChange={props.handleChange}
              value={props.customerDetails.companyEmail}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <TextField
              required
              fullWidth
              name="customerName"
              label="Customer Name"
              onChange={props.handleChange}
              value={props.customerDetails.customerName}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="outlined" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
}
