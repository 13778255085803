import React from "react";

import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import DataTable from "../../../custom components/DataTable";

export default function ComponentTable({ components, options }) {
  return <DataTable columns={columns} options={options} data={components} />;
}

const columns = [
  {
    name: "name",
    label: "Component Name",
    options: {
      customBodyRender: (value) => (value ? value : "Not Available"),
    },
  },
  {
    name: "variable_name",
    label: "Variable Name",
    options: {
      customBodyRender: (value) => (value ? value : "Not Available"),
    },
  },
  {
    name: "component_id",
    label: "Component ID",
    options: {
      customBodyRender: (value) => (value ? value : "Not Available"),
    },
  },
  {
    name: "description",
    label: "Component Description",
    options: {
      customBodyRender: (value) => (value ? value : "Not Available"),
    },
  },
  {
    name: "actions",
    label: "Actions",
    options: {
      customBodyRender: () => {
        return (
          <IconButton>
            <Delete />
          </IconButton>
        );
      },
    },
  },
];
