import React, { useState, useEffect } from "react";

import Table from "./bot services components/Table";
import { PageHeader, Loader } from "../../custom components";
import { useLoader, useAxios, usePermissions } from "../../custom hooks";

export default function BotServices(props) {
  const loader = useLoader();
  const axios = useAxios();
  const pagePermissions = usePermissions(props.pageID);

  const [botServices, setBotServices] = useState([]);

  useEffect(() => {
    loader.start();
    axios({
      url: "/bots",
    }).then((response) => {
      loader.stop();
      setBotServices(response.data);
    });
  }, [axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Bot Services"
        addIcon={[
          "Add New Bot Service",
          "/bot-service-details",
          pagePermissions.create,
        ]}
      />

      <Table botServices={botServices} />
    </Loader>
  );
}
