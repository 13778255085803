import React, { useEffect, useState } from "react";
import { loaderStart, loaderStop } from "../../redux/actions/loaderActions";
import Form from "./bot details component/Form";
import queryString from "query-string";
import {
  BotDetailsObject,
  ConfigureDetailFromResponseData,
} from "./bot details component/BotDetailsObject";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router";
import { ENV } from "../../config/config";

export default function BotBuilder() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [configureDetails, setConfigureDetails] = useState(BotDetailsObject);
  const [editState, setEditState] = useState(false);
  const [originalImage, setOriginalImage] = useState("");

  const agentId = queryString.parse(window.location.search).agent_id;
  const token = queryString.parse(window.location.search).token;

  useEffect(() => {
    dispatch(loaderStart());
    var config = {
      method: "get",
      url:
        "https://" +
        (ENV === "prod" ? "" : ENV + ".") +
        "api.dolphinchat.ai/bot/v1/check/agent/token/" +
        agentId +
        "?token=" +
        token,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === true) {
          var config = {
            method: "get",
            url:
              "https://" +
              (ENV === "prod" ? "" : ENV + ".") +
              "api.dolphinchat.ai/bot/v1/agent/widget/" +
              agentId,
            headers: {},
          };

          axios(config)
            .then(function (response) {
              if (response.data.status === true) {
                setConfigureDetails(
                  ConfigureDetailFromResponseData(response.data.data)
                );
                setOriginalImage(response.data.data.widget?.bubble?.avatar);
                dispatch(loaderStop());
              } else {
                history.push("/errorPageLoaded");
              }
            })
            .catch(function (error) {
              history.push("/errorPageLoaded");

              console.log(error);
            });
        }
      })
      .catch(function (error) {
        history.push("/errorPageLoaded");
        console.log(error);
      });
  }, [dispatch, editState, agentId, token, history]);

  return (
    <React.Fragment>
      <Form
        configureDetails={configureDetails}
        setConfigureDetails={setConfigureDetails}
        editState={editState}
        setEditState={setEditState}
        token={token}
        agentId={agentId}
        originalImage={originalImage}
        setOriginalImage={setOriginalImage}
      />
    </React.Fragment>
  );
}
