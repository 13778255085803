import React from "react";

import { Grid, Typography } from "@material-ui/core";

export default function ComponentHeader({ headerName, id, buttons = [] }) {
  return (
    <Grid container alignItems="center" style={{ minHeight: "47px" }}>
      <Grid item xs={6}>
        <Typography variant="h4">{headerName}</Typography>
      </Grid>

      {!!id ? (
        <Grid
          item
          xs={6}
          container
          style={{ gap: "4px", justifyContent: "flex-end" }}
        >
          {buttons.length > 0 &&
            buttons.map((button, index) => (
              <Grid item key={index}>
                {button}
              </Grid>
            ))}
        </Grid>
      ) : (
        <Grid item xs />
      )}
    </Grid>
  );
}
