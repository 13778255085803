export default function getPagePermissions(pagePermission) {
  var components = {};

  for (var component of pagePermission.components) {
    if (component.permissions.read) {
      components = {
        ...components,
        [component.variable_name]: {
          read: component.permissions.read,
          create: component.permissions.create,
          update: component.permissions.update,
          delete: component.permissions.delete,
        },
      };
    }
  }

  return {
    pageID: pagePermission.page_id,
    pageName: pagePermission.page_name,
    create: pagePermission.page_permission.create,
    update: pagePermission.page_permission.update,
    read: pagePermission.page_permission.read,
    delete: pagePermission.page_permission.delete,
    components: components,
  };
}
