import { useHistory } from "react-router-dom";
import { DataTable } from "../../../../custom components";
import convertDate from "../../../../util/convertDate";

export default function Table({ groups, authToken }) {
  const history = useHistory();
  const options = {
    onRowClick: handleRowClick,
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  function handleRowClick(rowData, rowMetaData) {
    history.push(
      "/broadcast-group-details/" + groups[rowMetaData.dataIndex].group_id
    );
  }

  return <DataTable columns={columns} options={options} data={groups} />;
}

const columns = [
  {
    name: "group_id",
    label: "Group ID",
    options: {
      customBodyRender: (value) => {
        return value
          ? value.slice(0, 15) + (value.length > 15 ? "..." : "")
          : "Not Available";
      },
    },
  },
  {
    name: "group_name",
    label: "Group Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "is_active",
    label: "Group Status",
    options: {
      customBodyRender: (value) => {
        return value === true ? "Active" : "Inactive";
      },
    },
  },

  {
    name: "total_users",
    label: "Total Members",
    options: {
      customBodyRender: (value) => {
        return value ? value : 0;
      },
    },
  },
  {
    name: "updated_at",
    label: "Updated At",
    options: {
      display: false,
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
