import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().max(25).required("First Name is required"),
  lastName: Yup.string().max(25).required("Last Name is required"),
  email: Yup.string()
    .email("Enter a valid email")
    .max(255)
    .required("Email is required"),
  gender: Yup.string().required("Please select a gender"),
  groupID: Yup.string().required("Select a permissions group for member"),
});

export default validationSchema;
