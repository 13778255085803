import { useState, useEffect } from "react";
import { usePermissions, useAxios, useLoader } from "../../custom hooks";
import { Loader, PageHeader } from "../../custom components";
import validationSchema from "./bot integration details components/validationSchema";
import * as Yup from "yup";

import Form from "./bot integration details components/Form";
import {
  botIntegrationDetailsObject,
  objectFromResponse,
} from "./bot integration details components/botIntegrationDetailsObject";

import { Button } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

export default function BotIntegrationDetails(props) {
  const componentAxios = useAxios({ baseURL: "component" });
  const defaultAxios = useAxios();
  const loader = useLoader();
  const pagePermissions = usePermissions(props.pageID);
  const botIntegrationID = props.match.params.botIntegrationID || "";

  const [editState, setEditState] = useState(true);
  const [formData, setFormData] = useState(botIntegrationDetailsObject);
  const [businessList, setBusinessList] = useState([]);
  const [botList, setBotList] = useState([]);
  const [integrationGroupList, setIntegrationGroupList] = useState([]);
  const [integrationList, setIntegrationList] = useState([]);
  const [integrationDetails, setIntegrationDetails] = useState({});
  const [validation, setValidation] = useState(validationSchema);

  useEffect(() => {
    loader.start(6);

    if (Boolean(botIntegrationID)) {
      setEditState(false);

      componentAxios({
        url: "/get_agent_integrations?integration_id=" + botIntegrationID,
      })
        .then((response) => {
          if (response.status) {
            setFormData(objectFromResponse(response.data[0]));

            componentAxios({
              url:
                "/get_integrations?group_id=" +
                response.data[0].integration_group_id,
            }).then((response) => {
              if (response.status) {
                setIntegrationList(response.data);
                loader.apiComplete();
              }
            });

            componentAxios({
              url:
                "/get_integrations?integration_id=" +
                response.data[0].integration_id,
            })
              .then((response) => {
                if (response.status) {
                  setIntegrationDetails(response.data);
                  let newValidation = Yup.object();

                  for (let credential of response.data.required_credentials) {
                    newValidation = newValidation.concat(
                      Yup.object({
                        [credential.field_name]: Yup.string().required(
                          credential.label + " is required"
                        ),
                        // .matches(credential.frontend_regex),
                      })
                    );
                  }

                  setValidation((prevValue) =>
                    prevValue.concat(
                      Yup.object({ requiredCredentials: newValidation })
                    )
                  );

                  loader.apiComplete();
                }
              })
              .catch((err) => console.log(err));

            defaultAxios({
              url:
                "/client/agents/" +
                response.data[0].business_id +
                "?mode=dropdown",
            }).then((response) => {
              if (response.status) {
                setBotList(response.data);
                loader.apiComplete();
              }
            });
          }

          loader.apiComplete();
        })
        .catch((error) => console.log(error));
    } else {
      loader.apiComplete(4);
    }

    defaultAxios({
      url: "/dashboard?mode=dropdown",
    })
      .then((response) => {
        if (response.status) {
          loader.apiComplete();
          setBusinessList(response.data);
        }
      })
      .catch((error) => console.log(error));

    componentAxios({
      url: "/get_integration_groups?mode=ddl",
    })
      .then((response) => {
        if (response.status) {
          loader.apiComplete();
          setIntegrationGroupList(response.data);
        }
      })
      .catch((error) => console.log(error));
  }, [loader, componentAxios, defaultAxios, botIntegrationID]);

  return (
    <Loader>
      <PageHeader
        pageName="Bot Integration Details"
        breadcrumbs={[
          { name: "Bot Integrations", location: "bot-integrations" },
        ]}
        icons={[
          pagePermissions.update && !editState && Boolean(botIntegrationID) && (
            <Button
              variant="contained"
              color="primary"
              endIcon={<Edit />}
              onClick={() => setEditState((prevValue) => !prevValue)}
            >
              Edit
            </Button>
          ),
        ]}
      />

      <Form
        formData={formData}
        setFormData={setFormData}
        pagePermissions={pagePermissions}
        businessList={businessList}
        botList={botList}
        setBotList={setBotList}
        integrationGroupList={integrationGroupList}
        integrationList={integrationList}
        setIntegrationList={setIntegrationList}
        editState={editState}
        setEditState={setEditState}
        integrationDetails={integrationDetails}
        setIntegrationDetails={setIntegrationDetails}
        validation={validation}
        setValidation={setValidation}
      />
    </Loader>
  );
}
