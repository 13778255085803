import React from "react";
import "./botbuilder.css";
import BotInterface from "./BotInterface";

const MobileViewBot = ({
  formikProps,
  phoneView,
  setPhoneView,
  expanded,
  setExpanded,
  agentId,
  showBubble,
  setShowBubble,
}) => {
  return (
    <React.Fragment>
      {phoneView ? (
        <div className="smartphone">
          <BotInterface
            formikProps={formikProps}
            showBubble={showBubble}
            setShowBubble={setShowBubble}
            phoneView={phoneView}
            setPhoneView={setPhoneView}
            expanded={expanded}
            setExpanded={setExpanded}
            agentId={agentId}
          />
        </div>
      ) : (
        <BotInterface
          phoneView={phoneView}
          setPhoneView={setPhoneView}
          formikProps={formikProps}
          showBubble={showBubble}
          setShowBubble={setShowBubble}
          expanded={expanded}
          setExpanded={setExpanded}
          agentId={agentId}
        />
      )}
    </React.Fragment>
  );
};
export default MobileViewBot;
