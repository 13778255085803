import React, { useState } from "react";
import { Paper, DataTable } from "../../../custom components";
import {
  Grid,
  IconButton,
  Tooltip,
  Switch,
  Typography,
} from "@material-ui/core";
import { Add, DeleteOutline } from "@material-ui/icons";
import TestingModeDialog from "./TestingModeDialog";

export default function TestingMode({ formikProps, editState, permissions }) {
  const [dialogState, setDialogState] = useState(false);

  const columns = [
    {
      name: "phone_no",
      label: "Phone Number",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "",
      options: {
        empty: true,
        customBodyRenderLite: (_, rowIndex) => (
          <IconButton
            disabled={!editState}
            style={{
              color: editState ? "#ff0000" : "#00000042",
              float: "right",
            }}
            onClick={() => deletePhoneNo(rowIndex)}
          >
            <DeleteOutline />
          </IconButton>
        ),
      },
    },
  ];

  return (
    <Paper>
      <Grid item xs={12}>
        <Grid container alignItems="center" style={{ minHeight: "47px" }}>
          <Grid item xs={6}>
            <Typography
              variant="h4"
              display="inline"
              style={{ marginRight: "0.5rem", verticalAlign: "middle" }}
            >
              Testing Mode
            </Typography>

            <Switch
              name="testMode"
              disabled={!editState}
              defaultChecked={formikProps.values.testMode}
              onChange={formikProps.handleChange}
            />

            {formikProps.errors.phoneNumbers && (
              <Typography
                variant="caption"
                display="block"
                style={{ color: "#f44336" }}
              >
                At least one number is required *
              </Typography>
            )}
          </Grid>

          <Grid item xs={6} container justifyContent="flex-end">
            {editState && (
              <Tooltip title="Add Phone Number">
                <IconButton
                  color="primary"
                  onClick={() => setDialogState(true)}
                >
                  <Add />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <DataTable
          data={formikProps.values.phoneNumbers}
          columns={columns}
          options={options}
        />
      </Grid>

      <TestingModeDialog
        formikProps={formikProps}
        dialogState={dialogState}
        setDialogState={setDialogState}
      />
    </Paper>
  );

  function deletePhoneNo(rowIndex) {
    formikProps.setValues((prevValue) => ({
      ...prevValue,
      phoneNumbers: prevValue.phoneNumbers.filter(
        (_, index) => index !== rowIndex
      ),
    }));
  }
}

const options = {
  selectableRows: "none",
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  filter: false,
};
