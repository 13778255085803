import convertDate from "../../../util/convertDate";
import { titleCase, sentenceCase } from "change-case-all";

export const integrationDetailsObject = {
  ID: "",
  name: "",
  groupID: "",
  requiredCredentials: [],
  webhookIntegrations: true,
  webhookEvents: [],
  integrationStatus: true,
  description: "",
  createdBy: "",
  updatedBy: "",
  createdOn: "",
  updatedOn: "",
};

export function objectFromResponse(responseData) {
  return {
    ID: responseData._id,
    name: titleCase(responseData.name) || "",
    groupID: responseData.integration_group_id || "",
    requiredCredentials: responseData.required_credentials || [],
    webhookIntegrations: responseData.webhook_integration_available || true,
    webhookEvents: responseData.webhook_event || [],
    integrationStatus: responseData.is_active || false,
    description: sentenceCase(responseData.description) || "",
    createdBy: responseData.created_by || "",
    updatedBy: responseData.updated_by || "",
    createdOn: convertDate(responseData.created_on) || "",
    updatedOn: convertDate(responseData.updated_on) || "",
  };
}

export function objectFromFormData(formData) {
  return {
    name: titleCase(formData.name),
    integration_group_id: formData.groupID,
    required_credentials: formData.requiredCredentials,
    webhook_integration_available: formData.webhookIntegrations,
    webhook_event: formData.webhookEvents,
    is_active: formData.integrationStatus,
    description: sentenceCase(formData.description),
    icon_url: "http:",
    authorization_url: "http:",
  };
}
