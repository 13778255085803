import { useRef } from "react";
import { useDispatch } from "react-redux";
import {
  alertSuccess,
  alertError,
  alertInfo,
  alertWarning,
} from "../redux/actions/alertActions";

export default function useAlert() {
  const dispatch = useDispatch();

  return useRef({
    success: (message) => dispatch(alertSuccess(message)),

    error: (message) => dispatch(alertError(message)),

    info: (message) => dispatch(alertInfo(message)),

    warning: (message) => dispatch(alertWarning(message)),
  }).current;
}
