import { useHistory } from "react-router-dom";
import DataTable from "../../../custom components/DataTable";
import convertDate from "../../../util/convertDate";

export default function Table({ botServices }) {
  const history = useHistory();

  const options = {
    onRowClick: (rowData, rowMetaData) =>
      history.push(
        "/bot-service-details/" + botServices[rowMetaData.dataIndex]._id
      ),
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  return <DataTable columns={columns} options={options} data={botServices} />;
}

const columns = [
  {
    name: "name",
    label: "Bot Service Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "repo_name",
    label: "Repository Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "nlu_port",
    label: "NLU Port",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "actions_port",
    label: "Actions Port",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "status.name",
    label: "Status",
    options: {
      customBodyRender: (value) => {
        return value ? value.replaceAll("_", " ") : "Not Available";
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
