import { useState } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { MdOutlineAddComment, MdDriveFileMoveOutline } from "react-icons/md";

import { ENV } from "../../../config/config";
import { Autocomplete } from "../../../custom components";
import { useAxios, useAlert } from "../../../custom hooks";

export default function MoveBotUtterancesToolbar({
  sourceServiceID,
  selectedRows,
  setSelectedRows,
  botServiceList,
}) {
  const axios = useAxios();
  const alert = useAlert();
  const [dialogState, setDialogState] = useState(false);

  return (
    <Grid
      container
      justifyContent="flex-end"
      style={{ width: "80%", margin: "5px", gap: "5px" }}
    >
      <MigrateUtterances />

      <CopyUtterances />

      <Dialog open={dialogState} onClose={handleClose} fullWidth>
        <DialogTitle>Select a Bot Service to copy to</DialogTitle>

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{ botServiceID: "" }}
          validationSchema={Yup.object().shape({
            botServiceID: Yup.string().required("Bot Service Name is required"),
          })}
          onSubmit={(formData, { setSubmitting }) => {
            axios({
              url: "/copy/utterances",
              method: "POST",
              data: {
                bot_service_id: formData.botServiceID,
                utterance_ids: selectedRows,
              },
            }).then((response) => {
              if (response.status) {
                alert.success(response.message);
                setSubmitting(false);
                handleClose();
                setSelectedRows([]);
              }
            });
          }}
        >
          {(formikProps) => (
            <form noValidate onSubmit={formikProps.handleSubmit}>
              <DialogContent>
                <Autocomplete
                  name="botServiceID"
                  label="Bot Service ID"
                  defaults={{
                    primaryKey: "_id",
                    displayLabel: "name",
                  }}
                  options={botServiceList}
                  formikProps={formikProps}
                />
              </DialogContent>

              <DialogActions style={{ paddingRight: "24px" }}>
                <Button
                  onClick={formikProps.handleSubmit}
                  variant="contained"
                  color="primary"
                  disabled={
                    formikProps.dirty
                      ? formikProps.isSubmitting || !formikProps.isValid
                      : !formikProps.dirty
                  }
                >
                  Copy
                </Button>

                <Button
                  onClick={handleClose}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </Grid>
  );

  function MigrateUtterances() {
    return (
      ENV !== "prod" && (
        <Tooltip title="Migrate Utterances">
          <IconButton onClick={migrateUtterances} color="primary">
            <MdOutlineAddComment />
          </IconButton>
        </Tooltip>
      )
    );

    function migrateUtterances() {
      axios({
        url: "/move/utterances",
        method: "POST",
        data: {
          bot_service_id: sourceServiceID,
          utterance_ids: selectedRows,
        },
      })
        .then((response) => {
          if (response.status) {
            alert.success(response.message);
            setSelectedRows([]);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  function CopyUtterances() {
    return (
      <Tooltip title="Copy Utterances to another Bot Service">
        <IconButton onClick={() => setDialogState(true)} color="primary">
          <MdDriveFileMoveOutline />
        </IconButton>
      </Tooltip>
    );
  }

  function handleClose() {
    setDialogState(false);
  }
}
