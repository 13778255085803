import { useState, useEffect } from "react";
import { usePermissions, useAxios, useLoader } from "../../custom hooks";
import { Loader, PageHeader } from "../../custom components";

import Form from "./admin page details components/Form";
import {
  adminPageDetailsObject,
  objectFromResponse,
} from "./admin page details components/adminPageDetailsObject";

export default function AdminPageDetails(props) {
  const axios = useAxios();
  const loader = useLoader();
  const pagePermissions = usePermissions(props.pageID);

  const [formData, setFormData] = useState(adminPageDetailsObject);
  const [editState, setEditState] = useState(true);

  const pageID = props.match.params.pageID || "";

  useEffect(() => {
    loader.start(1);

    if (!!pageID) {
      setEditState(false);

      axios({
        url: "/admin/page/" + pageID,
      })
        .then((response) => {
          setFormData(objectFromResponse(response.data));
          loader.apiComplete();
        })
        .catch((error) => console.log(error));
    } else {
      loader.apiComplete();
    }
  }, [axios, pageID, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Admin Page Details"
        breadcrumbs={[{ name: "Admin Pages", location: "pages/admin/all" }]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      <Form
        formData={formData}
        setFormData={setFormData}
        editState={editState}
        setEditState={setEditState}
        pagePermissions={pagePermissions}
      />
    </Loader>
  );
}
