import React from "react";

import * as Yup from "yup";
import { Formik } from "formik";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core";

import { TextField } from "../../../../custom components";

import {
  alertError,
  alertSuccess,
} from "../../../../redux/actions/alertActions";

import { useAxios } from "../../../../custom hooks";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
}));

export default function CreateGroupPopUp({
  createGroupModal,
  setCreateGroupModal,
  setNewGroup,
  authToken,
  agentID,
}) {
  const groupDetails = {
    group_name: "",
  };

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const axios = useAxios({
    baseURL: "adminV2",
    customAuthToken: "Bearer " + authToken,
  });

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={createGroupModal}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>Create Group</DialogTitle>

      <Formik
        enableReinitialize
        initialValues={groupDetails}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={(formData, { setSubmitting }) => {
          axios({
            url: "/broadcast/create/group/" + agentID,
            method: "POST",
            data: {
              group_name: formData.group_name,
              is_active: true,
            },
          })
            .then((response) => {
              if (response.status) {
                dispatch(alertSuccess(response.message));
                setNewGroup(response.data.group_id);
                history.push(
                  "/broadcast-group-details/" + response.data.group_id
                );
              } else {
                dispatch(alertError(response.message));
              }
              setSubmitting(false);
              handleClose();
            })
            .catch((err) => {
              console.log(err);
              dispatch(alertError("Please provide a unique group name."));
            });
        }}
      >
        {(dialogFormikProps) => (
          <form noValidate onSubmit={dialogFormikProps.handleSubmit}>
            <DialogContent>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    name="group_name"
                    label="Group Name"
                    formikProps={dialogFormikProps}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: "12px 24px 24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                    value="save"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "end" }}>
                  <Button
                    onClick={dialogFormikProps.handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={
                      !(dialogFormikProps.dirty && dialogFormikProps.isValid)
                    }
                  >
                    Create Group
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );

  function handleClose() {
    setCreateGroupModal(false);
  }
}

const validationSchema = Yup.object().shape({
  group_name: Yup.string().max(50).required("Group Name is required."),
});
