import React, { useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { Paper, ComponentHeader } from "../../../custom components";

function PermissionsDisplay({
  permissions,
  setValues,
  editState,
  permissionPages,
  componentPermissions,
}) {
  const [selectAllOption, setselectAllOption] = useState(false);

  function handleSelectAll(e) {
    setselectAllOption(e.target.checked);
    const value = {};
    permissionPages.forEach((item) => {
      value[item["page_slug"]] = {
        access: e.target.checked,
      };
    });

    setValues((prevValue) => ({
      ...prevValue,
      permissions: {
        adminPermissions: value,
        managerPermissions: value,
        billerPermissions: value,
      },
    }));
  }

  return (
    <Paper gridSpacing={1}>
      <Grid item xs={10}>
        <ComponentHeader headerName="Permissions" />
      </Grid>

      <Grid item xs={2}>
        <Typography style={{ color: !editState && "grey", fontWeight: 600 }}>
          <Checkbox
            checked={selectAllOption}
            disabled={!editState}
            onChange={handleSelectAll}
          />
          Select All
        </Typography>{" "}
      </Grid>

      <Grid item xs={12}>
        <Table size="medium">
          <TableHead style={{ background: "#bbd4ff" }}>
            <TableRow>
              <TableCell>Pages</TableCell>
              <TableCell align="center">Admin Permissions</TableCell>
              <TableCell align="center">Manager Permissions</TableCell>
              <TableCell align="center">Biller Permissions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissionPages.map((permissionPage) => (
              <TableRow key={permissionPage._id}>
                <TableCell>{permissionPage.page_name}</TableCell>
                {[
                  { id: "adminPermissions", name: "Admin Permissions" },
                  { id: "managerPermissions", name: "Manager Permissions" },
                  { id: "billerPermissions", name: "Biller Permissions" },
                ].map((category) => (
                  <TableCell align="center" key={category.id}>
                    <Checkbox
                      name={category.id + "." + permissionPage.page_slug}
                      checked={
                        permissions[category.id][permissionPage.page_slug]
                          ?.access || false
                      }
                      onChange={permissionsChange}
                      disabled={!(editState && componentPermissions.update)}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Paper>
  );

  function permissionsChange(event) {
    const { name, checked } = event.target;

    var permissionName = name.split(".");

    setValues((prevValue) => ({
      ...prevValue,
      permissions: {
        ...prevValue.permissions,
        [permissionName[0]]: {
          ...prevValue.permissions[permissionName[0]],
          [permissionName[1]]: {
            ...prevValue.permissions[permissionName[0]][permissionName[1]],
            access: checked,
          },
        },
      },
    }));
  }
}

export default React.memo(PermissionsDisplay);
