import * as types from "../../constants/reducerConstants";

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        authToken: actions.authToken,
        userDetails: actions.userDetails,
        pagePermissions: actions.pagePermissions,
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        authToken: undefined,
        userDetails: undefined,
        pagePermissions: undefined,
      };

    default:
      return state;
  }
}
