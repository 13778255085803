import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  nameCaptionReplyTime: Yup.string()
    .max(35, "It should not be greater than 35 letters")
    .required("Title is required!"),

  welcomeMessage: Yup.string()
    .max(70, "Should not be more then 70 letters")
    .required("Welcome Message is required"),

  buttonText: Yup.string()
    .max(20, "Should not be greater than 20 letters")
    .required("Button text is required."),

  preFilledMessage: Yup.string().max(
    500,
    "Should not be greater than 30 characters"
  ),
  TitleName: Yup.string()
    .max(20, "Should not be greater than 20 characters")
    .required("Title is required"),
  whatsappNumber: Yup.string().min(6).required("It is required"),
  parentUrl: Yup.string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\\?[a-zA-Z-_%\\/@?]+)*([^\\/\w\\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "Enter correct domain name"
    )
    .required("Domain Name is required"),
});

export default validationSchema;
