import * as types from "../../constants/reducerConstants";

export function loaderStart(apiCount = 1) {
  return {
    type: types.LOADER_START,
    apiCount: apiCount,
  };
}

export function apiComplete(apiCount = 1) {
  return {
    type: types.API_COMPLETE,
    apiCount: apiCount,
  };
}

export function loaderStop() {
  return {
    type: types.LOADER_STOP,
  };
}

export function loaderError() {
  return {
    type: types.LOADER_ERROR,
  };
}
