import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { dashboardLayoutRoutes, authLayoutRoutes } from "./index";

import DashboardLayout from "../layouts/DashboardLayout";
import AuthLayout from "../layouts/AuthLayout";

//Contract Page
import AdminLayout from "../pages/contract/Admin/AdminLayout";
import CustomerLayout from "../pages/contract/Customer/CustomerLayout";

//Create Profile Page
import RegisterMember from "../pages/member/RegisterMember";

//404 Page when we visit something thats not a correct path
import Page404 from "../pages/error/Page404";
import BotBuilder from "../pages/bot builder/BotBuilder";
import BotInterface from "../pages/bot builder/bot details component/BotInterface";

import WhatsappWidgetInterface from "../pages/style whatsapp widget/style whatsapp widget components/WhatsappInterface";
import StyleWhatsappWidget from "../pages/style whatsapp widget/StyleWhatsappWidget";

import ErrorWithoutAnyButton from "../pages/bot builder/bot details component/ErrorWithoutAnyButton";
import GroupList from "../pages/broadcast/group/GroupList";
import GroupDetails from "../pages/broadcast/group/groups components/GroupDetails";
import BroadcastList from "../pages/broadcast/broadcast/BroadcastList";
import BroadcastDetails from "../pages/broadcast/broadcast/broadcast components/BroadcastDetails";

const childRoutes = (Layout, routes) =>
  routes.map(({ component: Component, children, path, id }, index) => {
    return children ? (
      children.map((child, index) => {
        const ElementComponent = child.component || React.Fragment;
        return (
          <Route
            key={index}
            path={child.path}
            exact
            render={(props) => (
              <Layout>
                <ElementComponent pageID={child.id} {...props} />
              </Layout>
            )}
          />
        );
      })
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component pageID={id} {...props} />
          </Layout>
        )}
      />
    ) : null;
  });

const Routes = () => (
  <Router>
    <Switch>
      {/* Child routes takes in layout and the routes in those layouts. */}
      {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
      {childRoutes(AuthLayout, authLayoutRoutes)}
      {/* Routes for contract page */}
      <Route exact path="/contract/admin/:businessID" component={AdminLayout} />
      <Route
        exact
        path="/contract/customer/:businessID"
        component={CustomerLayout}
      />
      <Route exact path="/botBuilder" component={BotBuilder} />

      <Route exact path="/botInterface" component={BotInterface} />

      <Route
        exact
        path="/whatsAppInterface"
        component={WhatsappWidgetInterface}
      />
      <Route exact path="/errorPageLoaded" component={ErrorWithoutAnyButton} />

      <Route exact path="/styleYourBot" component={StyleWhatsappWidget} />
      {/*Routes to render profile page for invited member*/}
      <Route exact path="/register/:authToken" component={RegisterMember} />

      <Route exact path="/group-list" component={GroupList} />

      <Route
        exact
        path="/broadcast-group-details/:groupID?"
        component={GroupDetails}
      />

      <Route exact path="/broadcast-list" component={BroadcastList} />

      <Route
        exact
        path="/broadcast-details/:broadcastID?"
        component={BroadcastDetails}
      />
      <Route exact path="/styleyourbot" component={StyleWhatsappWidget} />

      {/*Routes to render profile page for invited member*/}
      <Route exact path="/register/:authToken" component={RegisterMember} />

      {/* Routes to render 404 page */}
      <Route
        render={() => (
          // If nothing then just render 404
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);
export default Routes;
