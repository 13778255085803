import React, { useState } from "react";
import { Formik } from "formik";
import FormDisplay from "./FormDisplay";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { validationSchema } from "./validationSchema";
import { objectFromFormData } from "./BotDetailsObject";
import axios from "axios";
import { alertError, alertSuccess } from "../../../redux/actions/alertActions";
import BotView from "./BotView";
import { ENV } from "../../../config/config";

export default function Form({
  configureDetails,
  setEditState,
  editState,
  setConfigureDetails,
  token,
  agentId,
  originalImage,
  setOriginalImage,
}) {
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState("panel1");

  const [styleModal, setStyleModal] = useState(false);

  return (
    <Grid alignItems="stretch" container spacing={2}>
      <Formik
        enableReinitialize
        initialValues={configureDetails}
        validationSchema={validationSchema}
        onSubmit={(configureDetails, { setSubmitting }) => {
          var data = JSON.stringify(objectFromFormData(configureDetails));
          var config = {
            method: "patch",
            url:
              "https://" +
              (ENV === "prod" ? "" : ENV + ".") +
              "api.dolphinchat.ai/bot/v1/agent/widget",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            data: data,
          };
          axios(config)
            .then(function (response) {
              if (response.data.status === true) {
                dispatch(alertSuccess(response.data.message));
                setEditState(false);
                setSubmitting(false);
              } else {
                dispatch(alertError(response.data.message));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }}
      >
        {(props) => (
          <React.Fragment>
            <Grid item xs={12} sm={7}>
              <form noValidate onSubmit={props.handleSubmit}>
                <FormDisplay
                  formikProps={props}
                  setConfigureDetails={setConfigureDetails}
                  configureDetails={configureDetails}
                  setEditState={setEditState}
                  editState={editState}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  styleModal={styleModal}
                  setStyleModal={setStyleModal}
                  agentId={agentId}
                  originalImage={originalImage}
                  setOriginalImage={setOriginalImage}
                />
              </form>
            </Grid>
            <Grid item xs={5}>
              <BotView
                formikProps={props}
                expanded={expanded}
                setExpanded={setExpanded}
                agentId={agentId}
              />
            </Grid>
          </React.Fragment>
        )}
      </Formik>
    </Grid>
  );
}
