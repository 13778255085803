import { useAlert } from "../custom hooks";
import _ from "lodash";
import TextFieldWithIcon from "./TextFieldWithIcon";
import { Tooltip, IconButton } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";

export default function TextFieldWithCopy(props) {
  const alert = useAlert();

  return (
    <TextFieldWithIcon
      {...props}
      icon={
        <Tooltip title={"Copy " + props.label}>
          <IconButton
            style={{ padding: 0, margin: "12px" }}
            color="primary"
            onClick={() => {
              navigator.clipboard.writeText(
                props.value || _.get(props.formikProps.values, props.name)
              );
              alert.success(props.label + " has been copied to clipboard!");
            }}
          >
            <FileCopy fontSize="small" />
          </IconButton>
        </Tooltip>
      }
    />
  );
}
