import React, { useState, useEffect } from "react";

import { Loader, PageHeader } from "../../custom components";
import { useLoader, useAxios, usePermissions } from "../../custom hooks";
import {
  botServiceDetailsObject,
  objectFromResponse,
} from "./bot service details components/botServiceDetailsObject";
import Form from "./bot service details components/Form";
import MoveBotUtterances from "./bot service details components/MoveBotUtterances";

export default function BotServiceDetails(props) {
  const loader = useLoader();
  const axios = useAxios();
  const pagePermissions = usePermissions(props.pageID);

  const botServiceID = props.match.params.botServiceID || "";

  const [formData, setFormData] = useState(botServiceDetailsObject);
  const [editState, setEditState] = useState(!botServiceID);
  const [utterances, setUtterances] = useState([]);
  const [botServiceList, setBotServiceList] = useState([]);

  useEffect(() => {
    if (!!botServiceID) {
      loader.start(2);

      axios({
        url: "/bots?bot_service_id=" + botServiceID,
      })
        .then((response) => {
          setFormData(objectFromResponse(response.data));

          axios({
            url: "/service/utterance?bot_service_id=" + response.data._id,
          }).then((response) => {
            if (response.status) {
              loader.apiComplete();

              setUtterances(response.data);
            }
          });
        })
        .catch((error) => console.log(error));

      axios({
        url: "/bot/service/detail",
      }).then((response) => {
        if (response.status) {
          loader.apiComplete();

          setBotServiceList(response.data);
        }
      });
    }
  }, [botServiceID, axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Bot Service Details"
        breadcrumbs={[{ name: "Bot Services", location: "bot-services" }]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      <Form
        formData={formData}
        setFormData={setFormData}
        editState={editState}
        setEditState={setEditState}
        pagePermissions={pagePermissions}
      />

      {!editState && (
        <MoveBotUtterances
          sourceServiceID={formData.botServiceID}
          utterances={utterances}
          botServiceList={botServiceList}
        />
      )}
    </Loader>
  );
}
