import React, { useState } from "react";

import { useDispatch } from "react-redux";

import { Grid, Button } from "@material-ui/core";

import { Publish, Person, GetApp } from "@material-ui/icons";

import AWS from "aws-sdk";

import UserListTable from "./UserListTable";

import { ComponentHeader, Paper } from "../../../../custom components";
import { useAlert, useAxiosV2 } from "../../../../custom hooks";
import { alertError } from "../../../../redux/actions/alertActions";

export default function UserListComponent({
  userList,
  setListUsers,
  groupID,
  setUser,
  formikProps,
  setUserList,
  authToken,
}) {
  const alert = useAlert();
  const dispatch = useDispatch();

  const axios = useAxiosV2({
    baseURL: "adminV2",
  });

  const [addUserPopUp, setAddUserPopUp] = useState({
    state: false,
    groupUserID: "",
  });

  const downloadDummyCSV = () => {
    window.location.href =
      "https://dc-chat-media.s3.ap-south-1.amazonaws.com/broadcast/1639570500690/user_uploading_format.xlsx";
  };

  const handleUploadFile = (CSV) => {
    if (formikProps.values.groupStatus) {
      const file = CSV.target.files[0];
      const reader = new FileReader();

      if (file) {
        reader.readAsDataURL(file);
        reader.onload = function () {
          var bucketName = "dc-chat-media";
          var bucketRegion = "ap-south-1";
          var identityPoolID =
            "ap-south-1:16fa1fcf-7cdd-4593-9ee0-6f621526defc";

          AWS.config.update({
            region: bucketRegion,
            credentials: new AWS.CognitoIdentityCredentials({
              IdentityPoolId: identityPoolID,
            }),
          });

          var s3 = new AWS.S3({
            apiVersion: "2006-03-01",
            params: {
              Bucket: bucketName,
            },
          });
          var filePath = groupID + "/" + new Date().getTime() + "/" + file.name;
          var upload = s3.upload({
            Key: filePath,
            ACL: "public-read",
            Body: file,
          });
          var promise = upload.promise();
          promise.then(function (data) {
            axios({
              url: "/broadcast/create/group/user/" + groupID,
              method: "POST",
              authToken: "Bearer " + authToken,
              data: {
                data_type: "url",
                group_id: groupID,
                url: data.Location,
              },
            }).then((response) => {
              if (response.status) {
                alert.success("User has been uploaded successfully");
                setUser(data.Location);
              } else {
                dispatch(
                  alertError("Something went wrong , Please try again.")
                );
              }
            });
          });
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      }
    } else {
      alert.warning(
        "The group status is inactive, make it active to add users"
      );
    }
  };
  const onClickCSV = (e) => {
    if (!formikProps.values.groupStatus) {
      alert.warning(
        "The group status is inactive, make it active to add users"
      );
    }
  };
  const handleAddNewUser = () => {
    if (formikProps.values.groupStatus) {
      setAddUserPopUp({
        state: true,
        groupUserID: "",
      });
    } else {
      alert.warning(
        "The group status is inactive, make it active to add users"
      );
    }
  };
  return (
    <Paper>
      <Grid item xs={4}>
        <ComponentHeader headerName="Group Users" />
      </Grid>

      <Grid item xs></Grid>

      <Grid
        item
        style={{
          textAlign: "end",
          alignSelf: "center",
        }}
      >
        <Button variant="contained" color="primary" onClick={downloadDummyCSV}>
          <GetApp /> &nbsp; Download Sample Format
        </Button>
      </Grid>

      <Grid
        style={{
          textAlign: "end",
          alignSelf: "center",
        }}
        item
      >
        <Button
          onClick={handleAddNewUser}
          variant="contained"
          color="primary"
          component="span"
        >
          <Person />
          &nbsp; Add New User
        </Button>
      </Grid>

      <Grid
        item
        style={{
          textAlign: "end",
          alignSelf: "center",
        }}
      >
        <input
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
          accept=".xlsx"
          disabled={!formikProps.values.groupStatus}
          onChange={(e) => handleUploadFile(e)}
          onClick={(e) => {
            e.target.value = null;
          }}
        />
        <label style={{ marginTop: "10px" }} htmlFor="raised-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={onClickCSV}
          >
            <Publish />
            &nbsp; Upload Data File
          </Button>
        </label>
      </Grid>

      <Grid item xs={12}>
        <UserListTable
          setUser={setUser}
          userList={userList}
          groupID={groupID}
          setListUsers={setListUsers}
          addUserPopUp={addUserPopUp}
          setUserList={setUserList}
          setAddUserPopUp={setAddUserPopUp}
          authToken={authToken}
        />
      </Grid>
    </Paper>
  );
}
