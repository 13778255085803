import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import DateFnsUtils from "@date-io/date-fns";

import styled, { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";

import { Snackbar as MuiSnackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import createTheme from "./theme";
import Routes from "./routes/Routes";
import { alertClose } from "./redux/actions/alertActions";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const Snackbar = styled(MuiSnackbar)`
  top: 8px;
  right: 10px;
`;

export default function App() {
  const dispatch = useDispatch();

  const theme = useSelector((state) => state.themeReducer);
  const alert = useSelector((state) => state.alertReducer);

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | DolphinChat"
          defaultTitle="DolphinChat Dashboard"
        />
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
              <ThemeProvider theme={createTheme(theme.currentTheme)}>
                <Snackbar
                  open={alert.status}
                  autoHideDuration={4000}
                  onClose={() => {
                    dispatch(alertClose());
                  }}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <Alert
                    variant="filled"
                    onClose={() => {
                      dispatch(alertClose());
                    }}
                    severity={alert.alertType || "info"}
                  >
                    {alert.alertMessage}
                  </Alert>
                </Snackbar>
                <Routes />
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </HelmetProvider>
    </React.Fragment>
  );
}
