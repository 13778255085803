import React, { useState, useEffect } from "react";

import Table from "./pages components/Table";
import { PageHeader, Loader } from "../../custom components";
import { useLoader, useAxios, usePermissions } from "../../custom hooks";

export default function SuperadminPages(props) {
  const loader = useLoader();
  const axios = useAxios();
  const pagePermissions = usePermissions(props.pageID);

  const [pages, setPages] = useState([]);

  useEffect(() => {
    loader.start();

    axios({
      url: "/pages",
    }).then((response) => {
      loader.stop();
      setPages(response.data);
    });
  }, [axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Superadmin Pages"
        addIcon={["Add New Page", "/pages/superadmin", pagePermissions.create]}
      />

      <Table pages={pages} />
    </Loader>
  );
}
