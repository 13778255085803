import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Formik } from "formik";
import useAxios from "../../../custom hooks/useAxios";

import validationSchema from "./validationSchema";
import FormDisplay from "./FormDisplay";

import { objectFromFormData } from "./memberDetailsObject";
import { alertSuccess, alertError } from "../../../redux/actions/alertActions";

export default function Form({ formData, setFormData, groupList }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const axios = useAxios();

  const [editState, setEditState] = useState(true);

  useEffect(() => {
    if (formData.memberID !== "") {
      setEditState(false);
    }
  }, [formData]);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        var url = "/member";
        var method = "POST";

        if (formData.memberID) {
          url = url + "/" + formData.memberID;
          method = "PATCH";
        }

        axios({
          url: url,
          method: method,
          data: objectFromFormData(formData),
        })
          .then((response) => {
            setSubmitting(false);
            if (response.status) {
              if (method === "POST") {
                dispatch(alertSuccess("Invite sent to members email address"));
                history.push("/members");
              } else {
                setEditState(false);
                setFormData(formData);
                dispatch(alertSuccess("Member Details Updated!"));
              }
            } else {
              dispatch(alertError(response.message || "Something went wrong"));
            }
          })
          .catch((error) => {
            setSubmitting(false);
            dispatch(alertError(error.message || "Something went wrong"));
          });
      }}
    >
      {(props) => (
        <form noValidate onSubmit={props.handleSubmit}>
          <FormDisplay
            formikProps={props}
            editState={editState}
            setEditState={setEditState}
            groupList={groupList}
          />
        </form>
      )}
    </Formik>
  );
}
