import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import Form from "./Form";

import { useAxiosV2, useLoader } from "../../../../custom hooks";

import { PageHeader } from "../../../../custom components";

import { groupDetailsObject, objectFromResponse } from "./groupDetailsObject";

import { apiComplete } from "../../../../redux/actions/loaderActions";
import { alertError } from "../../../../redux/actions/alertActions";

export default function GroupDetails(props) {
  var agentID = localStorage.getItem("agent_id")
    ? localStorage.getItem("agent_id")
    : "";

  var memberID = localStorage.getItem("member_id")
    ? localStorage.getItem("member_id")
    : "";

  const groupID = props.match.params.groupID || "";

  const loader = useLoader();
  const dispatch = useDispatch();

  const accessAxios = useAxiosV2({
    baseURL: "adminV2",
    disableAuthToken: true,
  });

  const axios = useAxiosV2({
    baseURL: "adminV2",
  });

  const [editState, setEditState] = useState(true);
  const [authToken, setAuthToken] = useState("");
  const [formData, setFormData] = useState(groupDetailsObject);
  const [userList, setUserList] = useState([]);
  //for deletion
  const [listUsers, setListUsers] = useState([]);
  //for adding users
  const [user, setUser] = useState({});

  useEffect(() => {
    if (Boolean(groupID)) {
      setEditState(false);
    }
  }, [groupID]);

  useEffect(() => {
    loader.start(2);
    if (Boolean(groupID)) {
      accessAxios({
        url: "/token/" + memberID,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          setAuthToken(response.data.token);
          axios({
            url: "/broadcast/get/group/" + agentID + "?group_id=" + groupID,
            method: "GET",
            authToken: "Bearer " + response.data.token,
          })
            .then((response) => {
              if (response.status) {
                apiComplete(1);
                setFormData(objectFromResponse(response.data));
              } else {
                dispatch(alertError(response.message));
              }
            })
            .catch((error) => {
              console.log(error);
            });
          axios({
            url:
              "/broadcast/get/group/user/" +
              groupID +
              "?start=" +
              0 +
              "&length=" +
              20,
            method: "GET",
            authToken: "Bearer " + response.data.token,
          }).then((response) => {
            if (response.status) {
              apiComplete(2);
              setUserList(response.data);
            } else {
              dispatch(alertError(response.message));
            }
          });
        }
      });
    } else {
      apiComplete();
    }
  }, [
    loader,
    groupID,
    listUsers,
    user,
    dispatch,
    axios,
    memberID,
    accessAxios,
    agentID,
  ]);
  return (
    <React.Fragment>
      <PageHeader
        pageName="Group Details"
        breadcrumbs={[
          {
            name: "Groups",
            location: `group-list?agent_id=${agentID}&member_id=${memberID}`,
          },
        ]}
        memberID={memberID}
      />

      <Form
        formData={formData}
        groupID={groupID}
        setFormData={setFormData}
        userList={userList}
        setUserList={setUserList}
        setListUsers={setListUsers}
        editState={editState}
        setEditState={setEditState}
        setUser={setUser}
        authToken={authToken}
      />
    </React.Fragment>
  );
}
