export const registerMemberObject = {
  firstName: "",
  lastName: "",
  email: "",
  gender: "",
};

export function objectFromResponse(responseObject) {
  return {
    firstName: responseObject.first_name,
    lastName: responseObject.last_name,
    email: responseObject.email,
    gender: responseObject.gender,
    token: responseObject.token,
  };
}

export function dataFromObject(registerMemberObject) {
  return {
    first_name: registerMemberObject.firstName,
    last_name: registerMemberObject.lastName,
    email: registerMemberObject.email,
    gender: registerMemberObject.gender,
    password1: registerMemberObject.password,
    password2: registerMemberObject.confirmPassword,
  };
}
