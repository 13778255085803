import React, { useState } from "react";
import "./whatsapp.css";

import WhatsappWidgetInterface from "./WhatsappInterface";
const MobileViewBot = ({
  formikProps,
  phoneView,
  setPhoneView,
  expanded,
  setExpanded,
  agentId,
}) => {
  const [showBubble, setShowBubble] = useState(true);
  return (
    <React.Fragment>
      {phoneView ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="whatsappsmartphone">
            <WhatsappWidgetInterface
              formikProps={formikProps}
              showBubble={showBubble}
              setShowBubble={setShowBubble}
              phoneView={phoneView}
              setPhoneView={setPhoneView}
              expanded={expanded}
              setExpanded={setExpanded}
              agentId={agentId}
            />
          </div>
        </div>
      ) : (
        <WhatsappWidgetInterface
          phoneView={phoneView}
          setPhoneView={setPhoneView}
          formikProps={formikProps}
          showBubble={showBubble}
          setShowBubble={setShowBubble}
          expanded={expanded}
          setExpanded={setExpanded}
          agentId={agentId}
        />
      )}
    </React.Fragment>
  );
};
export default MobileViewBot;
