import React from "react";
import { Helmet } from "react-helmet-async";
import { Paper } from "@material-ui/core";

import styled from "styled-components/macro";

import Form from "./signin details components/Form";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

export default function SignIn() {
  return (
    <Wrapper>
      <Helmet title="Sign In" />

      <Form />
    </Wrapper>
  );
}
