import {
  Grid,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { TextField } from "../../../custom components";
import { Formik } from "formik";
import * as Yup from "yup";

export default function TestingMode({
  formikProps,
  dialogState,
  setDialogState,
}) {
  return (
    <Dialog open={dialogState} onClose={handleClose}>
      <DialogTitle style={{ paddingBottom: "4px" }}>
        Add Phone Number
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={{
          countryCode: "91",
          phoneNumber: "",
        }}
        validationSchema={Yup.object().shape({
          countryCode: Yup.string()
            .matches(/^[0-9]{1,4}$/, "Please enter a valid Country code")
            .required("Country Code is required!"),
          phoneNumber: Yup.string()
            .matches(/^[1-9][0-9]{9}$/, "Please enter a valid Phone Number")
            .required("Phone Number is required!"),
        })}
        validateOnMount
        onSubmit={(formData) => {
          formikProps.setValues((prevValue) => ({
            ...prevValue,
            phoneNumbers: [
              ...prevValue.phoneNumbers,
              { phone_no: formData.countryCode + formData.phoneNumber },
            ],
          }));

          handleClose();
        }}
      >
        {(dialogFormikProps) => (
          <form>
            <DialogContent>
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <TextField
                    label="Country Code"
                    name="countryCode"
                    formikProps={dialogFormikProps}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {"( +"}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">{")"}</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    label="Phone Number"
                    name="phoneNumber"
                    formikProps={dialogFormikProps}
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions style={{ padding: "0px 24px 16px" }}>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !(dialogFormikProps.dirty && dialogFormikProps.isValid)
                }
                onClick={dialogFormikProps.handleSubmit}
              >
                Add
              </Button>

              <Button onClick={handleClose} variant="outlined" color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );

  function handleClose() {
    setDialogState(false);
  }
}
