import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  Grid,
  Typography,
  Switch,
  MenuItem,
  Button,
  Divider as MuiDivider,
  IconButton,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import { SketchPicker } from "react-color";
import TextField from "../../../custom components/TextField";
import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";
import {
  ColorLens,
  ExpandMore,
  DeleteOutlined,
  Info,
  CloudUpload,
  Check,
} from "@material-ui/icons";
import TextFieldWithIcon from "../../../custom components/TextFieldWithIcon";
import FormHeader from "../../../custom components/FormHeader";
import CropImage from "./CropImage";
import StyleModalConfirmation from "./ConfirmStyleModal";

const Divider = styled(MuiDivider)(spacing);
const useStyles = makeStyles((theme) => ({
  heading: {
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  iconButton: {
    "&:hover": {
      background: "none",
    },
  },
  avatar: {
    "&:focus": {
      outline: "1px dashed #0091f3",
    },
  },
}));

export default function FormDisplay({
  formikProps,
  setEditState,
  editState,
  expanded,
  setExpanded,
  styleModal,
  setStyleModal,
  agentId,
  originalImage,
  setOriginalImage,
}) {
  const classes = useStyles();

  const [backgroundHide, setBackgroundHide] = useState(true);

  const [foregroundHide, setForegroundHide] = useState(true);

  const [openModal, setOpenModal] = useState(false);

  const [options, setOptions] = useState(true);

  const [optionalMenuFields] = useState({
    text: "Visit Website",
    type: "url",
    url: "www.dolphinchat.ai",
  });

  const [inputList] = useState({
    text: "",
    type: "",
    payload: "",
    url: "",
  });

  const [image, setImage] = useState({
    image: "",
    imageName: "",
  });

  const pickerStyle = {
    default: {
      picker: {
        position: "absolute",
        bottom: "53px",
        left: "40%",
      },
    },
  };
  const handleChangeMenu = (event, index) => {
    const { name, value } = event.target;
    const nameText = name.split(".").pop();
    let newArr = formikProps.values.menu.map((item, i) => {
      if (index === i) {
        if (name === "type") {
          if (value === "url") delete item.payload;
          if (value === "postback") delete item.url;
        }
        return { ...item, [nameText]: value };
      } else {
        return item;
      }
    });
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      menu: newArr,
    }));
  };
  let useClickOutside = (handler) => {
    let domNode = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!domNode.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", maybeHandler);

      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return domNode;
  };
  let domNode = useClickOutside(() => {
    setForegroundHide(true);
  });

  let useClickForOutside = (handler) => {
    let domNodeBackground = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!domNodeBackground.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", maybeHandler);

      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return domNodeBackground;
  };
  let domNodeBackground = useClickForOutside(() => {
    setBackgroundHide(true);
  });
  const handleAddField = () => {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      menu: [...formikProps.values.menu, inputList],
    }));
  };

  function handleSubmit() {
    setOptions(false);
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      menu: [...formikProps.values.menu, optionalMenuFields],
    }));
  }

  const removeField = (index) => {
    const newMenuValues = [...formikProps.values.menu];
    newMenuValues.splice(index, 1);
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      menu: newMenuValues,
    }));
  };

  const handleForeground = () => {
    setForegroundHide(!foregroundHide);
  };

  const handleChangeAvatar = (e) => {
    var src = e.target.currentSrc;
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      bubbleAvatar: src,
    }));
  };

  const handleChangePhoto = (photo) => {
    const file = photo.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setImage({
          image: reader.result,
          imageName: photo.target.files[0],
        });
      },
      false
    );
    if (file) {
      reader.readAsDataURL(file);
    }

    setOpenModal(true);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function toggleEdit() {
    setEditState(!editState);
  }
  return (
    <Paper
      elevation={2}
      style={{
        padding: "15px",
        height: "93vh",
        maxHeight: "93vh",
        overflow: "auto",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormHeader formName="Widget Style" />
        </Grid>
        <Grid item xs={6} style={{ textAlign: "end" }}>
          {!editState && (
            <React.Fragment>
              <Button variant="contained" color="primary" onClick={toggleEdit}>
                Edit
              </Button>
            </React.Fragment>
          )}

          {editState && (
            <React.Fragment>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: "10px" }}
                onClick={() => setEditState(!editState)}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  formikProps.dirty
                    ? formikProps.isSubmitting || !formikProps.isValid
                    : !formikProps.dirty
                }
              >
                Save
              </Button>
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h5" className={classes.heading}>
                General settings
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Manage basic settings from here, Add ripple effect, enable
                floating etc.
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Grid style={{ marginBottom: "30px" }} container spacing={3}>
                <Grid item xs={3}>
                  <Typography style={{ padding: "12px" }}>
                    Auto Open &nbsp;
                    <Tooltip title="Enable this to automatically open the conversation window on page load.">
                      <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
                        <Info />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid style={{ textAlign: "end" }} item xs={3}>
                  <Switch
                    checked={formikProps.values.windowAutoOpen}
                    name="windowAutoOpen"
                    onChange={formikProps.handleChange}
                    disabled={!editState}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography varinat="h6" style={{ padding: "12px" }}>
                    Ripple Effect &nbsp;
                    <Tooltip title="Enable this to add a ripple effect to the widget bubble and get your users attention.">
                      <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
                        <Info />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid style={{ textAlign: "end" }} item xs={3}>
                  <Switch
                    checked={formikProps.values.bubbleRipple}
                    name="bubbleRipple"
                    onChange={formikProps.handleChange}
                    disabled={!editState}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ padding: "12px" }}>
                    Float &nbsp;
                    <Tooltip title="Enable this to give the user freedom to move the widget horizontally to avoid overlapping of website content.">
                      <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
                        <Info />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid style={{ textAlign: "end" }} item xs={3}>
                  <Switch
                    checked={formikProps.values.bubbleFloat}
                    name="bubbleFloat"
                    onChange={formikProps.handleChange}
                    disabled={!editState}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextFieldWithIcon
                    name="parentURL"
                    label="Domain Name"
                    case="none"
                    required
                    disabled={!editState}
                    formikProps={formikProps}
                    icon={
                      <Tooltip title="Domain of your website where you want to add a widget.">
                        <IconButton style={{ color: "#cbcbcb" }}>
                          <Info />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="popupText"
                    label="PopUp Text"
                    case="none"
                    required
                    disabled={!editState}
                    formikProps={formikProps}
                    style={{
                      paddingRight: "0px",
                    }}
                    inputProps={{
                      maxLength: 100,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {formikProps.values.popupText.length}/100
                          <Tooltip title="A welcome text for visitors to start the conversation.">
                            <IconButton style={{ color: "#cbcbcb" }}>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      formikProps.setValues((prevVal) => ({
                        ...prevVal,
                        popupText: e.target.value,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    name="bubblePosition"
                    label="Bubble Position"
                    value={formikProps.values.bubblePosition}
                    formikProps={formikProps}
                    disabled={!editState}
                  >
                    <MenuItem value={"left"}>Left</MenuItem>
                    <MenuItem value={"right"}>Right</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: "0.9rem" }} variant="h5">
                    Avatar
                  </Typography>
                  <Typography
                    style={{
                      color: "#808080",
                    }}
                    variant="body2"
                  >
                    Image that represents your business or chatbot use-case.
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      padding: "20px",
                      width: "500px",
                    }}
                  >
                    <Avatar
                      style={{
                        height: "60px",
                        width: "60px",
                        margin: "0 10px 0 10px",
                        cursor: "pointer",
                      }}
                      disabled={!editState}
                      onClick={handleChangeAvatar}
                      className={classes.avatar}
                      src="https://dc-chat-media.s3.ap-south-1.amazonaws.com/1c25502e214b4b21a5ff635d613d21a3/1632118620967/manAvatar.jpg"
                    />

                    <Avatar
                      style={{
                        height: "60px",
                        width: "60px",
                        margin: "0 10px 0 10px",
                        cursor: "pointer",
                      }}
                      disabled={!editState}
                      onClick={handleChangeAvatar}
                      src="https://dc-chat-media.s3.ap-south-1.amazonaws.com/1c25502e214b4b21a5ff635d613d21a3/1632118671542/manAvatar2.jpg"
                    />

                    <Avatar
                      style={{
                        height: "60px",
                        width: "60px",
                        margin: "0 10px 0 10px",
                        cursor: "pointer",
                      }}
                      disabled={!editState}
                      onClick={handleChangeAvatar}
                      src="https://dc-chat-media.s3.ap-south-1.amazonaws.com/1c25502e214b4b21a5ff635d613d21a3/1632118719512/womanAvatar.jpg"
                    />

                    <Avatar
                      style={{
                        height: "60px",
                        width: "60px",
                        margin: "0 10px 0 10px",
                        cursor: "pointer",
                      }}
                      disabled={!editState}
                      onClick={handleChangeAvatar}
                      src="https://dc-chat-media.s3.ap-south-1.amazonaws.com/1c25502e214b4b21a5ff635d613d21a3/1632118749788/womanAvatar2.jpg"
                    />

                    <Typography
                      style={{ margin: "15px 0px 15px 15px" }}
                      variant="h5"
                    >
                      OR
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        style={{
                          margin: "0 10px 0 10px",
                          height: "60px",
                          width: "60px",
                          cursor: "pointer",
                        }}
                        src={originalImage}
                        onClick={handleChangeAvatar}
                      />
                      <input
                        style={{ display: "none" }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        disabled={!editState}
                        onChange={(event) => handleChangePhoto(event)}
                      />
                      <label htmlFor="raised-button-file">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          style={{ margin: "10px" }}
                          disabled={!editState}
                        >
                          <CloudUpload />
                          &nbsp; Upload
                        </Button>
                      </label>
                    </div>
                  </div>
                  {image !== "" && (
                    <CropImage
                      openModal={openModal}
                      setOpenModal={setOpenModal}
                      image={image.image}
                      formikProps={formikProps}
                      imageName={image.imageName}
                      agentId={agentId}
                      setOriginalImage={setOriginalImage}
                    />
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid style={{ marginTop: "10px" }} item xs={12}>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h5" className={classes.heading}>
                Header settings
              </Typography>
              <Typography className={classes.secondaryHeading}>
                You can change all your header settings here! Expand it to make
                changes.
              </Typography>
            </AccordionSummary>
            <Divider style={{ margin: "0 0 20px 0" }} />
            <AccordionDetails>
              <Grid style={{ marginBottom: "30px" }} container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    name="windowHeaderTitle"
                    label="Title"
                    required
                    formikProps={formikProps}
                    disabled={!editState}
                    inputProps={{
                      maxLength: 20,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {formikProps.values.windowHeaderTitle.length}/20
                          <Tooltip title="he main title of your bot. This could be the name of your business or any virtual avatar.">
                            <IconButton style={{ color: "#cbcbcb" }}>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      formikProps.setValues((prevVal) => ({
                        ...prevVal,
                        windowHeaderTitle: e.target.value,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="windowHeaderCaption"
                    label="Caption(optional)"
                    formikProps={formikProps}
                    disabled={!editState}
                    inputProps={{
                      maxLength: 30,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {formikProps.values.windowHeaderCaption.length}/30
                          <Tooltip title="With caption you can provide extra information about your chatbot or use-case">
                            <IconButton style={{ color: "#cbcbcb" }}>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      formikProps.setValues((prevVal) => ({
                        ...prevVal,
                        windowHeaderCaption: e.target.value,
                      }));
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    name="fontFamily"
                    label="Font Family"
                    value={formikProps.values.fontFamily}
                    disabled={!editState}
                    formikProps={formikProps}
                  >
                    <MenuItem value={"sans-serif"}>Sans-serif</MenuItem>
                    <MenuItem value={"'Roboto', sans-serif"}>
                      Roboto, sans-serif
                    </MenuItem>
                    <MenuItem value={"'Open Sans', sans-serif"}>
                      Open, sans-serif
                    </MenuItem>
                    <MenuItem value={"'Lato', sans-serif"}>
                      Lato, sans-serif
                    </MenuItem>
                    <MenuItem value={"'Noto Sans', sans-serif"}>
                      Noto, sans-serif
                    </MenuItem>
                    <MenuItem value={"'Nunito Sans', sans-serif"}>
                      Nunito, sans-serif
                    </MenuItem>
                    <MenuItem value={"'PT Sans', sans-serif"}>
                      PT, sans-serif
                    </MenuItem>
                    <MenuItem value={"'Prompt', sans-serif"}>
                      Prompt, sans-serif
                    </MenuItem>
                    <MenuItem value={"'Raleway', sans-serif"}>
                      Raleway, sans-serif
                    </MenuItem>
                    <MenuItem value={"'Quicksand', sans-serif"}>
                      Quicksand, sans-serif
                    </MenuItem>
                    <MenuItem value={"'Ubuntu', sans-serif"}>
                      Ubuntu, sans-serif
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <div
                    style={{
                      position: "relative",
                    }}
                    ref={domNode}
                  >
                    {!foregroundHide && (
                      <SketchPicker
                        styles={pickerStyle}
                        display={foregroundHide}
                        onChange={({ hex }) => {
                          formikProps.setValues((prevVal) => ({
                            ...prevVal,
                            windowHeaderForeground: hex,
                          }));
                        }}
                        color={formikProps.values.windowHeaderForeground}
                      />
                    )}
                    <TextField
                      label="Foreground Colour"
                      formikProps={formikProps}
                      case="none"
                      name="windowHeaderForeground"
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{
                                background:
                                  formikProps.values.windowHeaderForeground,
                                height: "15px",
                                width: "15px",
                                borderRadius: "50%",
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              color="primary"
                              onClick={handleForeground}
                              disabled={!editState}
                            >
                              <ColorLens />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      position: "relative",
                    }}
                    ref={domNodeBackground}
                  >
                    <TextFieldWithIcon
                      disabled
                      label="Background Colour"
                      formikProps={formikProps}
                      case="none"
                      name="windowHeaderBackground"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{
                                background:
                                  formikProps.values.windowHeaderBackground,
                                height: "15px",
                                width: "15px",
                                borderRadius: "50%",
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              color="primary"
                              onClick={() => setBackgroundHide(!backgroundHide)}
                              disabled={!editState}
                            >
                              <ColorLens />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {!backgroundHide && (
                      <SketchPicker
                        styles={pickerStyle}
                        onChange={({ hex }) => {
                          formikProps.setValues((prevVal) => ({
                            ...prevVal,
                            windowHeaderBackground: hex,
                          }));
                        }}
                        color={formikProps.values.windowHeaderBackground}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Grid style={{ marginTop: "20px" }} item xs={12}>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h5" className={classes.heading}>
                  Footer settings
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  Manage menu, attachments, emojis etc.
                </Typography>
              </AccordionSummary>
              <Divider />
              <AccordionDetails>
                <Grid style={{ marginBottom: "30px" }} container spacing={3}>
                  <Grid item xs={3}>
                    <Typography style={{ padding: "12px" }}>
                      White Label &nbsp;
                      <Tooltip title="WWhen enabled, Powered by Dolphinchat will be displayed to the users. To disable this contact support@dolphinchat.ai">
                        <IconButton
                          style={{ color: "#cbcbcb", padding: "0px" }}
                        >
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid style={{ textAlign: "end" }} item xs={3}>
                    <Switch
                      checked={formikProps.values.whiteLabel}
                      name="whiteLabel"
                      onChange={formikProps.handleChange}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ padding: "12px" }}>
                      Emoji &nbsp;
                      <Tooltip title="Enable this to allow users to share emoji’s along with text.">
                        <IconButton
                          style={{ color: "#cbcbcb", padding: "0px" }}
                        >
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid style={{ textAlign: "end" }} item xs={3}>
                    <Switch
                      checked={formikProps.values.emoji}
                      name="emoji"
                      onChange={formikProps.handleChange}
                      disabled={!editState}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ padding: "12px" }}>
                      Audio &nbsp;
                      <Tooltip title="This option enable notification sound for new messages when bot is minimised or closed.">
                        <IconButton
                          style={{ color: "#cbcbcb", padding: "0px" }}
                        >
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid style={{ textAlign: "end" }} item xs={3}>
                    <Switch
                      checked={formikProps.values.audio}
                      name="audio"
                      onChange={formikProps.handleChange}
                      disabled={!editState}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      style={{
                        fontsize: "0.9rem",
                      }}
                      variant="h5"
                    >
                      Attachments
                    </Typography>

                    <Typography variant="body2" style={{ color: "#808080" }}>
                      You can enable the attachments in the attachments section
                      given in the footer.
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ padding: "12px" }}>
                      Image &nbsp;
                      <Tooltip title="Enable this to allow users to share Image from chatbot">
                        <IconButton
                          style={{ color: "#cbcbcb", padding: "0px" }}
                        >
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid style={{ textAlign: "end" }} item xs={3}>
                    <Switch
                      checked={formikProps.values.attachmentImage}
                      name="attachmentImage"
                      onChange={formikProps.handleChange}
                      disabled={!editState}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ padding: "12px" }}>
                      Video &nbsp;
                      <Tooltip title="Enable this to allow users to share audio from chatbot">
                        <IconButton
                          style={{ color: "#cbcbcb", padding: "0px" }}
                        >
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid style={{ textAlign: "end" }} item xs={3}>
                    <Switch
                      checked={formikProps.values.attachmentVideo}
                      onChange={formikProps.handleChange}
                      name="attachmentVideo"
                      disabled={!editState}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Typography style={{ padding: "12px" }}>
                      Documents &nbsp;
                      <Tooltip title="Enable this to allow users to share video from chatbot">
                        <IconButton
                          style={{ color: "#cbcbcb", padding: "0px" }}
                        >
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid style={{ textAlign: "end" }} item xs={3}>
                    <Switch
                      checked={formikProps.values.attachmentDocuments}
                      onChange={formikProps.handleChange}
                      name="attachmentDocuments"
                      disabled={!editState}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ padding: "12px" }}>
                      Location &nbsp;
                      <Tooltip title="Enable this to allow users to share location from chatbot">
                        <IconButton
                          style={{ color: "#cbcbcb", padding: "0px" }}
                        >
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid style={{ textAlign: "end" }} item xs={3}>
                    <Switch
                      checked={formikProps.values.attachmentLocation}
                      name="attachmentLocation"
                      onChange={formikProps.handleChange}
                      disabled={!editState}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography style={{ fontSize: "0.9rem" }} variant="h5">
                      Menu
                    </Typography>
                    <Typography
                      style={{
                        color: "#808080",
                      }}
                      variant="body2"
                    >
                      Allow users to select from a list of options for example
                      Restart, Visit Website, Know more etc
                    </Typography>
                    <Typography
                      style={{ padding: "0px 10px 10px 0", color: "#808080" }}
                      variant="body2"
                    >
                      There are two menu options POSTBACK which will perform
                      some action on the bot itself and the URL which will
                      redirect you to the url you had provided. Click on the
                      &nbsp;&nbsp;
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-justify"
                        viewBox="0 0 16 16"
                        style={{
                          position: "absolute",
                          marginLeft: "-10px",
                          marginTop: "2px",
                        }}
                      >
                        <path
                          fillRule="evenodd"
                          d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
                        />
                      </svg>
                      &nbsp; in bot footer to view the option.
                    </Typography>
                  </Grid>

                  {formikProps.values.menu.length === 0 && options && (
                    <React.Fragment key={0}>
                      <Grid item xs={3}>
                        <TextField
                          name="text"
                          label="Text"
                          case="none"
                          value={optionalMenuFields.text}
                          disabled={!editState}
                          formikProps={formikProps}
                          inputProps={{
                            maxLength: 20,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="type"
                          label="Type"
                          case="none"
                          value={"URL"}
                          formikProps={formikProps}
                          disabled={!editState}
                        ></TextField>
                      </Grid>

                      <Grid item xs={4}>
                        <TextField
                          type="text"
                          name="url"
                          label="URL"
                          case="none"
                          value={optionalMenuFields.url}
                          disabled={!editState}
                          formikProps={formikProps}
                          inputProps={{
                            maxLength: 40,
                          }}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                  {editState &&
                    options &&
                    formikProps.values.menu.length === 0 && (
                      <Grid
                        style={{
                          display: "flex",
                          padding: "0px",
                          flexDirection: "row",
                        }}
                        item
                        xs={1}
                      >
                        <Tooltip title="Delete Menu">
                          <IconButton
                            className={classes.iconButton}
                            style={{ marginRight: "3px", padding: "0px" }}
                            onClick={() => setOptions(false)}
                          >
                            <DeleteOutlined
                              style={{ fontSize: "20px", color: "#ff0000" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Add Menu">
                          <IconButton
                            className={classes.iconButton}
                            style={{ padding: "0px" }}
                            onClick={() => handleSubmit()}
                          >
                            <Check
                              style={{ fontSize: "20px" }}
                              color="primary"
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  {formikProps.values.menu.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={3}>
                        <TextField
                          name={"menu[" + index + "].text"}
                          label="Text"
                          value={formikProps.values.menu[index].text}
                          required
                          fullWidth
                          error={Boolean(
                            formikProps.touched.menu?.[index]?.text &&
                              formikProps.errors.menu?.[index]?.text
                          )}
                          helperText={
                            formikProps.touched.menu?.[index]?.text &&
                            formikProps.errors.menu?.[index]?.text
                          }
                          onBlur={formikProps.handleBlur}
                          onChange={(event, name) =>
                            handleChangeMenu(event, index, event.target.name)
                          }
                          disabled={!editState}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          select
                          name={"menu[" + index + "].type"}
                          label="Type"
                          fullWidth
                          case="none"
                          onChange={(event, name) =>
                            handleChangeMenu(event, index, event.target.name)
                          }
                          value={formikProps.values.menu[index].type}
                          onBlur={formikProps.handleBlur}
                          disabled={!editState}
                        >
                          <MenuItem disabled value={"postback"}>
                            Postback
                          </MenuItem>
                          <MenuItem value={"url"}>URL</MenuItem>
                        </TextField>
                      </Grid>
                      {item.type === "postback" ? (
                        <Grid item xs={4}>
                          <TextField
                            name={"menu[" + index + "].payload"}
                            label="Payload"
                            value={formikProps.values.menu[index].payload}
                            required
                            fullWidth
                            error={Boolean(
                              formikProps.touched.menu?.[index]?.payload &&
                                formikProps.errors.menu?.[index]?.payload
                            )}
                            helperText={
                              formikProps.touched.menu?.[index]?.payload &&
                              formikProps.errors.menu?.[index]?.payload
                            }
                            onBlur={formikProps.handleBlur}
                            onChange={(event, name) =>
                              handleChangeMenu(event, index, event.target.name)
                            }
                            disabled={!editState}
                          />
                        </Grid>
                      ) : item.type === "url" ? (
                        <Grid item xs={4}>
                          <TextField
                            name={"menu[" + index + "].url"}
                            label="URL"
                            value={formikProps.values.menu[index].url}
                            required
                            fullWidth
                            error={Boolean(
                              formikProps.touched.menu?.[index]?.url &&
                                formikProps.errors.menu?.[index]?.url
                            )}
                            helperText={
                              formikProps.touched.menu?.[index]?.url &&
                              formikProps.errors.menu?.[index]?.url
                            }
                            onBlur={formikProps.handleBlur}
                            onChange={(event, name) =>
                              handleChangeMenu(event, index, event.target.name)
                            }
                            disabled={!editState}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={4}></Grid>
                      )}
                      {editState && (
                        <Grid style={{ textAlign: "end" }} item xs={1}>
                          <Tooltip title="Delete Menu">
                            <IconButton onClick={() => removeField(index)}>
                              <DeleteOutlined
                                style={{ fontSize: "20px", color: "#ff0000" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </React.Fragment>
                  ))}
                  {editState && (
                    <React.Fragment>
                      <Grid item xs></Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          disabled={Boolean(formikProps.errors?.menu)}
                          onClick={handleAddField}
                        >
                          Add Item
                        </Button>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>

        <Grid item xs></Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setStyleModal(true)}
            disabled={formikProps.dirty}
          >
            Add to Website
          </Button>
        </Grid>
        {styleModal && (
          <StyleModalConfirmation
            styleModal={styleModal}
            setStyleModal={setStyleModal}
            agentId={agentId}
          />
        )}
      </Grid>
    </Paper>
  );
}
