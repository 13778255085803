import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useAxios, useAlert } from "../../../custom hooks";
import { useHistory } from "react-router";
import FormDisplay from "./FormDisplay";
import BotListDisplay from "./BotListDisplay";
import { objectFromFormData } from "./businessDetailsObject";
import validationSchema from "./validationSchema";
import { ButtonFooter } from "../../../custom components";

export default function Form({
  formData,
  setFormData,
  pagePermissions,
  botList,
  planList,
}) {
  const axios = useAxios();
  const history = useHistory();
  const alert = useAlert();

  const [editState, setEditState] = useState(true);

  useEffect(() => {
    if (formData.businessID !== "") {
      setEditState(false);
    }
  }, [formData.businessID]);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        var url = "/business";
        let method = "POST";

        if (Boolean(formData.businessID)) {
          url = url + "/" + formData.businessID;
          method = "PATCH";
        }

        axios({
          url: url,
          method: method,
          data: objectFromFormData(formData),
        })
          .then((res) => {
            if (res.status) {
              alert.success(res.message);
              setEditState(false);
              setSubmitting(false);

              if (method === "POST") {
                history.push("/business-details/" + res.data.business_id);
              } else {
                setFormData(formData);
              }
            }
          })
          .catch((err) => console.log(err));
      }}
    >
      {(formikProps) => (
        <form noValidate onSubmit={formikProps.handleSubmit}>
          {pagePermissions.components.form?.read && (
            <FormDisplay
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
              permissions={pagePermissions.components.form}
              planList={planList}
            />
          )}

          {pagePermissions.components.botList?.read && !editState && (
            <BotListDisplay botList={botList} />
          )}

          <ButtonFooter
            editState={editState}
            setEditState={setEditState}
            deletePermission={pagePermissions.delete}
            handleDelete={handleDelete}
            id={formikProps.values.businessID}
            formikProps={formikProps}
          />
        </form>
      )}
    </Formik>
  );

  function handleDelete() {
    axios({
      url: "/business/" + formData.businessID,
      method: "patch",
      data: {
        is_deleted: true,
      },
    })
      .then((response) => {
        if (response.status) {
          alert.success("Business Deleted!");
          history.push("/businesses");
        }
      })
      .catch((error) => console.log(error));
  }
}
