import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useAlert, useAxios } from "../../../custom hooks";

import { objectFromFormData } from "./integrationGroupDetailsObject";
import FormDisplay from "./FormDisplay";
import { useHistory } from "react-router-dom";
import { ButtonFooter } from "../../../custom components";
import IntegrationTable from "./IntegrationTable";
import { titleCase } from "change-case-all";
import validationSchema from "./validationSchema";

export default function Form({
  formData,
  setFormData,
  integrationGroupID,
  integrations,
}) {
  const axios = useAxios({ baseURL: "component" });

  const history = useHistory();
  const alert = useAlert();
  const [editState, setEditState] = useState(false);

  useEffect(() => {
    if (formData.ID === "") {
      setEditState(true);
    } else {
      setEditState(false);
    }
  }, [formData.ID]);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        var url = "";
        if (Boolean(integrationGroupID)) {
          url = "/update_integration_group";
        } else {
          url = "/create_integration_group";
        }
        axios({
          url: url,
          data: objectFromFormData(formData),
          method: "POST",
        })
          .then((res) => {
            if (res.status) {
              alert.success(titleCase(res.message));
              if (Boolean(integrationGroupID)) {
                setFormData(formData);
              } else {
                history.push("/integration-group-details/" + res.data._id);
              }
              setSubmitting(false);
              setEditState(false);
            }
          })
          .catch((err) => console.log(err));
      }}
      validateOnMount
    >
      {(formikProps) => (
        <form noValidate onSubmit={formikProps.handleSubmit}>
          <FormDisplay
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
          />
          {integrationGroupID && (
            <IntegrationTable integrations={integrations} />
          )}
          <ButtonFooter
            editState={editState}
            setEditState={setEditState}
            id={formikProps.values.ID}
            formikProps={formikProps}
          />
        </form>
      )}
    </Formik>
  );
}
