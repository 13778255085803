import React from "react";
import {
  Grid,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  IconButton,
  Switch,
} from "@material-ui/core";

import { TextField } from "../../../custom components";

import { Add, DeleteOutlined } from "@material-ui/icons";

export default function DisplayFormDailog({
  dialogFormikProps,
  setModal,
  editState,
  modal,
}) {
  function handleClose() {
    setModal((prevValue) => ({
      ...prevValue,
      state: false,
    }));
  }

  const newWebhookEventRequiredFields = {
    field_name: "",
    field_label: "",
  };

  const handleAddWebhookRequiredFields = (rowIndex) => {
    dialogFormikProps.setValues((prevVal) => ({
      ...prevVal,
      ...prevVal.available_fields?.push(newWebhookEventRequiredFields),
    }));
  };

  const handleRemoveWebhookRequiredFields = (index) => {
    dialogFormikProps.setValues((prevVal) => ({
      ...prevVal,
      ...prevVal.available_fields?.splice(index, 1),
    }));
  };

  return (
    <React.Fragment>
      <DialogContent>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={6}>
            <TextField
              fullWidth
              name="name"
              label="Webhook Event Name"
              disabled={!editState}
              formikProps={dialogFormikProps}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ padding: "12px" }}>
              Enable notification &nbsp;
            </Typography>
          </Grid>
          <Grid style={{ textAlign: "end" }} item xs={2}>
            <Switch
              checked={dialogFormikProps.values?.notification}
              name="notification"
              disabled={!editState}
              onChange={dialogFormikProps.handleChange}
            />
          </Grid>
          {dialogFormikProps.values?.notification && (
            <React.Fragment>
              <Grid style={{ alignSelf: "center" }} item xs={6}>
                <Typography variant="h6" style={{ color: "#808080" }}>
                  Webhook Event Fields
                </Typography>
              </Grid>

              <Grid
                style={{ alignSelf: "center", textAlign: "end" }}
                item
                xs={6}
              >
                <IconButton
                  onClick={() => handleAddWebhookRequiredFields(modal.rowIndex)}
                  disabled={!editState}
                  color="primary"
                >
                  <Add />
                </IconButton>
              </Grid>

              {dialogFormikProps.values?.available_fields?.map((_, index) => (
                <React.Fragment key={index}>
                  <Grid item xs={6}>
                    <TextField
                      name={"available_fields[" + index + "].field_name"}
                      formikProps={dialogFormikProps}
                      label="Webhook Event Field Name"
                      disabled={!editState}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <TextField
                      name={"available_fields[" + index + "].field_label"}
                      formikProps={dialogFormikProps}
                      label="Webhook Event Field Label"
                      disabled={!editState}
                      fullWidth
                    />
                  </Grid>

                  <Grid
                    style={{ alignSelf: "center", textAlign: "end" }}
                    item
                    xs={1}
                  >
                    <IconButton
                      onClick={() => handleRemoveWebhookRequiredFields(index)}
                      disabled={!editState}
                    >
                      <DeleteOutlined
                        style={{ fontSize: "20px", color: "#ff0000" }}
                      />
                    </IconButton>
                  </Grid>
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: "12px 24px 24px" }}>
        <Button
          onClick={dialogFormikProps.handleSubmit}
          variant="contained"
          color="primary"
          disabled={!(dialogFormikProps.dirty && dialogFormikProps.isValid)}
        >
          Submit
        </Button>
        <Button onClick={handleClose} variant="outlined" color="primary">
          Close
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}
