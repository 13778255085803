import { Grid } from "@material-ui/core";
import React, { useState } from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

import "../../whatsapp preview/whatsappPreview.css";

import { TextField, TextFieldWithCopy } from "../../../../custom components";

export default function UserDetailsForm({ dialogFormikProps, groupUserId }) {
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);

  const handleChangePhoneNumber = (value, country, e) => {
    if (value.length <= 3) {
      setValidPhoneNumber(true);
    } else {
      setValidPhoneNumber(false);
    }
    if (
      e.target.className === "country" ||
      e.target.className === "country-name"
    ) {
      dialogFormikProps.setValues((prevVal) => ({
        ...prevVal,
        mobileNumber: "+" + country.dialCode + "",
      }));
    } else {
      dialogFormikProps.setValues((prevVal) => ({
        ...prevVal,
        mobileNumber: value,
      }));
    }
  };
  return (
    <Grid container spacing={4} alignItems="center">
      {groupUserId && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextFieldWithCopy
              fullWidth
              disabled
              name="groupID"
              label="Group ID"
              formikProps={dialogFormikProps}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldWithCopy
              fullWidth
              disabled
              name="groupUserID"
              label="User ID"
              formikProps={dialogFormikProps}
            />
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="userName"
          label="Name"
          formikProps={dialogFormikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <PhoneInput
          value={dialogFormikProps.values.mobileNumber}
          onChange={handleChangePhoneNumber}
          disabled={dialogFormikProps.values.groupUserID}
          inputProps={{
            name: "mobileNumber",
          }}
          error={Boolean(
            dialogFormikProps.touched.mobileNumber &&
              dialogFormikProps.errors.mobileNumber
          )}
          helperText={
            dialogFormikProps.touched.mobileNumber &&
            dialogFormikProps.errors.mobileNumber
          }
          onBlur={dialogFormikProps.handleBlur}
          inputStyle={{
            width: "100%",
            fontSize: "12px",
            color: "currentColor",
            borderColor: !validPhoneNumber ? "#4076d2" : "#f44336",
            padding: "17.5px 14px 18.5px 58px",
            cursor: "default",
          }}
        />
        {validPhoneNumber && (
          <span className="validationError">Whatsapp Number is Required</span>
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="userEmail"
          label="Email"
          formikProps={dialogFormikProps}
        />
      </Grid>
    </Grid>
  );
}
