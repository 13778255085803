import { Grid } from "@material-ui/core";

import MoveBotUtteranceToolbar from "./MoveBotUtteranceToolbar";
import { Paper, ComponentHeader, DataTable } from "../../../custom components";
import convertDate from "../../../util/convertDate";

export default function MoveBotUtterances({
  utterances,
  botServiceList,
  sourceServiceID,
}) {
  const options = {
    selectableRows: "multiple",
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
    customToolbarSelect: ({ data }, _, setSelectedRows) => {
      const selectedRows = data.map(
        ({ dataIndex }) => utterances[dataIndex].utterance_id
      );

      return (
        <MoveBotUtteranceToolbar
          sourceServiceID={sourceServiceID}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          botServiceList={botServiceList}
        />
      );
    },
  };

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader headerName="Move Utterances" />
      </Grid>

      <Grid item xs={12}>
        <DataTable options={options} columns={columns} data={utterances} />
      </Grid>
    </Paper>
  );
}

const columns = [
  {
    name: "key",
    label: "Utterance Key",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "value",
    label: "Utterance Value",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
      setCellHeaderProps: () => {
        return { style: { width: "60%" } };
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
