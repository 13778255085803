import React from "react";

import { Dialog, DialogTitle, makeStyles } from "@material-ui/core";

import { Formik } from "formik";

import * as Yup from "yup";

import DisplayFormDailog from "./DisplayFormDailog";

import { nanoid } from "nanoid/non-secure";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "700px" },
}));

export default function WebhookEventModal({
  modal,
  formikProps,
  setModal,
  editState,
}) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={modal.state}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>Webhook Events</DialogTitle>

      <Formik
        enableReinitialize
        initialValues={modal.webhooks}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(formData) => {
          if (modal.mode === "add") {
            formikProps.setValues((prevVal) => ({
              ...prevVal,
              webhookEvents: [
                ...formikProps.values.webhookEvents,
                newField(formData),
              ],
            }));
          } else {
            formikProps.setValues((prevValue) => {
              let NewwebhookEvents = [];

              for (let fields of prevValue.webhookEvents) {
                if (fields.name === formData.name) {
                  NewwebhookEvents.push(newField(formData));
                } else {
                  NewwebhookEvents.push(fields);
                }
              }

              return {
                ...prevValue,
                webhookEvents: NewwebhookEvents,
              };
            });
          }
          handleClose();
        }}
      >
        {(dialogFormikProps) => (
          <form noValidate onSubmit={dialogFormikProps.handleSubmit}>
            <DisplayFormDailog
              dialogFormikProps={dialogFormikProps}
              setModal={setModal}
              modal={modal}
              editState={editState}
              formikProps={formikProps}
            />
          </form>
        )}
      </Formik>
    </Dialog>
  );
  function handleClose() {
    setModal((prevValue) => ({
      ...prevValue,
      state: false,
    }));
  }
}

function newField(webhooks) {
  return {
    _id: Boolean(webhooks._id) ? webhooks._id : nanoid(10),
    name: webhooks.name,
    available_fields: webhooks.available_fields,
    notification: webhooks.notification,
  };
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("This is the required field."),
});
