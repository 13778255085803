import React from "react";

import { ENV } from "../../../config/config";
import { useAxios, useAlert } from "../../../custom hooks";

import { Grid, Tooltip, IconButton } from "@material-ui/core";
import {
  Replay,
  PauseCircleOutline,
  MoveToInboxTwoTone,
} from "@material-ui/icons";
import {
  TextField,
  TextFieldWithCopy,
  Paper,
  CreatedAndUpdatedDetails,
  ComponentHeader,
} from "../../../custom components";

export default function FormDisplay({
  formikProps,
  editState,
  componentPermissions,
}) {
  const axios = useAxios();
  const alert = useAlert();

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          headerName="Bot Service Details"
          id={formikProps.values.botServiceID}
          buttons={[<MigrateBotService />, <RestartBot />, <StopBot />]}
        />
      </Grid>

      {!!formikProps.values.botServiceID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextFieldWithCopy
              label="Bot Service ID"
              name="botServiceID"
              disabled
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6} />
        </React.Fragment>
      )}

      <Grid item xs={6}>
        <TextField
          label="Bot Service Name"
          name="botServiceName"
          required
          formikProps={formikProps}
          disabled={!(editState && componentPermissions.update)}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          label="NLU Port"
          name="NLUPort"
          required
          formikProps={formikProps}
          disabled={!(editState && componentPermissions.update)}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          label="Actions Port"
          name="actionsPort"
          required
          formikProps={formikProps}
          disabled={!(editState && componentPermissions.update)}
        />
      </Grid>

      {!!formikProps.values.botServiceID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextField
              label="Bot Service Repository Name"
              name="botServiceRepoName"
              disabled
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Bot Service Status"
              name="botServiceStatus"
              disabled
              formikProps={formikProps}
            />
          </Grid>

          <CreatedAndUpdatedDetails values={formikProps.values} />
        </React.Fragment>
      )}
    </Paper>
  );

  function RestartBot() {
    return (
      <Tooltip title="Restart Bot">
        <IconButton onClick={restartBot} color="primary">
          <Replay />
        </IconButton>
      </Tooltip>
    );

    function restartBot() {
      axios({
        url: "/bot/restart/" + formikProps.values.botServiceID,
        method: "POST",
      })
        .then((response) => {
          if (response.status) {
            alert.success(response.message);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  function MigrateBotService() {
    return (
      ENV !== "prod" && (
        <Tooltip title="Migrate Bot Service">
          <IconButton onClick={migrateBotServices} color="primary">
            <MoveToInboxTwoTone />
          </IconButton>
        </Tooltip>
      )
    );

    function migrateBotServices() {
      axios({
        url: "/move/bot/service",
        method: "POST",
        data: { bot_service_id: formikProps.values.botServiceID },
      })
        .then((response) => {
          if (response.status) {
            alert.success(response.message);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  function StopBot() {
    return (
      formikProps.values.botServiceStatus === "running" && (
        <Tooltip title="Stop Bot">
          <IconButton onClick={stopBot} color="primary">
            <PauseCircleOutline />
          </IconButton>
        </Tooltip>
      )
    );

    function stopBot() {
      axios({
        url: "/bot/pause/" + formikProps.values.botServiceID,
        method: "POST",
      })
        .then((response) => {
          if (response.status) {
            alert.success(response.message);
          }
        })
        .catch((error) => console.log(error));
    }
  }
}
