import * as types from "../../constants/reducerConstants";

export function alertSuccess(message) {
  return {
    type: types.ALERT_OPEN,
    alertType: "success",
    alertMessage: message,
  };
}

export function alertError(message) {
  return {
    type: types.ALERT_OPEN,
    alertType: "error",
    alertMessage: message,
  };
}

export function alertInfo(message) {
  return {
    type: types.ALERT_OPEN,
    alertType: "info",
    alertMessage: message,
  };
}

export function alertWarning(message) {
  return {
    type: types.ALERT_OPEN,
    alertType: "warning",
    alertMessage: message,
  };
}

export function alertClose() {
  return {
    type: types.ALERT_CLOSE,
  };
}
