import DataTable from "../../../custom components/DataTable";
import { useHistory } from "react-router-dom";

export default function Table({ whatsappNumber }) {
  const history = useHistory();
  const options = {
    onRowClick: handleRowClick,
  };

  function handleRowClick(rowData, rowMetaData) {
    history.push(
      "/whatsapp-number-detail/" + whatsappNumber[rowMetaData.dataIndex]._id
    );
  }
  return (
    <DataTable columns={columns} data={whatsappNumber} options={options} />
  );
}

const columns = [
  {
    name: "_id",
    label: "Id",
  },
  {
    name: "whatsapp_service",
    label: "Whatsapp Service",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "bot_name",
    label: "Bot Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "phone_number",
    label: "Phone Number",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
];
