import React from "react";

import { Formik } from "formik";

import FormDisplay from "./FormDisplay";
import { validationSchema } from "./whatsappStyleObject";

export default function BusinessForm({
  editState,
  businessProfile,
  businessVerticals,
}) {
  return (
    <Formik
      enableReinitialize
      initialValues={businessProfile}
      validationSchema={validationSchema}
      validateOnMount
    >
      {(formikProps) => (
        <form noValidate onSubmit={formikProps.handleSubmit}>
          <FormDisplay
            editState={editState}
            formikProps={formikProps}
            businessVerticals={businessVerticals}
          />
        </form>
      )}
    </Formik>
  );
}
