import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useAxios } from "../../../custom hooks";

import {
  alertError,
  alertSuccess,
  alertWarning,
} from "../../../redux/actions/alertActions";

import validationSchema from "./validationSchema";
import { objectFromFormData } from "./pageDetailsObject";
import FormDisplay from "./FormDisplay";
import { useHistory } from "react-router-dom";

export default function Form({ formData, setFormData }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const axios = useAxios();
  const [editState, setEditState] = useState(false);

  useEffect(() => {
    if (!formData.pageID) {
      setEditState(true);
    }
  }, [formData]);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        var method = "POST";
        var URL = "/page";

        if (formData.pageID !== "") {
          method = "PATCH";
          URL = URL + "/" + formData.pageID;
        }

        axios({
          url: URL,
          method: method,
          data: objectFromFormData(formData),
        })
          .then((response) => {
            if (response.status) {
              dispatch(alertSuccess(response.message));
              setEditState(false);

              if (method === "POST") {
                history.push("/pages/superadmin/" + response.data.page_id);
              } else {
                setFormData(formData);
              }
            } else {
              dispatch(alertError(response.message));
            }
            setSubmitting(false);
          })
          .catch((response) => dispatch(alertWarning(response.message)));
      }}
      validateOnMount
    >
      {(props) => (
        <form noValidate onSubmit={props.handleSubmit}>
          <FormDisplay
            editState={editState}
            setEditState={setEditState}
            formikProps={props}
          />
        </form>
      )}
    </Formik>
  );
}
