import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  botServiceName: Yup.string().max(50).required("Bot Service Name is required"),
  NLUPort: Yup.string()
    .matches(/^[0-9]*$/, "Enter Correct Port number")
    .length(4, "Enter Correct Port number")
    .required("NLU Port Required"),
  actionsPort: Yup.string()
    .matches(/^[0-9]*$/, "Enter Correct Port number")
    .length(4, "Enter Correct Port number")
    .required("Actions Port Required")
    .when("NLUPort", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().notOneOf(
        [Yup.ref("NLUPort")],
        "Actions port can't be the same as NLU Port"
      ),
    }),
});

export default validationSchema;
