export const utteranceDetailsObject = {
  ID: "",
  key: "",
  value: "",
  botServiceID: "",
};

export function objectFromResponse(responseData) {
  return {
    ID: responseData.utterance_id,
    key: responseData.key,
    value: responseData.value,
    botServiceID: responseData.bot_service_id,
    createdAt: responseData.created_at,
    createdBy: responseData.created_by,
    updatedAt: responseData.updated_at,
    updatedBy: responseData.updated_by,
  };
}

export function objectFromFormData(formData) {
  return {
    key: formData.key,
    value: formData.value,
    bot_service_id: formData.botServiceID,
  };
}
