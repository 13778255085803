import convertDate from "../../../../util/convertDate";
import { titleCase } from "change-case-all";

export const userDetailObject = {
  userName: "",
  mobileNumber: "",
  userEmail: "",
  groupID: "",
  groupUserID: "",
  createdAt: "",
  updatedAt: "",
};
export function objectFromResponse(responseData) {
  return {
    userName: titleCase(responseData.name) || "",
    mobileNumber: responseData.mobile_number || "",
    userEmail: responseData.email || "",
    groupID: responseData.group_id || "",
    groupUserID: responseData.group_user_id || "",
    createdAt: convertDate(responseData.created_at),
    updatedAt: convertDate(responseData.updated_at),
  };
}

export function objectFromFormData(formData) {
  return {
    data_type: "phone_number",
    name: titleCase(formData.userName),
    mobile_number: formData.mobileNumber,
    email: formData.userEmail,
  };
}
