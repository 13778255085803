import React, { useState } from "react";

import { Grid, IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import { ComponentHeader, Paper } from "../../../custom components";
import Table from "../webhook evnts components/Table";
import WebhookEventModal from "../webhook evnts components/WebhookEventModal";

export default function RequiredCredentials({
  formikProps,
  editState,
  setEditState,
  permissions,
}) {
  const [modal, setModal] = useState({
    state: false,
    mode: "add",
    webhooks: {
      name: "",
      available_fields: [
        {
          field_name: "",
          field_label: "",
        },
      ],
      notification: false,
    },
  });

  const handleAddWebhookEvent = () => {
    setModal({
      state: true,
      mode: "add",
      webhooks: {
        name: "",
        available_fields: [
          {
            field_name: "",
            field_label: "",
          },
        ],
        notification: false,
      },
      rowIndex: formikProps.values.webhookEvents.length,
    });
  };

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          headerName="Webhook Events"
          id={true}
          buttons={[
            <IconButton
              disabled={!editState}
              onClick={handleAddWebhookEvent}
              color="primary"
            >
              <Add />
            </IconButton>,
          ]}
        />
      </Grid>
      {formikProps.values.webhookEvents?.length !== 0 && (
        <Grid item xs={12}>
          <Table
            webhookEvents={formikProps.values.webhookEvents}
            formikProps={formikProps}
            modal={modal}
            setModal={setModal}
            editState={editState}
          />
        </Grid>
      )}

      <WebhookEventModal
        modal={modal}
        formikProps={formikProps}
        setModal={setModal}
        editState={editState}
      />
    </Paper>
  );
}
