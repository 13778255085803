import React from "react";
import { useDispatch } from "react-redux";

import DataTable from "../../../../custom components/DataTable";

import { useAlert, useAxiosV2 } from "../../../../custom hooks";
import { alertError } from "../../../../redux/actions/alertActions";
import AddUserPopUp from "./AddUserPopup";

export default function UserListTable({
  userList,
  setListUsers,
  groupID,
  addUserPopUp,
  setAddUserPopUp,
  setUser,
  setUserList,
  authToken,
}) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const axios = useAxiosV2({
    baseURL: "adminV2",
  });

  function handleRowClick(rowData, rowMetaData) {
    setAddUserPopUp({
      state: true,
      groupUserID: userList[rowMetaData.dataIndex].group_user_id,
    });
  }

  const options = {
    viewColumns: false,
    selectableRows: "multiple",
    rowsPerPageOptions: [10, 20, 25, 50, 100, 150, 200, 250, 500],
    download: false,
    print: false,
    pagination: true,
    onSearchChange(searchText) {
      if (searchText) {
        axios({
          url: "/broadcast/get/group/user/" + groupID + "?search=" + searchText,
          method: "GET",
          authToken: "Bearer " + authToken,
        }).then((response) => {
          if (response.status) {
            setUserList(response.data);
          }
        });
      } else {
        axios({
          url:
            "/broadcast/get/group/user/" +
            groupID +
            "?start=" +
            0 +
            "&length=" +
            20,
          method: "GET",
          authToken: "Bearer " + authToken,
        }).then((response) => {
          if (response.status) {
            setUserList(response.data);
          }
        });
      }
    },
    onChangePage(currentPage) {
      var start = currentPage * 15;
      var end = currentPage * 20;
      axios({
        url:
          "/broadcast/get/group/user/" +
          groupID +
          "?start=" +
          start +
          "&length=" +
          end,
        method: "GET",
        authToken: "Bearer " + authToken,
      }).then((response) => {
        if (response.status) {
          setUserList(response.data);
        }
      });
    },
    onChangeRowsPerPage(numberOfRows) {
      axios({
        url:
          "/broadcast/get/group/user/" +
          groupID +
          "?start=" +
          0 +
          "&length=" +
          numberOfRows,
        method: "GET",
        authToken: "Bearer " + authToken,
      }).then((response) => {
        if (response.status) {
          setUserList(response.data);
        }
      });
    },
    onRowClick: handleRowClick,

    onRowsDelete: (rowsDeleted) => {
      var listOfUserIds = [];
      rowsDeleted.data.map((item) =>
        listOfUserIds.push(userList[item.dataIndex].group_user_id)
      );
      if (
        listOfUserIds.length !== 0 &&
        !window.confirm("Are you sure you want to delete these group users?")
      ) {
        return;
      } else {
        if (listOfUserIds.length !== 0) {
          axios({
            url: "/broadcast/delete/group/mapped/users",
            method: "DELETE",
            authToken: "Bearer " + authToken,
            data: {
              group_user_ids: listOfUserIds,
            },
          }).then((res) => {
            if (res.status) {
              alert.success(res.message);
              setListUsers(listOfUserIds);
            } else {
              dispatch(alertError(res.message));
            }
          });
        }
      }
    },
  };

  const columns = [
    {
      name: "group_user_id",
      label: "User ID",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "mobile_number",
      label: "Phone Number",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
  ];

  return (
    <React.Fragment>
      <DataTable columns={columns} options={options} data={userList} />
      {addUserPopUp.state && (
        <AddUserPopUp
          authToken={authToken}
          addUserPopUp={addUserPopUp}
          setAddUserPopUp={setAddUserPopUp}
          groupID={groupID}
          setUser={setUser}
        />
      )}
    </React.Fragment>
  );
}
