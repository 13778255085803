import { useState, useEffect } from "react";

import Table from "./bots components/Table";
import { usePermissions, useAxios, useLoader } from "../../custom hooks";
import { Loader, PageHeader } from "../../custom components";

export default function Bots(props) {
  const axios = useAxios();
  const loader = useLoader();
  const pagePermissions = usePermissions(props.pageID);

  const [bots, setBots] = useState([]);

  useEffect(() => {
    loader.start();

    axios({
      url: "/all/agents",
    })
      .then((response) => {
        if (response.status) {
          setBots(response.data);
          loader.stop();
        }
      })
      .catch((err) => console.log(err));
  }, [loader, axios]);

  return (
    <Loader>
      <PageHeader
        pageName="Bots"
        addIcon={["Add New Bot", "/bot-details", pagePermissions.create]}
      />

      <Table bots={bots} />
    </Loader>
  );
}
