import React from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";

import { useAxios, useAlert } from "../../../custom hooks";
import { ButtonFooter } from "../../../custom components";
import validationSchema from "./validationSchema";
import { objectFromFormData } from "./botServiceDetailsObject";
import FormDisplay from "./FormDisplay";

export default function Form({
  formData,
  setFormData,
  editState,
  setEditState,
  pagePermissions,
}) {
  const history = useHistory();
  const axios = useAxios();
  const alert = useAlert();

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        var method = "POST";

        if (!!formData.botServiceID) {
          method = "PATCH";
        }

        axios({
          url: "/bots",
          method: method,
          data: objectFromFormData(formData),
        })
          .then((response) => {
            if (response.status) {
              setSubmitting(false);
              setEditState(false);
              alert.success(response.message);

              if (method === "POST") {
                deployBot(response.data.bot_service_id);

                history.push(
                  "/bot-service-details/" + response.data.bot_service_id
                );
              } else {
                setFormData(formData);
              }
            }
          })
          .catch((error) => console.log(error));
      }}
    >
      {(formikProps) => (
        <form noValidate onSubmit={formikProps.handleSubmit}>
          {pagePermissions.components.form?.read && (
            <FormDisplay
              formikProps={formikProps}
              editState={editState}
              componentPermissions={pagePermissions.components.form}
            />
          )}

          <ButtonFooter
            id={formikProps.values.botServiceID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
          />
        </form>
      )}
    </Formik>
  );

  function deployBot(serviceID) {
    axios({
      url: "/bot/deploy/" + serviceID,
      method: "POST",
    })
      .then((response) => {
        if (response.status) {
          alert.success(response.message);
        } else {
          alert.error(response.message);
        }
      })
      .catch((error) => console.log(error));
  }
}
