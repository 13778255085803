import React, { useState, useEffect } from "react";
import convertDate from "../../util/convertDate";

import {
  Divider as MuiDivider,
  Typography,
  Paper,
  Modal,
  TextField as MuiTextField,
  Grid,
  Button,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@material-ui/core";

import {
  MessageRounded,
  BusinessCenterRounded,
  AccountCircleRounded,
} from "@material-ui/icons";

import PerfectScrollbar from "react-perfect-scrollbar";
import "../../vendor/perfect-scrollbar.css";

import styled from "styled-components/macro";

import { useHistory } from "react-router-dom";
import { spacing } from "@material-ui/system";

import {
  DataTable,
  TextFieldWithIcon,
  Loader,
  PageHeader,
} from "../../custom components";
import { useLoader, useAxios } from "../../custom hooks";

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)`
  div input {
    text-transform: capitalize;
  }
`;

export default function Users(props) {
  const loader = useLoader();
  const axios = useAxios();

  const [users, setUsers] = useState([]);

  let history = useHistory();

  useEffect(() => {
    loader.start();

    axios({
      url: "/bot/users",
    }).then((data) => {
      setUsers(data.data);
      loader.stop();
    });
  }, [axios, loader]);

  const [modal, setModal] = useState({
    state: false,
    user: {},
  });

  function handleCellClick(cellData, cellMetaData) {
    if (cellMetaData.colIndex !== 4) {
      setModal({
        state: true,
        user: users[cellMetaData.dataIndex],
      });
    } else {
      history.push({
        pathname: "/conversations",
        user: users[cellMetaData.dataIndex],
      });
    }
  }

  function handleModalClose() {
    setModal({
      state: false,
      user: {},
    });
  }

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "agent_name",
      label: "Bot Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "business_name",
      label: "Business Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: () => (
          <IconButton color="primary" size="small">
            <MessageRounded fontSize="small" />
          </IconButton>
        ),
      },
    },
  ];

  const options = {
    onCellClick: handleCellClick,
  };

  return (
    <Loader>
      <PageHeader pageName="Users" />

      <Modal
        open={modal.state}
        onClose={handleModalClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={2}
          style={{
            maxHeight: "90vh",
            width: "50%",
          }}
        >
          <PerfectScrollbar
            style={{
              maxHeight: "85vh",
              padding: "15px",
            }}
          >
            <Typography variant="h3">User Detail</Typography>

            <Divider mt={2} mb={4} />

            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  fullWidth
                  disabled
                  variant="outlined"
                  value={modal.user.name || "Not Available"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  fullWidth
                  disabled
                  variant="outlined"
                  value={modal.user.primary_email || "Not Available"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Phone Number"
                  fullWidth
                  disabled
                  variant="outlined"
                  value={modal.user.primary_phone || "Not Available"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldWithIcon
                  label={"Bot Name"}
                  fullWidth
                  disabled
                  variant="outlined"
                  value={modal.user.agent_name || "Not Available"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="View Bot">
                          <IconButton
                            color="primary"
                            onClick={() =>
                              history.push(
                                "/bot-details/" + modal.user.agent_id
                              )
                            }
                          >
                            <AccountCircleRounded />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldWithIcon
                  label="Business Name"
                  fullWidth
                  disabled
                  variant="outlined"
                  value={modal.user.business_name || "Not Available"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="View Business">
                          <IconButton
                            color="primary"
                            onClick={() =>
                              history.push(
                                "/business-details/" + modal.user.business_id
                              )
                            }
                          >
                            <BusinessCenterRounded />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Created At"
                  fullWidth
                  disabled
                  variant="outlined"
                  value={convertDate(modal.user.createdAt) || "Not Available"}
                />
              </Grid>
            </Grid>

            <Divider mt={4} mb={2} />

            <Grid container>
              <Grid item xs />
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleModalClose}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </PerfectScrollbar>
        </Paper>
      </Modal>

      <DataTable columns={columns} options={options} data={users} />
    </Loader>
  );
}
