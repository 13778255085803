import { Fragment } from "react";
import TextField from "./TextField";
import { Grid } from "@material-ui/core";
import convertDate from "../util/convertDate";

export default function CreatedAndUpdatedDetails({ values }) {
  return (
    <Fragment>
      {!!values.createdBy && (
        <Grid item xs={6}>
          <TextField
            label="Created By"
            name="createdBy"
            disabled
            value={values.createdBy}
          />
        </Grid>
      )}

      <Grid item xs={6}>
        <TextField
          label="Created At"
          name="createdAt"
          value={convertDate(values.createdAt)}
          disabled
        />
      </Grid>

      {!!values.createdBy && (
        <Grid item xs={6}>
          <TextField
            label="Updated By"
            name="updatedBy"
            disabled
            value={values.updatedBy}
          />
        </Grid>
      )}

      <Grid item xs={6}>
        <TextField
          label="Updated At"
          name="updatedAt"
          value={convertDate(values.updatedAt)}
          disabled
        />
      </Grid>
    </Fragment>
  );
}
