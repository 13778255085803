import React from "react";

import { IconButton } from "@material-ui/core";

import DataTable from "../../../custom components/DataTable";
import { DeleteOutline } from "@material-ui/icons";

export default function Table({
  webhookEvents,
  formikProps,
  modal,
  setModal,
  editState,
}) {
  const removeWebhookEventsField = (index) => {
    const newWebhookEvents = [...formikProps.values.webhookEvents];
    newWebhookEvents.splice(index, 1);
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      webhookEvents: newWebhookEvents,
    }));
  };

  function handleCellClick(cellData, cellMetaData) {
    var cellValues = webhookEvents[cellMetaData.dataIndex];
    if (cellMetaData.colIndex !== 4) {
      setModal({
        state: true,
        mode: "edit",
        webhooks: {
          id: cellValues.id,
          name: cellValues.name,
          available_fields: cellValues.available_fields,
          notification: cellValues.notification,
        },
        rowIndex: cellMetaData.rowIndex,
      });
    }
  }

  const options = {
    search: false,
    viewColumns: false,
    selectableRows: "none",
    filter: false,
    download: false,
    print: false,
    pagination: false,
    onCellClick: handleCellClick,
  };

  const columns = [
    {
      name: "name",
      label: "Webhook Event Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "notification",
      label: "Enable Notification",
      options: {
        customBodyRender: (value) => {
          return value === true ? "Yes" : "No";
        },
      },
    },
    {
      name: "available_fields",
      label: "Fields Name",
      options: {
        customBodyRender: (value) => {
          return value
            ? value.map((item) => item.field_name).join(", ")
            : "Not Available";
        },
      },
    },
    {
      name: "available_fields",
      label: "Fields Label",
      options: {
        customBodyRender: (value) => {
          return value
            ? value.map((item) => item.field_label).join(", ")
            : "Not Available";
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (rowIndex, index) => (
          <IconButton
            disabled={!editState}
            onClick={() => removeWebhookEventsField(index.rowIndex)}
            style={{ color: editState ? "#ff0000" : "#00000042" }}
            size="small"
          >
            <DeleteOutline fontSize="small" />
          </IconButton>
        ),
      },
    },
  ];

  return <DataTable columns={columns} options={options} data={webhookEvents} />;
}
