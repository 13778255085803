import { useHistory } from "react-router-dom";
import DataTable from "../../../custom components/DataTable";
import convertDate from "../../../util/convertDate";

export default function Table({ pages }) {
  const history = useHistory();

  const options = {
    onRowClick: handleRowClick,
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  function handleRowClick(_, rowMetaData) {
    history.push("/pages/superadmin/" + pages[rowMetaData.dataIndex].page_id);
  }

  return <DataTable columns={columns} options={options} data={pages} />;
}

const columns = [
  {
    name: "page_name",
    label: "Page Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "page_id",
    label: "Page ID",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "is_active",
    label: "Status",
    options: {
      customBodyRender: (value) => {
        return value ? "Active" : "Inactive";
      },
    },
  },
  {
    name: "component",
    label: "No. of Components",
    options: {
      customBodyRender: (value) => {
        return value ? value.length : "Inactive";
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
