import React, { useState, useEffect } from "react";

import Table from "./members components/Table";
import { Loader, PageHeader } from "../../custom components";
import { useLoader, useAxios, usePermissions } from "../../custom hooks";

export default function MembersPage(props) {
  const loader = useLoader();
  const axios = useAxios();
  const pagePermissions = usePermissions(props.pageID);

  const [members, setMembers] = useState([]);

  useEffect(() => {
    loader.start();
    axios({
      url: "/members",
    }).then((response) => {
      loader.apiComplete();
      setMembers(response.data);
    });
  }, [axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Members"
        addIcon={["Add New Member", "/member-details", pagePermissions.create]}
      />

      <Table members={members} />
    </Loader>
  );
}
