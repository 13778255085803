import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  integrationName: Yup.string()
    .max(255)
    .required("Integration group name is required"),
  status: Yup.string().required("Please select the integration group status"),
  description: Yup.string().max(525).required("Description is required"),
});

export default validationSchema;
