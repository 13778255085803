import { useState, useEffect } from "react";
import { useHistory } from "react-router";

import { Button } from "@material-ui/core";

import Table from "./integration group components/Table";
import { usePermissions, useLoader, useAxios } from "../../custom hooks";
import { Loader, PageHeader } from "../../custom components";

export default function IntegrationGroup(props) {
  const axios = useAxios({ baseURL: "component" });
  const loader = useLoader();
  const history = useHistory();

  const pagePermissions = usePermissions(props.pageID);

  const [groupIntegration, setGroupIntegration] = useState([]);

  useEffect(() => {
    loader.start();
    axios({
      url: "get_integration_groups",
    })
      .then((response) => {
        if (response.status) {
          setGroupIntegration(response.data);
          loader.stop();
        }
      })
      .catch((err) => console.log(err));
  }, [loader, axios]);

  return (
    <Loader>
      <PageHeader
        pageName="Group Integration"
        icons={[
          pagePermissions.create && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/integration-group-details")}
            >
              Add New Group Intgeration
            </Button>
          ),
        ]}
      />
      <Table groupIntegration={groupIntegration} />
    </Loader>
  );
}
