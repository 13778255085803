import { React } from "react";
import { useDispatch } from "react-redux";
import { PhotoCamera } from "@material-ui/icons";
import { Grid, IconButton } from "@material-ui/core";

import { useAxios } from "../../../../custom hooks";
import { Paper, FormHeader, TextField } from "../../../../custom components";
import {
  alertSuccess,
  alertError,
} from "../../../../redux/actions/alertActions";

export default function Profile({ formikProps, editState }) {
  const axios = useAxios();
  const dispatch = useDispatch();

  function handleChangePicture(event) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("display_picture", file);

    if (file.size >= 5120) {
      axios({
        url: "/set_profile_photo/" + formikProps.values.whatsappNumberID,
        method: "POST",
        data: formData,
      }).then((response) => {
        dispatch(alertSuccess(response.message));
      });
    } else {
      dispatch(alertError("Something went wrong"));
    }
  }

  return (
    <Paper>
      <Grid item xs={12}>
        <FormHeader formName="Whatsapp Display Picture" />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          disabled={!editState}
          name="profilePicture"
          label="Display Picture"
          formikProps={formikProps}
          placeholder="No file chosen"
          InputProps={{
            readOnly: true,
            startAdornment: (
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onChange={handleChangePicture}
              >
                <input
                  hidden
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={handleChangePicture}
                />
                <PhotoCamera />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </Paper>
  );
}
