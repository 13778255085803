import React, { useState, useEffect } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { TextField, Autocomplete } from "../../../custom components";
import { useAxios } from "../../../custom hooks";

export default function DialogFormDisplay({
  dialogFormikProps,
  webhookEvents,
  handleClose,
  dialogMode,
  formikProps,
}) {
  const axios = useAxios();
  const [templateList, setTemplateList] = useState([]);

  useEffect(() => {
    axios({
      url: "/template/" + formikProps.values.botID,
    }).then((response) => {
      if (response.status) {
        setTemplateList(response.data);
      }
    });
  }, [axios, formikProps.values.botID]);

  return (
    <React.Fragment>
      <DialogContent>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            <TextField
              select
              required
              name="name"
              label="Event Name"
              disabled={!Boolean(dialogMode === "add")}
              formikProps={dialogFormikProps}
            >
              {webhookEvents.map((webhookEvent) => (
                <MenuItem
                  value={webhookEvent.name}
                  key={webhookEvent.name}
                  disabled={formikProps.values.webhookEvents.find(
                    (event) => event.event_name === webhookEvent.name
                  )}
                >
                  {webhookEvent.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {Boolean(dialogFormikProps.values.name) && (
            <Grid item xs={12}>
              <TextField
                select
                required
                name="notification"
                label="Notification"
                formikProps={dialogFormikProps}
                disabled={!!(templateList.length < 1)}
              >
                <MenuItem value={true}>Enabled</MenuItem>
                <MenuItem value={false}>Disabled</MenuItem>
              </TextField>
            </Grid>
          )}

          {Boolean(dialogFormikProps.values.notification) && (
            <Grid item xs={12}>
              <Autocomplete
                name="templateID"
                label="Template"
                defaults={{
                  primaryKey: "_id",
                  displayLabel: "name",
                }}
                onChange={onTemplateChange}
                options={templateList}
                formikProps={dialogFormikProps}
              />
            </Grid>
          )}

          {Boolean(dialogFormikProps.values.notification) &&
            Boolean(dialogFormikProps.values.fields.length > 0) &&
            dialogFormikProps.values.fields.map((_, index) => (
              <Grid item xs={12} key={index}>
                <TextField
                  select
                  required
                  name={"fields[" + index + "].field_name"}
                  label={"Placeholder Variable " + (index + 1)}
                  formikProps={dialogFormikProps}
                >
                  {webhookEvents
                    .find(
                      (webhookEvent) =>
                        webhookEvent.name === dialogFormikProps.values.name
                    )
                    .available_fields.map((field) => (
                      <MenuItem
                        value={field.field_name}
                        key={field.field_name}
                        onClick={() => {
                          dialogFormikProps.setValues((prevValue) => {
                            prevValue.fields[index].field_label =
                              field.field_label;

                            return prevValue;
                          });
                        }}
                      >
                        {field.field_label}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            ))}
        </Grid>
      </DialogContent>

      <DialogActions style={{ padding: "12px 24px 24px" }}>
        <Button
          onClick={dialogFormikProps.handleSubmit}
          variant="contained"
          color="primary"
          disabled={!(dialogFormikProps.dirty && dialogFormikProps.isValid)}
        >
          Submit
        </Button>
        <Button onClick={handleClose} variant="outlined" color="primary">
          Close
        </Button>
      </DialogActions>
    </React.Fragment>
  );

  function onTemplateChange(_, template, type) {
    if (type === "select-option") {
      dialogFormikProps.setValues((prevValue) => ({
        ...prevValue,
        templateID: template._id,
        templateName: template.name,
        templateVariableCount: template.template_body.count_of_variable,
        fields: Array(template.template_body.count_of_variable).fill({
          field_name: "",
          field_label: "",
        }),
      }));
    } else {
      dialogFormikProps.setValues((prevValue) => ({
        ...prevValue,
        templateID: "",
        templateName: "",
        templateVariableCount: "",
        fields: [],
      }));
    }
  }
}
