import * as types from "../../constants/reducerConstants";

var initialState = {
  loading: false,
  error: false,
  apiCount: 0,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.LOADER_START:
      return {
        loading: true,
        error: false,
        apiCount: actions.apiCount,
      };

    case types.API_COMPLETE: {
      let apiCount = state.apiCount - actions.apiCount;

      let newState = {
        loading: apiCount > 0 ? true : false,
        error: state.error,
        apiCount: apiCount > 0 ? apiCount : 0,
      };

      return newState;
    }

    case types.LOADER_ERROR: {
      let newState = {
        loading: state.loading,
        error: true,
        apiCount: state.apiCount,
      };

      return newState;
    }

    case types.LOADER_STOP:
      return {
        loading: false,
        error: false,
        apiCount: 0,
      };

    default:
      return state;
  }
}
