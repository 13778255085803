import React from "react";

import { Grid, Typography, Tooltip, IconButton } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

export default function FormHeader({
  formName,
  editIcon,
  setEditState,
  handleReset,
}) {
  return (
    <Grid container alignItems="center" style={{ minHeight: "47px" }}>
      <Grid item xs={6}>
        <Typography variant="h4" display="inline">
          {formName}
        </Typography>
      </Grid>
      {editIcon ? (
        <Grid item xs={6} container justifyContent="flex-end">
          <Grid item>
            <Tooltip title="Edit Details">
              <IconButton
                color="primary"
                onClick={() => {
                  setEditState((prevValue) => !prevValue);
                  handleReset();
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs />
      )}
    </Grid>
  );
}
