import { useHistory } from "react-router";
import convertDate from "../../../util/convertDate";
import DataTable from "../../../custom components/DataTable";

export default function Table({ bots }) {
  const history = useHistory();

  const options = {
    onRowClick: (cellData, cellMetaData) => {
      history.push("/bot-details/" + bots[cellMetaData.dataIndex].agent_id);
    },
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  return <DataTable columns={columns} options={options} data={bots} />;
}

const columns = [
  {
    name: "agent_name",
    label: "Bot Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "business_name",
    label: "Business Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "channel_name",
    label: "Bot Channel",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "total_users",
    label: "Total Users",
    options: {
      customBodyRender: (value) => {
        return value ? value : "0";
      },
    },
  },
  {
    name: "total_conversations",
    label: "Total Messages",
    options: {
      customBodyRender: (value) => {
        return value ? value : "0";
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
  {
    name: "agent_id",
    label: "Bot ID",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
      display: false,
    },
  },
  {
    name: "business_id",
    label: "Business ID",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
      display: false,
    },
  },
  {
    name: "is_active",
    label: "Bot Status",
    options: {
      customBodyRender: (value) => {
        return value === true ? "Active" : "Inactive";
      },
      display: false,
    },
  },
];
