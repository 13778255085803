import React from "react";
import { Formik } from "formik";
import { useAxios, useAlert } from "../../../custom hooks";
import { useHistory } from "react-router";

import FormDisplay from "./FormDisplay";
import { objectFromFormData } from "./utteranceDetailsObject";
import validationSchema from "./validationSchema";
import { ButtonFooter } from "../../../custom components";

export default function Form({
  formData,
  setFormData,
  editState,
  setEditState,
  pagePermissions,
  botServiceList,
}) {
  const axios = useAxios();
  const history = useHistory();
  const alert = useAlert();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        var url = "/service/utterance";
        let method = "POST";
        if (!!formData.ID) {
          url = url + "/" + formData.ID;
          method = "PATCH";
        }
        axios({
          url: url,
          method: method,
          data: objectFromFormData(formData),
          disableRedirect: true,
        })
          .then((response) => {
            setSubmitting(false);

            if (response.status) {
              alert.success(response.message);
              setEditState(false);
              if (method === "POST") {
                history.push("/utterances/" + response.data.utterance_id);
              } else {
                setFormData(formData);
              }
            }
          })
          .catch((err) => console.log(err));
      }}
    >
      {(formikProps) => (
        <form noValidate onSubmit={formikProps.handleSubmit}>
          {pagePermissions.components.form?.read && (
            <FormDisplay
              formikProps={formikProps}
              editState={editState}
              permissions={pagePermissions.components.form}
              botServiceList={botServiceList}
            />
          )}

          <ButtonFooter
            editState={editState}
            setEditState={setEditState}
            deletePermission={pagePermissions.delete}
            handleDelete={handleDelete}
            id={formikProps.values.ID}
            formikProps={formikProps}
          />
        </form>
      )}
    </Formik>
  );

  function handleDelete() {
    axios({
      url: "/service/utterance/" + formData.ID,
      method: "DELETE",
    })
      .then((response) => {
        if (response.status) {
          alert.success(response.message);
          history.push("/utterances/all");
        }
      })
      .catch((error) => console.log(error));
  }
}
