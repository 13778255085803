import React, { useState } from "react";
import { useDispatch } from "react-redux";

import ComponentsTable from "./ComponentsTable";
import useAxios from "../../../custom hooks/useAxios";

import {
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";

import { TextField, ComponentHeader, Paper } from "../../../custom components";

import {
  alertError,
  alertSuccess,
  alertWarning,
} from "../../../redux/actions/alertActions";

export default function ComponentsDisplay({ pageID, components }) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const [componentsList, setComponentsList] = useState(components);
  const [dialogConfig, setDialogConfig] = useState({
    state: false,
    componentName: "",
    componentDescription: "",
  });

  const options = {
    onCellClick: handleCellClick,
  };

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          headerName="Components"
          id={true}
          buttons={[
            <Tooltip title="Add New Component">
              <IconButton onClick={addNewComponent} color="primary">
                <Add />
              </IconButton>
            </Tooltip>,
          ]}
        />
      </Grid>

      {componentsList.length > 0 && (
        <Grid item xs>
          <ComponentsTable components={componentsList} options={options} />
        </Grid>
      )}

      <Dialog open={dialogConfig.state} onClose={handleDialogClose}>
        <DialogTitle>Add new Component</DialogTitle>
        <DialogContent style={{ overflow: "hidden", marginBottom: "10px" }}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <TextField
                name="componentName"
                label="Component Name"
                onChange={handleChange}
                fullWidth
                value={dialogConfig.componentName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="componentDescription"
                label="Component Description"
                onChange={handleChange}
                fullWidth
                multiline
                rows={3}
                case="none"
                value={dialogConfig.componentDescription}
              />
            </Grid>
            <Grid item xs />
            <Grid item>
              <Button
                variant="contained"
                onClick={handleSubmit}
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Paper>
  );

  function addNewComponent() {
    setDialogConfig({
      state: true,
      componentName: "",
      componentDescription: "",
    });
  }

  function handleCellClick(cellData, cellMetaData) {
    if (cellMetaData.colIndex === 4) {
      axios({
        url:
          "/page/component/" +
          componentsList[cellMetaData.dataIndex].component_id,
        method: "DELETE",
      })
        .then((response) => {
          if (response.status) {
            dispatch(alertSuccess("Component Deleted!"));
            setComponentsList((prevValue) =>
              prevValue.filter(
                (component) =>
                  component.component_id !==
                  componentsList[cellMetaData.dataIndex].component_id
              )
            );
          } else {
            dispatch(alertError("Component could not be deleted!"));
          }
        })
        .catch(() =>
          dispatch(alertWarning("It seems something has gone wrong!"))
        );
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;

    setDialogConfig((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }

  function handleSubmit() {
    var variableName = dialogConfig.componentName
      .split(/[\s,_]+/)
      .map((stringName, index) => {
        if (index !== 0) {
          stringName =
            stringName.slice(0, 1).toUpperCase() +
            stringName.slice(1).toLowerCase();
        } else {
          stringName = stringName.toLowerCase();
        }

        return stringName;
      })
      .join("");

    axios({
      url: "/page/component/",
      method: "POST",
      data: {
        page_id: pageID,
        name: dialogConfig.componentName,
        description: dialogConfig.componentDescription,
        variable_name: variableName,
      },
    })
      .then((response) => {
        if (response.status) {
          dispatch(alertSuccess("Component Added!"));
          setComponentsList((prevValue) => [
            ...prevValue,
            {
              component_id: response.data.component_id,
              name: dialogConfig.componentName,
              description: dialogConfig.componentDescription,
              variable_name: variableName,
            },
          ]);
          handleDialogClose();
        } else {
          dispatch(alertError("Component could not be added!"));
        }
      })
      .catch(() =>
        dispatch(alertWarning("It seems something has gone wrong!"))
      );
  }

  function handleDialogClose() {
    setDialogConfig({
      state: false,
      componentName: "",
      componentDescription: "",
    });
  }
}
