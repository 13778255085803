import * as Yup from "yup";

export const emailComponentObject = {
  email: "",
};

export const passwordComponentObject = {
  password: "",
  confirmPassword: "",
};

export const emailComponentSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .matches(/@dolphinchat\.ai$/, "Enter email with correct domain name!")
    .max(255)
    .required("Email is required"),
});

export const passwordComponentSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#@$!%*?&])[A-Za-z0-9#@$!%*?&]{8,}$/,
      "Please enter a valid password!"
    )
    .max(255)
    .required("Please enter password"),

  confirmPassword: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string()
      .oneOf([Yup.ref("password")], "Both password need to be the same")
      .required("Please enter confirm password"),
  }),
});
