import React from "react";
import { useDispatch } from "react-redux";
import { alertSuccess } from "../../../redux/actions/alertActions";
import {
  Paper,
  Modal,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { Cancel, FileCopy } from "@material-ui/icons";
import { ENV } from "../../../config/config";
export default function StyleModalConfirmation({
  styleModal,
  setStyleModal,
  agentId,
}) {
  const dispatch = useDispatch();
  function handleModalClose() {
    setStyleModal(false);
  }

  const text = `<!-- DolphinChat web widget -- > 
  <script type="text/javascript" src="https://widget.dolphinchat.ai/web/bot-agent-1.0.js" id="cbot-universal-id" crossorigin="anonymous" data-agent="${agentId}" data-env="${ENV}"></script>
  <!-- DolphinChat web widget ends-- >
  `;

  const copyText = () => {
    navigator.clipboard.writeText(text);
    dispatch(alertSuccess(" copied to clipboard!"));
  };

  return (
    <React.Fragment>
      <Modal
        open={styleModal}
        onClose={handleModalClose}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          style={{ padding: "30px", width: "400px", borderRadius: "20px" }}
          elevation={2}
        >
          <Cancel
            style={{
              marginLeft: "98%",
              color: "#cbcbcb",
              marginTop: "0px",
              cursor: "pointer",
            }}
            onClick={handleModalClose}
          />
          <Grid container spacing={3}>
            <Typography style={{ padding: "10px" }} variant="h4">
              Add the widget to your website
            </Typography>

            <Typography
              style={{ padding: "10px", color: "#808080" }}
              variant="h6"
            >
              To add the changes to your bot follow the required steps!
            </Typography>
            <Typography style={{ padding: "10px" }} variant="body1">
              <b> Step 1:</b> Copy the given script tag below in the box.
            </Typography>
            <Typography style={{ padding: "10px" }} variant="body1">
              <b>Step 2:</b> Paste the script tag in the head section of the
              code
            </Typography>
            <div
              style={{
                border: "1px solid #cbcbcb",
                margin: "5px",
              }}
            >
              <Grid
                style={{
                  padding: "10px 10px 0",
                  textAlign: "end",
                }}
                item
                xs={12}
              >
                <Tooltip title="copy text">
                  <IconButton
                    style={{ padding: "0px" }}
                    onClick={copyText}
                    color="primary"
                  >
                    <FileCopy />
                  </IconButton>
                </Tooltip>
              </Grid>
              <div style={{ padding: "10px 10px 0 10px" }}>
                <Typography style={{ padding: "0 0 10px 0" }} variant="body1">
                  {`<!-- DolphinChat web widget -- >`}
                </Typography>
                <Typography variant="body1">
                  {`<script type="text/javascript" src="https://widget.dolphinchat.ai/web/bot-agent-1.0.js" id="cbot-universal-id" data-agent="${agentId}" data-env="${
                    ENV === "prod" ? "main" : ENV
                  }"></script>`}
                </Typography>
                <Typography
                  style={{ padding: "10px 0 10px 0" }}
                  variant="body1"
                >
                  {`<!-- DolphinChat web widget ends-- >`}
                </Typography>
              </div>
            </div>
          </Grid>
        </Paper>
      </Modal>
    </React.Fragment>
  );
}
