import { useState, useEffect } from "react";

import Form from "./whatsapp number details component/Form";
import { PageHeader, Loader } from "../../custom components";
import { useAxios, useLoader, usePermissions } from "../../custom hooks";
import {
  whatsappDetailsObject,
  objectFromResponseData,
} from "../whatsapp number/whatsapp number details component/whatsappDetailsObject";
import {
  whatsappStyleObject,
  objectFromBusinessProfileDetails,
} from "./whatsapp profile style components/whatsappStyleObject";
import BusinessForm from "./whatsapp profile style components/BusinessForm";

export default function WhatsappNumberDetailsPage(props) {
  const axios = useAxios();
  const loader = useLoader();

  const pagePermissions = usePermissions(props.pageID);
  const whatsappNumberID = props.match.params.whatsappNumberID || "";

  const [editState, setEditState] = useState(true);
  const [businessData, setBusinessData] = useState([]);
  const [businessVerticals, setBusinessVerticals] = useState([]);
  const [formData, setFormData] = useState(whatsappDetailsObject);
  const [businessProfile, setBusinessProfile] = useState(whatsappStyleObject);

  useEffect(() => {
    if (formData.whatsappNumberID !== "") {
      setEditState(false);
    }
  }, [formData]);

  useEffect(() => {
    loader.start(3);
    if (whatsappNumberID) {
      axios({
        url: "/get_whatsapp_no_details?id=" + whatsappNumberID,
      })
        .then((response) => {
          setFormData(objectFromResponseData(response.data));
          loader.apiComplete();
        })
        .catch((error) => console.log(error));

      axios({
        url: "/fetch_profile_details?id=" + whatsappNumberID,
      })
        .then((response) => {
          setBusinessProfile(
            objectFromBusinessProfileDetails(response.data, whatsappNumberID)
          );
          loader.apiComplete();
        })
        .catch((error) => console.log(error));

      axios({
        url: "/business_industry_drop_down_verticals",
      }).then((response) => {
        setBusinessVerticals(response.data);
        loader.apiComplete();
      });
    } else {
      loader.apiComplete(2);
    }

    axios({
      url: "/business/list",
    }).then((response) => {
      setBusinessData(response.data);
      loader.apiComplete();
    });
  }, [whatsappNumberID, axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Whatsapp Number Details"
        breadcrumbs={[
          { name: "WhatsApp Numbers", location: "/whatsapp-numbers/all" },
        ]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      <Form
        formData={formData}
        setFormData={setFormData}
        editState={editState}
        setEditState={setEditState}
        businessData={businessData}
        setBusinessData={setBusinessData}
        componentPermissions={pagePermissions.components}
      />

      <BusinessForm
        editState={editState}
        businessVerticals={businessVerticals}
        businessProfile={businessProfile}
        setBusinessProfile={setBusinessProfile}
      />
    </Loader>
  );
}
