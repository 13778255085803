export const ENV = "dev";

export const BASE_URL = {
  superadmin: {
    url: "https://dev.api.dolphinchat.ai",
    version: "/v2/superadmin",
  },
  adminV1: {
    url: "https://dev.api.dolphinchat.ai",
    version: "",
  },
  adminV2: {
    url: "https://dev.api.dolphinchat.ai",
    version: "/v2/admin",
  },
  component: {
    url: "https://dev.component.dolphinchat.ai/integrations",
    version: "/v1",
  },
};
