import React from "react";

import { Grid, Button, Divider, MenuItem } from "@material-ui/core";

import {
  Paper,
  TextField,
  FormHeader,
  TextFieldWithCopy,
} from "../../../custom components";

export default function FormDisplay({
  formikProps,
  businessData,
  editState,
  setEditState,
}) {
  return (
    <Paper>
      <Grid item xs={12}>
        <FormHeader
          formName="WhatsApp Number Details"
          handleReset={formikProps.handleReset}
          setEditState={setEditState}
        />
      </Grid>

      {formikProps.values.whatsappNumberID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextFieldWithCopy
              label="WhatsApp Number ID"
              name="whatsappNumberID"
              case="none"
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6} />
        </React.Fragment>
      )}
      <Grid item xs={4}>
        <TextField
          select
          label="Business Name"
          name="businessID"
          disabled={!editState}
          formikProps={formikProps}
        >
          {businessData?.map((businessName) => (
            <MenuItem key={businessName._id} value={businessName._id}>
              {businessName.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="WhatsApp Service"
          name="whatsappServices"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="WhatsApp Access Token"
          name="accessToken"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="Phone Number"
          name="phoneNumber"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="Admin Username"
          name="adminUsername"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="Admin Password"
          name="adminPassword"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="URL"
          name="url"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="Bot Name"
          name="botName"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="Namespace"
          name="namespace"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          label="Whatsapp Business Id"
          name="whatsappBusinessID"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      {editState && (
        <React.Fragment>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs></Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                formikProps.dirty
                  ? formikProps.isSubmitting || !formikProps.isValid
                  : !formikProps.dirty
              }
            >
              Submit
            </Button>
          </Grid>
          {formikProps.values.whatsappNumberID !== "" && (
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  setEditState(false);
                  formikProps.handleReset();
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
          )}
        </React.Fragment>
      )}
    </Paper>
  );
}
