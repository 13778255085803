export const BotDetailsObject = {
  agentID: "",
  webhook: "",
  parentURL: "",
  bubbleAvatar: "",
  bubblePosition: "",
  bubbleFloat: false,
  bubbleRipple: true,
  popupText: "",
  fontFamily: "",
  windowDisplay: "",
  windowHeight: "",
  windowWidth: "",
  windowAutoOpen: false,
  windowHeaderCaption: "",
  windowHeaderTitle: "",
  windowHeaderBackground: "",
  windowHeaderForeground: "",
  whiteLabel: true,
  menu: [],
  attachmentImage: false,
  attachmentVideo: false,
  attachmentDocuments: false,
  attachmentLocation: false,
  audio: false,
  emoji: false,
};
export function ConfigureDetailFromResponseData(responseData) {
  if (responseData.widget) {
    return {
      agentID: responseData.widget.agent,
      webhook: responseData.widget.webhook,
      parentURL: responseData.widget.parent_url || "www.dolphinchat.ai",
      bubblePosition: responseData.widget.bubble.position || "right",
      bubbleFloat: responseData.widget.bubble.float || false,
      bubbleRipple: responseData.widget.bubble.ripple || true,
      popupText: responseData.widget.popup.text || "Welcome To DolphinChat",
      fontFamily: responseData.widget.fontFamily || "sans-serif",
      windowDisplay: responseData.widget.window.display || "default",
      windowHeight: responseData.widget.window.height || "50",
      windowWidth: responseData.widget.window.width || "100",
      windowAutoOpen: responseData.widget.window.autoOpen || false,
      windowHeaderCaption:
        responseData.widget.window.header.caption || "Bots for seamless chats",
      windowHeaderTitle:
        responseData.widget.window.header.title || "DolphinChat",
      windowHeaderBackground:
        responseData.widget.window.header.background || "#417505",
      windowHeaderForeground:
        responseData.widget.window.header.foreground || "#f0f0f0",
      whiteLabel: responseData.widget.window.whiteLabel || false,
      menu: responseData.widget.window.menu || [],
      attachmentImage: false,
      attachmentVideo: false,
      attachmentDocuments: false,
      attachmentLocation: false,
      audio: responseData.widget.audio || false,
      emoji: responseData.widget.window.emoji || false,
      bubbleAvatar:
        responseData.widget.bubble.avatar ||
        "https://dc-chat-media.s3.ap-south-1.amazonaws.com/1c25502e214b4b21a5ff635d613d21a3/1632118620967/manAvatar.jpg",
    };
  } else
    return {
      agentID: responseData.agent_id,
      webhook: "https://dev.api.dolphinchat.ai/bot/v1/middleware/",
      parentURL: "www.dolphinchat.ai",
      bubbleAvatar:
        "https://dc-chat-media.s3.ap-south-1.amazonaws.com/1c25502e214b4b21a5ff635d613d21a3/1632118620967/manAvatar.jpg",
      bubblePosition: "right",
      bubbleFloat: false,
      bubbleRipple: true,
      popupText: "Welcome To DolphinChat",
      fontFamily: "sans-serif",
      windowDisplay: "default",
      windowHeight: "50",
      windowWidth: "100",
      windowAutoOpen: false,
      windowHeaderCaption: "Bots for seamless chats",
      windowHeaderTitle: "DolphinChat",
      windowHeaderBackground: "#417505",
      windowHeaderForeground: "#f0f0f0",
      whiteLabel: true,
      menu: [],
      attachmentImage: false,
      attachmentVideo: false,
      attachmentDocuments: false,
      attachmentLocation: false,
      audio: false,
      emoji: false,
    };
}
export function objectFromFormData(formData) {
  return {
    bubble: {
      ripple: formData.bubbleRipple,
      avatar: formData.bubbleAvatar,
      position: formData.bubblePosition,
      float: formData.bubbleFloat,
    },
    popup: {
      text: formData.popupText,
    },
    parent_url: formData.parentURL,
    webhook: formData.webhook,
    agent: formData.agentID,
    fontFamily: formData.fontFamily,
    window: {
      display: formData.windowDisplay,
      height: formData.windowHeight,
      width: formData.windowWidth,
      autoOpen: formData.windowAutoOpen,
      header: {
        caption: formData.windowHeaderCaption,
        title: formData.windowHeaderTitle,
        background: formData.windowHeaderBackground,
        foreground: formData.windowHeaderForeground,
      },
      menu: formData.menu,
      whiteLabel: formData.whiteLabel,
      attachments: {
        image: formData.attachmentImage,
        video: formData.attachmentVideo,
        documents: formData.attachmentDocuments,
        location: formData.attachmentLocation,
      },
      emoji: formData.emoji,
    },
    audio: formData.audio,
    calendar: [],
  };
}
