import * as Yup from "yup";

export const whatsappDetailsObject = {
  whatsappNumberID: "",
  whatsappServices: "",
  accessToken: "",
  phoneNumber: "",
  adminUsername: "",
  adminPassword: "",
  url: "",
  botName: "",
  namespace: "",
  whatsappBusinessID: "",
  businessVerticalId: "",
  businessID: "",
};

export function objectFromResponseData(responseData) {
  return {
    whatsappNumberID: responseData._id,
    whatsappServices: responseData.whatsapp_service,
    phoneNumber: responseData.phone_number,
    whatsappBusinessID: responseData.whatsapp_business_id,
    accessToken: responseData.whatsapp_access_token,
    botName: responseData.bot_name,
    url: responseData.url,
    namespace: responseData.namespace,
    adminUsername: responseData.admin_username,
    adminPassword: responseData.admin_password,
    businessVerticalId: responseData.vertical,
    businessID: responseData.business_id,
  };
}

export function objectFromFormData(formData) {
  return {
    whatsapp_service: formData.whatsappServices,
    url: formData.url,
    phone_number: formData.phoneNumber,
    bot_name: formData.botName,
    whatsapp_access_token: formData.accessToken,
    whatsapp_business_id: formData.whatsappBusinessID,
    namespace: formData.namespace,
    admin_username: formData.adminUsername,
    admin_password: formData.adminPassword,
    vertical: formData.businessVerticalId,
    business_id: formData.businessID,
  };
}

export const validationSchema = Yup.object().shape({
  whatsappServices: Yup.string()
    .max(25)
    .required("Whatsapp Service is required"),
  botName: Yup.string().required("Bot Name is required"),
  accessToken: Yup.string().required("Acess Token is required"),
  phoneNumber: Yup.string()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Please enter a valid mobile number"
    )
    .required("Whatsapp Number is required"),
  adminUsername: Yup.string().required("Admin Username is required"),
  adminPassword: Yup.string().required("Admin Password is required"),
  url: Yup.string().required("URL is required"),
  namespace: Yup.string().required("Namespace is required"),
  whatsappBusinessID: Yup.string().required("Whatsapp Business ID is required"),
  businessID: Yup.string().required("Business Name is required"),
});
