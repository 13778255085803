import React, { useState } from "react";

import AWS from "aws-sdk";

import {
  Grid,
  MenuItem,
  InputAdornment,
  Button,
  Typography,
} from "@material-ui/core";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { Info } from "@material-ui/icons";

import {
  Autocomplete,
  ComponentHeader,
  Paper,
  TextField,
  TextFieldWithCopy,
} from "../../../../custom components";

import GenerateCSVPopUp from "./GenerateCSV";

import convertDateToBackendFormat from "./convertDateToBackendFormat";
import { useAxios } from "../../../../custom hooks";
import { alertWarning } from "../../../../redux/actions/alertActions";
import { useDispatch } from "react-redux";

export default function FormDisplay({
  formikProps,
  editState,
  setEditState,
  template,
  scheduledTime,
  templateData,
  setTemplateData,
  setScheduledTime,
  groupList,
  authToken,
  agentID,
  memberID,
}) {
  const [CSVPopUp, setCSVPopUp] = useState(false);
  const dispatch = useDispatch();
  const axios = useAxios({
    baseURL: "adminV2",
    customAuthToken: "Bearer " + authToken,
  });

  let newDate = new Date();
  let date_raw = newDate.getDate();
  let month_raw = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  var date = year + "-" + month_raw + "-" + date_raw;

  const dummyXSLX = () => {
    if (!Boolean(formikProps.values.template)) {
      dispatch(alertWarning("Need to select template first."));
    } else {
      var ws_data = [["mobile_number"]];
      var body_variable = 0;
      if (templateData.template_data?.template_body?.count_of_variable > 0) {
        body_variable =
          templateData.template_data?.template_body?.count_of_variable;
        for (
          var i = 0;
          i < templateData.template_data?.template_body?.count_of_variable;
          i++
        ) {
          ws_data[0][i + 1] = "body_variable" + (i + 1);
        }
      }
      if (templateData.template_data?.template_header?.count_of_variable > 0) {
        for (
          var j = 0;
          j < templateData.template_data?.template_header?.count_of_variable;
          j++
        ) {
          ws_data[0][body_variable + 1] = "header_variable" + (j + 1);
        }
      }

      if (templateData.template_data?.template_button?.count_of_variable > 0) {
        for (
          var button = 0;
          button <
          templateData.template_data?.template_button?.count_of_variable;
          button++
        ) {
          ws_data[0][body_variable + 1] = "button_variable" + (button + 1);
        }
      }

      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Broadcast_Data",
        CreatedDate: new Date(),
      };
      wb.SheetNames.push("Broadcast Sheet");
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      wb.Sheets["Broadcast Sheet"] = ws;
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      }
      FileSaver.saveAs(
        new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
        "broadcast_data.xlsx"
      );
    }
  };
  const handleChange = (e) => {
    formikProps.setValues((prev) => ({
      ...prev,
      broadcastDate: e.target.value,
    }));
    var time = convertDateToBackendFormat(e.target.value);

    axios({
      url: "/broadcast/time?schedule_date=" + time,
      method: "GET",
    }).then((response) => {
      if (response.status) {
        setScheduledTime(response.data);
      }
    });
  };
  async function handleTemplate(templates, type) {
    if (type === "select-option") {
      axios({
        url: "/wa/template/variable/" + agentID + "/" + templates._id,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          setTemplateData(response.data);
        }
      });
      formikProps.setValues((prev) => ({
        ...prev,
        template: templates._id,
        templateName: templates.name,
      }));
    } else {
      templates = {
        _id: "",
        name: "",
      };
      formikProps.setValues((prev) => ({
        ...prev,
        template: templates._id,
        templateName: templates.name,
      }));
      setTemplateData({});
    }
  }

  const handleChangeCSV = (CSV) => {
    const file = CSV.target.files[0];

    const fileSize = file?.size;
    const sizeInMB = (fileSize / (1024 * 1024)).toFixed(2);

    if (sizeInMB > 16) {
      dispatch(alertWarning("Please upload a file less than 16 MB!"));
    } else {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = function () {
          var bucketName = "dc-chat-media";
          var bucketRegion = "ap-south-1";
          var identityPoolID =
            "ap-south-1:16fa1fcf-7cdd-4593-9ee0-6f621526defc";

          AWS.config.update({
            region: bucketRegion,
            credentials: new AWS.CognitoIdentityCredentials({
              IdentityPoolId: identityPoolID,
            }),
          });

          var s3 = new AWS.S3({
            apiVersion: "2006-03-01",
            params: {
              Bucket: bucketName,
            },
          });
          var filePath = "broadcast/" + new Date().getTime() + "/" + file.name;
          var upload = s3.upload({
            Key: filePath,
            ACL: "public-read",
            Body: file,
          });
          var promise = upload.promise();
          promise.then(function (data) {
            formikProps.setValues((prev) => ({
              ...prev,
              uploadCSVFile: data.Location,
              CSVFile: file.name,
            }));
          });
          reader.onerror = function (error) {
            console.log("Error: ", error);
          };
        };
      }
    }
  };

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader headerName="Broadcast Details" />
        </Grid>
        {formikProps.values.ID && (
          <React.Fragment>
            <Grid item xs={6}>
              <TextFieldWithCopy
                required
                label="Broadcast ID"
                name="ID"
                disabled
                formikProps={formikProps}
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </React.Fragment>
        )}

        <Grid item xs={6}>
          <TextField
            required
            label="Broadcast Name"
            name="broadcastName"
            disabled={!editState}
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            required
            label="When To Broadcast"
            name="whenToBroadcast"
            disabled={!editState}
            formikProps={formikProps}
          >
            <MenuItem value={"now"}>Now</MenuItem>
            <MenuItem value={"later"}>Later</MenuItem>
          </TextField>
        </Grid>

        {Boolean(formikProps.values.whenToBroadcast) &&
          formikProps.values.whenToBroadcast !== "now" && (
            <React.Fragment>
              <Grid item xs={6}>
                <TextField
                  type="date"
                  name="broadcastDate"
                  label="Select Date"
                  onChange={handleChange}
                  formikProps={formikProps}
                  inputProps={{
                    min: date,
                  }}
                  disabled={
                    !editState || formikProps.values.whenToBroadcast === "now"
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  select
                  label="Select Time"
                  name="broadcastTime"
                  disabled={
                    !editState || formikProps.values.whenToBroadcast === "now"
                  }
                  formikProps={formikProps}
                >
                  {scheduledTime.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.time}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </React.Fragment>
          )}

        <Grid item xs={6}>
          <Autocomplete
            name="template"
            label="Template"
            defaults={{
              primaryKey: "_id",
              displayLabel: "name",
            }}
            options={template}
            onChange={(_, value, type) => handleTemplate(value, type)}
            disabled={!editState}
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            required
            label="Upload File"
            name="CSVFile"
            disabled
            formikProps={formikProps}
            style={{ padding: "0px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <input
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    disabled={!editState}
                    accept=".xlsx"
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                    onChange={(event) => handleChangeCSV(event)}
                  />
                  <label disabled={!editState} htmlFor="raised-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!editState}
                      component="span"
                    >
                      Upload File
                    </Button>
                  </label>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid
          style={{
            background: "#e8e8e8",
            display: "flex",
            margin: "20px 0 0 0",
          }}
          item
          xs={12}
        >
          <div style={{ width: "30px" }}>
            <Info color="secondary" style={{ fontSize: "20px" }} />
          </div>
          <div>
            <Typography variant="body2" style={{ letterSpacing: "0.5px" }}>
              You need to select a template to download blank file or to create
              a file.
            </Typography>
          </div>
        </Grid>
        <Grid
          style={{ background: "#e8e8e8", display: "flex", margin: "5px 0" }}
          item
          xs={12}
        >
          <div style={{ width: "30px" }}>
            <Info color="secondary" style={{ fontSize: "20px" }} />
          </div>
          <div>
            <Typography variant="body2" style={{ letterSpacing: "0.5px" }}>
              If the file uploaded does not follow the template, numbers will
              not get added.
            </Typography>
          </div>
        </Grid>

        <Grid
          style={{
            background: "#e8e8e8",
            display: "flex",
            margin: "0 0 5px 0 ",
          }}
          item
          xs={12}
        >
          <div style={{ width: "30px" }}>
            <Info color="secondary" style={{ fontSize: "20px" }} />
          </div>
          <div>
            <Typography variant="body2" style={{ letterSpacing: "0.5px" }}>
              <Typography
                style={{
                  color: "#0075F6",
                  cursor: "pointer",
                  letterSpacing: "1px",
                }}
                variant="button"
                id="template_download_link"
                onClick={() => dummyXSLX()}
              >
                Generate Blank File
              </Typography>
              &nbsp; to upload data in a proper format. The number should start
              with country code and without plus(+).
              <br /> If the template contains any multimedia variable then
              please provide public URL for it.
            </Typography>
          </div>
        </Grid>

        <Grid
          style={{
            background: "#e8e8e8",
            display: "flex",
          }}
          item
          xs={12}
        >
          <div style={{ width: "30px" }}>
            <Info color="secondary" style={{ fontSize: "20px" }} />
          </div>
          <div>
            <Typography variant="body2" style={{ letterSpacing: "0.5px" }}>
              &nbsp;You can create and download File by giving some specific
              mapped variables if any ,You can also give custom values according
              to your need.&nbsp;
              <Typography
                style={{
                  color: "#0075F6",
                  cursor: "pointer",
                  letterSpacing: "1px",
                }}
                variant="button"
                onClick={() =>
                  !Boolean(formikProps.values.template)
                    ? dispatch(alertWarning("Need to select template first."))
                    : setCSVPopUp(true)
                }
              >
                Generate File
              </Typography>
              &nbsp; using user data e.g. mobile number, email, name etc.
            </Typography>
          </div>
        </Grid>

        {CSVPopUp && (
          <GenerateCSVPopUp
            CSVPopUp={CSVPopUp}
            setCSVPopUp={setCSVPopUp}
            formikProps={formikProps}
            templateData={templateData}
            template={template}
            setTemplateData={setTemplateData}
            groupList={groupList}
            authToken={authToken}
            agentID={agentID}
            memberID={memberID}
          />
        )}
      </Paper>
    </React.Fragment>
  );
}
