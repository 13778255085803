import React, { useState, useEffect } from "react";

import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { Formik } from "formik";
import axios from "../../../util/axios";

import {
  alertSuccess,
  alertError,
  alertInfo,
} from "../../../redux/actions/alertActions";

import {
  registerMemberObject,
  objectFromResponse,
  dataFromObject,
} from "./registerObject";

import validationSchema from "./validationSchema";
import FormDisplay from "./FormDisplay";

export default function Form(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(registerMemberObject);

  useEffect(() => {
    axios({
      url: "/register/" + props.authToken,
      method: "GET",
    })
      .then((response) => {
        if (response.data.status === true)
          setFormData((prevValue) => ({
            ...prevValue,
            ...objectFromResponse(response.data.data),
          }));
        else {
          dispatch(alertInfo("This link has either expired or invalid!"));
          history.push("/404");
        }
      })
      .catch(() => history.push("/404"));
  }, [history, dispatch, props.authToken]);

  return (
    <React.Fragment>
      <Formik
        initialValues={formData}
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          axios({
            url: "/member/register/",
            method: "PUT",
            authToken: "Bearer " + values.token,
            data: dataFromObject(values),
          })
            .then((response) => {
              setSubmitting(false);
              if (response.data.status === true) {
                dispatch(alertSuccess("Profile Updated!"));
                history.push("/sign-in");
              } else {
                dispatch(
                  alertError(response.data.error || "Something went wrong")
                );
              }
            })
            .catch((error) => {
              setSubmitting(false);
              dispatch(alertInfo(error.data.message || "Something went wrong"));
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          isValid,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormDisplay
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              isSubmitting={isSubmitting}
              touched={touched}
              values={values}
              isValid={isValid}
            />
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
}
