import React, { useState } from "react";
import { Paper, ComponentHeader } from "../../../custom components";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import WebhookEventsTable from "./WebhookEventsTable";
import WebhookEventDialog from "./WebhookEventDialog";

export default function WebhookEvents({
  formikProps,
  editState,
  integrationDetails,
  permissions,
}) {
  const [modalProps, setModalProps] = useState({
    state: false,
    mode: "",
    value: {
      name: "",
      notification: false,
      templateID: "",
      fields: [],
    },
  });

  if (
    !Boolean(
      integrationDetails.webhook_integration_available &&
        integrationDetails.webhook_event &&
        formikProps.values.botID
    ) ||
    integrationDetails.webhook_event.length === 0
  ) {
    return "";
  }

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          headerName="Webhook Events"
          id={true}
          buttons={[
            editState &&
              formikProps.values.webhookEvents.length <
                integrationDetails.webhook_event.length && (
                <Tooltip title="Add New Event">
                  <IconButton color="primary" onClick={createWebhookEvent}>
                    <Add />
                  </IconButton>
                </Tooltip>
              ),
          ]}
        />
      </Grid>

      {Boolean(formikProps.values.webhookEvents.length > 0) && (
        <Grid item xs={12}>
          <WebhookEventsTable
            formikProps={formikProps}
            setModalProps={setModalProps}
            editState={editState}
            permissions={permissions}
          />
        </Grid>
      )}

      <WebhookEventDialog
        modalProps={modalProps}
        setModalProps={setModalProps}
        formikProps={formikProps}
        webhookEvents={integrationDetails.webhook_event}
      />
    </Paper>
  );

  function createWebhookEvent() {
    setModalProps({
      state: true,
      mode: "add",
      value: {
        name: "",
        notification: false,
        templateID: "",
        fields: [],
      },
    });
  }
}
