import startDate from "../../../util/startDate";
import convertDate from "../../../util/convertDate";
import { titleCase } from "change-case-all";

export const businessDetailsObject = {
  businessID: "",
  businessName: "",
  adminEmail: "",
  address: "",
  country: "",
  state: "",
  city: "",
  pincode: "",
  creatorEmail: "",
  businessStatus: "",
  tenure: "",
  planID: "",
  startDate: "",
  updatedAt: "",
  createdAt: "",
};

export function objectFromResponseData(resposneObject) {
  return {
    businessID: resposneObject._id,
    businessName: resposneObject.name || "",
    adminEmail: resposneObject.admin_email || "",
    address: resposneObject.address.address || "",
    country: resposneObject.address.country || "",
    state: resposneObject.address.state || "",
    city: resposneObject.address.city || "",
    pincode: resposneObject.address.pincode || "",
    creatorEmail: resposneObject.creator_email || "",
    businessStatus: resposneObject.is_active || "",
    tenure: resposneObject.billing_tenure || "",
    planID: resposneObject.plan_id || "",
    startDate: startDate(resposneObject.start_date) || "",
    updatedAt: convertDate(resposneObject.updated_at) || "",
    createdAt: convertDate(resposneObject.created_at) || "",
  };
}

export function objectFromFormData(formData) {
  return {
    business_name: titleCase(formData.businessName),
    admin_email: formData.adminEmail,
    address: titleCase(formData.address),
    country: titleCase(formData.country),
    state: titleCase(formData.state),
    city: titleCase(formData.city),
    pincode: parseInt(formData.pincode),
    creator_email: formData.creatorEmail,
    is_active: formData.businessStatus,
    tenure: formData.tenure,
    plan_id: formData.planID,
    start_date: new Date(formData.startDate).getTime() / 1000,
  };
}
