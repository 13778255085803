import React from "react";

import { Grid, Divider, Button, MenuItem } from "@material-ui/core";

import {
  Paper,
  TextField,
  TextFieldWithCopy,
  FormHeader,
} from "../../../custom components";

export default function FormDisplay({ formikProps, editState, setEditState }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <FormHeader
          formName="Page Information"
          editIcon={Boolean(formikProps.values.pageID)}
          setEditState={setEditState}
          handleReset={formikProps.handleReset}
        />
      </Grid>

      {formikProps.values.pageID !== "" && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextFieldWithCopy
              label="Page ID"
              name="pageID"
              fullWidth
              disabled
              case="none"
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs />
        </React.Fragment>
      )}
      <Grid item xs={6}>
        <TextField
          label="Page Name"
          name="pageName"
          fullWidth
          required
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          label="Page Status"
          name="pageStatus"
          fullWidth
          required
          disabled={!editState}
          formikProps={formikProps}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Page Description"
          name="pageDescription"
          fullWidth
          required
          multiline
          case="none"
          rows={3}
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>
      {formikProps.values.pageID !== "" && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextField
              label="Created By"
              name="createdBy"
              fullWidth
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Created At"
              name="createdAt"
              fullWidth
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Updated By"
              name="updatedBy"
              fullWidth
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Updated At"
              name="createdAt"
              fullWidth
              disabled
              formikProps={formikProps}
            />
          </Grid>
        </React.Fragment>
      )}

      {editState && (
        <React.Fragment>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs></Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                formikProps.dirty
                  ? formikProps.isSubmitting || !formikProps.isValid
                  : !formikProps.dirty
              }
            >
              Submit
            </Button>
          </Grid>
          {formikProps.values.pageID !== "" && (
            <Grid
              item
              onClick={() => {
                setEditState(false);
                formikProps.handleReset();
              }}
            >
              <Button variant="outlined" color="primary">
                Cancel
              </Button>
            </Grid>
          )}
        </React.Fragment>
      )}
    </Paper>
  );
}
