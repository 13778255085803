import React from "react";
import { Formik } from "formik";
import { useAlert, useAxios } from "../../../custom hooks";
import { useHistory } from "react-router";

import FormDisplay from "./FormDisplay";
import { objectFromFormData } from "./integrationDetailsObject";
import validationSchema from "./validationSchema";
import { ButtonFooter } from "../../../custom components";
import RequiredCredentials from "./RequiredCredentials";
import WebhookEvents from "./WebhookEvents";

export default function Form({
  formData,
  setFormData,
  groupList,
  pagePermissions,
  setEditState,
  editState,
}) {
  const history = useHistory();
  const alert = useAlert();
  const axios = useAxios({ baseURL: "component" });

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        var url = "/create_integration";
        var method = "POST";
        if (Boolean(formData.ID)) {
          url = "update_integration?integration_id=" + formData.ID;
          method = "PATCH";
        }

        axios({
          url: url,
          method: method,
          data: objectFromFormData(formData),
        })
          .then((res) => {
            if (res.status) {
              alert.success(res.message);

              if (method === "POST") {
                history.push("/integration-details/" + res.data._id);
              } else {
                setFormData(formData);
              }
            }

            setSubmitting(false);
            setEditState(false);
          })
          .catch((err) => console.log(err));
      }}
    >
      {(formikProps) => (
        <form noValidate onSubmit={formikProps.handleSubmit}>
          {pagePermissions.components.form?.read && (
            <FormDisplay
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
              groupList={groupList}
              permissions={pagePermissions.components.form}
            />
          )}
          {pagePermissions.components.requiredCredentials?.read && (
            <RequiredCredentials
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
              permissions={pagePermissions.components.requiredCredentials}
            />
          )}
          {pagePermissions.components.webhookEvents?.read && (
            <WebhookEvents
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
              permissions={pagePermissions.components.webhookEvents}
            />
          )}
          <ButtonFooter
            editState={editState}
            setEditState={setEditState}
            id={formikProps.values.ID}
            formikProps={formikProps}
          />
        </form>
      )}
    </Formik>
  );
}
