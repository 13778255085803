import React from "react";

import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core";

import * as Yup from "yup";

import { Formik } from "formik";
import { useDispatch } from "react-redux";

import GenerateCSVForm from "./GenerateCSVForm";

import {
  alertError,
  alertSuccess,
} from "../../../../redux/actions/alertActions";
import { useAxios } from "../../../../custom hooks";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1000px" },
}));

export default function GenerateCSVPopUp({
  CSVPopUp,
  setCSVPopUp,
  template,
  formikProps,
  templateData,
  setTemplateData,
  groupList,
  authToken,
  memberID,
  agentID,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const axios = useAxios({
    baseURL: "adminV2",
    customAuthToken: "Bearer " + authToken,
  });

  const csv = {
    type: "4",
    group_id: "",
    group: "",
    filter_type: "",
    template_id: formikProps.values.template,
    header_variable: Array(
      templateData.template_data?.template_header?.count_of_variable
    ).fill({
      variable_default_value: "",
      variable_name: "",
      variable_value: "",
      file_name: "",
      variable_value_type: "custom",
    }),

    body_variable: Array(
      templateData.template_data?.template_body?.count_of_variable
    ).fill({
      variable_default_value: "",
      variable_name: "",
      variable_value: "",
      variable_value_type: "custom",
    }),

    button_variable: Array(
      templateData.template_data?.template_button?.count_of_variable
    ).fill({
      variable_default_value: "",
      variable_name: "",
      variable_value: "",
      variable_value_type: "custom",
    }),
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={CSVPopUp}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle style={{ borderBottom: "1px solid #cbcbcb" }}>
        Generate Data File
      </DialogTitle>

      <Formik
        initialValues={csv}
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={(formData, { setSubmitting }) => {
          if (formData.filter_type === "all") {
            formData.type = "1";
          }
          if (formData.filter_type === "active") {
            formData.type = "2";
          }
          if (formData.filter_type === "group") {
            formData.type = "3";
          }
          axios({
            url: "/broadcast/generate/file/" + agentID,
            method: "POST",
            data: formData,
          }).then((res) => {
            if (res.status) {
              dispatch(alertSuccess("File downloaded successfully."));
              window.location.href = res.data;
              setSubmitting(false);
              setCSVPopUp(false);
            } else {
              dispatch(alertError(res.message));
              setSubmitting(false);
            }
          });
        }}
      >
        {(dialogFormikProps) => (
          <form noValidate onSubmit={dialogFormikProps.handleSubmit}>
            <DialogContent>
              <GenerateCSVForm
                dialogFormikProps={dialogFormikProps}
                templateData={templateData}
                setTemplateData={setTemplateData}
                template={template}
                groupList={groupList}
                formikProps={formikProps}
                authToken={authToken}
                agentID={agentID}
                memberID={memberID}
              />
            </DialogContent>
            <DialogActions style={{ padding: "12px 24px 24px" }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                    value="save"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs></Grid>
                <Grid item></Grid>
                <Grid item xs style={{ textAlign: "end" }}>
                  <Button
                    onClick={dialogFormikProps.handleSubmit}
                    variant="contained"
                    disabled={
                      !Boolean(formikProps.values.template) ||
                      (dialogFormikProps.dirty
                        ? dialogFormikProps.isSubmitting ||
                          !dialogFormikProps.isValid
                        : !dialogFormikProps.dirty)
                    }
                    color="primary"
                  >
                    Download File
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );

  function handleClose() {
    setCSVPopUp(false);
  }
}
const validationSchema = Yup.object().shape({
  filter_type: Yup.string().required("This is required field"),
  group_id: Yup.string().when("filter_type", {
    is: (type) => type === "group",
    then: Yup.string().required("This is required field."),
  }),
  body_variable: Yup.array(
    Yup.object().shape({
      variable_name: Yup.string().required("This is the required field."),
      variable_value_type: Yup.string().required("This is the required field."),
      variable_value: Yup.string().when("variable_value_type", {
        is: (type) => type === "user_variable",
        then: Yup.string().required("This is the required field."),
        variable_default_value: Yup.string().required(
          "This is the required field."
        ),
        otherwise: Yup.string().required("This is the required field."),
      }),
    })
  ),
  header_variable: Yup.array(
    Yup.object().shape({
      variable_name: Yup.string().required("This is the required field."),
      variable_value_type: Yup.string().required("This is the required field."),
      variable_value: Yup.string().when("variable_value_type", {
        is: (type) => type === "user_variable",
        then: Yup.string().required("This is the required field."),
        variable_default_value: Yup.string().required(
          "This is the required field."
        ),
        otherwise: Yup.string().required("This is the required field."),
      }),
    })
  ),
  button_variable: Yup.array(
    Yup.object().shape({
      variable_name: Yup.string().required("This is the required field."),
      variable_value_type: Yup.string().required("This is the required field."),
      variable_value: Yup.string().when("variable_value_type", {
        is: (type) => type === "user_variable",
        then: Yup.string().required("This is the required field."),
        variable_default_value: Yup.string().required(
          "This is the required field."
        ),
        otherwise: Yup.string().required("This is the required field."),
      }),
    })
  ),
});
