import React from "react";

import { Grid, MenuItem, Divider, Button } from "@material-ui/core";

import {
  Paper,
  FormHeader,
  TextField,
  TextFieldWithCopy,
} from "../../../custom components";

export default function FormDisplay({
  formikProps,
  editState,
  setEditState,
  groupList,
}) {
  return (
    <Paper>
      <Grid item xs={12}>
        <FormHeader
          formName="Member Information"
          editIcon={Boolean(formikProps.values.memberID)}
          setEditState={setEditState}
          handleReset={formikProps.handleReset}
        />
      </Grid>

      {formikProps.values.memberID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextFieldWithCopy
              label="Member ID"
              name="memberID"
              case="none"
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6} />
        </React.Fragment>
      )}

      <Grid item xs={4}>
        <TextField
          label="First Name"
          name="firstName"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Last Name"
          name="lastName"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          select
          label="Gender"
          name="gender"
          disabled={!editState}
          formikProps={formikProps}
        >
          <MenuItem value={"Male"}>Male</MenuItem>
          <MenuItem value={"Female"}>Female</MenuItem>
          <MenuItem value={"Other"}>Other</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={4}>
        <TextField
          label="Email"
          name="email"
          case="none"
          disabled={formikProps.values.memberID !== ""}
          formikProps={formikProps}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          select
          label="Permissions Group"
          name="groupID"
          disabled={!editState}
          formikProps={formikProps}
        >
          {groupList.map((group) => (
            <MenuItem key={group._id} value={group._id}>
              {group.group_name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {formikProps.values.memberID && (
        <Grid item xs={4}>
          <TextField
            select
            label="Status"
            name="status"
            disabled={!editState}
            formikProps={formikProps}
          >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </Grid>
      )}

      {editState && (
        <React.Fragment>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs></Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                formikProps.dirty
                  ? formikProps.isSubmitting || !formikProps.isValid
                  : !formikProps.dirty
              }
            >
              Submit
            </Button>
          </Grid>
          {formikProps.values.memberID !== "" && (
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  setEditState(false);
                  formikProps.handleReset();
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
          )}
        </React.Fragment>
      )}
    </Paper>
  );
}
