import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  botIntegrationName: Yup.string()
    .max(50, "Name too long!")
    .required("Please provide name for Integration"),
  businessID: Yup.string().required("Please select a business for Integration"),
  botID: Yup.string().required("Please select a Bot for Integration"),
  integrationGroupID: Yup.string().required(
    "Please select a group for Integration"
  ),
  integrationID: Yup.string().required("Please select an Integration"),
  phoneNumbers: Yup.array().min(1, "Please enter at least one number!"),
});

export default validationSchema;
