import React from "react";
import "./whatsappPreview.css";
import { FaGrinAlt, FaArrowLeft, FaPaperPlane } from "react-icons/fa";
import { Typography } from "@material-ui/core";
import parse from "html-react-parser";
import { FaImage, FaPlayCircle, FaFileAlt } from "react-icons/fa";

export default function WhatsappPreview({ templateData, component }) {
  function textToHtmlFormat(string) {
    const htmlFormat = [
      {
        symbol: "*",
        tag: "b",
      },
      {
        symbol: "_",
        tag: "em",
      },
      {
        symbol: "~",
        tag: "del",
      },
      {
        symbol: "`",
        tag: "code",
      },
      {
        symbol: "\n",
        tag: "br",
      },
    ];

    htmlFormat.forEach(({ symbol, tag }) => {
      if (!string) return;

      const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, "gm");
      const match = string.match(regex);
      if (!match) return;

      match.forEach((m) => {
        let formatted = m;
        for (let i = 0; i < 2; i++) {
          formatted = formatted.replace(symbol, `<${i > 0 ? "/" : ""}${tag}>`);
        }
        string = string.replace(m, formatted);
      });
    });

    return string;
  }
  var bodyText = textToHtmlFormat(
    templateData?.template_data?.template_body?.text
  );
  if (bodyText) {
    var text = parse(bodyText);
  }
  return (
    <div
      className="outer-body"
      style={{
        maxWidth: "50vh",
      }}
    >
      <div className="chat-box-whatsapp">
        <div className="user-bar">
          <div className="back">
            <FaArrowLeft />
          </div>
          <div className="user-avatar">
            <img
              src="https://dev.app.dolphinchat.ai/dist/img/avatar1.jpg"
              alt="1"
            />
          </div>
          <div className="Username">
            <Typography>Jhon Doe</Typography>
            <Typography className="status">online</Typography>
          </div>
        </div>
        <div className="conversation">
          <div className="message-box">
            <Typography id="whatsapp-demo-message-header">
              {templateData?.template_data?.template_header?.type === "text" &&
                templateData?.template_data?.template_header?.text}
              {templateData?.template_data?.template_header?.type ===
                "image" && (
                <div align="center" className="img-preview">
                  <FaImage />
                </div>
              )}
              {templateData?.template_data?.template_header?.type ===
                "video" && (
                <div align="center" className="img-preview">
                  <FaPlayCircle />
                </div>
              )}
              {templateData?.template_data?.template_header?.type ===
                "document" && (
                <div align="center" class="img-preview">
                  <FaFileAlt />
                </div>
              )}
            </Typography>
            <Typography variant="body1">{text}</Typography>
            <Typography id="whatsapp-demo-message-footer">
              {templateData?.template_data?.template_footer?.text}
            </Typography>
            <div id="whatsapp-demo-message-button" align="center">
              {templateData?.template_data?.template_button?.buttons?.map(
                (item, index) => (
                  <React.Fragment key={index}>
                    <Typography className="wt-buttons">{item.text}</Typography>
                  </React.Fragment>
                )
              )}
            </div>
          </div>
        </div>
        <div className="msg-bar">
          <div className="input-box">
            <FaGrinAlt
              style={{ margin: "10px", color: "#6f7a7f" }}
              className="grinEmoticon"
            />
            <Typography style={{ color: "#6f7a7f" }} className="text-input-box">
              Type a message
            </Typography>
          </div>
          <div className="send-button">
            <FaPaperPlane />
          </div>
        </div>
      </div>
    </div>
  );
}
