import React, { useEffect, useState } from "react";
import { PageHeader, Loader } from "../../custom components";
import AnalyticsDisplay from "./dashboard components/AnalyticsDisplay";
import { insightDataObject } from "./dashboard components/insightDataObject";
import { useAxios, useLoader } from "../../custom hooks";

function Dashboard() {
  const axios = useAxios();
  const loader = useLoader();

  const [insightData, setInsightData] = useState(insightDataObject);

  useEffect(() => {
    loader.start(12);

    axios({ url: "/insight/total/bots" }).then((response) => {
      loader.apiComplete();
      setInsightData((prevValue) => ({
        ...prevValue,
        bots: response.data,
      }));
    });

    axios({ url: "/insight/total/user" }).then((response) => {
      loader.apiComplete();
      setInsightData((prevValue) => ({
        ...prevValue,
        users: response.data,
      }));
    });

    axios({ url: "/insight/total/session" }).then((response) => {
      loader.apiComplete();
      setInsightData((prevValue) => ({
        ...prevValue,
        sessions: response.data,
      }));
    });

    axios({ url: "/insight/total/message" }).then((response) => {
      loader.apiComplete();
      setInsightData((prevValue) => ({
        ...prevValue,
        messages: response.data,
      }));
    });

    axios({ url: "/insight/total/user/message" }).then((response) => {
      loader.apiComplete();
      setInsightData((prevValue) => ({
        ...prevValue,
        userMessages: response.data,
      }));
    });

    axios({ url: "/insight/total/agent/message" }).then((response) => {
      loader.apiComplete();
      setInsightData((prevValue) => ({
        ...prevValue,
        agentMessages: response.data,
      }));
    });

    axios({ url: "/insight/total/bot/message" }).then((response) => {
      loader.apiComplete();
      setInsightData((prevValue) => ({
        ...prevValue,
        botMessages: response.data,
      }));
    });

    axios({ url: "/insight/average/session" }).then((response) => {
      loader.apiComplete();
      setInsightData((prevValue) => ({
        ...prevValue,
        averageSessions: response.data,
      }));
    });

    axios({ url: "/insight/average/per/day/session" }).then((response) => {
      loader.apiComplete();
      setInsightData((prevValue) => ({
        ...prevValue,
        averageSessionsPerDay: response.data,
      }));
    });

    axios({ url: "/insight/human/handoff/rate" }).then((response) => {
      loader.apiComplete();
      setInsightData((prevValue) => ({
        ...prevValue,
        averageHumanhandoffRate: response.data * 100,
      }));
    });

    axios({ url: "/insight/restart/rate" }).then((response) => {
      loader.apiComplete();
      setInsightData((prevValue) => ({
        ...prevValue,
        restartRate: response.data * 100,
      }));
    });

    axios({ url: "/insight/fallback/rate" }).then((response) => {
      loader.apiComplete();
      setInsightData((prevValue) => ({
        ...prevValue,
        fallbackRate: response.data * 100,
      }));
    });
  }, [loader, axios]);

  return (
    <Loader>
      <PageHeader pageName="Dashboard" />

      <AnalyticsDisplay insightData={insightData} />
    </Loader>
  );
}

export default Dashboard;
