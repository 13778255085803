import { Paper as MuiPaper, Grid } from "@material-ui/core";

export default function Paper({ gridSpacing, noGrid, ...props }) {
  return (
    <MuiPaper
      elevation={2}
      style={{ padding: "15px", marginBottom: "0.5rem" }}
      {...props}
    >
      {Boolean(noGrid) ? (
        props.children
      ) : (
        <Grid
          container
          spacing={Boolean(gridSpacing) ? gridSpacing : 5}
          alignItems="center"
        >
          {props.children}
        </Grid>
      )}
    </MuiPaper>
  );
}
