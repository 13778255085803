import React from "react";
import AWS from "aws-sdk";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import {
  Grid,
  MenuItem,
  Typography,
  Button,
  InputAdornment,
} from "@material-ui/core";
import { Group } from "@material-ui/icons";

import { TextField, Autocomplete } from "../../../../custom components";
import { useAxios } from "../../../../custom hooks";
import { alertWarning } from "../../../../redux/actions/alertActions";

export default function GenerateCSVForm({
  dialogFormikProps,
  groupList,
  templateData,
  template,
  setTemplateData,
  formikProps,
  authToken,
  agentID,
  memberID,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const axios = useAxios({
    baseURL: "adminV2",
    customAuthToken: "Bearer " + authToken,
  });

  var extensions;
  if (templateData.template_data?.template_header?.type === "image") {
    extensions = "image/*";
  } else if (templateData.template_data?.template_header?.type === "video") {
    extensions = "video/mp4,video/x-m4v,video/*";
  } else {
    extensions = ".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf";
  }
  const handleChangeFile = (event, index) => {
    const file = event.target.files[0];

    const fileSize = file?.size;
    const sizeInMB = (fileSize / (1024 * 1024)).toFixed(2);

    const reader = new FileReader();
    if (sizeInMB > 16) {
      dispatch(alertWarning("Please upload a file less than 16 MB!"));
    } else {
      if (!!file) {
        reader.readAsDataURL(file);
        const type = file?.type;

        reader.onload = function () {
          var bucketName = "dc-chat-media";
          var bucketRegion = "ap-south-1";
          var identityPoolID =
            "ap-south-1:16fa1fcf-7cdd-4593-9ee0-6f621526defc";

          AWS.config.update({
            region: bucketRegion,
            credentials: new AWS.CognitoIdentityCredentials({
              IdentityPoolId: identityPoolID,
            }),
          });

          var s3 = new AWS.S3({
            apiVersion: "2006-03-01",
            params: {
              Bucket: bucketName,
            },
          });

          var filePath = "broadcast/" + file.name;

          var upload = s3.upload({
            Key: filePath,
            ACL: "public-read",
            Body: file,
            ContentType: type,
          });

          var promise = upload.promise();

          promise.then(function (data) {
            dialogFormikProps.setValues((prev) => ({
              ...prev,
              header_variable: setVariableValue(prev.header_variable),
            }));

            function setVariableValue(headerVariable) {
              headerVariable[index].variable_value = data.Location;
              headerVariable[index].file_name = file.name;
              return headerVariable;
            }
          });

          reader.onerror = function (error) {
            console.log("Error: ", error);
          };
        };
      }
    }
  };

  // Commented to not let user select template again , else map header do not load
  // async function handleTemplate(templates, type) {
  //   if (type === "select-option") {
  //     axios({
  //       url: "/wa/template/variable/" + agentID + "/" + templates._id,
  //       method: "GET",
  //     }).then((response) => {
  //       if (response.status) {
  //         setTemplateData(response);
  //       }
  //     });
  //     formikProps.setValues((prev) => ({
  //       ...prev,
  //       template: templates._id,
  //       templateName: templates.name,
  //     }));
  //   } else {
  //     templates = {
  //       _id: "",
  //       name: "",
  //     };
  //     formikProps.setValues((prev) => ({
  //       ...prev,
  //       template: templates._id,
  //       templateName: templates.name,
  //     }));
  //     setTemplateData({});
  //   }
  // }

  const handleGroup = (groupList, type) => {
    if (type === "select-option") {
      dialogFormikProps.setValues((prevValue) => ({
        ...prevValue,
        group_id: groupList.group_id,
        group: groupList.group_name,
      }));
    } else {
      dialogFormikProps.setValues((prevValue) => ({
        ...prevValue,
        group_id: "",
        group: "",
      }));
    }
  };

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={6}>
        <Typography variant="h6" color="primary">
          Instructions and steps
        </Typography>
      </Grid>

      <Grid item xs={6} style={{ textAlign: "end", alignSelf: "center" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() =>
            history.push({
              pathname: "/group-list",
              search: `?agent_id=${agentID}&member_id=${memberID}`,
            })
          }
        >
          <Group />
          &nbsp;Manage Groups
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          padding: "8px 8px",
          margin: "20px 0px",
          background: "rgb(232, 232, 232)",
          borderRadius: "4px",
          letterSpacing: "0.5px",
        }}
      >
        <Typography variant="body1">
          • &nbsp; You need to select the template to choose group or for
          mapping variables.
        </Typography>
        <Typography variant="body1">
          • &nbsp; The mapping variable fundamental has been provided to
          generate the file as per the data you want in the file.
        </Typography>
        <Typography variant="body1">
          • &nbsp; You can also download the blank file with only headers by
          clicking on Create File.
        </Typography>
        <Typography variant="body1">
          • &nbsp; Fallback value is been asked as a default value which will be
          mapped for the users if doesn't found mapped pre-defined value for
          them , so provide logical value.
        </Typography>
        <Typography variant="body1">
          • &nbsp; System variables are the values which exist in the system
          e.g. if you select name then the generated file will have names of the
          users as per your filter selection where as custom values are the
          value you want to provide.
        </Typography>
      </Grid>
      {/* Commented so that user cannot select the template again */}
      {/* <Grid item xs={6}>
        <Autocomplete
          name="template"
          label="Template"
          defaults={{
            primaryKey: "_id",
            displayLabel: "name",
          }}
          options={template}
          onChange={(_, value, type) => handleTemplate(value, type)}
          formikProps={formikProps}
        />
        <Typography>Template</Typography>
        <TextField></TextField>
      </Grid> */}

      <Grid item xs={6} style={{ padding: "10px 8px" }}>
        <TextField
          select
          label="Filter"
          name="filter_type"
          disabled={!Boolean(formikProps.values.template)}
          formikProps={dialogFormikProps}
        >
          <MenuItem value={"all"}>All Users</MenuItem>
          <MenuItem value={"active"}>Active Users</MenuItem>
          <MenuItem value={"group"}>Group</MenuItem>
        </TextField>
      </Grid>

      {dialogFormikProps.values.filter_type === "group" && (
        <Grid item xs={6} style={{ padding: "10px 8px" }}>
          <Autocomplete
            name="group_id"
            label="Select Group"
            defaults={{
              primaryKey: "group_id",
              displayLabel: "group_name",
            }}
            options={groupList}
            onChange={(_, value, type) => handleGroup(value, type)}
            disabled={!Boolean(formikProps.values.template)}
            formikProps={dialogFormikProps}
          />
        </Grid>
      )}

      {templateData.variables?.body?.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6">Map Body Variables</Typography>
        </Grid>
      )}

      {templateData.template_data?.template_body?.count_of_variable !== 0 &&
        Boolean(formikProps.values.template) &&
        templateData.variables?.body.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={3}>
              <TextField
                select
                label="Body Variable Name"
                name={"body_variable[" + index + "].variable_name"}
                formikProps={dialogFormikProps}
              >
                {templateData.variables?.body?.map((item, index) => (
                  <MenuItem
                    value={item}
                    disabled={dialogFormikProps.values?.body_variable.some(
                      (name) => name.variable_name === item
                    )}
                  >
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              xs={
                dialogFormikProps.values?.body_variable[index]
                  ?.variable_value_type === "custom"
                  ? 4
                  : 3
              }
            >
              <TextField
                select
                label="Body Variable Type"
                name={"body_variable[" + index + "].variable_value_type"}
                formikProps={dialogFormikProps}
              >
                <MenuItem value="user_variable">System Variables</MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </TextField>
            </Grid>
            {dialogFormikProps.values?.body_variable[index]
              ?.variable_value_type === "user_variable" && (
              <Grid item xs={3}>
                <TextField
                  select
                  label="System Variables"
                  name={"body_variable[" + index + "].variable_value"}
                  formikProps={dialogFormikProps}
                >
                  <MenuItem value="name">name</MenuItem>
                  <MenuItem value="phone_number">phone number</MenuItem>
                </TextField>
              </Grid>
            )}
            {dialogFormikProps.values?.body_variable[index]
              ?.variable_value_type === "custom" && (
              <Grid item xs={5}>
                <TextField
                  label="Custom Value"
                  name={"body_variable[" + index + "].variable_value"}
                  formikProps={dialogFormikProps}
                />
              </Grid>
            )}
            {dialogFormikProps.values?.body_variable[index]
              ?.variable_value_type === "user_variable" && (
              <Grid item xs={3}>
                <TextField
                  label={"Fallback Value"}
                  name={"body_variable[" + index + "].variable_default_value"}
                  formikProps={dialogFormikProps}
                />
              </Grid>
            )}
            {(!Boolean(
              dialogFormikProps.values?.body_variable[index]
                ?.variable_value_type
            ) ||
              dialogFormikProps.values?.body_variable.length === 0) && (
              <Grid item xs={3}></Grid>
            )}
          </React.Fragment>
        ))}

      {Boolean(formikProps.values.template) &&
        templateData.template_data?.template_header?.count_of_variable !==
          0 && (
          <Grid item xs={12}>
            <Typography variant="h6">Map Header Variables</Typography>
          </Grid>
        )}

      {templateData.template_data?.template_header?.count_of_variable !== 0 &&
        Boolean(formikProps.values.template) &&
        templateData.variables?.header?.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={3}>
              <TextField
                select
                label="Header Variable Name"
                name={"header_variable[" + index + "].variable_name"}
                formikProps={dialogFormikProps}
              >
                {templateData.variables.header.map((item, index) => (
                  <MenuItem
                    value={item}
                    disabled={dialogFormikProps.values?.header_variable.some(
                      (name) => name.variable_name === item
                    )}
                  >
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid
              item
              xs={
                dialogFormikProps.values?.header_variable[index]
                  ?.variable_value_type === "custom"
                  ? 4
                  : 3
              }
            >
              <TextField
                select
                label={"Header Variable Type"}
                name={"header_variable[" + index + "].variable_value_type"}
                formikProps={dialogFormikProps}
              >
                <MenuItem
                  value="user_variable"
                  disabled={
                    templateData.template_data?.template_header?.type !== "text"
                  }
                >
                  System Variables
                </MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </TextField>
            </Grid>

            {dialogFormikProps.values?.header_variable[index]
              ?.variable_value_type === "user_variable" &&
              templateData.template_data?.template_header?.type === "text" && (
                <Grid item xs={3}>
                  <TextField
                    select
                    label={"System Variables"}
                    name={"header_variable[" + index + "].variable_value"}
                    formikProps={dialogFormikProps}
                  >
                    <MenuItem value="name">name</MenuItem>
                    <MenuItem value="phone_number">phone number</MenuItem>
                  </TextField>
                </Grid>
              )}

            {dialogFormikProps.values?.header_variable[index]
              ?.variable_value_type === "custom" &&
              templateData.template_data?.template_header?.type === "text" && (
                <Grid item xs={5}>
                  <TextField
                    label={"Custom Value"}
                    onBlur={dialogFormikProps.handleBlur}
                    name={"header_variable[" + index + "].variable_value"}
                    formikProps={dialogFormikProps}
                  />
                </Grid>
              )}

            {dialogFormikProps.values?.header_variable[index]
              ?.variable_value_type === "user_variable" &&
              templateData.template_data?.template_header?.type === "text" && (
                <Grid item xs={3}>
                  <TextField
                    label={"Fallback Value"}
                    onBlur={dialogFormikProps.handleBlur}
                    name={
                      "header_variable[" + index + "].variable_default_value"
                    }
                    formikProps={dialogFormikProps}
                    style={{ padding: "0px" }}
                  />
                </Grid>
              )}

            {templateData.template_data?.template_header?.type !== "text" && (
              <Grid item xs={5}>
                <TextField
                  required
                  label="Upload File"
                  name={"header_variable[" + index + "].file_name"}
                  disabled
                  formikProps={dialogFormikProps}
                  style={{ padding: "0px" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <label
                          style={{
                            color: "#FFF",
                            backgroundColor: "#376fd0",
                            padding: "8px",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                          htmlFor="file-upload"
                          className="custom-file-upload"
                        >
                          Upload File
                        </label>
                        <input
                          style={{ display: "none" }}
                          onClick={(e) => {
                            e.target.value = null;
                          }}
                          onChange={(event) => handleChangeFile(event, index)}
                          id="file-upload"
                          type="file"
                          accept={extensions}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
          </React.Fragment>
        ))}

      {Boolean(formikProps.values.template) &&
        templateData.template_data?.template_button?.count_of_variable !==
          0 && (
          <Grid item xs={12} key={1}>
            <Typography variant="h6">Map Button Variables</Typography>
          </Grid>
        )}

      {templateData.template_data?.template_button?.count_of_variable !== 0 &&
        Boolean(formikProps.values.template) &&
        templateData.variables?.button.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item xs={3}>
              <TextField
                select
                label="Button Variable Name"
                name={"button_variable[" + index + "].variable_name"}
                formikProps={dialogFormikProps}
              >
                {templateData.variables?.button?.map((item, index) => (
                  <MenuItem
                    value={item}
                    disabled={dialogFormikProps.values?.button_variable.some(
                      (name) => name.variable_name === item
                    )}
                  >
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              xs={
                dialogFormikProps.values?.button_variable[index]
                  ?.variable_value_type === "custom"
                  ? 4
                  : 3
              }
            >
              <TextField
                select
                label="Button Variable Type"
                name={"button_variable[" + index + "].variable_value_type"}
                formikProps={dialogFormikProps}
              >
                <MenuItem value="user_variable">System Variables</MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </TextField>
            </Grid>
            {dialogFormikProps.values?.button_variable[index]
              ?.variable_value_type === "user_variable" && (
              <Grid item xs={3}>
                <TextField
                  select
                  label="System Variables"
                  name={"button_variable[" + index + "].variable_value"}
                  formikProps={dialogFormikProps}
                >
                  <MenuItem value="name">name</MenuItem>
                  <MenuItem value="phone_number">phone number</MenuItem>
                </TextField>
              </Grid>
            )}
            {dialogFormikProps.values?.button_variable[index]
              ?.variable_value_type === "custom" && (
              <Grid item xs={5}>
                <TextField
                  label="Custom Value"
                  name={"button_variable[" + index + "].variable_value"}
                  formikProps={dialogFormikProps}
                />
              </Grid>
            )}
            {dialogFormikProps.values?.button_variable[index]
              ?.variable_value_type === "user_variable" && (
              <Grid item xs={3}>
                <TextField
                  label={"Fallback Value"}
                  name={"button_variable[" + index + "].variable_default_value"}
                  formikProps={dialogFormikProps}
                />
              </Grid>
            )}
            {(!Boolean(
              dialogFormikProps.values?.button_variable[index]
                ?.variable_value_type
            ) ||
              dialogFormikProps.values?.button_variable.length === 0) && (
              <Grid item xs={3}></Grid>
            )}
          </React.Fragment>
        ))}
    </Grid>
  );
}
