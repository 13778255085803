import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  businessName: Yup.string().max(50).required("Please enter Business Name"),
  adminEmail: Yup.string()
    .email("Invalid Email")
    .required("Please enter Email"),
  address: Yup.string().max(100).required("Please enter Address"),
  startDate: Yup.string().required("Date is required!"),
  pincode: Yup.string()
    .length(6, "Pincode is invalid")
    .matches(/^[0-9]*$/, "Pincode is invalid")
    .required("Please enter Pincode"),
  city: Yup.string().max(25).required("Please enter City"),
  state: Yup.string().max(25).required("Please enter State"),
  country: Yup.string().max(25).required("Please enter Country"),
  planID: Yup.string().required("Please select a Plan"),
  businessStatus: Yup.string().required("Please set Business Status"),
  tenure: Yup.string().required("Please select Business Tenure"),
});

export default validationSchema;
