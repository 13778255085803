import React from "react";
import { useHistory } from "react-router";

import { Button, Typography } from "@material-ui/core";

import styled from "styled-components/macro";
const Wrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function PermissionDenied() {
  const history = useHistory();

  return (
    <Wrapper>
      <Typography variant="h1" gutterBottom>
        Oops!
      </Typography>

      <Typography variant="h4" gutterBottom>
        Permission Denied
      </Typography>

      <Typography variant="body2">
        It seems you do not have the permission to access this page.
      </Typography>

      <Typography variant="body2" gutterBottom>
        Please contact admin if this is an error!
      </Typography>

      <Button
        variant="contained"
        onClick={() => {
          history.goBack();
        }}
        color="primary"
        style={{ marginTop: "10px" }}
      >
        Back
      </Button>
    </Wrapper>
  );
}
