import convertDate from "../../../util/convertDate";

export const signinDetailsObject = {
  email: "",
  password: "",
};

export function userDetailFromResponseData(responseData) {
  return {
    memberID: responseData._id,
    firstName: responseData.first_name,
    lastName: responseData.last_name,
    email: responseData.email,
    gender: responseData.gender,
    preferredTimezone: responseData.preferred_timezone,
    lastLogin: convertDate(responseData.last_login),
    loginCount: responseData.login_count,
    firstLogin: convertDate(responseData.first_login),
    profilePicUrl: {
      low: responseData.profile_pic_url.low,
      med: responseData.profile_pic_url.med,
      high: responseData.profile_pic_url.high,
    },
  };
}

export function objectFromFormData(formData) {
  return {
    email: formData.email,
    password: formData.password,
  };
}
