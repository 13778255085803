//Theme Reducer
export const THEME_SET = "THEME_SET";

// Auth Reducer
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

//Alert Reducer
export const ALERT_OPEN = "ALERT_OPEN";
export const ALERT_CLOSE = "ALERT_CLOSE";

//Loader Reducer
export const LOADER_START = "LOADER_START";
export const LOADER_STOP = "LOADER_STOP";
export const API_COMPLETE = "API_COMPLETE";
export const LOADER_ERROR = "LOADER_ERROR";
