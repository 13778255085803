import React, { useState, useEffect } from "react";

import { Button } from "@material-ui/core";

import queryString from "query-string";

import Table from "./groups components/Table";
import { PageHeader } from "../../../custom components";
import { useAxiosV2, useLoader } from "../../../custom hooks";
import CreateGroupPopUp from "./groups components/CreateGroupPopup";
import { apiComplete } from "../../../redux/actions/loaderActions";

export default function GroupList(props) {
  const agentID = queryString.parse(window.location.search).agent_id || "";

  const memberID = queryString.parse(window.location.search).member_id || "";
  localStorage.setItem("member_id", memberID);
  localStorage.setItem("agent_id", agentID);

  const loader = useLoader();

  const axios = useAxiosV2({
    baseURL: "adminV2",
  });

  const accessAxios = useAxiosV2({
    baseURL: "adminV2",
    disableAuthToken: true,
  });

  const [groups, setGroups] = useState([]);
  const [newGroup, setNewGroup] = useState("");
  const [newAgent, setNewAgent] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [createGroupModal, setCreateGroupModal] = useState(false);

  useEffect(() => {
    loader.start();
    accessAxios({
      url: "/token/" + memberID,
      method: "GET",
    })
      .then((response) => {
        if (response.status) {
          setAuthToken(response.data.token);
          setNewAgent(true);
          if (newAgent) {
            axios({
              url: "/broadcast/get/group/" + agentID,
              method: "GET",
              authToken: "Bearer " + response.data.token,
            }).then((response) => {
              if (response.status) {
                apiComplete();
                setGroups(response.data);
              }
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [loader, axios, newGroup, agentID, newAgent, accessAxios, memberID]);

  return (
    <React.Fragment>
      <PageHeader
        pageName="Groups"
        memberID={memberID}
        icons={[
          <Button
            variant="contained"
            color="primary"
            onClick={() => setCreateGroupModal(true)}
          >
            Add New Group
          </Button>,
        ]}
      />

      <Table groups={groups} authToken={authToken} />

      {createGroupModal && (
        <CreateGroupPopUp
          createGroupModal={createGroupModal}
          setCreateGroupModal={setCreateGroupModal}
          setNewGroup={setNewGroup}
          authToken={authToken}
          agentID={agentID}
        />
      )}
    </React.Fragment>
  );
}
