//the page consist of all the business list by using MUIdatatable @pragya
import React, { useState, useEffect } from "react";
import { Loader, PageHeader } from "../../custom components";
import { useLoader, useAxios, usePermissions } from "../../custom hooks";
import { useHistory } from "react-router";
import { Button } from "@material-ui/core";
import Table from "./businesses components/Table";

export default function Business(props) {
  const history = useHistory();
  const loader = useLoader();
  const axios = useAxios();
  const pagePermissions = usePermissions(props.pageID);

  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    loader.start();

    axios({ url: "/dashboard" })
      .then((response) => {
        loader.stop();
        setBusinesses(response.data);
      })
      .catch((error) => console.error(error));
  }, [loader, axios]);

  return (
    <Loader>
      <PageHeader
        pageName="Businesses"
        icons={
          pagePermissions.create && [
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/business-details")}
            >
              Add New Business
            </Button>,
          ]
        }
      />

      <Table businesses={businesses} />
    </Loader>
  );
}
