import React from "react";
import Table from "mui-datatables";
import styled from "styled-components/macro";

const MUIDataTable = styled(Table)`
  .MuiTableCell-head {
    background-color: #bbd4ff;
    text-transform: capitalize;
  }

  .MuiTableCell-body {
    cursor: ${(props) =>
      Boolean(props.options.onRowClick || props.options.onCellClick)
        ? "pointer"
        : ""};
  }

  .MuiChip-root {
    margin-bottom: 8px;
  }
`;

export default function DataTable(props) {
  const options = {
    selectableRows: "none",
    rowsPerPageOptions: [10, 25, 50, 100],
    enableNestedDataAccess: ".",
    elevation: 2,
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  return (
    <React.Fragment>
      <MUIDataTable {...props} options={{ ...options, ...props.options }} />
    </React.Fragment>
  );
}
