import React, { useState, useEffect } from "react";

import {
  memberDetailsObject,
  objectFromResponseData,
} from "./member details components/memberDetailsObject";

import Form from "./member details components/Form";

import { PageHeader, Loader } from "../../custom components";
import { useLoader, useAxios, usePermissions } from "../../custom hooks";

export default function InviteMember(props) {
  const loader = useLoader();
  const axios = useAxios();
  const pagePermissions = usePermissions(props.pageID);

  const [formData, setFormData] = useState(memberDetailsObject);
  const [groupList, setGroupList] = useState([]);

  const memberID = props.match.params.memberID || "";

  useEffect(() => {
    loader.start(2);

    if (memberID) {
      axios({
        url: "/member/" + memberID,
      })
        .then((response) => {
          setFormData(objectFromResponseData(response.data));
          loader.apiComplete();
        })
        .catch((error) => console.log(error));
    } else {
      loader.apiComplete();
    }

    axios({
      url: "/groups/detail",
    }).then((response) => {
      setGroupList(response.data);
      loader.apiComplete();
    });
  }, [memberID, axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Member Details"
        breadcrumbs={[{ name: "Members", location: "members" }]}
      />

      <Form
        formData={formData}
        setFormData={setFormData}
        groupList={groupList}
        componentPermissions={pagePermissions.components}
      />
    </Loader>
  );
}
