import React, { useState, useEffect } from "react";

import { useHistory } from "react-router";

import queryString from "query-string";

import { Button } from "@material-ui/core";

import Table from "./broadcast components/Table";
import { PageHeader } from "../../../custom components";
import { useLoader, useAxiosV2 } from "../../../custom hooks";
import { apiComplete } from "../../../redux/actions/loaderActions";
import { Add } from "@material-ui/icons";

export default function BroadcastList(props) {
  const history = useHistory();
  const loader = useLoader();

  const agentID = queryString.parse(window.location.search).agent_id || "";

  const memberID = queryString.parse(window.location.search).member_id || "";
  localStorage.setItem("member_id", memberID);
  localStorage.setItem("agent_id", agentID);

  const accessAxios = useAxiosV2({
    baseURL: "adminV2",
    disableAuthToken: true,
  });

  const axios = useAxiosV2({
    baseURL: "adminV2",
  });

  const [authToken, setAuthToken] = useState("");

  const [broadcasts, setBroadcasts] = useState([]);

  const [cancelBroadcast, setCancelBroadcast] = useState("");

  useEffect(() => {
    loader.start();

    accessAxios({
      url: "/token/" + memberID,
      method: "GET",
    })
      .then((response) => {
        if (response.status) {
          setAuthToken(response.data.token);

          axios({
            url: "/broadcast/get/campaign/" + agentID,
            method: "GET",
            authToken: "Bearer " + response.data.token,
          }).then((response) => {
            if (response.status) {
              apiComplete();
              setBroadcasts(response.data);
            } else {
              apiComplete();
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [loader, accessAxios, memberID, cancelBroadcast, agentID, axios]);

  return (
    <React.Fragment>
      <PageHeader
        pageName="Broadcasts"
        memberID={memberID}
        icons={[
          <Button
            onClick={() =>
              history.push({
                pathname: "/broadcast-details",
                search: "?agent_id=" + agentID + "&member_id=" + memberID,
              })
            }
            variant="contained"
            color="primary"
          >
            <Add />
            &nbsp; Create Broadcast
          </Button>,
        ]}
      />

      <Table
        broadcasts={broadcasts}
        setCancelBroadcast={setCancelBroadcast}
        authToken={authToken}
        agentID={agentID}
        memberID={memberID}
      />
    </React.Fragment>
  );
}
