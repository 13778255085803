import React, { useState, useMemo } from "react";

import { Typography } from "@material-ui/core";
import { FaHome } from "react-icons/fa";
import Capture from "./Capture.PNG";

import "./botbuilder.css";

export default function BotInterface({ formikProps, phoneView, expanded }) {
  const [menuDiv, setMenuDive] = useState(false);
  const [attachment, setAttachment] = useState(false);
  const [emojiShow, setEmojiShow] = useState(false);

  const [attachIcon, setAttachIcon] = useState(
    formikProps.values.attachmentDocuments === true ||
      formikProps.values.attachmentImage === true ||
      formikProps.values.attachmentLocation === true ||
      formikProps.values.attachmentVideo === true
  );
  useMemo(() => {
    if (
      formikProps.values.attachmentDocuments === false &&
      formikProps.values.attachmentImage === false &&
      formikProps.values.attachmentLocation === false &&
      formikProps.values.attachmentVideo === false
    ) {
      setAttachIcon(false);
    }
    if (
      formikProps.values.attachmentDocuments === true ||
      formikProps.values.attachmentImage === true ||
      formikProps.values.attachmentLocation === true ||
      formikProps.values.attachmentVideo === true
    ) {
      setAttachIcon(true);
    }
  }, [formikProps.values]);

  const handleMenu = () => {
    setMenuDive(!menuDiv);
    setEmojiShow(false);
    setAttachment(false);
  };
  const handleAttachement = () => {
    setAttachment(!attachment);
    setEmojiShow(false);
    setMenuDive(false);
  };
  const handleEmoji = () => {
    setEmojiShow(!emojiShow);
    setAttachment(false);
    setMenuDive(false);
  };
  const handleKeyUp = (event) => {
    setAttachIcon(false);
    if (event.target.value.length === 0) {
      setAttachIcon(
        formikProps.values.attachmentDocuments === true ||
          formikProps.values.attachmentImage === true ||
          formikProps.values.attachmentLocation === true ||
          formikProps.values.attachmentVideo === true
      );
    }
  };
  return (
    <React.Fragment>
      {(expanded === "panel2" || expanded === "panel3") && (
        <div
          style={{
            height: phoneView ? "inherit" : "85%",
            maxHeight: phoneView ? "inherit" : "600px",
            width: phoneView ? "inherit" : "28%",
            maxWidth: phoneView ? "inherit" : "350px",
            bottom: !phoneView && "5%",
          }}
          className="chatScreen"
        >
          <div
            style={{
              background: formikProps.values.windowHeaderBackground,
              borderRadius: !phoneView && "10px 10px 0px 0px",
            }}
            className="chatHeader"
          >
            <div className="headerIcon">
              <img
                className="img"
                alt=""
                src={formikProps.values.bubbleAvatar}
              />
            </div>

            <div
              className="titleHeader"
              style={{
                color: "rgba(255, 255, 255)",
              }}
            >
              <div className="title">
                <Typography
                  style={{
                    color: formikProps.values.windowHeaderForeground,
                    fontFamily: formikProps.values.fontFamily,
                    fontSize: "14px",
                  }}
                >
                  {formikProps.values.windowHeaderTitle}
                </Typography>
              </div>
              <div className="caption">
                <Typography
                  style={{
                    color: formikProps.values.windowHeaderForeground,
                    fontFamily: formikProps.values.fontFamily,
                    fontSize: "12px",
                  }}
                >
                  {formikProps.values.windowHeaderCaption}
                </Typography>
              </div>
            </div>
            <div href="" className="closeButtonAnchor">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                style={{ color: formikProps.values.windowHeaderForeground }}
                className="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
              </svg>
            </div>
          </div>
          <div
            className="chatContainer"
            style={{
              overflow: "hidden",
              minHeight: phoneView
                ? "calc(100% - 147px)"
                : "calc(100% - 147px)",
            }}
          >
            <div className="chats">
              <div className="chatBotClearFix"></div>
              <p
                className="botMessage"
                style={{
                  fontFamily: formikProps.values.fontFamily,
                }}
              >
                Hello, Let me know how I can help you today?
              </p>
              <div className="cbotSingleCard"></div>
              <div className="chatBotClearFix"></div>
              <div
                style={{ fontFamily: formikProps.values.fontFamily }}
                className="cbotTime"
              >
                Bot 21:50
              </div>
              <div className="chatBotClearFix"></div>
              <p
                className="userResponse"
                style={{
                  background: formikProps.values.windowHeaderBackground,
                  color: formikProps.values.windowHeaderForeground,
                  fontFamily: formikProps.values.fontFamily,
                }}
              >
                I need to make an appointment.
              </p>
              <div className="cbotSingleCard"></div>
              <div className="chatBotClearFix"></div>
              <div
                style={{ fontFamily: formikProps.values.fontFamily }}
                className="cBotRightTime"
              >
                You 21:51
              </div>
            </div>
            <div className="chatBotClearFix"></div>
            {emojiShow && formikProps.values.emoji && (
              <div
                style={{
                  position: "relative",
                  bottom: phoneView ? "18px" : "18px",
                }}
              >
                <img
                  className="animate__animated animate__slideInUp"
                  style={{ width: "100%" }}
                  src={Capture}
                  alt=""
                />
              </div>
            )}
          </div>

          {attachment && attachIcon && (
            <div
              className="attachIcons animate__animated animate__slideInUp"
              style={{
                bottom: phoneView ? "89px" : "89px",
              }}
            >
              <ul className="botList">
                {formikProps.values.attachmentImage && (
                  <li className="nav-item" align="center">
                    <span className="cBot-attach" title="Send Image">
                      <label for="cBot-inimage">
                        <div
                          style={{
                            color: "#0d6efd",
                            backgroundColor: "#a1dce857",
                          }}
                          className="cBot-crop"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="19"
                            fill="currentColor"
                            className="bi bi-image cBot-imgfile"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>
                            <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"></path>
                          </svg>
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            textAlign: "center",
                            fontFamily: formikProps.values.fontFamily,
                          }}
                        >
                          Image
                        </div>
                        <input id="cBot-inimage" type="file" accept="image/*" />
                      </label>
                    </span>
                  </li>
                )}
                {formikProps.values.attachmentDocuments && (
                  <li className="nav-item" align="center">
                    <span className="cBot-attach" title="Send document">
                      <label for="cBot-infile">
                        <div
                          style={{
                            color: "#ff9900",
                            backgroundColor: "#f7d16157",
                          }}
                          className="cBot-crop"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="19"
                            fill="currentColor"
                            className="bi bi-file-earmark cBot-file"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"></path>
                          </svg>
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            textAlign: "center",
                            fontFamily: formikProps.values.fontFamily,
                          }}
                        >
                          Document
                        </div>
                        <input
                          id="cBot-infile"
                          type="file"
                          accept=".txt, .doc, .xls, .csv, .pdf "
                        />
                      </label>
                    </span>
                  </li>
                )}
                {formikProps.values.attachmentVideo && (
                  <li className="nav-item" align="center">
                    <span className="cBot-attach" title="Send video">
                      <label for="cBot-invideo">
                        <div
                          style={{
                            color: "#066d3d",
                            backgroundColor: "#77e2b099",
                          }}
                          className="cBot-crop"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="19"
                            fill="currentColor"
                            className="bi bi-camera-video cBot-camera"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                            />
                          </svg>
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            textAlign: "center",
                            fontFamily: formikProps.values.fontFamily,
                          }}
                        >
                          Video
                        </div>
                        <input id="cBot-invideo" type="file" accept="video/*" />
                      </label>
                    </span>
                  </li>
                )}
                {formikProps.values.attachmentLocation && (
                  <li className="nav-item" align="center">
                    <span
                      id="cBot-shareLocbt"
                      title="Send location"
                      className="cBot-attach"
                    >
                      <div
                        style={{
                          color: "#dc3545",
                          backgroundColor: "#f1828d42",
                        }}
                        className="cBot-crop"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          fill="currentColor"
                          className="bi bi-geo-alt cBot-location"
                          viewBox="0 0 16 16"
                        >
                          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        </svg>
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          textAlign: "center",
                          fontFamily: formikProps.values.fontFamily,
                        }}
                      >
                        Location
                      </div>
                    </span>
                  </li>
                )}
              </ul>
            </div>
          )}

          <div className="keypad">
            <div className="textInput">
              <div style={{ display: "flex", justifyContent: "center" }}>
                {formikProps.values.emoji && !menuDiv && (
                  <div className="emoji-box">
                    <div className="emoji">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-emoji-smile"
                        viewBox="0 0 16 16"
                        onClick={handleEmoji}
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                      </svg>
                    </div>
                  </div>
                )}
                <input
                  className="input"
                  placeholder="Type a message..."
                  onKeyUp={handleKeyUp}
                ></input>
                {!attachIcon && (
                  <div className="sendIcon">
                    <div
                      style={{
                        padding: phoneView
                          ? "3px 3px 3px 8px"
                          : "3px 3px 3px 7px",
                      }}
                      className="anchor"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 100 125"
                        enableBackground="new 0 0 100 100"
                      >
                        <path
                          fill="currentColor"
                          d="M5.1,90.8l7.6-32.9c0.3-1.4,1.5-2.5,3-2.7l42.5-4.4c1.2-0.1,1.2-1.9,0-2.1l-42.5-4.1c-1.5-0.1-2.7-1.2-3-2.6  L5.1,9.2C4.4,6.4,7.4,4.1,10,5.4l83.1,41.6c2.5,1.3,2.5,4.9,0,6.2L10,94.6C7.4,95.9,4.4,93.6,5.1,90.8z"
                        />
                      </svg>
                    </div>
                  </div>
                )}
                {attachIcon && !menuDiv && (
                  <div className="attachment">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="bi feather feather-paperclip"
                      onClick={handleAttachement}
                    >
                      <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
          {menuDiv && (
            <div
              style={{
                bottom: phoneView ? "44px" : "47px",
              }}
              className="botdata animate__animated animate__slideInUp "
            >
              <div className="cBot-menu" align="center">
                <div></div>
              </div>
              <div className="menuHeader">
                <b style={{ fontFamily: formikProps.values.fontFamily }}>
                  Menu
                </b>
              </div>
              <div>
                <ul className="listGroup">
                  {formikProps.values.menu.map((item, index) => (
                    <li
                      key={index}
                      style={{ fontFamily: formikProps.values.fontFamily }}
                      className="listMenu"
                    >
                      {item.text}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <div
            className="footer"
            style={{
              borderBottomLeftRadius: !phoneView && "10px",
              borderBottomRightRadius: !phoneView && "10px",
            }}
          >
            <div
              style={{
                color: "#7b7b7b",
              }}
              className="attachments"
            >
              {formikProps.values.menu.length !== 0 && !menuDiv && (
                <div className="menu">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    className="bi bi-justify"
                    viewBox="0 0 16 16"
                    onClick={handleMenu}
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
                    />
                  </svg>
                </div>
              )}
              {menuDiv && (
                <div className="menu">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    className="bi bi-x-lg"
                    viewBox="0 0 16 16"
                    onClick={() => setMenuDive(!menuDiv)}
                  >
                    <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
                  </svg>
                </div>
              )}

              <div className="homeButton">
                <div title="Restart" className="restart poweredBy">
                  <FaHome style={{ cursor: "pointer" }} />
                </div>
              </div>
            </div>
            {formikProps.values.whiteLabel && (
              <span
                style={{
                  fontFamily: formikProps.values.fontFamily,
                  fontSize: "10px",
                }}
                className="poweredBy"
              >
                <svg
                  width="11px"
                  height="11px"
                  id="Layer_1"
                  version="1.1"
                  viewBox="0 0 512 512"
                  x="0px"
                  xmlns="https://www.w3.org/2000/svg"
                  y="0px"
                  className="_pwdSVG"
                >
                  <g>
                    <g>
                      <polygon
                        fill="#ffe701"
                        points="426.667,213.333 288.36,213.333 333.706,0 148.817,0 85.333,298.667 227.556,298.667 227.556,512"
                      ></polygon>
                    </g>
                  </g>
                </svg>
                &nbsp; by &nbsp;
                <a className="poweredByLink" href="https://www.dolphinchat.ai">
                  <img
                    className="footerLogo"
                    alt=""
                    src="https://dolphinchat.ai/wp-content/uploads/2021/05/favicon.png"
                  />
                </a>
                &nbsp; DolphinChat
              </span>
            )}
          </div>
        </div>
      )}

      {expanded === "panel1" && (
        <div
          ref={(el) => {
            if (formikProps.values.bubbleFloat) {
              if (el) {
                var translateY =
                  formikProps.values.bubblePosition === "right"
                    ? `translate(${phoneView ? "-100px" : "-250px"}, 0)`
                    : `translate(${phoneView ? "100px" : "250px"}, 0)`;
                el.style.setProperty("transform", translateY, "important");
                setTimeout(() => {
                  if (formikProps.values.bubblePosition === "right") {
                    el.style.setProperty("transform", "none", "important");
                    el.style.setProperty("float", "left", "!important");
                  } else {
                    el.style.setProperty("transform", "none", "important");
                    el.style.setProperty("right", "20px");
                  }
                }, 3000);
                setTimeout(() => {
                  if (formikProps.values.bubblePosition === "right") {
                    el.style.setProperty("right", "20px");
                    el.style.removeProperty("float");
                  } else {
                    el.style.setProperty("float", "left");
                    el.style.removeProperty("right");
                  }
                }, 6000);
              }
            } else {
              if (el) {
                el.style.setProperty("transform", "none", "important");
              }
            }
          }}
          style={{
            animation:
              formikProps.values.bubbleRipple && "pulse-black 5s infinite",
            right:
              formikProps.values.bubblePosition === "right" &&
              (!phoneView ? "20px" : "3%"),
            float: formikProps.values.bubblePosition === "left" && "left",
            bottom: phoneView ? "2%" : "5%",
            background: `url(${formikProps.values.bubbleAvatar})`,
            marginLeft: formikProps.values.bubblePosition === "left" && "17px",
          }}
          id="bubble_id"
          className="buttonContainer"
        ></div>
      )}

      {expanded === "panel1" && (
        <div
          ref={(el) => {
            if (formikProps.values.bubbleFloat && !phoneView) {
              if (el) {
                var translateY =
                  formikProps.values.bubblePosition === "right"
                    ? `translate(${phoneView ? "-100px" : "-100px"}, 0)`
                    : `translate(${phoneView ? "100px" : "100px"}, 0)`;
                el.style.setProperty("transform", translateY, "important");
                setTimeout(() => {
                  if (formikProps.values.bubblePosition === "right") {
                    el.style.setProperty("transform", "none", "important");
                    el.style.setProperty("float", "left", "!important");
                  } else {
                    el.style.setProperty("right", "50px");
                    el.style.setProperty("transform", "none", "important");
                  }
                }, 3000);
                setTimeout(() => {
                  if (formikProps.values.bubblePosition === "right") {
                    el.style.setProperty("transform", "none", "important");
                    el.style.setProperty("right", "50px");
                  } else {
                    el.style.setProperty("transform", "none", "important");
                    el.style.setProperty("float", "left", "!important");
                    el.style.removeProperty("right");
                  }
                }, 6000);
              }
            } else {
              if (el) {
                el.style.setProperty("transform", "none", "important");
              }
            }
          }}
          style={{
            bottom: phoneView ? "calc(5% + 60px)" : "calc(5% + 70px)",
            right:
              formikProps.values.bubblePosition === "right" &&
              (phoneView ? "8%" : "35px"),
            float: formikProps.values.bubblePosition === "left" && "left",
            marginLeft: formikProps.values.bubblePosition === "left" && "17px",
          }}
          className="popUpBubble"
        >
          <div className="closeButtonPopup">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              style={{ color: formikProps.values.windowHeaderBackground }}
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
            </svg>
          </div>
          <div
            style={{
              background: formikProps.values.windowHeaderBackground,
              color: formikProps.values.windowHeaderForeground,
              fontFamily: formikProps.values.fontFamily,
            }}
            className="bubbleText"
          >
            <span>{formikProps.values.popupText}</span>
          </div>
          <div className="chatBubble">
            <input
              style={{
                fontFamily: formikProps.values.fontFamily,
              }}
              className="chatInput"
              placeholder="Send Message"
            ></input>
            <div
              style={{
                background: formikProps.values.windowHeaderBackground,
                color: formikProps.values.windowHeaderForeground,
              }}
              className="chatBubbleSend"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 100 125"
                enableBackground="new 0 0 100 100"
                style={{
                  color: formikProps.values.windowHeaderForeground,
                }}
              >
                <path
                  fill="currentColor"
                  d="M5.1,90.8l7.6-32.9c0.3-1.4,1.5-2.5,3-2.7l42.5-4.4c1.2-0.1,1.2-1.9,0-2.1l-42.5-4.1c-1.5-0.1-2.7-1.2-3-2.6  L5.1,9.2C4.4,6.4,7.4,4.1,10,5.4l83.1,41.6c2.5,1.3,2.5,4.9,0,6.2L10,94.6C7.4,95.9,4.4,93.6,5.1,90.8z"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
