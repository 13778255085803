import { useHistory } from "react-router";
import convertDate from "../../../util/convertDate";
import DataTable from "../../../custom components/DataTable";

export default function Table({ utterances }) {
  const history = useHistory();

  const options = {
    onRowClick: (_, cellMetaData) => {
      history.push(
        "/utterances/" + utterances[cellMetaData.dataIndex].utterance_id
      );
    },
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  return <DataTable columns={columns} options={options} data={utterances} />;
}

const columns = [
  {
    name: "utterance_id",
    label: "Utterance ID",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
      display: false,
    },
  },
  {
    name: "key",
    label: "Utterance Key",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "value",
    label: "Utterance Value",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
      setCellHeaderProps: () => {
        return { style: { width: "45%" } };
      },
    },
  },
  {
    name: "bot_service_name",
    label: "Bot Service",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
