import React, { useState } from "react";
import { useAxios, useAlert } from "../../../custom hooks";
import { useHistory } from "react-router";
import { ENV } from "../../../config/config";
import channel from "../../../constants/channelID";

import {
  TextField,
  TextFieldWithIcon,
  TextFieldWithCopy,
  ComponentHeader,
  Paper,
  Dialog,
  CreatedAndUpdatedDetails,
} from "../../../custom components";

import { Grid, MenuItem, IconButton, Tooltip } from "@material-ui/core";

import {
  Android,
  MessageRounded,
  PeopleRounded,
  Replay,
  Stop,
} from "@material-ui/icons";
import { MdStyle } from "react-icons/md";

export default function FormDisplay({
  formikProps,
  editState,
  businessList,
  botServiceList,
  botPlanList,
  permissions,
}) {
  const axios = useAxios();
  const alert = useAlert();
  const history = useHistory();

  const [dialogState, setDialogState] = useState(false);

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          headerName="Bot Details"
          buttons={[
            <RestartBot />,
            <StopBotService />,
            <StyleYourBot />,
            <DirectToBot />,
          ]}
          id={formikProps.values.botID}
        />
      </Grid>

      {formikProps.values.botID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextFieldWithCopy
              label="Bot ID"
              name="botID"
              disabled
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6} />
        </React.Fragment>
      )}

      <Grid item xs={6}>
        <TextField
          label="Bot Name"
          name="botName"
          required
          disabled={!(editState && permissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          select
          label="Bot Channel"
          name="botChannel"
          required
          disabled={formikProps.values.botID !== ""}
          formikProps={formikProps}
        >
          <MenuItem value={channel.whatsapp}>WhatsApp</MenuItem>
          <MenuItem value={channel.rest}>Web</MenuItem>
          <MenuItem value={channel.facebook}>Facebook</MenuItem>
          <MenuItem value={channel.instagram}>Instagram</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={3}>
        <TextField
          select
          label="Bot Status"
          name="botStatus"
          disabled={!(editState && permissions.update)}
          required
          formikProps={formikProps}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          label="Business Name"
          name="businessID"
          required
          disabled={formikProps.values.botID !== ""}
          formikProps={formikProps}
        >
          {businessList.map((business) => {
            return (
              <MenuItem key={business._id} value={business._id}>
                {business.name}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>

      <Grid item xs={3}>
        <TextField
          select
          label="Bot Service"
          name="botServiceID"
          required
          formikProps={formikProps}
          disabled={!(editState && permissions.update)}
        >
          {botServiceList.map((botService) => (
            <MenuItem key={botService._id} value={botService._id}>
              {botService.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={3}>
        <TextField
          select
          label="Bot Plan"
          name="botPlanID"
          required
          disabled={!(editState && permissions.update)}
          formikProps={formikProps}
        >
          {botPlanList.map((botPlan) => (
            <MenuItem key={botPlan.agent_plan_id} value={botPlan.agent_plan_id}>
              {botPlan.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {formikProps.values.botID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextFieldWithCopy
              label="Guest Access Token"
              name="guestAccessToken"
              disabled
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={2}>
            <TextFieldWithIcon
              label="Total Users"
              name="totalUsers"
              disabled
              icon={
                formikProps.values.totalUsers > 0 ? (
                  <Tooltip title="View Users">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        history.push({
                          pathname: "/users",
                          agentID: formikProps.values.agentID,
                        })
                      }
                    >
                      <PeopleRounded />
                    </IconButton>
                  </Tooltip>
                ) : undefined
              }
              variant="outlined"
              value={formikProps.values.totalUsers}
            />
          </Grid>

          <Grid item xs={2}>
            <TextFieldWithIcon
              label="Total Messages"
              name="totalConversations"
              icon={
                formikProps.values.totalConversations > 0 ? (
                  <Tooltip title="View Conversations">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        history.push({
                          pathname: "/conversations",
                          agentID: formikProps.values.botID,
                        })
                      }
                    >
                      <MessageRounded />
                    </IconButton>
                  </Tooltip>
                ) : undefined
              }
              disabled
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              label="Total Sessions"
              name="totalSessions"
              disabled
              formikProps={formikProps}
            />
          </Grid>

          <CreatedAndUpdatedDetails values={formikProps.values} />
        </React.Fragment>
      )}

      <Dialog
        dialogState={dialogState}
        setDialogState={setDialogState}
        handleAgree={stopBot}
        dialogContent={{
          title: "Confirm Stopping Bot!",
          body: "Stopping the bot will also stop the bot service. In turn, all other bots linked to the same service will also stop!",
          dangerButton: "Stop Bot",
        }}
      />
    </Paper>
  );

  function stopBot() {
    axios({
      url: "/bot/pause/" + formikProps.values.botServiceID,
      method: "POST",
    })
      .then((response) => {
        if (response.status) {
          alert.success(response.message);
        } else {
          alert.error(response.message);
        }
      })
      .catch((error) => console.log(error));
  }

  function RestartBot() {
    return (
      <Tooltip title="Restart Bot">
        <IconButton color="primary" onClick={restartBot}>
          <Replay />
        </IconButton>
      </Tooltip>
    );

    function restartBot() {
      axios({
        url: "/bot/restart/" + formikProps.values.botServiceID,
        method: "POST",
      })
        .then((response) => {
          if (response.status) {
            alert.success(response.message);
          } else {
            alert.error(response.message);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  function StopBotService() {
    return (
      <Tooltip title="Stop Bot Service">
        <IconButton color="primary" onClick={() => setDialogState(true)}>
          <Stop />
        </IconButton>
      </Tooltip>
    );
  }

  function StyleYourBot() {
    return (
      (formikProps.values.botChannel === channel.rest ||
        formikProps.values.botChannel === channel.whatsapp) && (
        <Grid item style={{ alignSelf: "center" }}>
          <Tooltip title="Customize Bot Appearance">
            <IconButton
              color="primary"
              onClick={() => {
                window.open(styleBotURL(), "_blank");
              }}
            >
              <MdStyle />
            </IconButton>
          </Tooltip>
        </Grid>
      )
    );

    function styleBotURL() {
      var baseURL =
        "https://" +
        (ENV === "prod" ? "" : ENV + ".") +
        "superadmin.dolphinchat.ai/";

      if (formikProps.values.botChannel === channel.rest) {
        return (
          baseURL +
          "botBuilder?agent_id=" +
          formikProps.values.botID +
          "&token=" +
          formikProps.values.guestAccessToken
        );
      }

      if (formikProps.values.botChannel === channel.whatsapp) {
        return (
          baseURL +
          "styleyourbot?agent_id=" +
          formikProps.values.botID +
          "&token=" +
          formikProps.values.guestAccessToken
        );
      }
    }
  }

  function DirectToBot() {
    return (
      <Tooltip title="Direct to bot">
        <IconButton
          color="primary"
          onClick={() => {
            window.open(botURL(), "_blank");
          }}
        >
          <Android />
        </IconButton>
      </Tooltip>
    );

    function botURL() {
      if (formikProps.values.botChannel === channel.rest) {
        return (
          "https://" +
          (ENV === "prod" ? "" : ENV + ".") +
          "demo.dolphinchat.ai/?agentID=" +
          formikProps.values.botID
        );
      }

      if (formikProps.values.botChannel === channel.whatsapp) {
        return "https://wa.me/" + formikProps.values.authentication.phoneNumber;
      }

      if (
        formikProps.values.botChannel === channel.facebook ||
        formikProps.values.botChannel === channel.instagram
      ) {
        return "http://m.me/" + formikProps.values.authentication.pageID;
      }
    }
  }
}
