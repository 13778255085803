import { useHistory } from "react-router-dom";
import DataTable from "../../../custom components/DataTable";
import convertDate from "../../../util/convertDate";

export default function Table({ groups }) {
  const history = useHistory();
  const options = {
    onRowClick: handleRowClick,
  };

  function handleRowClick(_, rowMetaData) {
    history.push("/group-details/" + groups[rowMetaData.dataIndex].group_id);
  }

  return <DataTable columns={columns} options={options} data={groups} />;
}

const columns = [
  {
    name: "group_name",
    label: "Group Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "group_id",
    label: "Group ID",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "member_count",
    label: "Member Count",
    options: {
      customBodyRender: (value) => {
        return value ? value : "0";
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
