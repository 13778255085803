import React from "react";

import {
  TextField,
  TextFieldWithCopy,
  FormHeader,
  Paper,
  CreatedAndUpdatedDetails,
} from "../../../custom components";

import { Grid, MenuItem } from "@material-ui/core";

export default function FormDisplay({
  formikProps,
  editState,
  permissions,
  botServiceList,
}) {
  return (
    <Paper>
      <Grid item xs={12}>
        <FormHeader formName="Utterance Details" />
      </Grid>
      {!!formikProps.values.ID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextFieldWithCopy
              label="Utterance ID"
              name="ID"
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6} />
        </React.Fragment>
      )}

      <Grid item xs={6}>
        <TextField
          label="Utterance Key"
          name="key"
          required
          disabled={!!formikProps.values.ID}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          label="Bot Service"
          name="botServiceID"
          required
          disabled={!!formikProps.values.ID}
          formikProps={formikProps}
        >
          {botServiceList.map((botService) => (
            <MenuItem value={botService._id} key={botService._id}>
              {botService.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <TextField
          multiline
          label="Utterance Value"
          name="value"
          rows={12}
          required
          disabled={!(editState && permissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      {!!formikProps.values.ID && (
        <CreatedAndUpdatedDetails values={formikProps.values} />
      )}
    </Paper>
  );
}
