import { Grid, Typography, Switch } from "@material-ui/core";
import { Paper, ComponentHeader } from "../../../custom components";

export default function DefaultPermissions({
  formikProps,
  editState,
  permissions,
}) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader headerName="Default Permissions" />
      </Grid>

      {["access", "read", "create", "update", "delete"].map(
        (permissionType) => (
          <Grid key={permissionType} item xs container alignItems="center">
            <Grid item xs={4}>
              <Switch
                name={permissionType}
                checked={formikProps.values.defaultPermissions[permissionType]}
                onChange={handlePermissionsChange}
                disabled={disabledValue(permissionType)}
              />
            </Grid>

            <Grid item xs={4}>
              <Typography
                variant="body1"
                style={{ textTransform: "capitalize" }}
              >
                {permissionType}
              </Typography>
            </Grid>
          </Grid>
        )
      )}
    </Paper>
  );

  function disabledValue(permissionType) {
    const value = !(permissions.update && editState);
    if (permissionType === "access") {
      return value;
    }

    if (permissionType === "read") {
      return value || !formikProps.values.defaultPermissions.access;
    }

    return value || !formikProps.values.defaultPermissions.read;
  }

  function handlePermissionsChange({ target }) {
    const { name, checked } = target;

    formikProps.setValues((prevValue) => {
      let defaultPermissions = prevValue.defaultPermissions;

      if (name === "access") {
        defaultPermissions = {
          access: checked,
          read: defaultPermissions.read && checked,
          create: defaultPermissions.create && checked,
          update: defaultPermissions.update && checked,
          delete: defaultPermissions.delete && checked,
        };
      } else if (name === "read") {
        defaultPermissions = {
          access: defaultPermissions.access,
          read: checked,
          create: defaultPermissions.create && checked,
          update: defaultPermissions.update && checked,
          delete: defaultPermissions.delete && checked,
        };
      } else {
        defaultPermissions[name] = checked;
      }

      return {
        ...prevValue,
        defaultPermissions: defaultPermissions,
      };
    });
  }
}
