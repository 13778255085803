import React from "react";

import DataTable from "../../../custom components/DataTable";

export default function RegexTable() {
  const data = [
    {
      regex_type: "Only Characters",
      regex_value_javascript: "[A-Za-z]*",
      regex_value_python: "^[A-Za-z]{1,}$",
    },
    {
      regex_type: "Aphanumeric Values",
      regex_value_javascript: "[A-Za-z_0-9]*",
      regex_value_python: "^[A-Za-z0-9]{1,}$",
    },
    {
      regex_type: "Email",
      regex_value_javascript: "/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/",
      regex_value_python: "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Z|a-z]{2,}$",
    },
  ];
  const options = {
    search: false,
    viewColumns: false,
    selectableRows: "none",
    filter: false,
    download: false,
    print: false,
    pagination: false,
    responsive: "standard",
    fixedHeader: true,
    tableBodyHeight: "225px",
  };

  const columns = [
    {
      name: "regex_type",
      label: "Regex Type",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "regex_value_javascript",
      label: "Regex(Javascript)",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "regex_value_python",
      label: "Regex(Python)",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
  ];

  return <DataTable columns={columns} options={options} data={data} />;
}
