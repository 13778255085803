import React from "react";
import { useState, useEffect } from "react";

import {
  botServiceDetailsObject,
  objectFromResponse,
} from "./group details components/groupDetailsObject";
import Form from "./group details components/Form";

import { useLoader, useAxios, usePermissions } from "../../custom hooks";

import { PageHeader, Loader } from "../../custom components";

export default function BotServiceDetails(props) {
  const loader = useLoader();
  const axios = useAxios();
  const pagePermissions = usePermissions(props.pageID);

  const [formData, setFormData] = useState(botServiceDetailsObject);
  const [pageList, setPageList] = useState([]);

  const groupID = props.match.params.groupID || "";

  useEffect(() => {
    loader.start(2);

    if (groupID) {
      axios({
        url: "/group/" + groupID,
      })
        .then((response) => {
          loader.apiComplete();
          setFormData(objectFromResponse(response.data));
        })
        .catch((error) => console.log(error));
    } else {
      loader.apiComplete();
    }

    axios({
      url: "/pages/detail",
    }).then((response) => {
      loader.apiComplete();
      setPageList(response.data);
    });
  }, [groupID, axios, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Group Details"
        breadcrumbs={[{ name: "Groups", location: "groups" }]}
      />

      <Form
        pageList={pageList}
        formData={formData}
        setFormData={setFormData}
        componentPermissions={pagePermissions}
      />
    </Loader>
  );
}
