import { useState, useEffect } from "react";

import { usePermissions, useAxios, useLoader } from "../../custom hooks";
import { Loader, PageHeader } from "../../custom components";
import Form from "./bot details components/Form";
import {
  botDetailsObject,
  objectFromResponse,
} from "./bot details components/botDetailsObject";

export default function BotDetails(props) {
  const axios = useAxios();
  const loader = useLoader();
  const pagePermissions = usePermissions(props.pageID);

  const botID = props.match.params.botID || "";

  const [editState, setEditState] = useState(!botID);
  const [formData, setFormData] = useState(botDetailsObject);
  const [businessList, setBusinessList] = useState([]);
  const [botPlanList, setBotPlanList] = useState([]);
  const [botServiceList, setBotServiceList] = useState([]);
  const [permissionPages, setPermissionPages] = useState([]);

  useEffect(() => {
    loader.start(5);

    axios({
      url: "/business/list",
    })
      .then((response) => {
        loader.apiComplete();
        setBusinessList(response.data);
      })
      .catch((error) => console.log(error));

    axios({
      url: "/admin/pages/detail",
    })
      .then((response) => {
        loader.apiComplete();
        setPermissionPages(response.data);
      })
      .catch((error) => console.log(error));

    axios({
      url: "/bot/service/detail",
    })
      .then((response) => {
        loader.apiComplete();
        setBotServiceList(response.data);
      })
      .catch((error) => console.log(error));

    axios({
      url: "/master/agent/plans?mode=2",
    })
      .then((response) => {
        loader.apiComplete();
        setBotPlanList(response.data);
      })
      .catch((error) => console.log(error));

    if (botID) {
      axios({
        url: "/agent/" + botID,
      })
        .then((response) => {
          loader.apiComplete();
          setFormData(objectFromResponse(response.data));
        })
        .catch((error) => console.log(error));
    } else {
      loader.apiComplete();
    }
  }, [axios, botID, loader]);

  return (
    <Loader>
      <PageHeader
        pageName="Bot Details"
        breadcrumbs={[{ name: "Bot", location: "bots" }]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      <Form
        editState={editState}
        setEditState={setEditState}
        formData={formData}
        setFormData={setFormData}
        businessList={businessList}
        botPlanList={botPlanList}
        botServiceList={botServiceList}
        permissionPages={permissionPages}
        pagePermissions={pagePermissions}
      />
    </Loader>
  );
}
