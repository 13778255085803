import React from "react";
import { TextField, Paper, ComponentHeader } from "../../../custom components";
import { Grid, MenuItem } from "@material-ui/core";

export default function RequiredCredentials({
  formikProps,
  editState,
  integrationDetails,
  permissions,
}) {
  if (
    !Boolean(integrationDetails.required_credentials) ||
    integrationDetails.required_credentials.length === 0
  ) {
    return "";
  }

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader headerName="Required Credentials" />
      </Grid>

      {integrationDetails.required_credentials.map((requiredCredential) => (
        <Grid item xs={6} key={requiredCredential.label}>
          {requiredCredential.type === "boolean" ? (
            <TextField
              select
              required
              label={requiredCredential.label}
              name={"requiredCredentials." + requiredCredential.field_name}
              disabled={!(editState && permissions.update)}
              formikProps={formikProps}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </TextField>
          ) : (
            <TextField
              required
              label={requiredCredential.label}
              name={"requiredCredentials." + requiredCredential.field_name}
              disabled={!(editState && permissions.update)}
              formikProps={formikProps}
            />
          )}
        </Grid>
      ))}
    </Paper>
  );
}
