import convertDate from "../../../../util/convertDate";
import { titleCase } from "change-case-all";
import convertScheduledDate from "./convertDateToFormat";
import convertDateToBackendFormat from "./convertDateToBackendFormat";

export const broadcastDetailsObject = {
  ID: "",
  broadcastName: "",
  template: "",
  templateName: "",
  whenToBroadcast: "",
  broadcastDate: "",
  broadcastTime: "",
  uploadCSVFile: "",
  broadcastStatus: "",
  CSVFile: "",
  createdBy: "",
  updatedBy: "",
  createdAt: "",
  updatedAt: "",
};

export function objectFromResponse(responseData) {
  return {
    ID: responseData._id,
    broadcastName: titleCase(responseData.broadcast_name),
    templateName: responseData.template_name,
    template: responseData.template_id,
    whenToBroadcast: responseData.schedule_type,
    broadcastStatus: responseData.status,
    broadcastDate: convertScheduledDate(responseData.scheduled_date) || "",
    broadcastTime: responseData.scheduled_time || "",
    createdBy: responseData.created_by,
    updatedBy: responseData.updated_by,
    createdAt: convertDate(responseData.created_at),
    updatedAt: convertDate(responseData.updated_at),
  };
}

export function objectFromFormData(formData, status) {
  if (status === "draft") {
    return {
      broadcast_name: formData.broadcastName,
      schedule_type: formData.whenToBroadcast,
      template_id: formData.template,
      broadcast_status: "d35ceb22fa0e4c2b964e253062bf3460",
      schedule_date: convertDateToBackendFormat(formData.broadcastDate),
      schedule_time: formData.broadcastTime,
      broadacst_url: formData.uploadCSVFile,
    };
  } else if (status === "pending" && formData.whenToBroadcast !== "now") {
    return {
      broadcast_name: formData.broadcastName,
      schedule_type: formData.whenToBroadcast,
      template_id: formData.template,
      broadcast_status: "86844d49cfb147ff92b0cae976ec43ec",
      schedule_date: convertDateToBackendFormat(formData.broadcastDate),
      schedule_time: formData.broadcastTime,
      broadacst_url: formData.uploadCSVFile,
    };
  } else if (status === "pending" && formData.whenToBroadcast === "now") {
    return {
      broadcast_name: formData.broadcastName,
      schedule_type: formData.whenToBroadcast,
      template_id: formData.template,
      broadcast_status: "86844d49cfb147ff92b0cae976ec43ec",
      broadacst_url: formData.uploadCSVFile,
    };
  }
}
