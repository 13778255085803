export default function convertScheduledDate(scheduledDate) {
  var time;
  var backendDate = scheduledDate.split("-");
  var sortedDate = backendDate[1] + "-" + backendDate[0] + "-" + backendDate[2];
  var date = new Date(sortedDate);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  time = year + "-" + month + "-" + day;
  return time;
}
