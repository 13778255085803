import { useHistory } from "react-router";
import { Grid } from "@material-ui/core";
import { DataTable, Paper, FormHeader } from "../../../custom components";

export default function BotListDisplay({ botList }) {
  const history = useHistory();

  const options = {
    search: false,
    viewColumns: false,
    selectableRows: "none",
    filter: false,
    download: false,
    print: false,
    pagination: false,
    onCellClick: (_cellData, cellMetaData) =>
      history.push("/bot-details/" + botList[cellMetaData.dataIndex].agent_id),
  };

  if (botList.length === 0) {
    return "";
  }

  return (
    <Paper>
      <Grid item xs={12}>
        <FormHeader formName="Bots" />
      </Grid>

      <Grid item xs={12}>
        <DataTable data={botList} options={options} columns={columns} />
      </Grid>
    </Paper>
  );
}

const columns = [
  {
    name: "agent_name",
    label: "Bot Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "channel_name",
    label: "Channel Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "total_users",
    label: "Total Users",
    options: {
      customBodyRender: (value) => {
        return value ? value : "0";
      },
    },
  },
  {
    name: "total_conversations",
    label: "Total Messages",
    options: {
      customBodyRender: (value) => {
        return value ? value : "0";
      },
    },
  },
];
