import React from "react";
import { Formik } from "formik";
import { useAxios, useAlert } from "../../../custom hooks";
import { useHistory } from "react-router";

import { ButtonFooter } from "../../../custom components";
import FormDisplay from "./FormDisplay";
import { objectFromFormData } from "./adminPageDetailsObject";
import validationSchema from "./validationSchema";
import DefaultPermissions from "./DefaultPermissions";

export default function Form({
  formData,
  setFormData,
  pagePermissions,
  editState,
  setEditState,
}) {
  const axios = useAxios();
  const history = useHistory();
  const alert = useAlert();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        var url = "/admin/page";
        let method = "POST";

        if (formData.pageID) {
          url = url + "/" + formData.pageID;
          method = "PATCH";
        }

        axios({
          url: url,
          method: method,
          data: objectFromFormData(formData),
          disableRedirect: true,
        })
          .then((res) => {
            setSubmitting(false);

            if (res.status) {
              alert.success(res.message);

              setEditState(false);

              if (method === "POST") {
                history.push("/pages/admin/" + res.data.page_id);
              } else {
                setFormData(formData);
              }
            }
          })
          .catch((err) => console.log(err));
      }}
    >
      {(formikProps) => (
        <React.Fragment>
          {pagePermissions.components.form?.read && (
            <FormDisplay
              formikProps={formikProps}
              editState={editState}
              permissions={pagePermissions.components.form}
            />
          )}

          {pagePermissions.components.defaultPermissions?.read && (
            <DefaultPermissions
              formikProps={formikProps}
              editState={editState}
              permissions={pagePermissions.components.defaultPermissions}
            />
          )}

          <ButtonFooter
            editState={editState}
            setEditState={setEditState}
            deletePermission={pagePermissions.delete}
            handleDelete={handleDelete}
            id={formikProps.values.pageID}
            formikProps={formikProps}
          />
        </React.Fragment>
      )}
    </Formik>
  );

  function handleDelete() {
    axios({
      url: "/admin/page/" + formData.pageID,
      method: "PATCH",
      data: {
        is_deleted: true,
      },
    })
      .then((response) => {
        if (response.status) {
          alert.success("Page Deleted!");
          history.push("/pages/admin/all");
        }
      })
      .catch((error) => console.log(error));
  }
}
