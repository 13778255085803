import convertDate from "../../../util/convertDate";
import { titleCase, sentenceCase } from "change-case-all";

export const integrationGroupDetailsObject = {
  ID: "",
  integrationName: "",
  status: "",
  description: "",
  createdBy: "",
  updatedBy: "",
  createdAt: "",
  updatedAt: "",
};

export function objectFromResponse(responseData) {
  return {
    ID: responseData._id || "",
    integrationName: titleCase(responseData.name) || "",
    status: responseData.is_active || "",
    description: sentenceCase(responseData.description) || "",
    createdBy: responseData.created_by || "",
    updatedBy: responseData.updated_by || "",
    createdAt: convertDate(responseData.created_on) || "",
    updatedAt: convertDate(responseData.updated_on) || "",
  };
}
export function objectFromFormData(formData) {
  if (Boolean(formData.ID)) {
    return {
      _id: formData.ID,
      name: titleCase(formData.integrationName),
      is_active: formData.status,
      description: sentenceCase(formData.description),
    };
  } else {
    return {
      name: titleCase(formData.integrationName),
      is_active: formData.status,
      description: sentenceCase(formData.description),
    };
  }
}
