import React from "react";
import * as Yup from "yup";
import {
  TextField,
  TextFieldWithCopy,
  Paper,
  ComponentHeader,
  Autocomplete,
} from "../../../custom components";
import { Grid, MenuItem } from "@material-ui/core";
import { useAxios } from "../../../custom hooks";

export default function FormDisplay({
  formikProps,
  editState,
  businessList,
  botList,
  setBotList,
  integrationGroupList,
  integrationList,
  setIntegrationList,
  permissions,
  setIntegrationDetails,
  setValidation,
}) {
  const defaultAxios = useAxios();
  const componentAxios = useAxios({ baseURL: "component" });

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader headerName="Bot Integration Details" />
      </Grid>

      {formikProps.values.ID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextFieldWithCopy
              required
              label="Bot Integration ID"
              name="ID"
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </React.Fragment>
      )}

      <Grid item xs={6}>
        <TextField
          label="Bot Integration Name"
          name="botIntegrationName"
          required
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          label="Active Status"
          name="integrationStatus"
          required
          disabled={!editState}
          formikProps={formikProps}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <Autocomplete
          name="businessID"
          label="Business"
          defaults={{
            primaryKey: "_id",
            displayLabel: "name",
          }}
          options={businessList}
          onChange={(_, value, type) => handleBusinessChange(value, type)}
          disabled={!(editState && !Boolean(formikProps.values.ID))}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <Autocomplete
          name="botID"
          label="Bot"
          defaults={{
            primaryKey: "agent_id",
            displayLabel: "agent_name",
          }}
          onChange={(_, value, type) => handleBotChange(value, type)}
          options={botList}
          disabled={
            !(
              editState &&
              !Boolean(formikProps.values.ID) &&
              Boolean(formikProps.values.businessID) &&
              Boolean(botList.length > 0)
            )
          }
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          required
          label="Integration Group"
          name="integrationGroupID"
          onChange={handleIntegrationGroupChange}
          disabled={!(editState && !Boolean(formikProps.values.ID))}
          formikProps={formikProps}
        >
          {integrationGroupList.map((integrationGroup) => (
            <MenuItem value={integrationGroup._id} key={integrationGroup._id}>
              {integrationGroup.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          required
          label="Integration"
          name="integrationID"
          onChange={handleIntegrationChange}
          disabled={
            !(
              editState &&
              !Boolean(formikProps.values.ID) &&
              formikProps.values.integrationGroupID
            )
          }
          formikProps={formikProps}
        >
          {integrationList.map((integration) => (
            <MenuItem key={integration._id} value={integration._id}>
              {integration.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {formikProps.values.ID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextField
              label="Created By"
              name="createdBy"
              disabled
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Created At"
              name="createdOn"
              disabled
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Updated By"
              name="updatedBy"
              disabled
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Updated At"
              name="updatedOn"
              disabled
              formikProps={formikProps}
            />
          </Grid>
        </React.Fragment>
      )}
    </Paper>
  );

  async function handleBusinessChange(businessDetails, type) {
    if (type === "select-option") {
      await defaultAxios({
        url: "/client/agents/" + businessDetails._id + "?mode=dropdown",
      }).then((response) => {
        if (response.status) {
          setBotList(response.data);
        }
      });
    } else {
      businessDetails = {
        _id: "",
        name: "",
      };
    }

    formikProps.setValues((prevValue) => ({
      ...prevValue,
      businessID: businessDetails._id,
      botID: "",
      botAccessToken: "",
    }));
  }

  function handleBotChange(botDetails, type) {
    if (type === "select-option") {
      formikProps.setValues((prevValue) => ({
        ...prevValue,
        botID: botDetails.agent_id,
        botAccessToken: botDetails.access_token,
      }));
    } else {
      formikProps.setValues((prevValue) => ({
        ...prevValue,
        botID: "",
        botAccessToken: "",
      }));
    }
  }

  function handleIntegrationGroupChange(event) {
    const integrationGroupID = event.target.value;

    formikProps.setValues((prevValue) => ({
      ...prevValue,
      integrationID: "",
      integrationGroupID: integrationGroupID,
      requiredCredentials: {},
      webhookEvents: [],
    }));

    setIntegrationDetails({});

    componentAxios({
      url: "/get_integrations?group_id=" + integrationGroupID,
    }).then((response) => {
      if (response.status) {
        setIntegrationList(response.data);
      }
    });
  }

  function handleIntegrationChange(event) {
    const integrationID = event.target.value;

    componentAxios({
      url: "/get_integrations?integration_id=" + integrationID,
    })
      .then((response) => {
        if (response.status) {
          setIntegrationDetails(response.data);
          let newCredentials = {};
          let newValidation = Yup.object();

          for (let credential of response.data.required_credentials) {
            newCredentials = {
              ...newCredentials,
              [credential.field_name]: "",
            };

            newValidation = newValidation.concat(
              Yup.object({
                [credential.field_name]: Yup.string().required(
                  credential.label + " is required"
                ),
                // .matches(credential.frontend_regex),
              })
            );
          }

          formikProps.setValues((prevValue) => ({
            ...prevValue,
            integrationID: integrationID,
            integrationName: response.data.name,
            requiredCredentials: newCredentials,
          }));

          setValidation((prevValue) =>
            prevValue.concat(Yup.object({ requiredCredentials: newValidation }))
          );
        }
      })
      .catch((err) => console.log(err));
  }
}
