import React from "react";
import {
  TextField,
  TextFieldWithCopy,
  Paper,
  FormHeader,
} from "../../../custom components";
import { Grid, MenuItem } from "@material-ui/core";

export default function FormDisplay({ formikProps, editState, setEditState }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <FormHeader
          formName="Integration Group Details"
          editIcon={Boolean(formikProps.values.ID)}
          setEditState={setEditState}
          handleReset={formikProps.handleReset}
        />
      </Grid>
      {formikProps.values.ID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextFieldWithCopy
              required
              label="Integration Group ID"
              name="ID"
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item>
            <Grid item xs></Grid>
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={6}>
        <TextField
          required
          label="Integration Group Name"
          name="integrationName"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          label="Status"
          name="status"
          required
          disabled={!editState}
          formikProps={formikProps}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Integration Group Description"
          name="description"
          fullWidth
          required
          multiline
          case="none"
          rows={3}
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>
      {formikProps.values.ID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextField
              label="Created By"
              name="createdBy"
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Updated By"
              name="updatedBy"
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Created At"
              name="createdAt"
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Updated At"
              name="updatedAt"
              disabled
              formikProps={formikProps}
            />
          </Grid>
        </React.Fragment>
      )}
    </Paper>
  );
}
