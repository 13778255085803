import { useHistory } from "react-router";
import convertDate from "../../../util/convertDate";
import DataTable from "../../../custom components/DataTable";

export default function Table({ groupIntegration }) {
  const history = useHistory();
  const options = {
    onRowClick: (cellData, cellMetaData) => {
      history.push(
        "/integration-group-details/" +
          groupIntegration[cellMetaData.dataIndex]._id
      );
    },
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };
  return (
    <DataTable columns={columns} options={options} data={groupIntegration} />
  );
}

const columns = [
  {
    name: "name",
    label: "Integration Group Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "is_active",
    label: "Status",
    options: {
      customBodyRender: (value) => {
        return value === true ? "Active" : "Inactive";
      },
    },
  },
  {
    name: "created_on",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
  {
    name: "updated_on",
    label: "Updated At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
