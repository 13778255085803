import React, { useState } from "react";

import Sidebar from "./dashboard layout components/Sidebar";
import Header from "./dashboard layout components/AppBar";
import Footer from "./dashboard layout components/Footer";
import AuthGuard from "../custom components/AuthGuard";
import MainContent from "./dashboard layout components/MainContent";

import styled, { createGlobalStyle } from "styled-components/macro";
import { Hidden, CssBaseline, withWidth } from "@material-ui/core";

const drawerWidth = 258;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  transition: margin 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

const Dashboard = ({ children, width }) => {
  const [drawerOpen, setDrawerOpen] = useState(true);

  function handleDrawerToggle() {
    setDrawerOpen(!drawerOpen);
  }

  return (
    <AuthGuard>
      <Root>
        <CssBaseline />
        <GlobalStyle />

        <Hidden mdUp implementation="js">
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={drawerOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar
            open={drawerOpen}
            onClose={handleDrawerToggle}
            PaperProps={{ style: { width: drawerWidth } }}
          />
        </Hidden>

        <AppContent
          style={{
            marginLeft:
              drawerOpen && (width === "md" || width === "lg" || width === "xl")
                ? drawerWidth
                : null,
          }}
        >
          <Header onDrawerToggle={handleDrawerToggle} />

          <MainContent>{children}</MainContent>

          <Footer />
        </AppContent>
      </Root>
    </AuthGuard>
  );
};

export default withWidth()(Dashboard);
