import React, { Fragment } from "react";
import { useDispatch } from "react-redux";

import { Grid, Button, Divider } from "@material-ui/core";

import { useAxios } from "../../../../custom hooks";
import { Paper, FormHeader, TextField } from "../../../../custom components";
import {
  alertSuccess,
  alertError,
} from "../../../../redux/actions/alertActions";

export default function ProfileDescription({ formikProps, editState }) {
  const axios = useAxios();
  const dispatch = useDispatch();

  function handleChangeDescription() {
    axios({
      url: "/set_profile_description/" + formikProps.values.whatsappNumberID,
      method: "POST",
      data: { profile_description: formikProps.values.profileDescription },
    })
      .then((response) => {
        if (response.status) {
          dispatch(alertSuccess("Profile description  updated successfully"));
        } else {
          dispatch(alertError(response.message || "Something went wrong"));
        }
      })
      .catch((error) => {
        dispatch(alertError(error.message || "Something went wrong"));
      });
  }
  return (
    <Paper>
      <Grid item xs={12}>
        <FormHeader formName="Profile Description" />
      </Grid>

      <Grid item xs={12}>
        <TextField
          disabled={!editState}
          placeholder="Profile Description"
          name="profileDescription"
          formikProps={formikProps}
          multiline
        />
      </Grid>
      <Grid item xs={6}></Grid>

      <Grid item xs></Grid>

      {editState && (
        <Fragment>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs />

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleChangeDescription}
              disabled={
                !formikProps.isValid ||
                formikProps.isDirty ||
                !formikProps.values.profileDescription
              }
            >
              Update
            </Button>
          </Grid>
        </Fragment>
      )}
    </Paper>
  );
}
