import React from "react";

import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  MenuItem,
  Typography,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core";

import { Formik } from "formik";
import { TextField } from "../../../custom components";

import RegexTable from "./RegexHelp";

import * as Yup from "yup";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "800px" },
}));

export default function RequiredCredentialsModal({
  modal,
  formikProps,
  setModal,
  editState,
}) {
  const classes = useStyles();
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={modal.state}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>Required Credential</DialogTitle>

      <Formik
        enableReinitialize
        initialValues={modal.credentials}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(formData) => {
          if (modal.mode === "add") {
            formikProps.setValues((prevVal) => ({
              ...prevVal,
              requiredCredentials: [
                ...formikProps.values.requiredCredentials,
                newField(formData),
              ],
            }));
          } else {
            formikProps.setValues((prevValue) => {
              let newrequiredCredentials = [];

              for (let fields of prevValue.requiredCredentials) {
                if (fields.field_name === formData.field_name) {
                  newrequiredCredentials.push(newField(formData));
                } else {
                  newrequiredCredentials.push(fields);
                }
              }

              return {
                ...prevValue,
                requiredCredentials: newrequiredCredentials,
              };
            });
          }
          handleClose();
        }}
      >
        {(dialogFormikProps) => (
          <form noValidate onSubmit={dialogFormikProps.handleSubmit}>
            <DialogContent>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    name="field_name"
                    label="Field Name"
                    disabled={!editState}
                    formikProps={dialogFormikProps}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    name="label"
                    label="Field Label"
                    disabled={!editState}
                    formikProps={dialogFormikProps}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    name="failure_message"
                    label="Field Failure Message"
                    disabled={!editState}
                    formikProps={dialogFormikProps}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    select
                    fullWidth
                    required
                    name="type"
                    label="Field Type"
                    disabled={!editState}
                    formikProps={dialogFormikProps}
                  >
                    <MenuItem value={"text"}>Text</MenuItem>
                    <MenuItem value={"boolean"}>Boolean</MenuItem>
                  </TextField>
                </Grid>

                {dialogFormikProps.values?.type === "text" && (
                  <React.Fragment>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        name="backend_regex"
                        label="Field Regex Backend"
                        disabled={!editState}
                        formikProps={dialogFormikProps}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        name="frontend_regex"
                        label="Field Regex Frontend"
                        disabled={!editState}
                        formikProps={dialogFormikProps}
                      />
                    </Grid>
                  </React.Fragment>
                )}

                <Grid item xs={12}>
                  <Typography variant="body1" style={{ color: "#808080" }}>
                    Here are some examples of regex you can use them as
                    reference.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <RegexTable />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: "12px 24px 24px" }}>
              <Button
                onClick={dialogFormikProps.handleSubmit}
                variant="contained"
                color="primary"
                disabled={
                  !(dialogFormikProps.dirty && dialogFormikProps.isValid)
                }
              >
                Submit
              </Button>
              <Button onClick={handleClose} variant="outlined" color="primary">
                Close
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );

  function handleClose() {
    setModal((prevValue) => ({
      ...prevValue,
      state: false,
    }));
  }
}

function newField(requiredCredentials) {
  return {
    field_name: requiredCredentials.field_name,
    label: requiredCredentials.label,
    ...(Boolean(requiredCredentials.type) && {
      frontend_regex: requiredCredentials.frontend_regex,
    }),
    ...(Boolean(requiredCredentials.type) && {
      backend_regex: requiredCredentials.backend_regex,
    }),
    type: requiredCredentials.type,
    failure_message: requiredCredentials.failure_message,
  };
}

const validationSchema = Yup.object().shape({
  field_name: Yup.string().required("This is the required field."),
  label: Yup.string().required("This is the required field."),
  failure_message: Yup.string().required("This is the required field."),
});
