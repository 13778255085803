import React from "react";

import { Grid } from "@material-ui/core";

import {
  TextField,
  TextFieldWithCopy,
  FormHeader,
  Paper,
} from "../../../custom components";

export default function FormDisplay({ editState, setEditState, formikProps }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <FormHeader
          formName="Group Information"
          editIcon={Boolean(formikProps.values.groupID)}
          setEditState={setEditState}
          handleReset={formikProps.handleReset}
        />
      </Grid>

      {formikProps.values.groupID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextFieldWithCopy
              label="Group ID"
              name="groupID"
              fullWidth
              case="none"
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs />
        </React.Fragment>
      )}
      <Grid item xs={6}>
        <TextField
          label="Group Name"
          name="groupName"
          fullWidth
          required
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      {formikProps.values.groupID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextField
              label="Member Count"
              name="memberCount"
              fullWidth
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Created At"
              name="createdAt"
              fullWidth
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Updated At"
              name="updatedAt"
              fullWidth
              disabled
              formikProps={formikProps}
            />
          </Grid>
        </React.Fragment>
      )}
    </Paper>
  );
}
