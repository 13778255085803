import React, { useState, useEffect } from "react";

import {
  pageDetailsObject,
  objectFromResponse,
} from "./page details components/pageDetailsObject";

import Form from "./page details components/Form";
import ComponentsDisplay from "./page details components/ComponentsDisplay";
import { Loader, PageHeader } from "../../custom components";
import { useLoader, useAxios, usePermissions } from "../../custom hooks";

export default function SuperadminPageDetails(props) {
  const loader = useLoader();
  const axios = useAxios();
  const pagePermissions = usePermissions(props.pageID);

  const [formData, setFormData] = useState(pageDetailsObject);

  const pageID = props.match.params.pageID || "";

  useEffect(() => {
    if (pageID) {
      loader.start();

      axios({
        url: "/page/" + pageID,
      })
        .then((response) => {
          setFormData(objectFromResponse(response.data));
          loader.stop();
        })
        .catch((error) => console.log(error));
    }
  }, [pageID, loader, axios]);

  return (
    <Loader>
      <PageHeader
        pageName="Superadmin Page Details"
        breadcrumbs={[
          { name: "Superadmin Pages", location: "pages/superadmin/all" },
        ]}
      />

      <Form
        pagePermissions={pagePermissions}
        formData={formData}
        setFormData={setFormData}
      />

      {formData.pageID && (
        <ComponentsDisplay pageID={pageID} components={formData.components} />
      )}
    </Loader>
  );
}
