import {
  Grid,
  Typography,
  Paper as MuiPaper,
  Divider,
} from "@material-ui/core";
import styled from "styled-components";

const Paper = styled(MuiPaper)`
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ColoredTypography = styled(Typography)`
  color: #0089ff;
`;

export default function AnalyticsDisplay({ insightData }) {
  return (
    <Grid container spacing={5}>
      <Grid item xs={5}>
        <Paper>
          <ColoredTypography variant="h1">
            {insightData.messages}
          </ColoredTypography>
          <Typography variant="subtitle1">Total Messages</Typography>
          <Grid
            container
            spacing={5}
            justifyContent="center"
            style={{ marginTop: "10px" }}
          >
            <Grid item xs={3} style={{ textAlign: "center" }}>
              <ColoredTypography variant="h5">
                {insightData.userMessages}
              </ColoredTypography>
              <Typography variant="caption">User Messages</Typography>
            </Grid>
            <Grid item>
              <Divider variant="fullWidth" orientation="vertical" />
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              <ColoredTypography variant="h5">
                {insightData.agentMessages}
              </ColoredTypography>
              <Typography variant="caption">Agent Messages</Typography>
            </Grid>
            <Grid item>
              <Divider variant="fullWidth" orientation="vertical" />
            </Grid>
            <Grid item xs={3} style={{ textAlign: "center" }}>
              <ColoredTypography variant="h5">
                {insightData.botMessages}
              </ColoredTypography>
              <Typography variant="caption">Bot Messages</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={2}>
        <Paper>
          <ColoredTypography variant="h1">
            {insightData.users}
          </ColoredTypography>
          <Typography variant="subtitle1">Total Users</Typography>
        </Paper>
      </Grid>
      <Grid item xs={5}>
        <Paper>
          <ColoredTypography variant="h1">
            {insightData.sessions}
          </ColoredTypography>
          <Typography variant="subtitle1">Total Sessions</Typography>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            style={{ marginTop: "10px" }}
          >
            <Grid xs={5} item style={{ textAlign: "center" }}>
              <ColoredTypography variant="h4">
                {insightData.averageSessionsPerDay.toFixed(2)}
              </ColoredTypography>
              <Typography variant="caption">
                Average Sessions Per Day
              </Typography>
            </Grid>
            <Grid item>
              <Divider variant="fullWidth" orientation="vertical" />
            </Grid>
            <Grid item xs={5} style={{ textAlign: "center" }}>
              <ColoredTypography variant="h4">
                {insightData.averageSessions.toFixed(2)}
              </ColoredTypography>
              <Typography variant="caption">
                Average Messages per Session
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper>
          <ColoredTypography variant="h1">{insightData.bots}</ColoredTypography>
          <Typography variant="subtitle1">Total Bots</Typography>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper>
          <ColoredTypography variant="h1">
            {insightData.averageHumanhandoffRate.toFixed(2)} %
          </ColoredTypography>
          <Typography variant="subtitle1">Human Handoff Rate</Typography>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper>
          <ColoredTypography variant="h1">
            {insightData.restartRate.toFixed(2)} %
          </ColoredTypography>
          <Typography variant="subtitle1">Restart Rate</Typography>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper>
          <ColoredTypography variant="h1">
            {insightData.fallbackRate.toFixed(2)} %
          </ColoredTypography>
          <Typography variant="subtitle1">Fallback Rate</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}
