import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import AgreementLayout from "../Agreement/AgreementLayout";
import CustomerForm from "./CustomerForm";
import { useHistory } from "react-router-dom";

import axios from "../../../util/axios";

export default function CustomerLayout(props) {
  const history = useHistory();

  const [isSubmitted, setisSubmitted] = useState(false);

  const [customerDetails, setCustomerDetails] = useState({
    date: "",
    commercialPlan: "",
    projects: [],
    oneTimePricing: [],
    reccuringPricing: [],
    companyName: "",
    companyDesc: "",
    companyNo: "",
    companyAddr: "",
    companyEmail: "",
    customerName: "",
  });

  var businessID = props.match.params.businessID;

  useEffect(() => {
    axios({
      url: "/agreement/" + businessID,
      method: "GET",
    }).then((response) => {
      if (response.data.code === 200) {
        var agreement = response.data.data.agreement;

        if (agreement.dolphinchat.is_submitted) {
          setCustomerDetails((prevValue) => ({
            ...prevValue,
            date: agreement.dolphinchat.dated,
            commercialPlan: agreement.dolphinchat.recuring_commercials_plan,
            projects: agreement.dolphinchat.project_requirement,
            oneTimePricing: agreement.dolphinchat.one_time_commercials,
            reccuringPricing:
              agreement.dolphinchat.recurring_commercials_feature,
          }));
        }

        if (agreement.customer.is_submitted) {
          setisSubmitted(agreement.customer.is_submitted);

          setCustomerDetails((prevValue) => ({
            ...prevValue,
            companyName: agreement.customer.customer_company_legal_name,
            companyDesc: agreement.customer.customer_company_desc,
            companyNo: agreement.customer.customer_contact_number,
            companyAddr: agreement.customer.customer_address,
            companyEmail: agreement.customer.customer_email,
            customerName: agreement.customer.customer_name,
          }));
        }
      } else {
        history.push("/404");
      }
    });
  }, [businessID, history]);

  function handleChange(event) {
    const { name, value } = event.target;
    setCustomerDetails({
      ...customerDetails,
      [name]: value,
    });
  }

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        {!isSubmitted && (
          <Grid item xs={5}>
            <Paper
              elevation={3}
              style={{
                height: "95vh",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <CustomerForm
                bID={businessID}
                customerDetails={customerDetails}
                handleChange={handleChange}
              />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} sm={7} style={{ maxWidth: "100%", flexGrow: "1" }}>
          <Paper
            elevation={3}
            style={{ height: "95vh", overflowY: "scroll", overflowX: "hidden" }}
          >
            <AgreementLayout
              companyName={customerDetails.companyName}
              companyDesc={customerDetails.companyDesc}
              companyNo={customerDetails.companyNo}
              companyAddr={customerDetails.companyAddr}
              companyEmail={customerDetails.companyEmail}
              customerName={customerDetails.customerName}
              commercialPlan={customerDetails.commercialPlan}
              date={customerDetails.date}
              projects={customerDetails.projects}
              oneTimePricings={customerDetails.oneTimePricing}
              reccuringPricings={customerDetails.reccuringPricing}
            />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
