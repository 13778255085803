export const botServiceDetailsObject = {
  botServiceID: "",
  botServiceName: "",
  botServiceRepoName: "" || "Not Available",
  actionsPort: "",
  NLUPort: "",
  createdAt: "" || "Not Available",
  updatedAt: "" || "Not Available",
  botServiceStatusID: "",
};

export function objectFromResponse(responseData) {
  return {
    botServiceID: responseData._id,
    botServiceName: responseData.name,
    botServiceRepoName: responseData.repo_name,
    actionsPort: responseData.actions_port,
    NLUPort: responseData.nlu_port,
    createdAt: responseData.created_at,
    updatedAt: responseData.updated_at,
    botServiceStatus: responseData.status.name.replace("_", " "),
  };
}

export function objectFromFormData(formData) {
  return {
    bot_service_id: formData.botServiceID,
    name: formData.botServiceName,
    repo_name: formData.botServiceName.toLowerCase().replaceAll(" ", "-"),
    actions_port: parseInt(formData.actionsPort),
    nlu_port: parseInt(formData.NLUPort),
  };
}
