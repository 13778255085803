import React from "react";

import Profile from "./form display component/Profile";
import ProfileDescription from "./form display component/ProfileDescription";
import BusinessDisplay from "./form display component/BusinessDisplay";

export default function FormDisplay({
  editState,
  formikProps,
  businessVerticals,
}) {
  return (
    <React.Fragment>
      {formikProps.values.whatsappNumberID && (
        <Profile formikProps={formikProps} editState={editState} />
      )}

      {formikProps.values.whatsappNumberID && (
        <ProfileDescription formikProps={formikProps} editState={editState} />
      )}

      {formikProps.values.whatsappNumberID && (
        <BusinessDisplay
          editState={editState}
          formikProps={formikProps}
          businessVerticals={businessVerticals}
        />
      )}
    </React.Fragment>
  );
}
