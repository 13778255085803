import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  pageName: Yup.string().max(50).required("Page Name is required"),
  pageStatus: Yup.string().required("Page Status is required"),
  pageDescription: Yup.string()
    .max(250, "Page Description cannot exceed 250 Characters")
    .required("Page Description is required"),
});

export default validationSchema;
