import React from "react";
import {
  TextField,
  TextFieldWithCopy,
  Paper,
  ComponentHeader,
} from "../../../custom components";
import { Grid, MenuItem } from "@material-ui/core";
import { titleCase } from "change-case-all";

export default function FormDisplay({
  formikProps,
  editState,
  groupList,
  permissoins,
}) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader headerName="Integration Details" />
      </Grid>
      {formikProps.values.ID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextFieldWithCopy
              required
              label="Integration ID"
              name="ID"
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </React.Fragment>
      )}
      <Grid item xs={6}>
        <TextField
          required
          label="Integration Name"
          name="name"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          required
          label="Integration Group Name"
          name="groupID"
          disabled={!editState}
          formikProps={formikProps}
        >
          {groupList.map((item) => (
            <MenuItem key={item._id} value={item._id}>
              {titleCase(item.name)}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Integration Description"
          name="description"
          fullWidth
          required
          multiline
          case="none"
          rows={2}
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          label="Active Status"
          name="integrationStatus"
          required
          disabled={!editState}
          formikProps={formikProps}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </TextField>
      </Grid>

      {formikProps.values.ID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextField
              label="Created By"
              name="createdBy"
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Created At"
              name="createdOn"
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Updated By"
              name="updatedBy"
              disabled
              formikProps={formikProps}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label="Updated At"
              name="updatedOn"
              disabled
              formikProps={formikProps}
            />
          </Grid>
        </React.Fragment>
      )}
    </Paper>
  );
}
