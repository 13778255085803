import React, { useState } from "react";
import MobileViewBot from "./MobileViewBot";
import { PhoneAndroid, DesktopMac } from "@material-ui/icons";
import { IconButton, Tooltip } from "@material-ui/core";
import "./whatsapp.css";

export default function WhatsappStyleBot({
  formikProps,
  expanded,
  setExpanded,
  agentId,
}) {
  const [phoneView, setPhoneView] = useState(false);
  const [showBubble, setShowBubble] = useState(true);
  return (
    <React.Fragment>
      <div
        style={{
          width: "50px",
          display: "flex",
          flexDirection: "column",
          position: "fixed",
        }}
      >
        <Tooltip title="Phone View">
          <IconButton onClick={() => setPhoneView(true)}>
            <PhoneAndroid style={{ color: phoneView && "#0091f3" }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Desktop View">
          <IconButton onClick={() => setPhoneView(false)}>
            <DesktopMac style={{ color: !phoneView && "#0091f3" }} />
          </IconButton>
        </Tooltip>
      </div>

      <MobileViewBot
        phoneView={phoneView}
        setPhoneView={setPhoneView}
        formikProps={formikProps}
        showBubble={showBubble}
        setShowBubble={setShowBubble}
        expanded={expanded}
        setExpanded={setExpanded}
        agentId={agentId}
      />
    </React.Fragment>
  );
}
