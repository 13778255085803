import convertDate from "../../../util/convertDate";
import { useHistory } from "react-router";
import { DataTable } from "../../../custom components";

export default function Table({ businesses }) {
  const history = useHistory();

  const options = {
    onRowClick: (_cellData, cellMetaData) =>
      history.push(
        "/business-details/" + businesses[cellMetaData.dataIndex]._id
      ),

    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  return <DataTable data={businesses} columns={columns} options={options} />;
}

const columns = [
  {
    name: "name",
    label: "Business Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "agent_linked",
    label: "Total Bots",
    options: {
      customBodyRender: (value) => {
        return value ? value : "0";
      },
    },
  },
  {
    name: "is_active",
    label: "Business Status",
    options: {
      customBodyRender: (value) => {
        return value ? "Active" : "Inactive";
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
  {
    name: "_id",
    label: "Business ID",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
      display: false,
    },
  },
  {
    name: "creator_email",
    label: "Creator Email",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
      display: false,
    },
  },
  {
    name: "billing.tenure",
    label: "Tenure",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
      display: false,
    },
  },
  {
    name: "address.address",
    label: "Address",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
      display: false,
    },
  },
];
