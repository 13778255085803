import convertDate from "../../../util/convertDate";

export const botServiceDetailsObject = {
  groupID: "",
  groupName: "",
  memberCount: "" || "0",
  pagePermissions: [],
  createdAt: "" || "Not Available",
  updatedAt: "" || "Not Available",
};

export function objectFromResponse(responseData) {
  return {
    groupID: responseData.group_id,
    groupName: responseData.group_name,
    memberCount: responseData.member_count,
    pagePermissions: responseData.permission_page,
    createdAt: convertDate(responseData.created_at),
    updatedAt: convertDate(responseData.updated_at),
  };
}

export function objectFromFormData(formData) {
  return {
    group_name: formData.groupName,
    permission_pages: formData.pagePermissions,
  };
}
