import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { usePermissions, useAxios, useLoader } from "../../custom hooks";
import Table from "./bot integrations components/Table";

import { Loader, PageHeader } from "../../custom components";
import { Button } from "@material-ui/core";

export default function BotIntegrations(props) {
  const axios = useAxios({ baseURL: "component" });
  const loader = useLoader();
  const history = useHistory();
  const pagePermissions = usePermissions(props.pageID);

  const [botIntegrations, setBotIntegrations] = useState([]);

  useEffect(() => {
    loader.start();

    axios({
      url: "/get_agent_integrations?mode=list",
    })
      .then((response) => {
        if (response.status) {
          loader.stop();
          setBotIntegrations(response.data);
        }
      })
      .catch((err) => console.log(err));
  }, [loader, axios]);

  return (
    <Loader>
      <PageHeader
        pageName="Bot Integrations"
        icons={[
          pagePermissions.create && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/bot-integration-details")}
            >
              Add New Bot Integration
            </Button>
          ),
        ]}
      />

      <Table botIntegrations={botIntegrations} />
    </Loader>
  );
}
