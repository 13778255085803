import { useRef } from "react";
import { useDispatch } from "react-redux";
import {
  loaderStart,
  loaderStop,
  apiComplete,
  loaderError,
} from "../redux/actions/loaderActions";

export default function useLoader() {
  const dispatch = useDispatch();

  return useRef({
    start: (apiCount = 1) => dispatch(loaderStart(apiCount)),

    apiComplete: (apiCount = 1) => dispatch(apiComplete(apiCount)),

    stop: () => dispatch(loaderStop()),

    error: () => dispatch(loaderError()),
  }).current;
}
