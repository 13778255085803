import React, { useState, useEffect, useRef } from "react";
import {
  Paper,
  Grid,
  Typography,
  Switch,
  MenuItem,
  Button,
  Divider as MuiDivider,
  IconButton,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "./whatsapp.css";
import { SketchPicker } from "react-color";
import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";
import CropImage from "../style whatsapp widget components/CropImage";
import { ColorLens, ExpandMore, Info, CloudUpload } from "@material-ui/icons";
import TextFieldWithIcon from "../../../custom components/TextFieldWithIcon";
import { FormHeader, TextField } from "../../../custom components";
import StyleModalConfirmation from "./StyleModal";
const Divider = styled(MuiDivider)(spacing);
const useStyles = makeStyles((theme) => ({
  heading: {
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  iconButton: {
    "&:hover": {
      background: "none",
    },
  },
}));
export default function FormDisplay({
  formikProps,
  setEditState,
  editState,
  expanded,
  setExpanded,
  styleModal,
  setStyleModal,
  agentId,
  originalImage,
  setOriginalImage,
}) {
  const classes = useStyles();

  const [bubbleBackgroundColor, setBubbleBackgroundColor] = useState(true);
  const [bubbleIconColor, setBubbleIconColor] = useState(true);
  const [headerBackgroundColour, setHeaderBackgroundColor] = useState(true);
  const [buttonColor, setButtonColor] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);

  const [image, setImage] = useState({
    image: "",
    imageName: "",
  });

  const pickerStyle = {
    default: {
      picker: {
        position: "absolute",
        bottom: "53px",
        right: "0%",
      },
    },
  };
  const handleChangePhoneNumber = (value, country, e) => {
    if (value.length <= 3) {
      setValidPhoneNumber(true);
    } else {
      setValidPhoneNumber(false);
    }
    if (
      e.target.className === "country" ||
      e.target.className === "country-name"
    ) {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        whatsappNumber: "+" + country.dialCode + "",
      }));
    } else {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        whatsappNumber: value,
      }));
    }
  };
  let useClickOutside = (handler) => {
    let domNode = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!domNode.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", maybeHandler);

      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return domNode;
  };
  let domNode = useClickOutside(() => {
    setBubbleBackgroundColor(true);
  });

  let useClickOutsideIcon = (handler) => {
    let domIconNode = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!domIconNode.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", maybeHandler);

      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return domIconNode;
  };
  let domIconNode = useClickOutsideIcon(() => {
    setBubbleIconColor(true);
  });

  let useClickOutsideHeader = (handler) => {
    let domNodeHeader = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!domNodeHeader.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", maybeHandler);

      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return domNodeHeader;
  };
  let domNodeHeader = useClickOutsideHeader(() => {
    setHeaderBackgroundColor(true);
  });
  let useClickOutsideStart = (handler) => {
    let domNodeStart = useRef();

    useEffect(() => {
      let maybeHandler = (event) => {
        if (!domNodeStart.current.contains(event.target)) {
          handler();
        }
      };

      document.addEventListener("mousedown", maybeHandler);

      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });

    return domNodeStart;
  };
  let domNodeStart = useClickOutsideStart(() => {
    setButtonColor(true);
  });

  const handleChangeAvatar = (e) => {
    var src = e.target.currentSrc;
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      pictureCustom: src,
    }));
  };
  const handleChangePhoto = (photo) => {
    const file = photo.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        setImage({
          image: reader.result,
          imageName: photo.target.files[0],
        });
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);

      setOpenModal(true);
    }
  };

  function toggleEdit() {
    setEditState(!editState);
  }
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Paper
      elevation={2}
      style={{
        padding: "15px",
        height: "93vh",
        maxHeight: "93vh",
        overflow: "auto",
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormHeader formName="Widget Style" />
        </Grid>
        <Grid item xs={6} style={{ textAlign: "end" }}>
          {!editState && (
            <React.Fragment>
              <Button variant="contained" color="primary" onClick={toggleEdit}>
                Edit
              </Button>
            </React.Fragment>
          )}
          {editState && (
            <React.Fragment>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginRight: "10px" }}
                disabled={
                  formikProps.dirty
                    ? formikProps.isSubmitting || !formikProps.isValid
                    : !formikProps.dirty
                }
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setEditState(!editState)}
              >
                Cancel
              </Button>
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h5" className={classes.heading}>
                General Settings
              </Typography>

              <Typography className={classes.secondaryHeading}>
                Manage basic settings from here, Add position, enable prefilled
                message , change bubble icon colour etc
              </Typography>
            </AccordionSummary>
            <Divider style={{ margin: "0 0 20px 0" }} />
            <AccordionDetails>
              <Grid style={{ marginBottom: "10px" }} container spacing={5}>
                <Grid item xs={6}>
                  <TextField
                    name="parentUrl"
                    label="Domain Name"
                    case="none"
                    required
                    disabled={!editState}
                    formikProps={formikProps}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title=" Domain of your website where you want to add a widget.">
                            <IconButton style={{ color: "#cbcbcb" }}>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PhoneInput
                    value={formikProps.values.whatsappNumber}
                    onChange={handleChangePhoneNumber}
                    inputProps={{
                      name: "whatsappNumber",
                    }}
                    error={Boolean(
                      formikProps.touched.whatsappNumber &&
                        formikProps.errors.whatsappNumber
                    )}
                    helperText={
                      formikProps.touched.whatsappNumber &&
                      formikProps.errors.whatsappNumber
                    }
                    onBlur={formikProps.handleBlur}
                    inputStyle={{
                      width: "100%",
                      fontSize: "12px",
                      color: !editState
                        ? "rgba(0, 0, 0, 0.38)"
                        : "currentColor",
                      borderColor: !validPhoneNumber
                        ? editState
                          ? "#4076d2"
                          : " rgba(0, 0, 0, 0.38)"
                        : "#f44336",
                      padding: "17.5px 14px 18.5px 58px",
                      cursor: !editState && "default",
                    }}
                    disabled={!editState}
                  />
                  {validPhoneNumber && (
                    <span className="validationError">
                      Whatsapp Number is Required
                    </span>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Prefilled Message(Optional)"
                    name="preFilledMessage"
                    formikProps={formikProps}
                    disabled={!editState}
                    case="none"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="This message can be used as the initial message for the user.">
                            <IconButton style={{ color: "#cbcbcb" }}>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography style={{ padding: "12px" }}>
                    White Label &nbsp;
                    <Tooltip title="When enabled, Powered by Dolphinchat will be displayed to the users. To disable this contact support@dolphinchat.ai">
                      <IconButton style={{ color: "#cbcbcb", padding: "0px" }}>
                        <Info />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid style={{ textAlign: "end" }} item xs={3}>
                  <Switch
                    checked={
                      formikProps.values.hideDolphinchatLogo === 1
                        ? false
                        : true
                    }
                    name="whiteLabel"
                    onChange={formikProps.handleChange}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" style={{ fontSize: "0.9rem" }}>
                    Bubble Settings
                  </Typography>
                  <Typography style={{ color: "#808080" }} variant="body1">
                    You can choose the bubble icon colour and the background
                    colour of the bubble.
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    name="align"
                    label="Bubble Position"
                    value={formikProps.values.align}
                    formikProps={formikProps}
                    disabled={!editState}
                  >
                    <MenuItem value={"left"}>Left</MenuItem>
                    <MenuItem value={"right"}>Right</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      position: "relative",
                    }}
                    ref={domNode}
                  >
                    {!bubbleBackgroundColor && (
                      <SketchPicker
                        styles={pickerStyle}
                        onChange={({ hex }) => {
                          formikProps.setValues((prevVal) => ({
                            ...prevVal,
                            bubbleBackgroundColor: hex,
                          }));
                        }}
                        color={formikProps.values.bubbleBackgroundColor}
                      />
                    )}
                    <TextField
                      label="Background Colour"
                      formikProps={formikProps}
                      case="none"
                      name="bubbleBackgroundColor"
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{
                                background:
                                  formikProps.values.bubbleBackgroundColor,
                                height: "15px",
                                width: "15px",
                                borderRadius: "50%",
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              color="primary"
                              onClick={() =>
                                setBubbleBackgroundColor(!bubbleBackgroundColor)
                              }
                              disabled={!editState}
                            >
                              <ColorLens />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      position: "relative",
                    }}
                    ref={domIconNode}
                  >
                    {!bubbleIconColor && (
                      <SketchPicker
                        styles={pickerStyle}
                        onChange={({ hex }) => {
                          formikProps.setValues((prevVal) => ({
                            ...prevVal,
                            bubbleIconColor: hex,
                          }));
                        }}
                        color={formikProps.values.bubbleIconColor}
                      />
                    )}
                    <TextFieldWithIcon
                      label="Icon Colour"
                      formikProps={formikProps}
                      case="none"
                      name="bubbleIconColor"
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{
                                background: formikProps.values.bubbleIconColor,
                                height: "15px",
                                width: "15px",
                                borderRadius: "50%",
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              color="primary"
                              onClick={() =>
                                setBubbleIconColor(!bubbleIconColor)
                              }
                              disabled={!editState}
                            >
                              <ColorLens />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid style={{ marginTop: "30px" }} item xs={12}>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h5" className={classes.heading}>
                Header Settings
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Customize bot header by adding custom colors, business avatar,
                caption etc.
              </Typography>
            </AccordionSummary>
            <Divider style={{ margin: "0 0 20px 0" }} />
            <AccordionDetails>
              <Grid style={{ marginBottom: "10px" }} container spacing={5}>
                <Grid item xs={12}>
                  <Typography style={{ fontSize: "0.9rem" }} variant="h5">
                    Business Display
                  </Typography>
                  <Typography
                    style={{
                      color: "#808080",
                    }}
                    variant="body2"
                  >
                    Image that represents your business or chatbot use-case.
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Avatar
                      style={{
                        margin: "10px",
                        height: "55px",
                        width: "55px",
                        cursor: "pointer",
                      }}
                      onClick={handleChangeAvatar}
                      src={formikProps.values.pictureCustom}
                    ></Avatar>
                    <input
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      type="file"
                      disabled={!editState}
                      onChange={(event) => handleChangePhoto(event)}
                    />
                    <label
                      style={{ marginTop: "10px" }}
                      htmlFor="raised-button-file"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{ margin: "10px" }}
                        disabled={!editState}
                      >
                        <CloudUpload />
                        &nbsp; Upload
                      </Button>
                    </label>
                  </div>
                  {image !== "" && (
                    <CropImage
                      openModal={openModal}
                      setOpenModal={setOpenModal}
                      image={image.image}
                      formikProps={formikProps}
                      imageName={image.imageName}
                      agentId={agentId}
                      setOriginalImage={setOriginalImage}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Title"
                    case="none"
                    name="TitleName"
                    required
                    formikProps={formikProps}
                    disabled={!editState}
                    inputProps={{
                      maxLength: 20,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {formikProps.values.TitleName.length}/20
                          <Tooltip title="The main title of your bot. This could be the name of your business or any virtual avatar.">
                            <IconButton style={{ color: "#cbcbcb" }}>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Caption(Optional)"
                    name="nameCaptionReplyTime"
                    formikProps={formikProps}
                    disabled={!editState}
                    style={{
                      paddingRight: "0px",
                    }}
                    inputProps={{
                      maxLength: 35,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {formikProps.values.nameCaptionReplyTime.length}/35
                          <Tooltip title="With caption you can provide extra information about your chatbot or use-case">
                            <IconButton style={{ color: "#cbcbcb" }}>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      formikProps.setValues((prevVal) => ({
                        ...prevVal,
                        nameCaptionReplyTime: e.target.value,
                      }));
                    }}
                    case="none"
                  />
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      position: "relative",
                    }}
                    ref={domNodeHeader}
                  >
                    {!headerBackgroundColour && (
                      <SketchPicker
                        styles={pickerStyle}
                        onChange={({ hex }) => {
                          formikProps.setValues((prevVal) => ({
                            ...prevVal,
                            headerBackgroundColor: hex,
                          }));
                        }}
                        color={formikProps.values.headerBackgroundColor}
                      />
                    )}
                    <TextFieldWithIcon
                      label="Background Colour"
                      case="none"
                      name="headerBackgroundColor"
                      formikProps={formikProps}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{
                                background:
                                  formikProps.values.headerBackgroundColor,
                                height: "15px",
                                width: "15px",
                                borderRadius: "50%",
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              color="primary"
                              onClick={() =>
                                setHeaderBackgroundColor(
                                  !headerBackgroundColour
                                )
                              }
                              disabled={!editState}
                            >
                              <ColorLens />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid style={{ marginTop: "30px" }} item xs={12}>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h5" className={classes.heading}>
                Window Settings
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Customize the chat window welcome message.
              </Typography>
            </AccordionSummary>
            <Divider style={{ margin: "0 0 20px 0" }} />
            <AccordionDetails>
              <Grid style={{ marginBottom: "10px" }} container spacing={5}>
                <Grid item xs={6}>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <TextField
                      label="Welcome Message"
                      name="welcomeMessage"
                      formikProps={formikProps}
                      disabled={!editState}
                      required
                      style={{
                        marginRight: "0px",
                      }}
                      inputProps={{
                        maxLength: 70,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {formikProps.values.welcomeMessage.length}/70
                            <Tooltip title="This will be the predefined message in the chat window.">
                              <IconButton style={{ color: "#cbcbcb" }}>
                                <Info />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        formikProps.setValues((prevVal) => ({
                          ...prevVal,
                          welcomeMessage: e.target.value,
                        }));
                      }}
                      case="none"
                    />
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid style={{ marginTop: "30px" }} item xs={12}>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h5" className={classes.heading}>
                Footer Settings
              </Typography>
              <Typography className={classes.secondaryHeading}>
                Manage the button text and button colour in the footer
              </Typography>
            </AccordionSummary>
            <Divider style={{ margin: "0 0 20px 0" }} />
            <AccordionDetails>
              <Grid style={{ margin: "0 0 20px 0" }} container spacing={5}>
                <Grid item xs={6}>
                  <TextField
                    label="Button Text"
                    name="buttonText"
                    formikProps={formikProps}
                    disabled={!editState}
                    case="none"
                    required
                    inputProps={{
                      maxLength: 20,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {formikProps.values.buttonText.length}/20
                          <Tooltip title="This is the button text in the chat window which will initiate the chat.">
                            <IconButton style={{ color: "#cbcbcb" }}>
                              <Info />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      formikProps.setValues((prevVal) => ({
                        ...prevVal,
                        buttonText: e.target.value,
                      }));
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      position: "relative",
                    }}
                    ref={domNodeStart}
                  >
                    {!buttonColor && (
                      <SketchPicker
                        styles={pickerStyle}
                        onChange={({ hex }) => {
                          formikProps.setValues((prevVal) => ({
                            ...prevVal,
                            buttonColor: hex,
                          }));
                        }}
                        color={formikProps.values.buttonColor}
                      />
                    )}
                    <TextFieldWithIcon
                      label="Button Colour"
                      case="none"
                      name="headerBackgroundColor"
                      formikProps={formikProps}
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{
                                background: formikProps.values.buttonColor,
                                height: "15px",
                                width: "15px",
                                borderRadius: "50%",
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              color="primary"
                              onClick={() => setButtonColor(!buttonColor)}
                              disabled={!editState}
                            >
                              <ColorLens />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <React.Fragment>
          <Grid item xs></Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setStyleModal(true)}
              disabled={formikProps.dirty}
            >
              Add to Website
            </Button>
          </Grid>
        </React.Fragment>
        {styleModal && (
          <StyleModalConfirmation
            styleModal={styleModal}
            setStyleModal={setStyleModal}
            agentId={agentId}
          />
        )}
      </Grid>
    </Paper>
  );
}
