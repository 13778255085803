import React from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import { Formik } from "formik";
import DialogFormDisplay from "./DialogFormDisplay";
import * as Yup from "yup";

export default function WebhookEventDialog({
  modalProps,
  setModalProps,
  formikProps,
  webhookEvents,
}) {
  return (
    <Dialog open={modalProps.state} onClose={handleClose} fullWidth>
      <DialogTitle>Webhook Event</DialogTitle>

      <Formik
        enableReinitialize
        initialValues={modalProps.value}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(formData) => {
          if (modalProps.mode === "add") {
            formikProps.setValues((prevValue) => ({
              ...prevValue,
              webhookEvents: [...prevValue.webhookEvents, newEvent(formData)],
            }));
          } else {
            formikProps.setValues((prevValue) => {
              let newWebhookEvents = [];

              for (let event of prevValue.webhookEvents) {
                if (event.event_name === formData.name) {
                  newWebhookEvents.push(newEvent(formData));
                } else {
                  newWebhookEvents.push(event);
                }
              }

              return {
                ...prevValue,
                webhookEvents: newWebhookEvents,
              };
            });
          }

          handleClose();
        }}
      >
        {(dialogFormikProps) => (
          <form noValidate onSubmit={dialogFormikProps.handleSubmit}>
            <DialogFormDisplay
              dialogFormikProps={dialogFormikProps}
              formikProps={formikProps}
              webhookEvents={webhookEvents}
              handleClose={handleClose}
              dialogMode={modalProps.mode}
            />
          </form>
        )}
      </Formik>
    </Dialog>
  );

  function handleClose() {
    setModalProps((prevValue) => ({
      ...prevValue,
      state: false,
    }));
  }
}

function newEvent(eventData) {
  return {
    event_name: eventData.name,
    notify: eventData.notification,
    ...(Boolean(eventData.notification) && {
      template_id: eventData.templateID,
    }),
    ...(Boolean(eventData.notification) && {
      fields: eventData.fields,
    }),
  };
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please select an event!"),
  notification: Yup.boolean().required(
    "Please select a business for Integration"
  ),
  templateID: Yup.string().when("notification", {
    is: true,
    then: Yup.string().required("Please provide a template to compare"),
  }),
  fields: Yup.array(
    Yup.object().shape({
      field_name: Yup.string().required("Please select a placeholder"),
    })
  ),
});
