export const insightDataObject = {
  bots: 0,
  users: 0,
  sessions: 0,
  messages: 0,
  userMessages: 0,
  agentMessages: 0,
  botMessages: 0,
  averageSessions: 0,
  averageSessionsPerDay: 0,
  averageHumanhandoffRate: 0,
  restartRate: 0,
  fallbackRate: 0,
};
