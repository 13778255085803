import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { DeleteOutlined } from "@material-ui/icons";
import {
  Grid,
  MenuItem,
  InputAdornment,
  Button,
  Divider,
  IconButton,
} from "@material-ui/core";

import { useAxios } from "../../../../custom hooks";
import { Paper, TextField, FormHeader } from "../../../../custom components";

import {
  alertSuccess,
  alertError,
} from "../../../../redux/actions/alertActions";
import { objectFromBusinessProfile } from "../whatsappStyleObject";

export default function BusinessDisplay({
  formikProps,
  editState,
  businessVerticals,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();

  function handleClick(index) {
    let web = [...formikProps.values.businessWebsite];
    web.splice(index, 1);
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      businessWebsite: web,
    }));
  }

  function handleAddFields() {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      businessWebsite: [...prevVal.businessWebsite, { websiteName: "" }],
    }));
  }

  function setBusinessProfile() {
    axios({
      url: "/set_business_profile/" + formikProps.values.whatsappNumberID,
      method: "POST",
      data: objectFromBusinessProfile(formikProps.values),
    })
      .then((response) => {
        if (response.status) {
          dispatch(alertSuccess("Business profile  updated successfully"));
        } else {
          dispatch(alertError(response.message || "Something went wrong"));
        }
      })
      .catch((error) => {
        dispatch(alertError(error.message || "Something went wrong"));
      });
  }

  return (
    <Paper>
      <Grid item xs={12}>
        <FormHeader formName="Business Profile " />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Address"
          name="profileAddress"
          formikProps={formikProps}
          disabled={!editState}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          multiline
          placeholder="Description"
          name="businessProfileDescription"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Email "
          name="businessProfileEmail"
          disabled={!editState}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          label="Category"
          name="businessVerticalId"
          disabled={!editState}
          formikProps={formikProps}
        >
          {businessVerticals?.map((business) => (
            <MenuItem key={business.id} value={business.id}>
              {business.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {formikProps.values.businessWebsite.map((_, index) => (
        <Grid item xs={6} key={index}>
          <TextField
            label="Websites"
            name={"businessWebsite[" + index + "].websiteName"}
            disabled={!editState}
            formikProps={formikProps}
            InputProps={{
              endAdornment: editState && (
                <InputAdornment position={"end"}>
                  <IconButton
                    onClick={() => handleClick(index)}
                    style={{ color: "red", padding: "0px" }}
                  >
                    <DeleteOutlined />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      ))}

      {editState && (
        <Fragment>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs></Grid>

          <Grid item>
            {formikProps.values.businessWebsite.length < 2 && (
              <Button
                variant="outlined"
                color="primary"
                disabled={!editState}
                onClick={handleAddFields}
                style={{ margin: "4px" }}
              >
                Add Website
              </Button>
            )}

            <Button
              onClick={setBusinessProfile}
              variant="contained"
              color="primary"
              disabled={!formikProps.isValid || formikProps.isDirty}
            >
              Update
            </Button>
          </Grid>
        </Fragment>
      )}
    </Paper>
  );
}
