import { useState, useEffect } from "react";
import { usePermissions, useAxios, useLoader } from "../../custom hooks";

import { Loader, PageHeader } from "../../custom components";

import Form from "./utterance details components/Form";
import {
  utteranceDetailsObject,
  objectFromResponse,
} from "./utterance details components/utteranceDetailsObject";

export default function UtteranceDetails(props) {
  const axios = useAxios();
  const loader = useLoader();
  const pagePermissions = usePermissions(props.pageID);

  const ID = props.match.params.utteranceID || "";

  const [formData, setFormData] = useState(utteranceDetailsObject);
  const [botServiceList, setBotServiceList] = useState([]);
  const [editState, setEditState] = useState(!ID);

  useEffect(() => {
    loader.start(2);

    axios({
      url: "/bot/service/detail",
    })
      .then((response) => {
        loader.apiComplete();

        setBotServiceList(response.data);
      })
      .catch((error) => console.log(error));

    if (!!ID) {
      axios({
        url: "/service/utterance?utterance_id=" + ID,
      })
        .then((response) => {
          if (response.status) {
            loader.apiComplete();
            setFormData(objectFromResponse(response.data));
          }
        })
        .catch((error) => console.log(error));
    } else {
      loader.apiComplete();
    }
  }, [axios, loader, ID]);

  return (
    <Loader>
      <PageHeader
        pageName="Utterance Details"
        breadcrumbs={[{ name: "Utterances", location: "utterances/all" }]}
        editIcon={[editState, setEditState, pagePermissions.update]}
      />

      <Form
        formData={formData}
        setFormData={setFormData}
        editState={editState}
        setEditState={setEditState}
        pagePermissions={pagePermissions}
        botServiceList={botServiceList}
      />
    </Loader>
  );
}
