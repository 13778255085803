import React from "react";
import { Grid, Typography, Divider, Button } from "@material-ui/core";

import PagePermissionRow from "./PagePermissionRow";

import Paper from "../../../custom components/Paper";
import FormHeader from "../../../custom components/FormHeader";

export default function PagePermissionsDisplay({
  editState,
  setEditState,
  pageList,
  formikProps,
}) {
  return (
    <Paper>
      <Grid item xs={12}>
        <FormHeader
          formName="Page Permissions"
          editIcon={Boolean(formikProps.values.groupID)}
          setEditState={setEditState}
          handleReset={formikProps.handleReset}
        />
      </Grid>

      <Grid
        item
        xs={12}
        container
        alignItems="center"
        style={{ background: "#bbd4ff", padding: "25px 10px" }}
      >
        <Grid item xs={6}>
          <Typography variant="h6">Page Name</Typography>
        </Grid>
        <Grid item xs={6} container style={{ textAlign: "center" }}>
          <Grid item xs={3}>
            <Typography variant="h6">View</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">Create</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">Update</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">Delete</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} container alignItems="center">
        {pageList.length > 0 &&
          pageList.map((page) => (
            <PagePermissionRow
              key={page.page_id}
              editState={editState}
              displayPage={page}
              formikProps={formikProps}
            />
          ))}
      </Grid>

      {editState && (
        <React.Fragment>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs></Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={
                formikProps.dirty
                  ? formikProps.isSubmitting || !formikProps.isValid
                  : !formikProps.dirty
              }
            >
              Submit
            </Button>
          </Grid>
          {formikProps.values.groupID !== "" && (
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  setEditState(false);
                  formikProps.handleReset();
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
          )}
        </React.Fragment>
      )}
    </Paper>
  );
}
