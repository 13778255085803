import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { Formik } from "formik";

import * as Yup from "yup";

import {
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core";

import UserDetailsForm from "./UserDetailsForm";

import {
  alertError,
  alertSuccess,
} from "../../../../redux/actions/alertActions";

import {
  objectFromFormData,
  objectFromResponse,
  userDetailObject,
} from "./userDetailObject";

import { useAxiosV2 } from "../../../../custom hooks";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "400px" },
}));

export default function AddUserPopUp({
  addUserPopUp,
  setAddUserPopUp,
  groupID,
  setUser,
  authToken,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const axios = useAxiosV2({
    baseURL: "adminV2",
  });
  const [formData, setFormData] = useState(userDetailObject);

  useEffect(() => {
    if (addUserPopUp.groupUserID !== "") {
      axios({
        url:
          "/broadcast/get/group/user/" +
          groupID +
          "?group_user_id=" +
          addUserPopUp.groupUserID,
        method: "GET",
        authToken: "Bearer " + authToken,
      }).then((res) => {
        if (res.status) {
          setFormData(objectFromResponse(res.data));
        } else {
          dispatch(alertError(res.message));
        }
      });
    }
  }, [addUserPopUp.groupUserID, groupID, dispatch, axios, authToken]);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={addUserPopUp.state}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle
        style={{
          borderBottom: "1px solid #cbcbcb",
          fontSize: "30px!important",
        }}
      >
        {addUserPopUp.groupUserID ? "User Details" : "Add User"}
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={formData}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={(formData, { setSubmitting }) => {
          var data = objectFromFormData(formData);
          if (addUserPopUp.groupUserID !== "") {
            axios({
              url: "/broadcast/update/group/user/" + addUserPopUp.groupUserID,
              method: "PUT",
              data: data,
              authToken: "Bearer " + authToken,
            })
              .then((res) => {
                if (res.status) {
                  dispatch(alertSuccess(res.message));
                  setUser(data);
                } else {
                  dispatch(alertError(res.message));
                }
                setSubmitting(false);
                handleClose();
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            axios({
              url: "/broadcast/create/group/user/" + groupID,
              method: "POST",
              data: data,
              authToken: "Bearer " + authToken,
            })
              .then((res) => {
                if (res.status) {
                  dispatch(alertSuccess(res.message));
                  setUser(data);
                } else {
                  dispatch(
                    alertError(
                      res.errorMessage +
                        " Please give unique email and phone number."
                    )
                  );
                }
                setSubmitting(false);
                handleClose();
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }}
      >
        {(dialogFormikProps) => (
          <form noValidate onSubmit={dialogFormikProps.handleSubmit}>
            <DialogContent
              style={{ height: addUserPopUp.groupUserID ? "320px" : "250px" }}
            >
              <UserDetailsForm
                dialogFormikProps={dialogFormikProps}
                groupUserId={addUserPopUp.groupUserID}
              />
            </DialogContent>

            <DialogActions style={{ padding: "12px 24px 24px" }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClose}
                    value="save"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "end" }}>
                  <Button
                    onClick={dialogFormikProps.handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={
                      !(dialogFormikProps.dirty && dialogFormikProps.isValid)
                    }
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );

  function handleClose() {
    setAddUserPopUp({
      state: false,
      groupUserId: "",
    });
  }
}

const validationSchema = Yup.object().shape({
  mobileNumber: Yup.string().required("Phone Number is required."),
});
