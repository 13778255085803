import { useState, useEffect } from "react";
import { usePermissions, useAxios, useLoader } from "../../custom hooks";
import { Loader, PageHeader } from "../../custom components";
import Form from "./integration details components/Form";
import {
  integrationDetailsObject,
  objectFromResponse,
} from "./integration details components/integrationDetailsObject";
import { Edit } from "@material-ui/icons";
import { Button } from "@material-ui/core";

export default function IntegrationDetails(props) {
  const axios = useAxios({ baseURL: "component" });
  const loader = useLoader();
  const pagePermissions = usePermissions(props.pageID);

  const [editState, setEditState] = useState(true);

  const [formData, setFormData] = useState(integrationDetailsObject);
  const [groupList, setGroupList] = useState([]);

  const integrationID = props.match.params.integrationID || "";

  useEffect(() => {
    if (Boolean(integrationID)) {
      setEditState(false);
    }
  }, [integrationID]);

  useEffect(() => {
    loader.start(2);

    axios({
      url: "/get_integration_groups?mode=ddl",
    })
      .then((response) => {
        if (response.status) {
          loader.apiComplete();
          setGroupList(response.data);
        }
      })
      .catch((error) => console.log(error));

    if (Boolean(integrationID)) {
      axios({
        url: "/get_integrations?integration_id=" + integrationID,
      })
        .then((response) => {
          if (response.status) {
            loader.apiComplete();
            setFormData(objectFromResponse(response.data));
          }
        })
        .catch((error) => console.log(error));
    } else {
      loader.apiComplete();
    }
  }, [loader, axios, integrationID]);

  return (
    <Loader>
      <PageHeader
        pageName="Integration Details"
        breadcrumbs={[{ name: "Integrations", location: "integrations" }]}
        icons={[
          pagePermissions.update && Boolean(integrationID) && (
            <Button
              variant="contained"
              color="primary"
              endIcon={<Edit />}
              onClick={() => setEditState((prevValue) => !prevValue)}
            >
              Edit
            </Button>
          ),
        ]}
      />

      <Form
        formData={formData}
        setFormData={setFormData}
        pagePermissions={pagePermissions}
        groupList={groupList}
        editState={editState}
        setEditState={setEditState}
      />
    </Loader>
  );
}
