import React from "react";
import { Formik } from "formik";
import { useAxios, useAlert } from "../../../custom hooks";
import { useHistory } from "react-router";

import { ButtonFooter } from "../../../custom components";
import validationSchema from "./validationSchema";
import { objectFromFormData } from "./botDetailsObject";
import FormDisplay from "./FormDisplay";
import AuthenticationDisplay from "./AuthenticationDisplay";
import PermissionsDisplay from "./PermissionsDisplay";
import BusinessAdminPermissions from "./BusinessAdminPermissions";

export default function Form({
  editState,
  setEditState,
  formData,
  setFormData,
  businessList,
  botPlanList,
  botServiceList,
  permissionPages,
  pagePermissions,
}) {
  const axios = useAxios();
  const history = useHistory();
  const alert = useAlert();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        var url = "/agent";
        let method = "POST";

        if (formData.botID) {
          url = url + "/" + formData.botID;
          method = "PUT";
        }

        axios({
          url: url,
          method: method,
          data: objectFromFormData(formData),
        })
          .then((res) => {
            if (res.status) {
              alert.success(res.message);
              setSubmitting(false);
              setEditState(false);

              if (method === "POST") {
                createConfig(formData.permissions, res.data.agent_id);
                history.push("/bot-details/" + res.data.agent_id);
              } else {
                setFormData(formData);
              }
            }
          })
          .catch((err) => console.log(err));
      }}
    >
      {(formikProps) => (
        <React.Fragment>
          {pagePermissions.components.form?.read && (
            <FormDisplay
              formikProps={formikProps}
              editState={editState}
              businessList={businessList}
              botServiceList={botServiceList}
              botPlanList={botPlanList}
              permissions={pagePermissions.components.form}
            />
          )}

          {pagePermissions.components.authentication?.read && (
            <AuthenticationDisplay
              formikProps={formikProps}
              editState={editState}
              permissions={pagePermissions.components.authentication}
            />
          )}

          {pagePermissions.components.permissions?.read && (
            <PermissionsDisplay
              permissions={formikProps.values.permissions}
              setValues={formikProps.setValues}
              editState={editState}
              permissionPages={permissionPages}
              componentPermissions={pagePermissions.components.permissions}
            />
          )}

          {pagePermissions.components.permissions?.read && (
            <BusinessAdminPermissions
              pagePermissions={formikProps.values.pagePermissions}
              setValues={formikProps.setValues}
              editState={editState}
              permissionPages={permissionPages}
              permissions={pagePermissions.components.permissions}
            />
          )}

          <ButtonFooter
            editState={editState}
            setEditState={setEditState}
            deletePermission={pagePermissions.delete}
            handleDelete={handleDelete}
            id={formikProps.values.botID}
            formikProps={formikProps}
          />
        </React.Fragment>
      )}
    </Formik>
  );

  function createConfig(permissions, botID) {
    if (
      permissions.adminPermissions?.conversations_page?.access ||
      permissions.managerPermissions?.conversations_page?.access ||
      permissions.billerPermissions?.conversations_page?.access
    ) {
      axios({
        url: "/livechat/config/" + botID,
        method: "POST",
        data: {
          no_of_member_allowed: 10,
          routing_algorithm: "",
          auto_end_livechat_time: 15,
        },
      });
    }

    if (
      permissions.adminPermissions?.appointments_page?.access ||
      permissions.managerPermissions?.appointments_page?.access ||
      permissions.billerPermissions?.appointments_page?.access
    ) {
      axios({
        url: "/config/" + botID,
        method: "POST",
        data: {
          app_prefix_id: formData.botName.slice(0, 3).toUpperCase(),
          advance_booking_days: 15,
        },
      });
    }
  }

  function handleDelete() {
    axios({
      url: "/agent/" + formData.botID,
      method: "PATCH",
      data: {
        is_deleted: true,
      },
    })
      .then((response) => {
        if (response.status) {
          alert.success("Bot Deleted!");
          history.push("/bots");
        }
      })
      .catch((error) => console.log(error));
  }
}
