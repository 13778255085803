import React from "react";
import Paper from "@material-ui/core/Paper";

export default function AgreementLayout(props) {
  return (
    <React.Fragment>
      <Paper
        id="agreement"
        style={{
          padding: "20px",
          fontFamily: "Roboto, sans-serif",
          textAlign: "justify",
          wordBreak: "break-word",
        }}
        elevation={3}
      >
        <h2 style={{ textAlign: "center" }}>CHATBOT AGREEMENT</h2>
        <br />
        <h3 style={{ textAlign: "center" }}>Between</h3>
        <br />
        <br />
        <h3 style={{ textAlign: "center" }}>
          TAVROI RESEARCH PRIVATE LIMITED NEW DELHI, INDIA
        </h3>
        <br />
        <br />
        <h3 style={{ textAlign: "center" }}>And</h3>
        <br />
        <br />
        <h3 style={{ textAlign: "center" }}>
          {props.companyName || "(Company Legal Name)"}
        </h3>
        <br />
        <br />
        <br />
        <p style={{ textAlign: "center" }}>
          <strong>Dated: </strong>
          {props.date || "(Date)"}
        </p>
        <br />
        <br />
        <br />
        <p>
          THIS AGREEMENT is made on {props.date || "(Date)"} at New Delhi,
          India.
        </p>
        <br />
        <br />
        <h4>Between</h4>
        <p>
          <strong>M/s TAVROI RESEARCH PRIVATE LIMITED</strong>, a Company within
          the meaning of the Companies Act 2013 having its registered office at
          DB-113 D, DDA Flats, Hari Nagar Clock Tower, New Delhi, 110064
          (Hereinafter referred to as ‘DolphinChat’ or ‘Company’) which
          expression shall, wherever the context so admits, mean and include its
          successors, assignees, etc. of the ONE PART
        </p>
        <h4>And</h4>
        <p>
          <strong>{props.companyName || "(Company Name)"}</strong>,{" "}
          {props.companyAddr || "(Company Address)"} (hereinafter referred to as
          “Customer”) which expression shall, wherever the context so admits,
          mean and include its successors, assignees, etc. of the SECOND PART.
        </p>
        <br />
        <p>
          (“DolphinChat or “Company” and the “Customer” hereinafter are referred
          individually as ‘party’ and collectively as ‘parties’)
        </p>
        <br />
        <p style={{ paddingLeft: "30px" }}>
          <strong>(A)</strong> WHEREAS <strong>DolphinChat</strong> is involved
          in the development Chatbot & Voice Solutions through Artificial
          Intelligence (AI) platform for seamless communication between
          businesses and consumers.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          <strong>(B)</strong> WHEREAS <strong>Customer</strong> is{" "}
          {props.companyDesc || "(Company Description)"}
        </p>
        <p style={{ paddingLeft: "30px" }}>
          <strong>(C)</strong> AND WHEREAS DolphinChat and the Customer have
          desired to enter into this Agreement for mutual business interests.
          The Customer has desired to avail the services of the DolphinChat and
          the DolphinChat has agreed to provide its services on the following
          broad terms.
        </p>
        <br />
        <p>NOW, THEREFORE, THIS AGREEMENT WITNESSETH AS UNDER:</p>
        <br />
        <h4>1. DEFINITIONS</h4>
        <p style={{ paddingLeft: "30px" }}>
          <strong>(i) Chatbot and/or Chatbot Solution</strong> means a
          conversational computer program with Artificial Intelligence abilities
          for conducting a one-to-one dialogue via auditory or textual methods.
          These programs are designed to convincingly simulate a human behaviour
          as a conversational partner. Chatbots shall be used in dialog systems
          for various practical purposes including customer service or
          information acquisition.
          <br />
          <br />
          <strong>(ii) DolphinChat or Platform</strong> means the Conversational
          AI platform developed by Tavroi Research Private Limited. DolphinChat
          platform enables the development of AI enabled chatbots and voice
          assistants. The DolphinChat platform comprises of a Chatbot dashboard
          as well.
          <br />
          <br />
          <b>(iii) DolphinChat Dashboard</b> means a Chatbot dashboard provided
          to the customer along with the chatbot solution that helps the
          customer to view the user data and previous conversations along with
          the analytics, visualization for the customer to gain deeper insights
          about its users. The Chatbot dashboard includes a functionality for
          the customer to send broadcasts/notifications to the chatbot users for
          promotional as well as transactional messages.
          <br />
          <br />
          <b>(iv) Confidential Information means:</b>
          <br />
          <br />
          (i) any information disclosed by the parties to each other for and on
          behalf of each other during the Term or at any time before the
          termination of this Agreement whether disclosed in writing, orally or
          otherwise that at the time of disclosure:
          <br />
          (ii) was marked or described as "confidential"; or
          <br />
          (iii) should have been reasonably understood by the parties to be
          confidential in relation to information which is commercially
          sensitive or of a secret nature to any and all aspects of the business
          and financing.;
          <br />
          (iv) the Customer Data;
          <br />
          (v) Trade secrets of either of the parties
          <br />
          <br />
          <b>(v) Customer Data</b> means all data inclusive of all electronic
          data or information submitted by Customer and the Users to the
          service, works and materials: uploaded to or stored on the Platform by
          the Customer; transmitted by the Platform at the instigation of the
          Customer; supplied by the Customer to the DolphinChat for uploading
          to, transmission by or storage on the Platform; or generated by the
          Platform as a result of the use of the Hosted Services by the Customer
          (including analytics data relating to the use of the Platform and
          server log files);
          <br />
          <br />
          <b>(vi) Force Majeure Event</b> means an event, or a series of related
          events, that is outside the reasonable control of the party affected
          (including failures of the internet or any public telecommunications
          network, hacker attacks, denial of service attacks, virus or other
          malicious software attacks or infections, power failures, industrial
          disputes affecting any third party, changes to the law and
          regulations, government acts or omissions disasters, explosions,
          fires, floods, riots, epidemic, pandemic, terrorist attacks and wars,
          civil disobedience);
          <br />
          <br />
          <b>(vii) Hosting Services</b> means applications, IT infrastructure
          components or functions that are accessed from external service
          providers, typically through an internet connection.
          <br />
          <br />
          <b>(viii) Hosted Services Defects</b> means a defect, error or bug in
          the appearance, operation, functionality or performance of the chatbot
          due to failure of the hosting platform (3rd party hosting platform or
          customer’s server) or any incompatibility raised due to network
          failure, or act or omission of the customer or any person authorized
          by the customer for hosting platform.
          <br />
          <br />
          <b>(ix) Personal Data</b> means any information relating to an
          identified or identifiable individual where such information is
          contained with Customer Data and is protected similarly as personal,
          personal information or personally identifiable information under
          applicable Data Protection Laws.
          <br />
          <br />
          <b>(x) Terms of Service or Services</b> mean the services of automated
          conversations and broadcasts through Conversational Artificial
          Intelligence (AI) platform, DolphinChat;
          <br />
          <br />
          <b>(xi) User</b> means every person who interacts with the chatbot
          directly or indirectly any of the available Platforms, either using
          their e-mail address, phone number, social network profile or
          anonymously;
          <br />
          <br />
          <b>(xii) User Acceptance Testing</b> means the testing activities
          carried at the customer end upon the completion of the development,
          integration and deployment of the chatbot solution.
          <br />
          <br />
          <b>(xiii) User’s privacy & policy</b> means the opening content and
          introduction steps together with the applicable privacy policy and all
          information about how the personal data is managed and controlled
          while using the Chatbots made available on the Platform. Users shall
          provide their consent – which is freely given, specific, informed and
          unambiguous indication of the data subject’s wishes by which he or
          she, by a statement or by a clear affirmative action, signifies
          agreement to the processing of personal data relating to him or her –
          to the processing of personal data as well as Users shall be provided
          the relevant privacy policy when start using the Chatbots. From time
          to time, users shall be informed about any updates as for the relevant
          privacy policy by the customer by checking DolphinChat’ website whose
          link may be provided on customer websites by customer, it shall not
          mean that the customer is endorsing or has approved the site’s policy,
          and customer shall not be liable for any damages, or implications
          caused by visiting DolphinChat website.
        </p>
        <br />
        <h4>2. SERVICE AGREEMENT</h4>
        <p style={{ paddingLeft: "30px" }}>
          (i) DolphinChat shall provide the services of the design, development,
          integration, deployment, launch, support and maintenance of chatbot
          solution that solves the business challenges of the customer subject
          to the following terms and conditions.
          <br />
          <br />
          (ii) DolphinChat shall provide the Chatbot for, non-exclusive,
          non-transferable, restricted personal use to the customer and the
          chatbot shall be used for no other purpose without the prior written
          consent of DolphinChat.
        </p>
        <br />
        <h4>3. GENERAL TERMS FOR CUSTOMER FOR USING CHATBOTS</h4>
        <p style={{ paddingLeft: "30px" }}>
          (i) The chatbot solution shall be further launched for customer’s
          target consumers (users);
          <br />
          <br /> (ii) Customers may display information about its own
          advertisements and special offers as well as those of its contracted
          partners;
          <br />
          <br /> (iii) DolphinChat may make use of the company name/name and
          logo as a reference for marketing or promotional purposes on
          DolphinChat website and in other public or private communications with
          DolphinChat existing or potential developers and customers, subject to
          the standard trademark usage guidelines as advised to DolphinChat;
          <br />
          <br /> (iv) The Customer shall provide DolphinChat with instructions
          on what to do with the content or information the Users submit to the
          Platform to enable or disable third party integrations, manage
          permissions, retention and export settings;
          <br />
          <br /> (v) The Customer shall inform the users about the policies for
          using the Chatbots, including processing of personal data, on or
          before giving access of chatbot to the users;
          <br />
          <br /> (vi) DolphinChat reserve the right to update the Terms of
          Services and all applicable policies at any time in order to only
          improve the DolphinChat from time to time with a written notice to the
          Customer of seven working days in the interest of the Customer.
          DolphinChat and Customer shall associate in updating the applicable
          privacy policy of the Chatbot. Customer shall inform such changes as
          updated policies to the Users.
          <br />
          <br /> (vii) Customer shall not be entitled to change, lease, lend,
          sell, divide or decrypt in whole or in part the Chatbot solution or
          information learnt while using it. (viii) Customer shall not be
          entitled to decrypt the Chatbot solution or attempt to obtain or
          otherwise derive source code, trade secrets, or know-how or any
          portion thereof.
          <br />
          <br /> (ix) Customer shall not acquire any intellectual property right
          or the content accessible on and originating from Chatbot. (x)
          Customer shall not be entitled to modify, reverse engineer or
          otherwise alter the Chatbot.
          <br />
          <br /> (xi) Customer shall not use the chatbot to defame, abuse,
          harass, stalk, threaten, or otherwise violate the legal rights (such
          as rights of privacy and publicity) of others.
          <br />
          <br /> (xii) Customer shall not engage in activity that is false or
          misleading or that is harmful to others (including children), or the
          Chatbot.
          <br />
          <br /> (xiii) Customer shall not share inappropriate content,
          advertising, spam, spyware or malware.
          <br />
          <br /> (xiv) Customer shall not gain (or attempt to gain) unauthorized
          access to any service, data, account or network by any means;
          <br />
          <br /> (xv) Customer is prohibited from including misleading
          statements about Chatbot functionality, performance, origin or Data
          use.
          <br />
          <br /> (xvi) Customer is prohibited from transmitting any viruses or
          other code that may damage, detrimentally interfere with,
          surreptitiously intercept or expropriate any system or Data.
          <br />
          <br /> (xvii) Customer shall not distribute anything containing a
          computer virus or any code, file or software program intended to
          interrupt, destroy or limit the functionality of the Chatbot or its
          infrastructure.
          <br />
          <br /> (xviii) The Customer shall be a data controller, DolphinChat is
          only the data processor on behalf of the customer.
          <br />
          <br /> (xix) DolphinChat and Customer engaged in the operation of the
          Chatbot and providing the services available through the Chatbot may
          learn Users personal data only in case it is absolutely necessary in
          order to achieve the purpose of the use of the data in pursuant to
          terms of the conditions of the agreement herein.
        </p>
        <br />
        <h4>4. TERMS AND CONDITIONS APPLICABLE TO THE USERS</h4>
        <p style={{ paddingLeft: "30px" }}>
          (i) Users can use the Chatbots for the purposes permitted by the Terms
          of Service applicable as per this Agreement hereto;
          <br />
          <br /> (ii) DolphinChat and the Customer are rightful to use any
          content that Users submit via the Chatbots as far as it is necessary
          for DolphinChat and Customer to provide the service to Users; <br />
          <br />
          (iii) Users shall give consent for the service communications and
          administrative messages to be sent to the contact details which were
          given by the Users at the time of registration.
          <br />
          <br /> (iv) Users shall give consent to DolphinChat and the Customer
          to handle their personal data given by Users through the Chatbots.
          Users shall use images, signs, logos, brands or emblems used in the
          Chatbot. <br />
          <br />
          (v) Users shall be responsible for maintaining the privacy and
          security of their account.
          <br />
          <br /> (vi) DolphinChat and Customers shall not be held liable for any
          damage or loss that may result from Users failure to protect their
          login information, including your password.
          <br />
          <br /> (vii) Users shall check out the latest version of the Privacy
          policy and Terms of Service from DolphinChat’ website. <br />
          <br />
          (viii) Upon making the use of the website or any feature on the
          platform where users have chosen to submit their email address or
          mobile number or any other contact details or personal information the
          users understand, agree and authorize Customer and DolphinChat to
          receive calls, messages, emails from them with respect to the service
          they have to offer.
          <br />
          <br /> (ix) Users understand, and agree that the Customer may also
          send notifications and reminders with regards to the meetings booked
          by the users for the services that users may be using on the
          Customer’s Website.
          <br />
          <br /> (x) Users shall ensure that the platforms of the Customer and
          DolphinChat are being used in compliance with the applicable laws of
          the nation.
          <br />
          <br /> (xi) Users shall not use the website of the Customer and
          DolphinChat or any of the features provided by their platform than any
          other purposes which are intended and referred under this Agreement.
        </p>
        <br />
        <h4>5. RESPONSIBILITIES OF DOLPHINCHAT</h4>
        <p>DolphinChat shall:</p>
        <p style={{ paddingLeft: "30px" }}>
          (i) provide the design, development, integration, deployment, testing,
          training and supporting maintenance services of the chatbot along with
          DolphinChat Dashboard;
          <br />
          <br />
          (ii) make the Chatbot solutions available to the Customer and its
          authorized End Users as described in the Project Requirements, more
          particularly described in Schedule I hereto;
          <br />
          <br />
          (iii) not use or processing Personal Data for any purpose without
          Customers’ and Users’ prior permission, through the customer or
          through the users when users visit the official website of the
          Customer to check upon the terms of service or relevant applicable
          policies updates;
          <br />
          <br />
          (iv) always work in furtherance to increase and improvise materially
          the functionality of Chatbot Solutions until this Chatbot Agreement is
          in force between DolphinChat and Customer;
          <br />
          <br />
          (v) use commercially reasonable efforts to make and provide with the
          Chatbot Solutions available twenty hours a day, seven days a week,
          excluding planned downtime and having regard and between DolphinChat
          and the Customer;
          <br />
          <br />
          (vi) make sure that when the chatbot is under maintenance which
          excludes the development of alternations, changes or enhancements to
          the originally contracted purposes.
          <br />
          <br />
          (vii) make sure that at the time of a deployment of a new chatbot
          version, a prior written notice is being given to the Customer so as
          to prevent an existing system failure at the end of the Customer.
          <br />
          <br />
          (viii) implement necessary protocols for security and data protection;
          <br />
          <br />
          (ix) not be responsible for errors occurred due to hosted services
          defects.
          <br />
          <br />
          (x) make available such further know-how to the Customer relating to
          the development and evaluation of the product which is reasonably
          necessary and useful for such development of the product herein
          question by the it.
          <br />
          <br />
          (xi) be solely responsible as per the clause (i), of this Section, and
          shall provide for the supervision, operation, and maintenance of the
          chatbot to the marketing world class standards during the entire term
          of this Agreement.
        </p>
        <br />
        <h4>6. OBLIGATIONS OF THE CUSTOMER</h4>
        <p style={{ paddingLeft: "30px" }}>
          (i) The customer shall not sell the chatbot, license and services
          provided by DolphinChat further for any commercial purposes.
          <br />
          <br />
          (ii) The Customer hereby grants to the DolphinChat a non-exclusive
          license to copy, reproduce, store, distribute, publish, export, adapt,
          edit and translate the Customer Data to the extent reasonably required
          for the performance of the DolphinChat' obligations and the exercise
          of the DolphinChat rights under this Agreement. The Customer also
          grants to the DolphinChat the right to sub-license these rights to its
          hosting, connectivity and telecommunications services by seeking a
          prior written approval from it.
          <br />
          <br />
          (iii) The Customer warrants to the DolphinChat that the Customer Data
          or the Customer Data when used by the DolphinChat in accordance with
          this Agreement shall not infringe the Intellectual Property Rights or
          other legal rights of any person.
          <br />
          <br />
          (iv) The Customer shall enable the DolphinChat to create a back-up
          copy of Users data on a daily basis so as to retain customer and user
          data.
          <br />
          <br />
          (v) The Customer warrants to the DolphinChat that it has the legal
          right to disclose all User’s Personal Data that it does in fact
          disclose to the DolphinChat under or in connection with this
          Agreement.
          <br />
          <br />
          (vi) Customer shall inform its users about the terms and conditions of
          the users mentioned in Clause 4 including the User’ Privacy and
          Policy.
          <br />
          <br />
          (vii) Customer shall inform DolphinChat about its list of competitors
          on a time to time basis.
        </p>
        <br />
        <h4>7. OBLIGATIONS OF DOLPHINCHAT</h4>
        <p>DolphinChat shall:</p>
        <p>(i)</p>
        <p style={{ paddingLeft: "30px" }}>
          (a) keep the Customer Confidential Information strictly confidential;
          <br />
          <br />
          (b) not disclose the Customer Confidential Information to any person
          without the Customer's prior written consent.
          <br />
          <br />
          (c) use the same degree of care to protect the confidentiality of the
          Customer Confidential Information as the DolphinChat uses to protect
          the DolphinChat' own confidential information of a similar nature,
          with a reasonable degree of care;
          <br />
          <br />
          (d) not use any of the Customer Confidential Information for any
          purpose other than for a permitted purpose.
        </p>
        <p>
          (ii) Notwithstanding Clause 7(i), the DolphinChat may disclose the
          Customer Confidential Information to the DolphinChat' officers,
          employees, professional advisers, insurers, agents and subcontractors,
          who have a need to access the Customer Confidential Information for
          the performance of their work with respect to this Agreement and who
          shall in turn be bound to protect the confidentiality of the Customer
          Confidential Information.
        </p>
        <p>
          (iii) Clause 7(i) imposes no obligations upon the DolphinChat with
          respect to Customer Confidential Information that:
        </p>
        <p style={{ paddingLeft: "30px" }}>
          (a) is known to the DolphinChat before disclosure under this Agreement
          and is not subject to any other obligation of confidentiality; <br />
          <br />
          (b) is or becomes publicly known through no act or default of the
          DolphinChat; or <br />
          <br />
          (c) is obtained by the DolphinChat from a third party in circumstances
          where the DolphinChat has no reason to believe that there has been a
          breach of an obligation of confidentiality.
        </p>
        <p>
          (iv) The restrictions in this Clause 7(i) do not apply to the extent
          that any Customer Confidential Information is required to be disclosed
          by any law or regulation, by any judicial or governmental order or
          request, or pursuant to disclosure requirements relating to the other
          laws. <br />
          <br />
          (v) The provisions of this Clause 7(i) shall continue in force for a
          period of 5 years following the termination of this Agreement. <br />
          <br />
          (vi) DolphinChat shall not engage any third party to process the
          Customer Personal Data without the prior written consent of the
          Customer in writing, however, DolphinChat shall have an authority to
          engage as sub-processors with respect to Customer Personal Data,
          information of which shall be given subjecting it to the approval of
          the Customer <br />
          <br />
          (vii) DolphinChat shall, at the choice of the Customer, delete or
          return all of the Users’ Personal Data to the Customer after the
          provision of services relating to the processing, and shall delete
          existing copies save to the extent that requires storage of the
          relevant Personal Data.
        </p>
        <br />
        <h4>8. CONFIDENTIALITY</h4>
        <p>
          The obligations generated under this Section shall survive the
          suspension or termination of this Agreement.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          (i) The Confidential information refers to any data or information
          relating to the business of the parties which would reasonably be
          considered to be proprietary to a party and that is not generally
          known in the industry of the parties, and where the release of the
          Confidential Information could be reasonably be expected to cause harm
          to a party. <br />
          <br />
          (ii) DolphinChat agrees to not disclose or use any Confidential
          Information for any purposes which it has obtained during the course
          of implementation of this Agreement except as authorized in writing by
          the Customer to any third party. Any such failure to obey shall
          attract termination of this Agreement. <br />
          <br />
          (iii) All the information provided by either Parties whether written
          or oral is confidential information which is not to be disclosed
          whether it was provided before or after the date of this agreement.
          <br />
          <br />
          (iv) Such information should only be used in pursuant to this
          Agreement.
        </p>
        <br />
        <h4>9. INTELLECTUAL PROPERTY</h4>
        <p style={{ paddingLeft: "30px" }}>
          (i) The Customer hereby does not grant to the DolphinChat worldwide a
          royalty free right to use any data associated with the performance of
          the Customer’s website or any application to improve its existing
          platform. DolphinChat shall be obliged to return to the Customer any
          content, or any media containing content or other materials provided
          by the Customer under this Agreement whether during or after the term
          of this Agreement and shall be solely of the Customer.
          <br />
          <br />
          (ii) Except for the rights expressly granted in this Agreement, either
          party is restricted to make transfer of Intellectual Property rights
          between each other or any other third party.
        </p>
        <br />
        <h4>10. PAYMENT OF FEES, CHARGES & PAYMENT PROCESSES</h4>
        <p style={{ paddingLeft: "30px" }}>
          (i) The Customer shall pay to the DolphinChat the fees, charges and
          other commercials as per Schedule-II hereto.
          <br />
          <br />
          (ii) The DolphinChat shall issue invoice for the fees and Charges to
          the Customer upon the completion of the User Acceptance Testing at the
          customer’s end. DolphinChat shall issue the invoice for the
          monthly/yearly subscription on the first day of each calendar month.
          <br />
          <br />
          (iii) The Customer shall pay the amount mentioned in Invoices to the
          DolphinChat within the period of seven days from date of receipt of
          Invoice.
          <br />
          <br />
          (iv) The Customer shall pay the amount of invoice by bank transfer
          using such payment details as are notified by the DolphinChat to the
          Customer from time to time.
          <br />
          <br />
          (v) If the Customer failed to pay any amount of invoice to the
          DolphinChat on due dates the Customer shall pay interest on the
          overdue amount at the rate of 8% per annum which interest shall accrue
          daily basis until the date of actual payment and be compounded
          monthly. This payment of interest shall be payable as the liquidated
          damages and not as penalty.
          <br />
          <br />
          (vi) Any customization request from the customer post the Go-Live of
          the chatbot solution shall be charged separately as per the hourly
          rate of development resources.
        </p>
        <br />
        <h4>11. SUPPORT AND MAINTENANCE</h4>
        <p>
          Support and Maintenance shall be provided by DolphinChat to Customer
          for all the services delivered, in the duration of this agreement. Any
          technical issues or bugs which may arise shall be rectified by
          DolphinChat. DolphinChat shall also provide monthly updates in the
          form of performance and usage reports along with monthly NLP (Natural
          Language Processing) training of the chatbot. DolphinChat shall
          provide updates and upgrades for product enhancement. <br />
          <br /> On the basis of nature of issues arising, the Priority
          Categories have been defined as under:
        </p>
        <table
          style={{
            textAlign: "left",
            fontSize: "14px",
            borderCollapse: "collapse",
          }}
        >
          <tbody>
            <tr style={{ color: "#fff", background: "#1f3863" }}>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Priority Category
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Nature of Issues generally covered
              </th>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", background: "#ffc000" }}>
                <b> Priority 1</b>
              </td>
              <td style={{ border: "1px solid black" }}>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Service is completely unavailable (Reply Messages and
                    Notifications are not being sent or received).
                  </li>
                  <li>No workaround is available.</li>
                  <li>System has repeatedly crashed and hung</li>
                  <li>
                    Delayed response from platform leading to high transaction
                    time out
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", background: "#ffc000" }}>
                <b> Priority 2</b>
              </td>
              <td style={{ border: "1px solid black" }}>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>Service is extremely slow.</li>
                  <li>
                    System is available but is experiencing problems
                    periodically.
                  </li>
                  <li>Reply Messages and Notifications get delivered late</li>
                  <li>
                    Intermittent and/or infrequent system crashes or hangs
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black", background: "#ffc000" }}>
                <b> Priority 3</b>
              </td>
              <td style={{ border: "1px solid black" }}>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>“How-to” questions</li>
                  <li> Some delivery reports are not coming through</li>
                  <li>User interface errors</li>
                  <li>General technical queries</li>
                  <li>Configuration or set-up questions</li>
                  <li>Log request</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          The escalation path for the above priority categories is given in
          Schedule-III Hereto. The contact information is given in Schedule-IV
          Hereto.
        </p>
        <br />
        <h4>12. PROCESS OF SERVICES</h4>
        <p>
          The following process shall be followed for the various stages and
          areas in the development of the chatbot solution.
        </p>
        <table
          style={{
            textAlign: "left",
            fontSize: "15px",
            borderCollapse: "collapse",
          }}
        >
          <tbody>
            <tr style={{ color: "#fff", background: "#1f3863" }}>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Sr No
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Area
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Action
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Process
              </th>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>1.</td>
              <td style={{ border: "1px solid black", background: "#ffc000" }}>
                Design and Specification
              </td>
              <td style={{ border: "1px solid black" }}>
                Discussion, Planning, Design
              </td>
              <td style={{ border: "1px solid black" }}>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>Objectives & Functionalities</li>
                  <li>Discussion on Conversation Flow</li>
                  <li>Plan Review</li>
                  <li>Architectural Design</li>
                  <li>Conversational Design</li>
                  <li>Interface Design, Customization (Dashboard)</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>2.</td>
              <td style={{ border: "1px solid black", background: "#ffc000" }}>
                Project management
              </td>
              <td style={{ border: "1px solid black" }}>Development</td>
              <td style={{ border: "1px solid black" }}>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>Front-end Development (UI/UX)</li>
                  <li>Chatbot Development (NLP)</li>
                  <li>Database Setup</li>
                  <li>Dashboard Development</li>
                  <li>Integrations & NLP Training</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>3.</td>
              <td style={{ border: "1px solid black", background: "#ffc000" }}>
                Deployment and Go-Live
              </td>
              <td style={{ border: "1px solid black" }}>Delivery</td>
              <td style={{ border: "1px solid black" }}>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>Conversation Design Testing</li>
                  <li>Entities and Parameters Testing</li>
                  <li>Unit Testing</li>
                  <li>Integration Testing</li>
                  <li>Load Testing</li>
                  <li>Beta Testing</li>
                  <li>User Acceptance Testing (UAT)</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <h4>13. SEVERABILITY</h4>
        <p>
          If any provision or part of this Agreement is held to be illegal,
          invalid or unenforceable in whole or in part, under any applicable
          laws, such provision or part shall be deemed not to form part of this
          Agreement, but the legality, validity and enforceability of the
          remainder of this Agreement shall not be affected.
        </p>
        <br />
        <h4>14. WAIVER</h4>
        <p>
          No failure, delay or partial exercise on the part of either Party
          hereto of any right or remedy under this Agreement shall be construed
          or operate as a waiver hereof unless made in writing signed by the
          waiving party and such waiver shall be effective only with respect to
          the specific obligation stated aforesaid in this Agreement. The waiver
          by either Party of a breach of any provision of this Agreement by the
          other Party shall not operate or be construed as a waiver of any
          subsequent breach of the same provision or another provision of this
          Agreement. The rights and remedies provided in this Agreement are
          cumulative and are not exclusive of any rights or remedies provided by
          law.
        </p>
        <br />
        <h4>15. LIMITATION OF LIABILITY</h4>
        <p>
          In no event shall DolphinChat have any liability arising out of or
          related to the Terms of Service to Users, Customer or to any third
          party for any lost profits or revenues or for loss of income, loss of
          data or financial loss attributable to the Chatbot or any indirect,
          special, incidental, consequential, cover or punitive damages
          exceeding the total amount Customer paid for our Services based on the
          Chatbot Agreement.
        </p>
        <br />
        <h4>16. FORCE MAJEURE</h4>
        <p>
          No party be liable by reason of any failure or delay in the
          performance of its obligations on account of Force Majeure.
        </p>
        <p style={{ paddingLeft: "30px" }}>
          (i) If a party is wholly or partially precluded from complying with
          its obligations under this agreement by Force Majeure, then that
          party’s obligation to perform in accordance with this Agreement will
          be suspended for the duration of the Force Majeure.
          <br />
          <br />
          (ii) As soon as practicable after an event of Force Majeure arises,
          the party affected by the Force Majeure must notify the other party to
          the extent to which the notifying party is unable to perform its
          obligation under this Agreement.
        </p>
        <br />
        <h4>17. ASSIGNMENT</h4>
        <p>
          Neither party may assign or delegate any of its rights or obligations
          hereunder, without the prior written consent of the other party.
        </p>
        <br />
        <h4>18. TERM AND TERMINATION</h4>
        <p>
          (i) The duration of this Agreement shall be for a period of twelve
          (12) months.
          <br />
          <br />
          (ii) Either party hereto may terminate this Agreement without cause at
          any time, upon at least giving an advance written notice of sixty (60)
          days.
          <br />
          <br />
          (iii) The aggrieved party may terminate the Agreement after giving
          thirty (30) days written notice in case of any breach of any of the
          terms and conditions of the agreement, if the breach has not been
          cured within thirty working (30) days of time.
          <br />
          <br />
          (iii) Upon any termination, Customer shall pay any unpaid fees/charges
          remaining unpaid covering the term of services rendered. In no event
          shall any termination relieve Customer of the obligation to pay any
          fees payable to the DolphinChat for the current period and /or period
          prior to the effective date of termination.
        </p>
        <br />
        <h4>19. GOVERNING LAW AND DISPUTE RESOLUTION</h4>
        <p>
          (i) Governing Law: This agreement shall be governed by the and
          construed in accordance with the laws of India.
          <br />
          <br />
          (ii) Amicable Resolution of dispute: If any dispute or issue arises in
          relation to or in connection with this Agreement or any in respect of
          the validity, interpretation, implementation or alleged breach of any
          provision of this Agreement between the Parties, the Disputing parties
          shall attempt to first resolve such dispute or claim through
          discussions amongst them.
          <br />
          <br />
          (iii) Arbitration: If the Dispute is still not resolved through
          discussions between the persons appointed for the purpose mentioned
          under the sub clause 2 of Section 19 of this Agreement, then any
          Disputing Party shall submit the claim or Dispute to be finally
          settled by arbitration in accordance with the Arbitration and
          Conciliation Act, 1996 and/or the rules framed thereunder (“The
          Arbitration Act including the latest Amendment’s”).
          <br />
          <br />
          (iv) The Sole Arbitrator shall be appointed by Chief Justice of Delhi
          High Court. The proceedings of Arbitration shall be held in Delhi in
          English language. The award of the Arbitrator shall be final and
          binding upon the Parties. The Parties shall be entitled to seek
          interim relief from the courts of New Delhi.
          <br />
          <br />
          (v) Enforcement and Costs: Judgement upon any arbitral award rendered
          hereunder may be entered in any court at Delhi, or application may be
          made to such court for a judicial acceptance of the award and an order
          of enforcement, as the case may be. The Arbitral award shall be
          substantiated in writing and the arbitrator shall also have the right
          to decide on the costs of arbitral proceedings. Any award made by the
          arbitrator shall be final and binding on each of the Parties that were
          parties to the Dispute.
        </p>
        <br />
        <h4>20. WARRANTY</h4>
        <p>
          Each Party hereby represents and warrants that the following items
          with regard to them are in good order and compliant with all
          applicable law and regulations: the organization and standing; conduct
          of legitimate business activities, corporate power and authorization;
          governmental approvals/licenses; proprietary information agreements
          with employees; and that there have been no restrictions by any
          regulatory authority in conducting the business.
        </p>
        <br />
        <h4>21. INDEMNITY</h4>
        <p>
          Either Party agrees to indemnify, defend and hold harmless the other
          party, its Affiliates directors, officers, agents, employees from and
          against any and all losses, liabilities, claims, damages, demands,
          suits, actions, proceedings, costs and expenses in connection with or
          arising out of
        </p>
        <p style={{ paddingLeft: "30px" }}>
          (a) breach of or violation of or non-compliance with any of the
          obligation, warranties, representation, covenants and undertakings
          herein, <br />
          <br />
          (b) violation, breach or non-compliance with any Applicable Laws or
          relating to on any claim of the violation of the infringement of any
          intellectual property rights <br />
          <br />
          (c) or on accounts of fraud, misconduct, negligence,
          misrepresentation.
        </p>
        <br />
        <h4>22. NOTICES</h4>
        <p>
          All notices required to be served by either of the Parties hereto upon
          the other shall be deemed to have been duly and effectually served if
          delivered by hand or addressed by overnight courier at the following
          addresses and such service shall be deemed to have been effected in
          the case of delivery by hand, on the date of delivery and in case of
          delivery by overnight courier, on the date the notice is received by
          the following addresses:
        </p>
        <p style={{ paddingLeft: "30px" }}>
          (i) <b>To Customer</b>: At the following address of the Customer
          mentioned below
        </p>
        <p style={{ paddingLeft: "60px" }}>
          <b>Contact Number - </b>{" "}
          {props.companyNo || "(Company Contant number)"}
          <br />
          <b>Address - </b> {props.companyAddr || "(Company Address)"}
          <br />
          <b>Email ID - </b> {props.companyEmail || "(Company Email)"}
        </p>

        <p style={{ paddingLeft: "30px" }}>
          (i) <b>To DolphinChat</b>: At the Following Regd Office of mentioned
          below
        </p>
        <p style={{ paddingLeft: "60px" }}>
          <b>Contact Number - </b> +91-9818889720, +91-8989012181
          <br />
          <b>Address - </b> DB Block - 113 D, DDA LIG Flats, Hari Nagar Clock
          Tower, New Delhi – 110064
          <br />
          <b>Email ID - </b> ashish@dolphinchat.ai, kusha@dolphinchat.ai
        </p>
        <br />
        <h4>23. AMENDMENT</h4>
        <p>
          Any amendment or modification of this Agreement by either Party in
          accordance with this Agreement shall be binding, if it is signed, by
          each party, in writing or by an authorized representative of each
          Party.
        </p>
        <br />
        <h4>24. RELATIONSHIP BETWEEN THE PARTIES</h4>
        <p>
          The Parties are independent businesses and are not partners, principal
          and agent, or employer and employee, or in any other relationship of
          trust with each other.
        </p>
        <br />
        <h4>SCHEDULE-I (PROJECT REQUIREMENTS)</h4>
        <table
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: "15px",
            borderCollapse: "collapse",
          }}
        >
          <tbody>
            <tr style={{ color: "#fff", background: "#1f3863" }}>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Sr No
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Requirement
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Description
              </th>
            </tr>
            {props.projects &&
              props.projects.map((project, index) => (
                <tr key={index}>
                  <td
                    style={{
                      border: "1px solid black",
                      background: "#ffc000",
                      paddingLeft: "10px",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{ border: "1px solid black", paddingLeft: "10px" }}
                  >
                    {project.requirement}
                  </td>
                  <td
                    style={{ border: "1px solid black", paddingLeft: "10px" }}
                  >
                    {project.desc}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <br />
        <h4>SCHEDULE-II (COMMERCIALS)</h4>
        <p>
          The commercials as per the requirements of this project comprises of
          the following three components –
        </p>
        <h5>
          1. DOLPHINCHAT – CHANNEL ONE-TIME PRICING MODEL + NOTIFICATION PRICING
        </h5>
        <table
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: "15px",
            borderCollapse: "collapse",
          }}
        >
          <tbody>
            <tr style={{ color: "#fff", background: "#1f3863" }}>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Channel
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                One Time Setup
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Monthly Reccuring
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Per Notification
              </th>
            </tr>
            {props.oneTimePricings &&
              props.oneTimePricings.map((oneTimePricing, index) => (
                <tr key={index}>
                  <td
                    style={{
                      border: "1px solid black",
                      background: "#ffc000",
                      paddingLeft: "10px",
                    }}
                  >
                    {oneTimePricing.channel}
                  </td>
                  <td
                    style={{ border: "1px solid black", paddingLeft: "10px" }}
                  >
                    {oneTimePricing.one_time_setup}
                  </td>
                  <td
                    style={{ border: "1px solid black", paddingLeft: "10px" }}
                  >
                    {oneTimePricing.monthly_recurring}
                  </td>
                  <td
                    style={{ border: "1px solid black", paddingLeft: "10px" }}
                  >
                    {oneTimePricing.per_notification}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <br />
        <h5>2. DOLPHINCHAT - CHATBOT RECURRING PRICING MODEL (MONTHLY)</h5>
        <table
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: "15px",
            borderCollapse: "collapse",
          }}
        >
          <tbody>
            <tr style={{ color: "#fff", background: "#1f3863" }}>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Sr No
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Feature
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                {props.commercialPlan || "(Commerical Plan)"}
              </th>
            </tr>
            {props.reccuringPricings &&
              props.reccuringPricings.map((reccuringPricing, index) => (
                <tr key={index}>
                  <td
                    style={{
                      border: "1px solid black",
                      background: "#ffc000",
                      paddingLeft: "10px",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{ border: "1px solid black", paddingLeft: "10px" }}
                  >
                    {reccuringPricing.feature_name}
                  </td>
                  <td
                    style={{ border: "1px solid black", paddingLeft: "10px" }}
                  >
                    {reccuringPricing.feature_value}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <h5>3. MONTHLY TOP-UP OF CONVERSATIONS</h5>
        <p>
          Upon the completion of the number of conversations as part of the
          pricing plan, the customer shall be able to do a top-up recharge to
          increase the number of conversations limit of the pricing plan. The
          pricing for the top up recharge shall be mutually agreed upon by
          DolphinChat and the customer
        </p>
        <p>
          <b>PLEASE NOTE</b> – The invoice of the Channel One-Time Pricing Model
          shall be charged after the completion of UAT (User Acceptance Testing)
          and the Chatbot Recurring Pricing Model shall be charged at the start
          of each month/year.
        </p>
        <br />
        <h4>SCHEDULE-III (ESCALATION PATH)</h4>
        <p>
          <b>Priority 1</b> - Critical Problems
        </p>
        <p>
          <b>Agreed Response Time</b>: 4 hours
        </p>
        <table
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: "15px",
            borderCollapse: "collapse",
          }}
        >
          <tbody>
            <tr style={{ color: "#fff", background: "#1f3863" }}>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Case Duration
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Escalation to
              </th>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  background: "#ffc000",
                  paddingLeft: "10px",
                }}
              >
                0 to 4 hours
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                Senior ML Engineer
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  background: "#ffc000",
                  paddingLeft: "10px",
                }}
              >
                4 to 12 hours
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                Chief Technology Officer
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  background: "#ffc000",
                  paddingLeft: "10px",
                }}
              >
                More than 12 hours
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                Chief Operating Officer/ Chief Executive Officer
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          <b>Priority 2</b> - Significant Problems
        </p>
        <p>
          <b>Agreed Response Time</b>: 24 hours
        </p>
        <table
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: "15px",
            borderCollapse: "collapse",
          }}
        >
          <tbody>
            <tr style={{ color: "#fff", background: "#1f3863" }}>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Case Duration
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Escalation to
              </th>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  background: "#ffc000",
                  paddingLeft: "10px",
                }}
              >
                0 to 24 hours
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                Senior ML Engineer
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  background: "#ffc000",
                  paddingLeft: "10px",
                }}
              >
                24 to 36 hours
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                Chief Technology Officer
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  background: "#ffc000",
                  paddingLeft: "10px",
                }}
              >
                More than 36 hours
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                Chief Operating Officer/ Chief Executive Officer
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          <b>Priority 3</b> - Inconvenient Problems
        </p>
        <p>
          <b>Agreed Response Time</b>: 5 working days
        </p>
        <table
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: "15px",
            borderCollapse: "collapse",
          }}
        >
          <tbody>
            <tr style={{ color: "#fff", background: "#1f3863" }}>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Case Duration
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Escalation to
              </th>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  background: "#ffc000",
                  paddingLeft: "10px",
                }}
              >
                0 to 5 days
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                Senior ML Engineer
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  background: "#ffc000",
                  paddingLeft: "10px",
                }}
              >
                5 to 7 days
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                Chief Technology Officer
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  background: "#ffc000",
                  paddingLeft: "10px",
                }}
              >
                More than 7 days
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                Chief Operating Officer/ Chief Executive Officer
              </td>
            </tr>
          </tbody>
        </table>
        <h4>SCHEDULE-IV (CONTACT DETAILS)</h4>
        <table
          style={{
            textAlign: "left",
            width: "100%",
            fontSize: "15px",
            borderCollapse: "collapse",
          }}
        >
          <tbody>
            <tr style={{ color: "#fff", background: "#1f3863" }}>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Designation
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Name
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Contact Number
              </th>
              <th style={{ border: "1px solid black", padding: "5px" }}>
                Email Id
              </th>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  background: "#ffc000",
                  paddingLeft: "10px",
                }}
              >
                Chief Operating Officer
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                Kusha Kohli
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                +91-9311702428
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "5px" }}>
                kusha@dolphinchat.ai
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  background: "#ffc000",
                  paddingLeft: "10px",
                }}
              >
                Chief Executive Officer
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                Ashish Sharma
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                +91-9818889720
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "5px" }}>
                ashish@dolphinchat.ai
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  background: "#ffc000",
                  paddingLeft: "10px",
                }}
              >
                Chief Technology Officer
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                Ashwin Kain
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                +91-8585947937
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "5px" }}>
                ashwin@dolphinchat.ai
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid black",
                  background: "#ffc000",
                  paddingLeft: "10px",
                }}
              >
                Senior Chatbot Developer
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                Talha Sheikh
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "10px" }}>
                +91-7020471479
              </td>
              <td style={{ border: "1px solid black", paddingLeft: "5px" }}>
                Talha@dolphinchat.ai
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <br />
        <br />
        <p>
          IN WITNESS WHEREOF THE PARTIES HERETO HAVE EXECUTED THESE PRESENTS ON
          THE DAY AND YEAR FIRST HEREIN ABOVE WRITTEN IN PRESENCE OF THE
          WITNESSES MENTIONED:
        </p>
        <br />
        <br />
        <br />
        <br />
        <p>Kusha Kohli</p>
        <p>
          Signed and delivered by and on behalf of within Named “M/s Tavroi
          Research Private Limited”
        </p>
        <br />
        <br />
        <br />
        <br />
        <p>And</p>
        <br />
        <br />
        <br />
        <br />
        <p>{props.customerName || "(Customer Name)"}</p>
        <p>
          Signed and delivered by and on behalf of within Named{" "}
          {props.companyName || "(Company Name)"}
        </p>
      </Paper>
    </React.Fragment>
  );
}
