import React from "react";

import { IconButton } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";

import DataTable from "../../../custom components/DataTable";

export default function Table({
  requiredCredentials,
  formikProps,
  modal,
  editState,
  setModal,
}) {
  function handleCellClick(cellData, cellMetaData) {
    var cellValues = requiredCredentials[cellMetaData.dataIndex];
    if (cellMetaData.colIndex !== 6) {
      setModal({
        state: true,
        mode: "edit",
        credentials: {
          field_name: cellValues.field_name,
          label: cellValues.label,
          backend_regex: cellValues.backend_regex,
          frontend_regex: cellValues.frontend_regex,
          type: cellValues.type,
          failure_message: cellValues.failure_message,
        },
        rowIndex: cellMetaData.rowIndex,
      });
    }
  }

  const removeRequiredCredentialsField = (index) => {
    const newRequiredCredentialsValues = [
      ...formikProps.values.requiredCredentials,
    ];
    newRequiredCredentialsValues.splice(index, 1);
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      requiredCredentials: newRequiredCredentialsValues,
    }));
  };

  const options = {
    search: false,
    viewColumns: false,
    selectableRows: "none",
    filter: false,
    download: false,
    print: false,
    pagination: false,
    onCellClick: handleCellClick,
  };

  const columns = [
    {
      name: "field_name",
      label: "Field Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "label",
      label: "Field Label",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "backend_regex",
      label: "Backend Regex",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "frontend_regex",
      label: "Frontend Regex",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "type",
      label: "Field Type",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "failure_message",
      label: "Field Failure Message",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (_, index) => (
          <IconButton
            onClick={() => removeRequiredCredentialsField(index.rowIndex)}
            disabled={!editState}
            style={{ color: editState ? "#ff0000" : "#00000042" }}
            size="small"
          >
            <DeleteOutline fontSize="small" />
          </IconButton>
        ),
      },
    },
  ];

  return (
    <DataTable columns={columns} options={options} data={requiredCredentials} />
  );
}
