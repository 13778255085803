export default function scheduledDate(scheduledDate) {
  var backendDate = scheduledDate.split("-");
  var sortedDate = backendDate[1] + "-" + backendDate[0] + "-" + backendDate[2];
  var date = new Date(sortedDate);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = date.getFullYear();
  var month = months[date.getMonth()];

  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

  var time = day + " " + month + " " + year;

  return time;
}
