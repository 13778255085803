import React from "react";
import { Grid, Typography, Checkbox, Divider } from "@material-ui/core";
import ComponentPermissionRow from "./ComponentPermissionRow";

export default function PagePermissionRow({
  displayPage,
  editState,
  formikProps,
}) {
  var pagePermissions = formikProps.values.pagePermissions;
  var pagePermissionValue = pagePermissions.find(
    (pagePermission) => pagePermission.page_id === displayPage.page_id
  );

  var permissionTypes = ["read", "create", "update", "delete"];

  return (
    <React.Fragment>
      <Grid item xs={6} style={{ textTransform: "capitalize" }}>
        <Typography variant="h6" display="inline">
          {displayPage.page_name}
        </Typography>
      </Grid>

      <Grid item xs={6} container style={{ textAlign: "center" }}>
        {permissionTypes.map((permissionType, index) => (
          <Grid item xs={3} key={index}>
            <Checkbox
              name={permissionType}
              checked={
                pagePermissionValue?.page_permission?.[permissionType] || false
              }
              onChange={handlePermissionsChange}
              disabled={
                !editState ||
                (permissionType !== "read" &&
                  !pagePermissionValue?.page_permission?.read)
              }
            />
          </Grid>
        ))}
      </Grid>

      {pagePermissionValue?.page_permission?.read &&
        displayPage.component.length > 0 &&
        displayPage.component.map((component, index) => (
          <ComponentPermissionRow
            key={index}
            displayComponent={component}
            editState={editState}
            setValues={formikProps.setValues}
            componentPermissions={pagePermissionValue.components}
            pagePermissionValue={pagePermissionValue}
          />
        ))}

      <Grid item xs={12}>
        <Divider style={{ margin: "4px 0px" }} />
      </Grid>
    </React.Fragment>
  );

  function handlePermissionsChange(event) {
    const { name, checked } = event.target;

    formikProps.setValues((prevValue) => ({
      ...prevValue,
      pagePermissions: filterPagePermissions(prevValue.pagePermissions),
    }));

    function filterPagePermissions(pagePermissionsList) {
      var newPagePermissions = [];

      if (pagePermissionValue === undefined) {
        newPagePermissions.push(...pagePermissionsList, {
          page_name: displayPage.page_name,
          page_id: displayPage.page_id,
          page_permission: {
            read: true,
            create: false,
            delete: false,
            update: false,
          },
          components: [],
        });
      } else {
        for (var pagePermission of pagePermissionsList) {
          if (pagePermission.page_id === displayPage.page_id) {
            if (name === "read" && !checked) {
              newPagePermissions.push({
                ...pagePermission,
                page_permission: {
                  read: false,
                  create: false,
                  delete: false,
                  update: false,
                },
                components: [
                  ...pagePermission.components.map((component) => ({
                    ...component,
                    permissions: {
                      read: false,
                      delete: false,
                      create: false,
                      update: false,
                    },
                  })),
                ],
              });
            } else {
              newPagePermissions.push({
                ...pagePermission,
                page_permission: {
                  ...pagePermission.page_permission,
                  [name]: checked,
                },
              });
            }
          } else newPagePermissions.push(pagePermission);
        }
      }

      return newPagePermissions;
    }
  }
}
