export const memberDetailsObject = {
  memberID: "",
  firstName: "",
  lastName: "",
  email: "",
  gender: "",
  groupID: "",
  status: "",
};

export function objectFromResponseData(responseData) {
  return {
    memberID: responseData._id,
    firstName: responseData.first_name,
    lastName: responseData.last_name,
    email: responseData.email,
    gender: responseData.gender,
    groupID: responseData.group_id,
    status: responseData.is_active,
  };
}

export function objectFromFormData(formData) {
  return {
    first_name: formData.firstName,
    last_name: formData.lastName,
    email: formData.email,
    gender: formData.gender,
    group_id: formData.groupID,
    is_active: formData.status,
    email_notification: false,
  };
}
