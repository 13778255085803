import React from "react";

import { Helmet } from "react-helmet-async";
import {
  Paper,
  Typography,
  Divider,
  CssBaseline,
  Grid,
} from "@material-ui/core";
import Form from "./register member components/Form";
import styled from "styled-components";

const Container = styled.div`
  padding: 10px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f9fc;
`;

export default function RegisterMember(props) {
  const authToken = props.match.params.authToken;

  return (
    <Container>
      <CssBaseline />
      <Paper
        elevation={2}
        style={{
          width: "50vw",
          padding: "20px",
        }}
      >
        <Helmet title="Create Account" />

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom>
              Create Account
            </Typography>

            <Typography variant="h6" gutterBottom>
              Please fill in all the fields to register your account with
              DolphinChat.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Form authToken={authToken} />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
