import DataTable from "../../../custom components/DataTable";
import convertDate from "../../../util/convertDate";
import { useHistory } from "react-router-dom";

export default function Table({ members }) {
  const history = useHistory();
  const options = {
    onRowClick: handleRowClick,
    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  function handleRowClick(rowData, rowMetaData) {
    history.push("/member-details/" + members[rowMetaData.dataIndex]._id);
  }

  return <DataTable columns={columns} options={options} data={members} />;
}

const columns = [
  {
    name: "first_name",
    label: "First Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "last_name",
    label: "Last Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "_id",
    label: "Member ID",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
      display: false,
    },
  },
  {
    name: "gender",
    label: "Gender",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "is_active",
    label: "Status",
    options: {
      customBodyRender: (value) => {
        return value ? "Active" : "Inactive";
      },
    },
  },
  {
    name: "last_login",
    label: "Last Login",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
