import DataTable from "../../../custom components/DataTable";
import { IconButton } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";

export default function WebhookEventsTable({
  setModalProps,
  formikProps,
  editState,
}) {
  const options = {
    onCellClick: (_, cellMetaData) => {
      if (cellMetaData.colIndex !== 3 && editState) {
        const webhookEvent =
          formikProps.values.webhookEvents[cellMetaData.rowIndex];

        setModalProps({
          state: true,
          mode: "edit",
          value: {
            name: webhookEvent.event_name,
            notification: webhookEvent.notify,
            templateID: webhookEvent.template_id,
            fields: webhookEvent.fields,
          },
        });
      }
    },
    selectableRows: "none",
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    filter: false,
  };

  const columns = [
    {
      name: "event_name",
      label: "Event Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "fields",
      label: "Required Fields",
      options: {
        customBodyRender: (value) => {
          if (value.length > 0) {
            return value.map((field) => field.field_label).join(", ");
          }

          return "None";
        },
      },
    },
    {
      name: "notify",
      label: "Notifications",
      options: {
        customBodyRender: (value) => {
          return value ? "Enabled" : "Disabled";
        },
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        empty: true,
        customBodyRenderLite: (_, rowIndex) => (
          <IconButton
            disabled={!editState}
            style={{ color: editState ? "#ff0000" : "#00000042" }}
            onClick={() => deleteWebhookEvent(rowIndex)}
          >
            <DeleteOutline />
          </IconButton>
        ),
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      options={options}
      data={formikProps.values.webhookEvents}
    />
  );

  function deleteWebhookEvent(rowIndex) {
    formikProps.setValues((prevValue) => ({
      ...prevValue,
      webhookEvents: prevValue.webhookEvents.filter(
        (_, index) => index !== rowIndex
      ),
    }));
  }
}
