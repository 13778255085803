import styled from "styled-components/macro";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import PermissionDenied from "../../pages/error/PermissionDenied";

const Wrapper = styled(Box)`
  flex: 1;
  padding: 14px;
`;

export default function MainContent({ children }) {
  const { pagePermissions } = useSelector((state) => state.authReducer);
  const pageID = children.props.pageID;

  const permissionFound = pagePermissions.find(
    (pagePermission) => pagePermission.pageID === pageID
  );

  if (!(permissionFound && permissionFound.read)) {
    return <PermissionDenied />;
  }

  return <Wrapper>{children}</Wrapper>;
}
