import * as types from "../../constants/reducerConstants";

var initalState = {
  status: false,
  alertType: "info",
  alertMessage: "",
};

export default function reducer(state = initalState, actions) {
  switch (actions.type) {
    case types.ALERT_OPEN:
      return {
        ...state,
        status: true,
        alertType: actions.alertType,
        alertMessage: actions.alertMessage,
      };

    case types.ALERT_CLOSE:
      return {
        ...state,
        status: false,
      };

    default:
      return state;
  }
}
