import React, { useEffect, useState } from "react";
import Form from "./style whatsapp widget components/Form";
import queryString from "query-string";
import axios from "axios";
import { useHistory } from "react-router";
import {
  configureDetailFromResponseData,
  whatsappDetailObject,
} from "./style whatsapp widget components/whatsappDetailsObject";
import { ENV } from "../../config/config";

export default function StyleWhatsappWidget() {
  const history = useHistory();

  const [configureDetails, setConfigureDetails] =
    useState(whatsappDetailObject);

  const [editState, setEditState] = useState(false);
  const [originalImage, setOriginalImage] = useState("");

  const agentId = queryString.parse(window.location.search).agent_id;
  const token = queryString.parse(window.location.search).token;

  useEffect(() => {
    var config = {
      method: "get",
      url:
        "https://" +
        (ENV === "prod" ? "" : ENV + ".") +
        "api.dolphinchat.ai/bot/v1/check/agent/token/" +
        agentId +
        "?token=" +
        token,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === true) {
          var config = {
            method: "get",
            url:
              "https://" +
              (ENV === "prod" ? "" : ENV + ".") +
              "api.dolphinchat.ai/bot/v1/agent/widget/" +
              agentId,
            headers: {},
          };

          axios(config)
            .then(function (response) {
              if (response.data.status === true) {
                setConfigureDetails(
                  configureDetailFromResponseData(response.data.data)
                );
                setOriginalImage(response.data.data.widget?.pictureCustom);
              }
            })
            .catch(function (error) {
              history.push("/errorPageLoaded");
              console.log(error);
            });
        } else {
          history.push("/errorPageLoaded");
        }
      })
      .catch(function (error) {
        history.push("/errorPageLoaded");
        console.log(error);
      });
  }, [editState, agentId, token, history]);

  return (
    <React.Fragment>
      <Form
        editState={editState}
        setEditState={setEditState}
        token={token}
        agentId={agentId}
        configureDetails={configureDetails}
        originalImage={originalImage}
        setOriginalImage={setOriginalImage}
        setConfigureDetails={setConfigureDetails}
      />
    </React.Fragment>
  );
}
