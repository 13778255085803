import { Formik } from "formik";
import { Grid, Button, Typography } from "@material-ui/core";

import { useAxios, useAlert } from "../../../custom hooks";
import {
  emailComponentObject,
  emailComponentSchema,
} from "./resetPasswordObject";
import { Paper, TextField } from "../../../custom components";

export default function EmailComponent() {
  const { info, error } = useAlert();
  const axios = useAxios({ disableAuthToken: true });

  return (
    <Formik
      validateOnMount
      initialValues={emailComponentObject}
      validationSchema={emailComponentSchema}
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/forgot/password",
          data: { email: formData.email },
          method: "PUT",
        }).then((response) => {
          setSubmitting(false);

          if (response.status) {
            info(
              "Link to reset the password has been sent to your registered ID."
            );
          } else {
            error(response.error);
          }
        });
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <Paper>
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                Reset Password
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center">
                Please enter your email address to reset your password
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                name="email"
                label="Email Address"
                formikProps={formikProps}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={!formikProps.isValid || formikProps.isSubmitting}
              >
                Submit
              </Button>
            </Grid>
          </Paper>
        </form>
      )}
    </Formik>
  );
}
