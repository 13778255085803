import React from "react";

//Icons
import {
  BusinessCenterRounded,
  DashboardRounded,
  AccountCircleRounded,
  NoteTwoTone,
  AccountTreeTwoTone,
  PostAdd,
  WhatsApp,
} from "@material-ui/icons";

import { MdIntegrationInstructions } from "react-icons/md";

// Auth Components
import SignIn from "../pages/auth/SignIn";
import Page404 from "../pages/error/Page404";
import Page500 from "../pages/error/Page500";

// Dashboards Components
import Dashboard from "../pages/dashboard/Dashboard";

//Users Component
import Users from "../pages/users/Users";

//Business Component
import Business from "../pages/business/Businesses";
import BusinessDetails from "../pages/business/BusinessDetails";

//Bot Services Component
import BotServices from "../pages/bot service/BotServices";
import BotServiceDetails from "../pages/bot service/BotServiceDetails";

//Bot Components
import BotDetails from "../pages/bot/BotDetails";
import Bots from "../pages/bot/Bots";

//Conversations Component
import Conversations from "../pages/conversation/Conversations";

//Member Component
import MembersPage from "../pages/member/Members";
import MemberDetails from "../pages/member/MemberDetails";
import ResetPassword from "../pages/auth/ResetPassword";

//Superadmin Pages Components
import SuperadminPages from "../pages/superadmin page/SuperadminPages";
import SuperadminPageDetails from "../pages/superadmin page/SuperadminPageDetails";

//Admin Pages Components
import AdminPages from "../pages/admin page/AdminPages";
import AdminPageDetails from "../pages/admin page/AdminPageDetails";

//Group Components
import Groups from "../pages/group/Groups";
import GroupDetails from "../pages/group/GroupDetails";

//Test Page
import TestPage from "../pages/test page/TestPage";

//Bot Integrations
import BotIntegrations from "../pages/bot integration/BotIntegrations";
import BotIntegrationDetails from "../pages/bot integration/BotIntegrationDetails";

//Integrations Components
import Integrations from "../pages/integration/Integrations";
import IntegrationDetails from "../pages/integration/IntegrationDetails";

//Integration Group Components
import IntegrationGroup from "../pages/integration group/IntegrationGroup";
import IntegrationGroupDetails from "../pages/integration group/IntegrationGroupDetails";

//Utterances Components
import Utterances from "../pages/utterance/Utterances";
import UtteranceDetails from "../pages/utterance/UtteranceDetails";

//Whatsapp Components
import WhatsappNumberList from "../pages/whatsapp number/WhatsappNumberList";
import WhatsappNumberDetailsPage from "../pages/whatsapp number/WhatsappNumberDetailsPage";

import { ENV } from "../config/config";
import pageID from "../constants/pageID";

export const dashBoardRoute = {
  name: "Analytics",
  id: pageID.analyticsPage[ENV],
  icon: <DashboardRounded />,
  path: "/",
  component: Dashboard,
};

export const testRoute = {
  name: "Test Page",
  id: pageID.businessDetailsPage[ENV],
  path: "/test-page/:businessID?",
  component: TestPage,
};

export const businessRoutes = {
  id: "business_route",
  name: "Manage Business",
  icon: <BusinessCenterRounded />,
  children: [
    {
      name: "Businesses",
      id: pageID.businessListPage[ENV],
      path: "/businesses",
      component: Business,
    },
    {
      name: "Bot Services",
      id: pageID.botServiceListPage[ENV],
      path: "/bot-services",
      component: BotServices,
    },
    {
      name: "Bots",
      id: pageID.botListPage[ENV],
      path: "/bots",
      component: Bots,
    },
    {
      name: "Bot Integrations",
      id: pageID.botIntegrationListPage[ENV],
      path: "/bot-integrations",
      component: BotIntegrations,
    },
    {
      name: "Users",
      id: pageID.userListPage[ENV],
      path: "/users",
      component: Users,
    },
    {
      name: "Conversations",
      id: pageID.conversationsPage[ENV],
      path: "/conversations",
      component: Conversations,
    },
  ],
};

export const integrationRoutes = {
  id: "integrations_routes",
  name: "Integrations",
  icon: <MdIntegrationInstructions />,
  children: [
    {
      name: "Integrations",
      id: pageID.integrationListPage[ENV],
      path: "/integrations",
      component: Integrations,
    },
    {
      name: "Integration Groups",
      id: pageID.integrationGroupListPage[ENV],
      path: "/integration-group",
      component: IntegrationGroup,
    },
  ],
};

export const pagesRoutes = {
  id: "pages_routes",
  name: "Pages",
  icon: <PostAdd />,
  children: [
    {
      name: "Superadmin",
      id: pageID.superadminPageListPage[ENV],
      path: "/pages/superadmin/all",
      component: SuperadminPages,
    },
    {
      name: "Admin",
      id: pageID.adminPageListPage[ENV],
      path: "/pages/admin/all",
      component: AdminPages,
    },
  ],
};

export const businessDetailsRoute = {
  name: "Business Details",
  id: pageID.businessDetailsPage[ENV],
  path: "/business-details/:businessID?",
  component: BusinessDetails,
};

export const botServiceDetailsRoute = {
  name: "Bot Service Details",
  id: pageID.botServiceDetailsPage[ENV],
  path: "/bot-service-details/:botServiceID?",
  component: BotServiceDetails,
};

export const botDetailsRoute = {
  name: "Bot Details",
  id: pageID.botDetailsPage[ENV],
  path: "/bot-details/:botID?",
  component: BotDetails,
};

export const membersRoute = {
  name: "Members",
  id: pageID.memberListPage[ENV],
  icon: <AccountCircleRounded />,
  path: "/members",
  component: MembersPage,
};

export const memberDetailsRoute = {
  name: "Member Details",
  id: pageID.memberDetailsPage[ENV],
  path: "/member-details/:memberID?",
  component: MemberDetails,
};

export const superadminPageDetailsRoute = {
  name: "Page Details",
  id: pageID.superadminPageDetailsPage[ENV],
  path: "/pages/superadmin/:pageID?",
  component: SuperadminPageDetails,
};

export const adminPageDetailsRoute = {
  name: "Page Details",
  id: pageID.adminPageDetailsPage[ENV],
  path: "/pages/admin/:pageID?",
  component: AdminPageDetails,
};

export const groupsRoute = {
  name: "Permission Groups",
  id: pageID.groupListPage[ENV],
  icon: <AccountTreeTwoTone />,
  path: "/groups",
  component: Groups,
};

export const groupDetailsRoute = {
  name: "Group Details",
  id: pageID.groupDetailsPage[ENV],
  path: "/group-details/:groupID?",
  component: GroupDetails,
};

export const botIntegrationDetailsRoute = {
  name: "Bot Integration Details",
  id: pageID.botIntegrationDetailsPage[ENV],
  path: "/bot-integration-details/:botIntegrationID?",
  component: BotIntegrationDetails,
};

export const integrationDetailsRoute = {
  name: "Integration Details",
  id: pageID.integrationDetailsPage[ENV],
  path: "/integration-details/:integrationID?",
  component: IntegrationDetails,
};

export const integrationGroupDetailsRoute = {
  name: "Integration Group Details",
  id: pageID.integrationGroupDetailsPage[ENV],
  path: "/integration-group-details/:integrationGroupID?",
  component: IntegrationGroupDetails,
};

const utterancesRoute = {
  name: "Utterances",
  id: pageID.utteranceListPage[ENV],
  path: "/utterances/all",
  icon: <NoteTwoTone />,
  component: Utterances,
};

export const utteranceDetailsRoute = {
  name: "Utterance Details",
  id: pageID.utteranceDetailsPage[ENV],
  path: "/utterances/:utteranceID?",
  component: UtteranceDetails,
};

export const whatsappNumberRoute = {
  name: "Whatsapp Numbers",
  id: pageID.whatsappNumberListPage[ENV],
  path: "/whatsapp-numbers/all",
  icon: <WhatsApp />,
  component: WhatsappNumberList,
};

export const whatsappDetailsRoute = {
  name: "Whatsapp Number Details",
  id: pageID.whatsappNumberDetailsPage[ENV],
  path: "/whatsapp-number-detail/:whatsappNumberID?",
  component: WhatsappNumberDetailsPage,
};

const authRoutes = {
  id: "Auth",
  children: [
    {
      id: "sign_in",
      path: "/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      id: "reset_password",
      path: "/reset-password/:forgotPasswordToken?",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      id: "error_404",
      path: "/404",
      name: "404 Page",
      component: Page404,
    },
    {
      id: "error_500",
      path: "/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashBoardRoute,
  testRoute,
  businessRoutes,
  businessDetailsRoute,
  botServiceDetailsRoute,
  botDetailsRoute,
  membersRoute,
  memberDetailsRoute,
  pagesRoutes,
  superadminPageDetailsRoute,
  adminPageDetailsRoute,
  groupsRoute,
  groupDetailsRoute,
  botIntegrationDetailsRoute,
  integrationRoutes,
  integrationDetailsRoute,
  integrationGroupDetailsRoute,
  utterancesRoute,
  utteranceDetailsRoute,
  whatsappNumberRoute,
  whatsappDetailsRoute,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashBoardRoute,
  businessRoutes,
  integrationRoutes,
  pagesRoutes,
  membersRoute,
  groupsRoute,
  utterancesRoute,
  whatsappNumberRoute,
  whatsappDetailsRoute,
];
