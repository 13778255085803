import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().max(25).required("First Name is required"),
  lastName: Yup.string().max(25).required("Last Name is required"),
  email: Yup.string()
    .email("Enter a valid email")
    .max(255)
    .required("First Name is required"),
  gender: Yup.string().required("Please select a gender"),
  password: Yup.string()
    .matches(
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,20}$",
      "Password must be atleast 8 characters with one Capital letter, one Small letter, a Special character and one Number"
    )
    .required("Please enter a password"),
  confirmPassword: Yup.string()
    .matches(
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,20}$",
      "Password must be atleast 8 characters with one Capital letter, one Small letter, a Special character and one Number"
    )
    .required("Please enter a password")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
});

export default validationSchema;
