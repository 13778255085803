import React from "react";
import { useSelector } from "react-redux";

import { CircularProgress } from "@material-ui/core";
import styled from "styled-components/macro";
import LoadingError from "../pages/error/LoadingError";

const LoaderContainer = styled.div`
  height: 99%;
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function Loader({ children }) {
  const loadingPage = useSelector((state) => state.loaderReducer);

  if (loadingPage.error) {
    return <LoadingError />;
  }

  if (loadingPage.loading) {
    return (
      <LoaderContainer>
        <CircularProgress />
      </LoaderContainer>
    );
  }

  return children;
}
