import React from "react";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { Formik } from "formik";
import useAxios from "../../../custom hooks/useAxios";

import FormDisplay from "./FormDisplay";

import { objectFromFormData, validationSchema } from "./whatsappDetailsObject";
import { alertSuccess, alertError } from "../../../redux/actions/alertActions";

export default function Form({
  formData,
  editState,
  setFormData,
  businessData,
  setEditState,
  setBusinessData,
}) {
  const axios = useAxios();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        var url = "/register_whatsapp_no_details";
        var method = "POST";

        axios({
          url: url,
          method: method,
          data: objectFromFormData(formData),
        })
          .then((response) => {
            setSubmitting(false);
            if (response.status) {
              if (method === "POST") {
                dispatch(alertSuccess("You have been successfully registered"));
                history.push("/whatsapp-number-detail/" + response.data._id);
              } else {
                setEditState(false);
                setFormData(formData);
                dispatch(alertSuccess("WhatsApp detail updated successfully"));
              }
            } else {
              dispatch(alertError(response.message || "Something went wrong"));
            }
          })
          .catch((error) => {
            setSubmitting(false);
            dispatch(alertError(error.message || "Something went wrong"));
          });
      }}
    >
      {(props) => (
        <form noValidate onSubmit={props.handleSubmit}>
          <FormDisplay
            formikProps={props}
            editState={editState}
            businessData={businessData}
            setBusinessData={setBusinessData}
            setEditState={setEditState}
          />
        </form>
      )}
    </Formik>
  );
}
