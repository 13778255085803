import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  pageName: Yup.string()
    .max(50, "Page name too long!")
    .required("Please provide name for page"),
  pageStatus: Yup.bool().required("Please set page status"),
});

export default validationSchema;
