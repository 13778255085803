import React from "react";
import { useAxios, useAlert } from "../../../custom hooks";
import { ENV } from "../../../config/config";

import {
  TextField,
  TextFieldWithCopy,
  Paper,
  ComponentHeader,
  CreatedAndUpdatedDetails,
} from "../../../custom components";
import { Grid, MenuItem, IconButton, Tooltip } from "@material-ui/core";
import { Assignment } from "@material-ui/icons";

export default function FormDisplay({ formikProps, editState, permissions }) {
  const axios = useAxios();
  const alert = useAlert();

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          headerName="Page Details"
          id={formikProps.values.pageID}
          buttons={[
            ENV !== "prod" && (
              <Tooltip title="Migrate Page">
                <IconButton color="primary" onClick={() => migratePage()}>
                  <Assignment />
                </IconButton>
              </Tooltip>
            ),
          ]}
        />
      </Grid>

      {formikProps.values.pageID && (
        <React.Fragment>
          <Grid item xs={6}>
            <TextFieldWithCopy
              required
              label="Page ID"
              name="pageID"
              disabled
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6}></Grid>
        </React.Fragment>
      )}

      <Grid item xs={6}>
        <TextField
          label="Page Name"
          name="pageName"
          required
          disabled={!(editState && permissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          select
          label="Page Status"
          name="pageStatus"
          required
          disabled={!(editState && permissions.update)}
          formikProps={formikProps}
        >
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Inactive</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12}>
        <TextField
          multiline
          rows={5}
          label="Page Description"
          name="pageDescription"
          required
          disabled={!(editState && permissions.update)}
          formikProps={formikProps}
        />
      </Grid>

      {formikProps.values.pageID && (
        <CreatedAndUpdatedDetails values={formikProps.values} />
      )}
    </Paper>
  );

  function migratePage() {
    axios({
      url: "/move/admin/page",
      method: "POST",
      data: {
        page_id: formikProps.values.pageID,
      },
    })
      .then((res) => {
        if (res.status) {
          alert.success(res.message);
        }
      })
      .catch((err) => console.log(err));
  }
}
