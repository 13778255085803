import React, { useState } from "react";

import { Grid, IconButton } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import { ComponentHeader, Paper } from "../../../custom components";
import Table from "../required credentials components/Table";

import RequiredCredentialsModal from "../required credentials components/RequiredCredentialsModal";

export default function RequiredCredentials({
  formikProps,
  editState,
  setEditState,
  permissions,
}) {
  const [modal, setModal] = useState({
    state: false,
    mode: "add",
    credentials: {
      field_name: "",
      label: "",
      backend_regex: "",
      frontend_regex: "",
      type: "",
      failure_message: "",
    },
  });

  const handleAddRequiredCredentialsField = () => {
    setModal({
      state: true,
      credentials: {
        field_name: "",
        label: "",
        backend_regex: "",
        frontend_regex: "",
        type: "",
        failure_message: "",
      },
      mode: "add",
      rowIndex: formikProps.values.requiredCredentials.length,
    });
  };

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          headerName="Required Credentials"
          id={true}
          buttons={[
            <IconButton
              onClick={handleAddRequiredCredentialsField}
              color="primary"
              disabled={!editState}
            >
              <Add />
            </IconButton>,
          ]}
        />
      </Grid>
      {formikProps.values.requiredCredentials?.length !== 0 && (
        <Grid item xs={12}>
          <Table
            requiredCredentials={formikProps.values.requiredCredentials}
            formikProps={formikProps}
            modal={modal}
            setModal={setModal}
            editState={editState}
          />
        </Grid>
      )}
      <RequiredCredentialsModal
        modal={modal}
        formikProps={formikProps}
        setModal={setModal}
        editState={editState}
      />
    </Paper>
  );
}
