import React from "react";
import { useHistory } from "react-router";

import { Button, Typography } from "@material-ui/core";

import styled from "styled-components/macro";
const Wrapper = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default function LoadingError() {
  const history = useHistory();

  return (
    <Wrapper>
      <Typography variant="h1" gutterBottom>
        Error while loading!
      </Typography>

      <Typography variant="body2">
        It seems the data you tried to access could not be loaded.
      </Typography>

      <Typography variant="body2" gutterBottom>
        Please try again later or contact admin for help!
      </Typography>

      <Button
        variant="contained"
        onClick={() => {
          history.goBack();
        }}
        color="primary"
        style={{ marginTop: "10px" }}
      >
        Back
      </Button>
    </Wrapper>
  );
}
