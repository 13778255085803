import React, { useState, useEffect } from "react";
import { usePermissions, useLoader, useAxios } from "../../custom hooks";
import { Loader, PageHeader } from "../../custom components";
import {
  integrationGroupDetailsObject,
  objectFromResponse,
} from "./integration group components/integrationGroupDetailsObject";
import Form from "./integration group components/Form";

export default function IntegrationGroupDetails(props) {
  const axios = useAxios({ baseURL: "component" });
  const loader = useLoader();
  const pagePermissions = usePermissions(props.pageID);

  const [formData, setFormData] = useState(integrationGroupDetailsObject);
  const [integrations, setIntegrations] = useState([]);
  const integrationGroupID = props.match.params.integrationGroupID || "";

  useEffect(() => {
    if (Boolean(integrationGroupID)) {
      loader.start(2);
      axios({
        url: "/get_integration_groups?group_id=" + integrationGroupID,
      })
        .then((response) => {
          if (response.status) {
            loader.apiComplete();
            setFormData(objectFromResponse(response.data));
          }
        })
        .catch((error) => console.log(error));

      axios({
        url: "/get_integrations?group_id=" + integrationGroupID,
      })
        .then((response) => {
          if (response.status) {
            loader.apiComplete();
            setIntegrations(response.data);
          }
        })
        .catch((error) => console.log(error));
    } else {
      loader.apiComplete();
    }
  }, [integrationGroupID, loader, axios]);

  return (
    <Loader>
      <PageHeader
        pageName="Integration Group Details"
        breadcrumbs={[
          { name: "Integration Group", location: "integration-group" },
        ]}
      />
      <Form
        formData={formData}
        setFormData={setFormData}
        integrationGroupID={integrationGroupID}
        integrations={integrations}
        componentPermissions={pagePermissions.components}
      />
    </Loader>
  );
}
