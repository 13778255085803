export default function convertDateToBackendFormat(scheduledDate) {
  var date = new Date(scheduledDate);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  var time = day + "-" + month + "-" + year;

  return time;
}
