import { useRef, useEffect, useCallback } from "react";
import axios from "axios";
import { BASE_URL } from "../config/config";
import { useSelector } from "react-redux";
import { useAlert, useLoader } from ".";

export default function useAxios({
  baseURL = "superadmin",
  customBaseURL = "",
  customAuthToken = "",
  disableAuthToken = false,
} = {}) {
  const loader = useLoader();
  const alert = useAlert();

  const axiosInstance = useRef(axios.create());
  const auth = useSelector((state) => state.authReducer);

  useEffect(() => {
    axiosInstance.current = axios.create({
      headers: {
        ...(!disableAuthToken && {
          Authorization: customAuthToken || auth.authToken || "",
        }),
        "content-type": "application/json",
      },

      ...(Boolean(customBaseURL)
        ? { baseURL: customBaseURL }
        : {
            baseURL: BASE_URL[baseURL].url + BASE_URL[baseURL].version,
          }),

      validateStatus: (status) => status < 500,
    });
  }, [
    auth.authToken,
    baseURL,
    customAuthToken,
    customBaseURL,
    disableAuthToken,
  ]);

  return useCallback(
    ({ url, method = "GET", data, params, disableRedirect = false }) =>
      axiosInstance
        .current({
          url: url,
          method: method,
          data: data,
          params: params,
        })
        .then((response) => {
          if (response.status > 201 || !response.data.status) {
            alert.error(response.data.message || "Error loading data!");

            if (!disableRedirect) {
              loader.error();
            }
          }

          return response.data;
        }),
    [alert, axiosInstance, loader]
  );
}
