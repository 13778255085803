import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  windowHeaderTitle: Yup.string()
    .max(20, "It can be only of 20 letters.")
    .required("Title is required!"),

  windowHeaderCaption: Yup.string().max(30, "It should be of 30 letters only."),

  popupText: Yup.string()
    .max(100, "it should be of 100 letters")
    .required("Popup Text is required!"),

  parentURL: Yup.string()
    .matches(
      /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\\?[a-zA-Z-_%\\/@?]+)*([^\\/\w\\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
      "Enter correct domain name."
    )
    .required("Domain Name is required!"),

  menu: Yup.array().of(
    Yup.object().shape({
      text: Yup.string()
        .max(20, "It should be of 20 letters only")
        .required("This is the required field."),
      type: Yup.string().required("This is the required field."),
      url: Yup.string().when("type", {
        is: (type) => type === "url",
        then: Yup.string()
          .matches(
            /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\\?[a-zA-Z-_%\\/@?]+)*([^\\/\w\\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
            "Enter correct url."
          )
          .required("This is the required field."),
      }),
      payload: Yup.string().when("type", {
        is: (type) => type === "postback",
        then: Yup.string()
          .max(40, "This should be less than 40 letters")
          .required("This is the required field."),
      }),
    })
  ),
});

export const validationSchemaForModal = Yup.object().shape({
  text: Yup.string()
    .max(10, "It should be of 10 letters only")
    .required("Either delete or fill in the name"),
});
