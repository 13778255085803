import React from "react";

import { Button, Grid, CardMedia, Link } from "@material-ui/core";

import TextField from "../../../custom components/TextField";
import Logo from "../../../assets/img/logo/Login Logo.png";

export default function FormDisplay({ formikProps }) {
  return (
    <React.Fragment>
      <Grid container spacing={5}>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <CardMedia
            style={{
              marginRight: "10px",
              paddingTop: "90px",
              backgroundSize: "400px 98px",
            }}
            image={Logo}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="email"
            name="email"
            label="Email Address"
            case="none"
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="password"
            name="password"
            label="Password"
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <Link
            style={{ cursor: "pointer" }}
            href="/reset-password"
            color="primary"
          >
            Forgot password?
          </Link>
        </Grid>
        <Grid item xs />
        <Grid item xs={3}>
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            disabled={formikProps.isSubmitting || !formikProps.isValid}
          >
            Sign in
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
