import * as types from "../../constants/reducerConstants";
import getPagePermission from "../../util/getPagePermissions";

export function signIn(token, userDetails, pagePermissions) {
  return {
    type: types.AUTH_SIGN_IN_SUCCESS,
    authToken: token,
    userDetails: userDetails,
    pagePermissions: pagePermissions.map((pagePermission) =>
      getPagePermission(pagePermission)
    ),
  };
}

export function signOut() {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
    });
  };
}
