import { useHistory } from "react-router";
import convertDate from "../../../util/convertDate";
import DataTable from "../../../custom components/DataTable";

export default function Table({ integrations }) {
  const history = useHistory();

  const options = {
    onRowClick: (_, cellMetaData) => {
      history.push(
        "/integration-details/" + integrations[cellMetaData.dataIndex]._id
      );
    },
    sortOrder: {
      name: "created_on",
      direction: "desc",
    },
  };

  return <DataTable columns={columns} options={options} data={integrations} />;
}

const columns = [
  {
    name: "name",
    label: "Integration Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "_id",
    label: "Integration ID",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
      display: false,
    },
  },
  {
    name: "integration_group_name",
    label: "Integration Group",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "required_credentials_count",
    label: "Required Credentials Count",
    options: {
      customBodyRender: (value) => {
        return value ? value : "0";
      },
    },
  },
  {
    name: "webhook_integration_available",
    label: "Webhook Integration Status",
    options: {
      customBodyRender: (value) => {
        return value ? "Available" : "Unavailable";
      },
    },
  },
  {
    name: "created_by",
    label: "Created By",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "is_active",
    label: "Integration Status",
    options: {
      customBodyRender: (value) => {
        return value ? "Active" : "Inactive";
      },
      display: false,
    },
  },
  {
    name: "created_on",
    label: "Created At",
    options: {
      customBodyRender: (value) => {
        return value ? convertDate(value) : "Not Available";
      },
    },
  },
];
