import convertDate from "../../../util/convertDate";
import { titleCase } from "change-case-all";

export const botIntegrationDetailsObject = {
  ID: "",
  botIntegrationName: "",
  businessID: "",
  botID: "",
  botAccessToken: "",
  integrationGroupID: "",
  integrationID: "",
  integrationName: "",
  testMode: false,
  phoneNumbers: [],
  requiredCredentials: {},
  webhookIntegrations: true,
  webhookEvents: [],
  integrationStatus: true,
  iconURL: "",
  createdBy: "",
  updatedBy: "",
  createdOn: "",
  updatedOn: "",
};

export function objectFromResponse(responseData) {
  return {
    ID: responseData._id,
    botIntegrationName: responseData.bot_integration_name || "",
    businessID: responseData.business_id || "",
    botID: responseData.agent_id || "",
    botAccessToken: responseData.access_token || "",
    integrationGroupID: responseData.integration_group_id || "",
    integrationID: responseData.integration_id || "",
    integrationName: responseData.integration_name || "",
    integrationStatus: responseData.is_active || false,
    testMode: responseData.test_mode || false,
    phoneNumbers: responseData.default_phone_no || [],
    requiredCredentials: responseData.credentials || {},
    webhookIntegrations: responseData.webhook_enabled || false,
    webhookEvents: responseData.webhook_event || [],
    createdBy: responseData.created_by || "",
    updatedBy: responseData.updated_by || "",
    createdOn: convertDate(responseData.created_on) || "",
    updatedOn: convertDate(responseData.updated_on) || "",
  };
}

export function objectFromFormData(formData) {
  return {
    bot_integration_name: titleCase(formData.botIntegrationName),
    business_id: formData.businessID,
    agent_id: formData.botID,
    access_token: formData.botAccessToken,
    integration_id: formData.integrationID,
    integration_group_id: formData.integrationGroupID,
    integration_name: formData.integrationName,
    is_active: formData.integrationStatus,
    test_mode: formData.testMode,
    default_phone_no: formData.phoneNumbers,
    credentials: formData.requiredCredentials,
    webhook_enabled: formData.webhookIntegrations,
    webhook_event: formData.webhookEvents,
  };
}
