import React from "react";
import { ENV } from "../../../config/config";
import { Grid, Tooltip, MenuItem, Button } from "@material-ui/core";

import {
  Paper,
  FormHeader,
  TextField,
  TextFieldWithCopy,
} from "../../../custom components";

import { MenuBook } from "@material-ui/icons";

export default function FormDisplay({
  formikProps,
  editState,
  setEditState,
  permissions,
  planList,
}) {
  const pageMode = Boolean(formikProps.values.businessID) ? "edit" : "new";

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <FormHeader
            formName="Business Details"
            editIcon={Boolean(pageMode === "edit" && permissions.update)}
            setEditState={setEditState}
            handleReset={formikProps.handleReset}
          />
        </Grid>

        {Boolean(formikProps.values.businessID) && (
          <React.Fragment>
            <Grid item xs={6}>
              <TextFieldWithCopy
                label="Business ID"
                name="businessID"
                disabled
                formikProps={formikProps}
              />
            </Grid>
            <Grid
              item
              xs={6}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <Tooltip title="Business Agreement">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    window.open(
                      "https://" +
                        (ENV === "prod" ? "" : ENV + ".") +
                        "superadmin.dolphinchat.ai/contract/admin/" +
                        formikProps.values.businessID,
                      "_blank"
                    )
                  }
                  endIcon={<MenuBook />}
                >
                  Show Agreement
                </Button>
              </Tooltip>
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={4}>
          <TextField
            name="businessName"
            label="Business name"
            required
            disabled={!(editState && permissions.update)}
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="adminEmail"
            label="Admin Email"
            required
            disabled={!(pageMode === "new" && permissions.update)}
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            name="businessStatus"
            label="Business Status"
            required
            disabled={!(editState && permissions.update)}
            formikProps={formikProps}
          >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            name="planID"
            required
            label="Business Plan Type"
            disabled={!(editState && permissions.update)}
            formikProps={formikProps}
          >
            {planList.map((plan) => (
              <MenuItem key={plan._id} value={plan._id}>
                {plan.plan_type}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            name="tenure"
            label="Payment Plan"
            required
            disabled={!(editState && permissions.update)}
            formikProps={formikProps}
          >
            <MenuItem value="monthly">Monthly</MenuItem>
            <MenuItem value="half_yearly">Half Yearly</MenuItem>
            <MenuItem value="quaterly">Quaterly</MenuItem>
            <MenuItem value="yearly">Yearly</MenuItem>
          </TextField>
        </Grid>
        {pageMode === "edit" && (
          <Grid item xs={4}>
            <TextField
              name="creatorEmail"
              label="Creator Email ID"
              disabled
              formikProps={formikProps}
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <TextField
            type="date"
            name="startDate"
            label="Start Date"
            required
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!(editState && permissions.update)}
            formikProps={formikProps}
          />
        </Grid>
        {pageMode === "edit" && (
          <React.Fragment>
            <Grid item xs={4}>
              <TextField
                name="createdAt"
                label="Created At"
                disabled
                formikProps={formikProps}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="updatedAt"
                label="Updated At"
                disabled
                formikProps={formikProps}
              />
            </Grid>
          </React.Fragment>
        )}
      </Paper>
      <Paper>
        <Grid item xs={12}>
          <FormHeader
            formName="Address Details"
            editIcon={Boolean(pageMode === "edit" && permissions.update)}
            setEditState={setEditState}
            handleReset={formikProps.handleReset}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            name="address"
            label="Address"
            required
            disabled={!(editState && permissions.update)}
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="pincode"
            label="Pincode"
            required
            disabled={!(editState && permissions.update)}
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="city"
            label="City"
            required
            disabled={!(editState && permissions.update)}
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="state"
            label="State"
            required
            disabled={!(editState && permissions.update)}
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="country"
            label="Country"
            required
            disabled={!(editState && permissions.update)}
            formikProps={formikProps}
          />
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
