import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import EmailComponent from "./reset password components/EmailComponent";
import PasswordComponent from "./reset password components/PasswordComponent";

function ResetPassword() {
  const { forgotPasswordToken } = useParams();

  return (
    <React.Fragment>
      <Helmet title="Reset Password" />

      {!forgotPasswordToken ? (
        <EmailComponent />
      ) : (
        <PasswordComponent forgotPasswordToken={forgotPasswordToken} />
      )}
    </React.Fragment>
  );
}

export default ResetPassword;
