import React from "react";

import { Info } from "@material-ui/icons";

import {
  Grid,
  IconButton,
  Tooltip,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";

import {
  ComponentHeader,
  Paper,
  TextField,
  TextFieldWithCopy,
} from "../../../../custom components";

export default function FormDisplay({
  formikProps,
  groupID,
  editState,
  setEditState,
  authToken,
}) {
  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <ComponentHeader headerName="Group Details" />
        </Grid>
        <Grid item xs={6}>
          <TextFieldWithCopy
            required
            label="Group ID"
            name="ID"
            disabled
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            disabled={!editState}
            label="Group Name"
            name="groupName"
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            required
            disabled={!editState}
            label="Group Status"
            name="groupStatus"
            formikProps={formikProps}
          >
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Total Group Member"
            name="totalUsers"
            disabled
            formikProps={formikProps}
            style={{ padding: "0px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Total number of users existing in the group.">
                    <IconButton style={{ color: "#cbcbcb" }}>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Created By"
            name="createdBy"
            disabled
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Created At"
            name="createdAt"
            disabled
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Updated By"
            name="updatedBy"
            disabled
            formikProps={formikProps}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Updated At"
            name="updatedAt"
            disabled
            formikProps={formikProps}
          />
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
