import { Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { DataTable, Paper, FormHeader } from "../../../custom components";

export default function IntegrationTable({ integrations }) {
  const history = useHistory();
  const options = {
    search: false,
    viewColumns: false,
    selectableRows: "none",
    filter: false,
    download: false,
    print: false,
    pagination: false,
    onRowClick: (cellData, cellMetaData) => {
      history.push(
        "/integration-details/" + integrations[cellMetaData.dataIndex]._id
      );
    },
  };

  if (integrations.length === 0) {
    return "";
  }

  return (
    <Paper>
      <Grid item xs={12}>
        <FormHeader formName="Integrations" />
      </Grid>

      <Grid item xs={12}>
        <DataTable columns={columns} options={options} data={integrations} />;
      </Grid>
    </Paper>
  );
}

const columns = [
  {
    name: "_id",
    label: "Integration ID",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "name",
    label: "Integration Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
  {
    name: "integration_group_name",
    label: "Integration Group Name",
    options: {
      customBodyRender: (value) => {
        return value ? value : "Not Available";
      },
    },
  },
];
